import { forwardRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";

interface Props {
    id: string
    onChangeText?: (e: string) => void
    onChangePosition?: (e: number) => void
    value: string
    placeholder?: string
    label?: string
}


export const EditorInput = forwardRef<ReactQuill, Props>(
    ({ onChangeText, onChangePosition, value, placeholder, label, id }, ref) => {

        const modules = {
            toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: ["right", "center", "justify"] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
            ],

        };

        const formats = [
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "link",
            "color",
            "background",
            "align"
        ];

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "6px",
                    width: "100%",
                }}
            >
                {label && <span className="input-label">{label}</span>}
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={value}
                    ref={ref}
                    onChange={(e, v, source, editor) => {
                        onChangeText && onChangeText(e);
                    }}
                    className="bg-white w-full h-[122px]"
                    onChangeSelection={(s, v, e) => {
                        onChangePosition && onChangePosition(s?.index!);
                    }}
                />
            </div>
        );
    }
);

export default EditorInput;