import { useEffect } from "react";
import { NumericFormat } from "react-number-format";
import { keycloak } from "../../../lib/keycloak";
import { roleDepartment } from "../../../lib/atecos/dto";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { NewParticularity } from "../../particularities/newParticularity";
import { setShowNewParticularityModal } from "../../particularities/slice";
import { setEmailsHistoryFilters } from "../../projects/mails/slice";
import {
  setEditFirstInputEmployeesNumber,
  setEditFirstInputRevenue,
  setEditSecondInputEmployeesNumber,
  setEditSecondInputRevenue,
  setEditSignEmployeesNumber,
  setEditSignRevenue,
  setEditTargetListRequestAtecos,
  setEditTargetListRequestCommercials,
  setEditTargetListRequestDepartments,
  setEditTargetListRequestEmployeesNumber,
  setEditTargetListRequestName,
  setEditTargetListRequestParticularity,
  setEditTargetListRequestRevenue,
  setEditTargetListRequestRoles,
  setFindTargetListStatus,
} from "../../targetList/slice";
import { AtecosSegment } from "./atecos copy";
import SelectParticularities from "./selectParticularities";

export function Form() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListState = useAppSelector((state) => state.targetList);
  const contactsState = useAppSelector((state) => state.contacts);
  const usersState = useAppSelector(state => state.users)
  const mailsState = useAppSelector(state => state.mails)
  const dispatch = useAppDispatch();

  const signMap = new Map<("=" | "<" | ">" | "><"), string>([
    ["=", "Uguale a"],
    ["<", "Minore di"],
    [">", "Maggiore di"],
    ["><", "Compreso tra"],
  ]);

  useEffect(() => {
    dispatch(setEmailsHistoryFilters({
      ...mailsState.emailsHistoryFilters,
      fromEmail: targetListState.findAllTargetListsResponse?.data.find(item =>
        item.project.id === targetListState.findTargetListResponse?.projectId)?.project.emailFrom
    }
    )
    )
  }, [targetListState.findTargetListResponse])

  useEffect(() => {
    if (targetListState.signRevenue === undefined) {
      dispatch(setEditFirstInputRevenue(undefined));
      dispatch(setEditSecondInputRevenue(undefined));
    } else if (targetListState.signRevenue !== "><") dispatch(setEditSecondInputRevenue(undefined))
    dispatch(
      setEditTargetListRequestRevenue(
        targetListState.signRevenue + ":" + targetListState.firstInputRevenue + ":" + targetListState.secondInputRevenue
      )
    );
  }, [targetListState.signRevenue, targetListState.firstInputRevenue, targetListState.secondInputRevenue]);

  useEffect(() => {
    if (targetListState.signEmployeesNumber === undefined) {
      dispatch(setEditFirstInputEmployeesNumber(undefined));
      dispatch(setEditSecondInputEmployeesNumber(undefined));
    } else if (targetListState.signEmployeesNumber !== "><")
      dispatch(setEditSecondInputEmployeesNumber(undefined));

    dispatch(
      setEditTargetListRequestEmployeesNumber(
        targetListState.signEmployeesNumber +
        ":" +
        targetListState.firstInputEmployeesNumber +
        ":" +
        targetListState.secondInputEmployeesNumber
      )
    );
  }, [
    targetListState.signEmployeesNumber,
    targetListState.firstInputEmployeesNumber,
    targetListState.secondInputEmployeesNumber,
  ]);

  const disabled = contactsState.getCounterResponse && contactsState.getCounterResponse.some(section => (section.dto.inList.total + section.dto.targetListBin.total) > 0)

  useEffect(() => {
    if (targetListState.findTargetListStatus === 'successfully' && targetListState.findTargetListResponse !== undefined) {
      dispatch(setFindTargetListStatus('idle'))
      dispatch(setEditTargetListRequestName(targetListState.findTargetListResponse.name))
      dispatch(setEditTargetListRequestDepartments(targetListState.findTargetListResponse.departments))
      dispatch(setEditTargetListRequestRoles(targetListState.findTargetListResponse.roles))
      dispatch(setEditTargetListRequestAtecos(targetListState.findTargetListResponse.atecos))
      dispatch(setEditTargetListRequestCommercials(targetListState.findTargetListResponse.commercials.map(commercial => commercial.id)))
      dispatch(setEditTargetListRequestParticularity(targetListState.findTargetListResponse.particularity?.id))
      dispatch(setEditTargetListRequestRevenue(targetListState.findTargetListResponse.revenue))
      if (targetListState.findTargetListResponse.revenue.split(':')[0] !== "undefined")
        dispatch(setEditSignRevenue(targetListState.findTargetListResponse.revenue.split(':')[0] as "=" | "<" | ">" | "><"))
      dispatch(setEditFirstInputRevenue(Number(targetListState.findTargetListResponse.revenue.split(':')[1])))
      dispatch(setEditSecondInputRevenue(Number(targetListState.findTargetListResponse.revenue.split(':')[2])))
      dispatch(setEditTargetListRequestEmployeesNumber(targetListState.findTargetListResponse.employeesNumber))
      if (targetListState.findTargetListResponse.employeesNumber.split(':')[0] !== "undefined")
        dispatch(setEditSignEmployeesNumber(targetListState.findTargetListResponse.employeesNumber.split(':')[0] as "=" | "<" | ">" | "><"))
      dispatch(setEditFirstInputEmployeesNumber(Number(targetListState.findTargetListResponse.employeesNumber.split(':')[1])))
      dispatch(setEditSecondInputEmployeesNumber(Number(targetListState.findTargetListResponse.employeesNumber.split(':')[2])))
    }
  }, [targetListState.findTargetListStatus])

  return (
    <div className="flex flex-col gap-6 p-4">
      <div className="flex gap-4">
        <Input
          label="Nome del Target"
          placeholder="Nome lista target"
          disabled={disabled}
          error={targetListState.validateEditTargetList.errors.name}
          supportingText={
            targetListState.validateEditTargetList.errors.name
              ? "Inserire il nome della lista target."
              : ""
          }
          value={targetListState.editTargetListRequest.name}
          defaultValue={targetListState.editTargetListRequest.name}
          onChangeText={(e) => dispatch(setEditTargetListRequestName(e))}
        />
        <SelectCustom
          isMulti
          disabled={disabled}
          placeholder={"Indifferente"}
          options={groupBy(roleDepartment, "department").map((department) => ({
            label: department.key,
            value: department.key,
          }))}
          label="Reparti"
          defaultValue={targetListState.editTargetListRequest.departments}
          onChange={(e) => dispatch(setEditTargetListRequestDepartments(e))}
        />
        <SelectCustom
          isMulti
          placeholder={"Indifferente"}
          disabled={disabled}
          options={groupBy(
            roleDepartment.filter((row) =>
              targetListState.editTargetListRequest?.departments.includes(
                row.department
              )
            ),
            "role"
          ).map((department) => ({
            label: department.key,
            value: department.key,
          }))}
          label="Ruoli"
          defaultValue={targetListState.editTargetListRequest?.roles}
          onChange={(e) => dispatch(setEditTargetListRequestRoles(e))}
        />
      </div>
      <AtecosSegment />
      <div className="flex items-start gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isMulti
            placeholder={"Seleziona i commerciali"}
            error={targetListState.validateEditTargetList.errors.commercials}
            errorLabel="Selezionare almeno un commerciale."
            options={
              projectsState.findProjectResponse?.commercials.map(
                (commercial) => ({
                  label: commercial.name + " " + commercial.surname,
                  value: commercial.id,
                })
              )!
            }
            label="Assegna ai commerciali"
            defaultValue={targetListState.editTargetListRequest.commercialIds}
            onChange={(e) => dispatch(setEditTargetListRequestCommercials(e))}
            disabled={!usersState.findMeResponse?.isAutonomous && keycloak.hasRealmRole("commercial")}
          />
        </div>
        <div className="flex gap-4 justify-between items-end" style={{ width: "calc((100% - 32px) / 3 * 2)" }}>
          <div
            style={{ width: "calc((100% - 16px) / 2)" }}
          >
            <SelectParticularities />
          </div>
          {
            !disabled &&
            <span className="text-text-sm w-f text-neutral-600">
              La particolarità che stai cercando non è presente in elenco?
              <br />
              <span
                onClick={() => dispatch(setShowNewParticularityModal(true))}
                className="cursor-pointer font-semibold text-brandPrimary-600"
              >
                Creane una nuova!
              </span>
            </span>
          }
        </div>
      </div>
      <div className="flex gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isClearable
            onChange={(e) =>
              dispatch(setEditSignRevenue(e as "=" | "<" | ">" | "><"))
            }
            disabled={disabled}
            placeholder={"Indifferente"}
            options={[
              { label: "Uguale a", value: "=" },
              { label: "Minore di", value: "<" },
              { label: "Maggiore di", value: ">" },
              { label: "Compreso tra", value: "><" },
            ]}
            label="Fatturato"
            defaultValue={
              targetListState.signRevenue
            }
          />
        </div>
        {targetListState.signRevenue !== undefined && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              prefix="€ "
              value={targetListState.firstInputRevenue}
              thousandSeparator="."
              decimalSeparator=","
              onValueChange={(e) => {
                dispatch(setEditFirstInputRevenue(e.floatValue));
              }}
              decimalScale={2}
              disabled={disabled}
              fixedDecimalScale
              label={signMap.get(
                targetListState.signRevenue
              )}
              error={targetListState.validateEditTargetList.errors.revenueFirst}
              supportingText={
                targetListState.validateEditTargetList.errors.revenueFirst
                  ? "Inserire il fatturato"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
        {targetListState.signRevenue === "><" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              prefix="€ "
              value={
                targetListState.secondInputRevenue
              }
              thousandSeparator="."
              onValueChange={(e) => {
                dispatch(setEditSecondInputRevenue(e.floatValue));
              }}
              decimalSeparator=","
              decimalScale={2}
              disabled={disabled}
              fixedDecimalScale
              label={signMap.get(targetListState.signRevenue)}
              error={targetListState.validateEditTargetList.errors.revenueSecond}
              supportingText={
                targetListState.validateEditTargetList.errors.revenueSecond
                  ? "Inserire il fatturato"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
      </div>
      <div className="flex gap-4 w-full">
        <div style={{ width: "calc((100% - 32px) / 3)" }}>
          <SelectCustom
            isClearable
            disabled={disabled}
            placeholder={"Indifferente"}
            options={[
              { label: "Uguale a", value: "=" },
              { label: "Minore di", value: "<" },
              { label: "Maggiore di", value: ">" },
              { label: "Compreso tra", value: "><" },
            ]}
            label="Numero di dipendenti"
            onChange={e => dispatch(setEditSignEmployeesNumber(e as "=" | "<" | ">" | "><"))}
            defaultValue={
              targetListState.signEmployeesNumber
            }
          />
        </div>
        {targetListState.signEmployeesNumber !== undefined && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              value={
                targetListState.firstInputEmployeesNumber
              }
              onValueChange={(e) => {
                dispatch(setEditFirstInputEmployeesNumber(e.floatValue));
              }}
              thousandSeparator="."
              disabled={disabled}
              decimalSeparator=","
              label={signMap.get(targetListState.signEmployeesNumber)}
              error={
                targetListState.validateEditTargetList.errors.employeesFirst
              }
              supportingText={
                targetListState.validateEditTargetList.errors.employeesFirst
                  ? "Inserire il numero di dipendenti"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
        {targetListState.signEmployeesNumber === "><" && (
          <div style={{ width: "calc((100% - 32px) / 3)" }}>
            <NumericFormat
              value={targetListState.secondInputEmployeesNumber}
              thousandSeparator="."
              decimalSeparator=","
              disabled={disabled}
              label={signMap.get(targetListState.signEmployeesNumber)}
              onValueChange={(e) => {
                dispatch(setEditSecondInputEmployeesNumber(e.floatValue));
              }}
              error={
                targetListState.validateEditTargetList.errors.employeesSecond
              }
              supportingText={
                targetListState.validateEditTargetList.errors.employeesSecond
                  ? "Inserire il numero di dipendenti"
                  : ""
              }
              customInput={Input}
              id={"company-to-confirm-price"}
              key={"company-to-confirm-price"}
            />
          </div>
        )}
      </div>
      <NewParticularity />
    </div>
  );
}
