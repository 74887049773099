import {
  useAppSelector
} from "../../../../lib/redux/hooks";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../lib/filters/dto";
import { NotFound } from "../../../../ui/organisms/notFound";
import { Pagination } from "../../../../ui/organisms/pagination/index";
import { } from "../slice";
import { PeopleTable } from "./table";

interface Props {
  filters: FindAllContactsFilters
  updateFilters: (value: Partial<FindAllContactsFilters>) => void
  setPage: (page: number) => void
  contacts: FindContactsDatabaseResponse<ContactType.PERSON>
}

export function PeopleList(props: Props) {
  const { filters, updateFilters, setPage, contacts } = props
  const peopleState = useAppSelector((state) => state.people);

  return (
    <div className="flex-grow w-full" style={{ height: 'calc(100% - 63.8px)' }}>
      {contacts.data.length === 0 ||
        contacts.data === null ? (
        <div style={{ height: 'calc(100% - 64px - 60px)' }}>
          <NotFound />
        </div>
      ) : (
        <PeopleTable contacts={contacts} filters={filters} updateFilters={(value) => updateFilters(value)} />
      )}
      {
        !(contacts.data.length === 0 ||
          contacts.data === null) &&
        <Pagination
          itemsPerPage={filters.itemsPerPage}
          setItemsPerPage={value => updateFilters({ itemsPerPage: value })}
          found={contacts.total || 0}
          key={"pagination-people-bottom"}
          pages={contacts.totalPage || 0}
          currentPage={contacts.page || 0}
          setPage={(page) => {
            setPage(page)
          }}
        />
      }
    </div>
  );
}
