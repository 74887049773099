import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FindAllProjectsResponse,
  ProjectStateEnum,
} from "../../pages/projects/dto";
import { NewProjectsService } from "../../pages/projects/service";
import {
  FindAllTargetListsResponse,
  TargetListStatusEnum,
} from "../../pages/targetList/dto";
import { NewTargetListsService } from "../../pages/targetList/service";
import { PromiseStatuses } from "../types";
import { AtecoDTO } from "./dto";
import { NewAtecosService } from "./service";

interface AtecosSlice {
  findAllAtecosResponse: AtecoDTO[];
  findAllTargetListSuspendedResponse?: FindAllTargetListsResponse;
  findAllTargetListSuspendedStatus: PromiseStatuses;
  findAllProjectSuspendedResponse?: FindAllProjectsResponse;
  findAllProjectSuspendedStatus: PromiseStatuses;
  findAllAtecosStatus: PromiseStatuses;
}

const initialState: AtecosSlice = {
  findAllAtecosResponse: [],
  findAllAtecosStatus: "idle",
  findAllTargetListSuspendedStatus: "idle",
  findAllProjectSuspendedStatus: "idle",
};

export const findAllTargetListSuspended = createAsyncThunk(
  "atecos/findAllTargetListSuspended",
  async (): Promise<FindAllTargetListsResponse> => {
    const TargetListService = NewTargetListsService();

    return TargetListService.findAllTargetLists({
      commercial: [],
      contacts: "",
      itemsPerPage: 500,
      name: "",
      project: [],
      order: false,
      page: 0,
      sector: [],
      sort: "id",
      state: [TargetListStatusEnum.SUSPENDED],
      teamLeader: [],
      category: []
    });
  }
);

export const findAllProjectSuspended = createAsyncThunk(
  "atecos/findAllProjectSuspended",
  async (): Promise<FindAllProjectsResponse> => {
    const newProjectsService = NewProjectsService();

    return newProjectsService.findAllProjects({
      state: [ProjectStateEnum.SUSPENDED],
      commercial: [],
      name: "",
      sector: [],
      startDate: [],
      category: [],
      order: false,
      //@ts-ignore
      itemsPerPage: 0,
      page: 0,
    });
  }
);

export const findAllAtecos = createAsyncThunk(
  "atecos/findAllAtecos",
  async (_, thunkApi): Promise<AtecoDTO[]> => {
    const AtecosService = NewAtecosService();

    return AtecosService.findAllAtecos();
  }
);

const atecosSlice = createSlice({
  name: "atecos/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(findAllAtecos.pending, (state) => {
        state.findAllAtecosStatus = "loading";
      })
      .addCase(findAllAtecos.fulfilled, (state, action) => {
        state.findAllAtecosStatus = "successfully";
        state.findAllAtecosResponse = action.payload;
      })
      .addCase(findAllAtecos.rejected, (state) => {
        state.findAllAtecosStatus = "failed";
      })
      .addCase(findAllTargetListSuspended.pending, (state) => {
        state.findAllTargetListSuspendedStatus = "loading";
      })
      .addCase(findAllTargetListSuspended.fulfilled, (state, action) => {
        state.findAllTargetListSuspendedStatus = "successfully";
        state.findAllTargetListSuspendedResponse = action.payload;
      })
      .addCase(findAllTargetListSuspended.rejected, (state) => {
        state.findAllTargetListSuspendedStatus = "failed";
      })
      .addCase(findAllProjectSuspended.pending, (state) => {
        state.findAllProjectSuspendedStatus = "loading";
      })
      .addCase(findAllProjectSuspended.fulfilled, (state, action) => {
        state.findAllProjectSuspendedStatus = "successfully";
        state.findAllProjectSuspendedResponse = action.payload;
      })
      .addCase(findAllProjectSuspended.rejected, (state) => {
        state.findAllProjectSuspendedStatus = "failed";
      });
  },
  reducers: {
    setFindAllTargetListsSuspendedStatus: (state, action) => {
      state.findAllTargetListSuspendedStatus = action.payload;
    },
    setFindAllProjectsSuspendedStatus: (state, action) => {
      state.findAllProjectSuspendedStatus = action.payload;
    },
  },
});

export const {
  setFindAllProjectsSuspendedStatus,
  setFindAllTargetListsSuspendedStatus,
} = atecosSlice.actions;

export default atecosSlice.reducer;
