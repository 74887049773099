import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Pagination } from "../../ui/organisms/pagination";
import {
  deleteParticularity,
  findAllParticularities,
  setDeleteParticularityStatus,
  setFindAllParticularitiesItemsPerPage,
  setFindAllParticularitiesPage
} from "./slice";
import { ParticularityTable } from "./table";

export function ParticularitiesList() {
  const particularitiesState = useAppSelector((state) => state.particularities);
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (
      particularitiesState.deleteParticularityStatus === "failed" ||
      particularitiesState.deleteParticularityStatus === "successfully"
    ) {
      setIdToDelete(undefined);
      if (particularitiesState.deleteParticularityStatus === "successfully") {
        dispatch(
          findAllParticularities({
            itemsPerPage: particularitiesState.filters.itemsPerPage,
            order: true,
            page: 0,
            sort: "name",
            name: ''
          })
        );
      }
      dispatch(setDeleteParticularityStatus("idle"));
    }
  }, [particularitiesState.deleteParticularityStatus]);

  return (
    <div className="w-[100%] flex flex-col" style={{ height: 'calc(100% - 64px)' }}>
      <ParticularityTable setIdToDelete={function (arg?: string): void {
        setIdToDelete(arg)
      }} />
      <Pagination
        itemsPerPage={particularitiesState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllParticularitiesItemsPerPage(value))}
        found={particularitiesState.findAllResponse?.total || 0}
        pages={particularitiesState.findAllResponse?.totalPage || 1}
        currentPage={particularitiesState.filters.page}
        setPage={(page) => dispatch(setFindAllParticularitiesPage(page))}
      />
      <ConfirmModal
        title={"Elimina particolarità"}
        label={"Sei sicuro di voler eliminare questa particolarità?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteParticularity(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
