import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../lib/filters/dto";
import {
  useAppDispatch
} from "../../../../lib/redux/hooks";
import { NotFound } from "../../../../ui/organisms/notFound";
import { Pagination } from "../../../../ui/organisms/pagination/index";
import { CompaniesTable } from "./table";

interface Props {
  contacts: FindContactsDatabaseResponse<ContactType.COMPANY>
  filters: FindAllContactsFilters
  setFilters: (newFilters: Partial<FindAllContactsFilters>) => void
  changePage: (page: number) => void
}

export function CompaniesList(props: Props) {
  const { contacts, filters, changePage, setFilters } = props

  const dispatch = useAppDispatch();

  return (
    <div style={{ height: '100%' }}>
      {contacts.data.length === 0 ||
        contacts.data === null ? (
        <div style={{ height: 'calc(100% - 128px)' }}>
          <NotFound />
        </div>
      ) : (
        <CompaniesTable contacts={contacts} filters={filters} setFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
          setFilters(newFilters)
        }} />
      )}
      {
        !(contacts.data.length === 0 ||
          contacts.data === null) &&
        <Pagination
          itemsPerPage={filters.itemsPerPage}
          setItemsPerPage={value => setFilters({ itemsPerPage: value })}
          found={contacts.total || 0}
          key={"pagination-companies-bottom"}
          pages={contacts.totalPage || 0}
          currentPage={contacts.page || 0}
          setPage={(page) => {
            changePage(page)
          }}
        />
      }
    </div>
  );
}
