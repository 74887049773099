import { ReactElement } from "react";
import { DatabasePreferences } from "../../../lib/preferences/dto";
import { SortableItem } from "./sortableItem";

interface Props {
    preferences: DatabasePreferences[]
    prevOrderHeads: ReactElement[]
    postOrderHeads: ReactElement[]
    setOrder: (order: string) => void
    setPreferences: (items: DatabasePreferences[]) => void
}

export function CustomTHead(props: Props) {
    const { preferences, setOrder, setPreferences, prevOrderHeads, postOrderHeads } = props

    return (
        <thead className="h-[48px] font-semibold text-neutral-500 uppercase text-text-xs sticky z-30 top-0">
            <tr className="bg-neutral-50">
                {prevOrderHeads}
                {
                    preferences.filter(pref => pref.visible).map(pref =>
                        <th key={'head-' + pref.column} className="text-left px-4 hover:bg-neutral-100">
                            <SortableItem
                                key={'head-sort-' + pref.column}
                                width={pref.width!}
                                order={pref.order}
                                setOrder={setOrder}
                                setPreferences={setPreferences}
                                id={pref.column}
                                preferences={preferences}
                            />
                        </th >
                    )
                }
                {postOrderHeads}
            </tr>
        </thead>
    )
}