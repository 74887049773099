import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { BannersList } from "../../../ui/organisms/banner/bannerList";
import { findCompany, setDeleteCompanyStatus, setEditCompanyStatus, setSelectedIdsCompanies, setShowPopupCompanies } from "../../database/companies/slice";
import { setOtpRequestStatus } from "../../database/people/privacy/slice";
import { findPerson, setDeletePersonStatus, setEditPersonStatus, setNewPersonResponse, setNewPersonStatus, setSelectedIdsPeople, setShowPopupPeople } from "../../database/people/slice";
import { setBulkDeleteModal, setSoftDeleteModal } from "../../database/slice";
import { getContact, setAddContactsBulkStatus, setAddContactsStatus, setChangeContactStateStatus, setDeleteBulkContactsStatus, setRemoveContactsStatus, setSoftBulkContactsStatus } from "../contacts/slice";
import { TargetListPageEnum } from "../dto";
import { findTargetListById, setEditTargetListStatus, setTargetListPage } from "../slice";

interface Props {
    reloadCompanies: () => void
    reloadPeople: () => void
    peopleResetFilters: () => void
    companiesResetFilters: () => void
}

export function BannerTargetListDetails(props: Props) {
    const { peopleResetFilters, companiesResetFilters, reloadCompanies, reloadPeople } = props
    const targetListState = useAppSelector(state => state.targetList)
    const companiesState = useAppSelector(state => state.companies)
    const contactsState = useAppSelector((state) => state.contacts);
    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.deleteBulkStatus === "failed" ||
            contactsState.deleteBulkStatus === "successfully"
        ) {
            dispatch(setBulkDeleteModal(false))
            if (contactsState.deleteBulkStatus === "successfully") {
                peopleResetFilters()
                companiesResetFilters()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteBulkContactsStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.deleteBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.softBulkStatus === "failed" ||
            contactsState.softBulkStatus === "successfully"
        ) {
            dispatch(setSoftDeleteModal(false))
            if (contactsState.softBulkStatus === "successfully") {
                peopleResetFilters()
                companiesResetFilters()
            }
            timeoutId = setTimeout(() => {
                dispatch(setSoftBulkContactsStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.softBulkStatus])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.addContactsStatus === "failed" ||
            contactsState.addContactsStatus === "successfully"
        ) {
            if (contactsState.addContactsStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setAddContactsStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.addContactsStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.addContactsBulkStatus === "failed" ||
            contactsState.addContactsBulkStatus === "successfully"
        ) {
            if (contactsState.addContactsBulkStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setAddContactsBulkStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.addContactsBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            targetListState.editTargetListStatus === "failed" ||
            targetListState.editTargetListStatus === "successfully"
        ) {
            if (targetListState.editTargetListStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditTargetListStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [targetListState.editTargetListStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.removeContactsStatus === "failed" ||
            contactsState.removeContactsStatus === "successfully"
        ) {
            if (contactsState.removeContactsStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
            }
            timeoutId = setTimeout(() => {
                dispatch(setRemoveContactsStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.removeContactsStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.changeContactStateStatus === "failed" ||
            contactsState.changeContactStateStatus === "successfully"
        ) {
            if (contactsState.changeContactStateStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(getContact(contactsState.getContactResponse?.id!))
                dispatch(
                    findTargetListById(targetListState.findTargetListResponse?.id!)
                );
            }
            timeoutId = setTimeout(() => {
                dispatch(setChangeContactStateStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.changeContactStateStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteCompanyStatus === "failed" ||
            companiesState.deleteCompanyStatus === "successfully"
        ) {
            dispatch(setSelectedIdsCompanies([]))
            dispatch(setShowPopupCompanies(false))
            if (companiesState.deleteCompanyStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deletePersonStatus === "failed" ||
            peopleState.deletePersonStatus === "successfully"
        ) {
            dispatch(setSelectedIdsPeople([]))
            dispatch(setShowPopupPeople(false))
            if (peopleState.deletePersonStatus === "successfully") {
                reloadCompanies()
                reloadPeople()
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                reloadCompanies()
                reloadPeople()
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                reloadCompanies()
                reloadPeople()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
                dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);


    return (
        <BannersList banners={[
            {
                label: "Si è verificato un errore durante l'eliminazione dei contatti.",
                visible: contactsState.deleteBulkStatus === "failed",
                closeAction: () => dispatch(setDeleteBulkContactsStatus("idle")),
                type: "error",
            },
            {
                label: "Contatti eliminati.",
                visible: contactsState.deleteBulkStatus === "successfully",
                closeAction: () => dispatch(setDeleteBulkContactsStatus("idle")),
                type: "success",
            },
            {
                type: "success",
                visible: peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists,
                label: "Contatto aggiunto correttamente",
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }
            },
            {
                type: "error",
                visible: otpState.otpRequestStatus === "failed",
                label: "Si è verificato un errore durante l'invio dell'OTP.",
                closeAction: () => dispatch(setOtpRequestStatus("idle"))
            },
            {
                type: "warning",
                visible: peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true,
                label: "Il contatto che stai provando ad inserire esiste ed è associato all'azienda",
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }
            },
            {
                type: "error",
                visible: peopleState.newPersonStatus === "failed",
                label: peopleState.newPersonResponseMessage,
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                }
            },
            {
                type: "success",
                visible: peopleState.deletePersonStatus === "successfully",
                label: "Persona eliminata.",
                closeAction: () => dispatch(setDeletePersonStatus("idle"))
            },
            {
                type: "error",
                visible: peopleState.deletePersonStatus === "failed",
                label: "Si è verificato un errore durante l'eliminazione della persona.",
                closeAction: () => dispatch(setDeletePersonStatus("idle"))
            },
            {
                type: "success",
                visible: companiesState.deleteCompanyStatus === "successfully",
                label: "Azienda eliminata.",
                closeAction: () => dispatch(setDeleteCompanyStatus("idle"))
            },
            {
                type: "error",
                visible: companiesState.deleteCompanyStatus === "failed",
                label: "Si è verificato un errore durante l'eliminazione dell'azienda.",
                closeAction: () => dispatch(setDeleteCompanyStatus("idle"))
            },
            {
                type: "success",
                visible: companiesState.editCompanyStatus === "successfully",
                label: "Azienda aggiornata.",
                closeAction: () => dispatch(setEditCompanyStatus("idle"))
            },
            {
                type: "error",
                visible: companiesState.editCompanyStatus === "failed",
                label: "Si è verificato un errore durante la modifica dell'azienda.",
                closeAction: () => dispatch(setEditCompanyStatus("idle"))
            },
            {
                type: "success",
                visible: peopleState.editPersonStatus === "successfully",
                label: "Contatto aggiornato.",
                closeAction: () => dispatch(setEditPersonStatus("idle"))
            },
            {
                type: "error",
                visible: peopleState.editPersonStatus === "failed",
                label: "Si è verificato un errore durante la modifica del contatto.",
                closeAction: () => dispatch(setEditPersonStatus("idle"))
            },
            {
                type: "success",
                visible: contactsState.changeContactStateStatus === "successfully",
                label: "Esito aggiornato.",
                closeAction: () => dispatch(setChangeContactStateStatus("idle"))
            },
            {
                type: "error",
                visible: contactsState.changeContactStateStatus === "failed",
                label: "Si è verificato un errore durante la modifica dell'esito.",
                closeAction: () => dispatch(setChangeContactStateStatus("idle"))
            },
            {
                type: "success",
                visible: contactsState.addContactsStatus === "successfully",
                label: `${targetListState.editTargetListTabValue === 1 ? "Persone " : "Aziende"} aggiunte alla lista.`,
                closeAction: () => dispatch(setAddContactsStatus("idle"))
            },
            {
                type: "success",
                visible: targetListState.editTargetListStatus === "successfully",
                label: "Lista target modificata correttamente.",
                closeAction: () => dispatch(setEditTargetListStatus("idle"))
            },
            {
                type: "error",
                visible: targetListState.editTargetListStatus === "failed",
                label: "Si è verificato un errore durante la modifica della lista target",
                closeAction: () => dispatch(setEditTargetListStatus("idle"))
            },
            {
                type: "error",
                visible: contactsState.addContactsStatus === "failed",
                label: `Si è verificato un errore durante l'aggiunta delle ${targetListState.editTargetListTabValue === 1 ? "persone " : "aziende"} alla lista target.`,
                closeAction: () => dispatch(setAddContactsStatus("idle"))
            },
            {
                type: "success",
                visible: contactsState.addContactsBulkStatus === "successfully",
                label: "Contatti aggiunti alla lista.",
                closeAction: () => dispatch(setAddContactsBulkStatus("idle"))
            },
            {
                type: "error",
                visible: contactsState.addContactsBulkStatus === "failed",
                label: "Si è verificato un errore durante l'aggiunta dei contatti alla lista target.",
                closeAction: () => dispatch(setAddContactsBulkStatus("idle"))
            },
        ]
        } />
    )
}