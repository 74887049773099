import { ReactNode, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import ReactQuill from 'react-quill'
import { keycloak } from '../../../lib/keycloak'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks'
import { OptionType } from '../../../lib/types'
import { XCircleIcon } from '../../../ui/icons/xCircle'
import Button from '../../../ui/molecules/button'
import EditorInput from '../../../ui/molecules/editorInput'
import Input from '../../../ui/molecules/input/Input'
import { SelectCustom } from '../../../ui/molecules/select'
import { BannersList } from '../../../ui/organisms/banner/bannerList'
import { findProjectById } from '../slice'
import { VariablesBody } from './dto'
import { findAllCustomMails, findEmailsHistory, findMailByID, resetMailDetails, sendCustomMail, setFindAllCustomMailsStatus, setFindMailByIdResponse, setMailDetailsBody, setMailDetailsContent, setMailDetailsName, setMailDetailsObject, setMailDetailsTo, setSelectedTemplateId, setSendCustomMailStatus, setSendEmailModalOpen } from './slice'
import { translateVariables, variablesMap } from './utils'

export function SendEmailModal() {
    const mailsState = useAppSelector(state => state.mails)
    const dispatch = useAppDispatch()

    const keycloakState = useAppSelector(state => state.keycloak)
    const peopleState = useAppSelector(state => state.people)
    const projectState = useAppSelector(state => state.projects)
    const companyState = useAppSelector(state => state.companies)
    const targetListState = useAppSelector(state => state.targetList)

    let values: OptionType[] = []
    const [options, setOptions] = useState<OptionType[]>([])
    const [openVariables, setOpenVariables] = useState(false)

    const quillRef = useRef<ReactQuill>(null)
    const menuRef = useRef<HTMLTableCellElement>(null);
    const buttonRef = useRef<HTMLDivElement>(null);

    const findValuesMap = new Map<string, string>([
        ['commercialName', keycloak.hasRealmRole('commercial') ? keycloakState.keycloakUserInfo!.name ? keycloakState.keycloakUserInfo!.name : '' : ''],
        ['teamLeaderName', projectState.findProjectResponse ? (projectState.findProjectResponse.teamLeader.name + " " + projectState.findProjectResponse.teamLeader.surname) : ''],
        ['customerCompanyName', projectState.findProjectResponse ? projectState.findProjectResponse.customer.companyName ? projectState.findProjectResponse.customer.companyName : '' : ''],
        ['customerCompanyWebsite', projectState.findProjectResponse ? projectState.findProjectResponse.customer.companyWebsite ? projectState.findProjectResponse.customer.companyWebsite : '' : ''],
        ['addressee', companyState.findCompanyResponse && targetListState.editTargetListTabValue === 2 ? companyState.findCompanyResponse.name : targetListState.editTargetListTabValue === 1 && peopleState.findPersonResponse ? peopleState.findPersonResponse.name : '']
    ])

    let variableOptions: ReactNode[] = []
    variablesMap.forEach((key, item) => {
        variableOptions.push(
            <Button
                onClick={() => {
                    if (quillRef.current) {
                        const editor = quillRef.current.getEditor();
                        const range = editor.getSelection();

                        if (range) {
                            editor.insertText(range.index, "[" + item + "]");

                            dispatch(setMailDetailsBody(editor.root.innerHTML))
                        }
                    }
                }}
                label={item}
                size={'sm'}
                iconPosition={'left'}
                variant={'link'}
                color={'gray'}
            />
        );
    });

    useEffect(() => {
        if (targetListState.findTargetListResponse && !window.location.pathname.includes('edit-project'))
            dispatch(findProjectById(targetListState.findTargetListResponse.projectId))
    }, [])

    useEffect(() => {
        let body: VariablesBody = {}
        dispatch(findAllCustomMails({ ...mailsState.mailFilters, projectId: targetListState.findTargetListResponse?.projectId! }))
        dispatch(setMailDetailsName(projectState.findAllResponse?.data.find(item => item.id === targetListState.findTargetListResponse?.projectId)?.nameFrom))
        findValuesMap.forEach((item, key) => { if (item !== '') body[key as keyof VariablesBody] = item })
        dispatch(setMailDetailsBody(body))
    }, [mailsState.mailFilters, targetListState.editTargetListTabValue, companyState.findCompanyResponse, peopleState.findPersonResponse])

    useEffect(() => {
        if (mailsState.findAllMailsStatus === 'successfully' && mailsState.findAllMailsResponse) {
            mailsState.findAllMailsResponse.data.forEach(
                item => values.push({ value: item.id, label: item.templateName })
            )
            dispatch(setFindAllCustomMailsStatus('idle'))
            setOptions(values)
        }
    }, [mailsState.findAllMailsStatus])

    useEffect(() => {
    }, [mailsState.mailToSendDetails])

    useEffect(() => {
        if (mailsState.findMailByIdStatus === 'successfully' && mailsState.findMailByIdResponse) {
            dispatch(setMailDetailsObject(mailsState.findMailByIdResponse.subject))
            dispatch(setMailDetailsContent(mailsState.findMailByIdResponse.templateHTML))
        }
    }, [mailsState.findMailByIdStatus])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            mailsState.sendCustomMailStatus === "failed" ||
            mailsState.sendCustomMailStatus === "successfully"
        ) {
            if (mailsState.sendCustomMailStatus === "successfully") {
                dispatch(findEmailsHistory(mailsState.emailsHistoryFilters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setSendCustomMailStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [mailsState.sendCustomMailStatus]);

    if (mailsState.openSendEmailModal)
        return (
            <div className='transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center p-20 z-[100]'>
                <div className=' transition-all flex flex-col items-center gap-2 p-4 bg-white rounded-xl opacity-100 shadow-sm w-[45%]'>
                    <div className=' flex items-center gap-6 justify-between w-full '>
                        <span className=' text-heading-xs font-bold text-neutral-800'>Invia e-mail</span>
                        <Button onClick={() => {
                            dispatch(setSendEmailModalOpen(false));
                            setSelectedTemplateId(undefined);
                            dispatch(resetMailDetails())
                            dispatch(setFindMailByIdResponse(undefined))
                        }} size={'lg'} iconPosition={'only'}
                            variant={'link'} color={'blue'}
                            icon={<XCircleIcon color={'blue'} size={32} />} />
                    </div>
                    <div className=' flex flex-col w-full py-8 px-4 gap-3 border-t border-neutral-200'>
                        <div className='flex flex-col gap-3 '>
                            <SelectCustom defaultValue={mailsState.selectedTemplateId} label='Seleziona template-email' onChange={(choice) => {
                                if (typeof choice === 'string') {
                                    dispatch(setSelectedTemplateId(choice))
                                    dispatch(findMailByID(choice))
                                }
                            }}
                                placeholder={'Seleziona il template'} menuPortalTargetIsNotBody
                                noOptionsMessage='Non hai ancora creato alcun template custom'
                                options={
                                    options
                                } />
                        </div>
                        {targetListState.editTargetListTabValue === 1 &&
                            <div className='flex flex-col gap-3 '>
                                <Input label="Azienda associata" disabled value={peopleState.findPersonResponse ? (peopleState.findPersonResponse?.companyName.length > 0 ? peopleState.findPersonResponse?.companyName : 'Nessuna azienda associata') : 'N/A'} placeholder="Azienda associata" />
                            </div>
                        }
                        <div className='flex flex-col gap-3 '>
                            <Input label='Destinatario' defaultValue={mailsState.mailToSendDetails.to} onChange={(e) => dispatch(setMailDetailsTo(e.target.value))} placeholder="Specificare l'indirizzo email dell'utente" />
                        </div>
                        <div className='flex flex-col gap-3'>
                            <Input disabled label='Oggetto' value={mailsState.mailToSendObject} onChange={(e) => dispatch(setMailDetailsBody(e.target.value))} placeholder="Specificare l'oggetto della mail" />
                        </div>
                        <div className='flex flex-col gap-11'>
                            <EditorInput
                                label='Contenuto della mail'
                                value={translateVariables(mailsState.mailToSendContent)}
                                placeholder="Descrivere il contenuto dell'e-mail"
                                id='input-editor-settings-email-edit'
                                key='input-editor-settings-email-edit'
                                onChangeText={(e) => {
                                    const parser = new DOMParser();
                                    const doc = parser.parseFromString(e, "text/html");

                                    const links = doc.querySelectorAll("a");

                                    if (links.length > 0) {
                                        links.forEach((link) => {
                                            let href = link.getAttribute("href");

                                            if (href && !href.startsWith("http://") && !href.startsWith("https://")) {
                                                link.setAttribute("href", `https://${href}`);
                                            }
                                        });

                                        dispatch(setMailDetailsContent(doc.body.innerHTML))
                                    } else {
                                        dispatch(setMailDetailsContent(e))
                                    }
                                }}
                                ref={quillRef}
                            />
                            <div ref={buttonRef} className=' flex flex-col items-end justify-center'>
                                <Button
                                    onClick={() => setOpenVariables(prev => !prev)}
                                    label="Inserisci una variabile"
                                    size={'sm'}
                                    iconPosition={'left'}
                                    variant={'link'}
                                    color={'blue'}
                                />
                                {ReactDOM.createPortal((
                                    openVariables ?
                                        <div ref={menuRef} className="bg-white border-[1px] border-neutral-100 rounded-md absolute shadow-sm z-[100]"
                                            style={{
                                                top: buttonRef.current ? (buttonRef.current.getBoundingClientRect().top + 40) : 0,
                                                right: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0,
                                            }}
                                        >
                                            {variableOptions}
                                        </div> : null
                                ), document.body)}
                            </div>
                        </div>
                    </div>
                    <div className=' flex justify-end items-center w-full border-t gap-2 py-4 px-6 border-neutral-200'>
                        <Button
                            disabled={!mailsState.findMailByIdResponse}
                            onClick={() => {
                                dispatch(setSelectedTemplateId(undefined))
                                dispatch(sendCustomMail({ customMailId: mailsState.findMailByIdResponse!.id, request: mailsState.mailToSendDetails }))
                                dispatch(resetMailDetails())
                                dispatch(setSendEmailModalOpen(false))
                                dispatch(setFindMailByIdResponse(undefined))
                            }} label='Invia e-mail' size={'sm'} iconPosition={'left'} variant={'solid'} color={'green'} />
                    </div>
                </div>
            </div>
        )
    else return <BannersList
        banners={[
            {
                type: "success",
                visible: mailsState.sendCustomMailStatus === "successfully",
                label: "Email inviata correttamente.",
                closeAction: () => dispatch(setSendCustomMailStatus("idle")),
            },
            {
                type: "error",
                visible: mailsState.sendCustomMailStatus === "failed",
                label: "Si è verificato un errore durante l'invio dell'email.",
                closeAction: () => dispatch(setSendCustomMailStatus("idle")),
            }
        ]
        }
    />
}
