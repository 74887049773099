import { formatDate } from "date-fns";
import { useEffect, useState } from "react";
import { keycloak } from "../../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { TrashIcon } from "../../../../ui/icons/trash";
import Avatar from "../../../../ui/molecules/avatar";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import TextArea from "../../../../ui/molecules/textArea";
import { getContact, setUpdateNotesStatus, updateNotes } from "../slice";
import { findTargetListById } from "../../slice";

interface Props {
    reloadContacts: () => void
}

export function EditContactNotes(props: Props) {
    const { reloadContacts } = props
    const targetListsState = useAppSelector(state => state.targetList)
    const contactsState = useAppSelector(state => state.contacts)
    const usersState = useAppSelector(state => state.users)

    const dispatch = useAppDispatch()

    const [note, setNote] = useState<string>()

    useEffect(() => {
        if (contactsState.updateNotesStatus === 'successfully') {
            reloadContacts()
            dispatch(getContact(contactsState.getContactResponse?.id!))
            dispatch(findTargetListById(targetListsState.findTargetListResponse?.id!))
            setNote('')
            dispatch(setUpdateNotesStatus('idle'))
        }
    }, [contactsState.updateNotesStatus])

    const notes = contactsState.getContactResponse?.notes

    if (usersState.findUsersByUserIdStatus === 'loading') {
        return (
            <div className="w-full p-6 flex items-center justify-center">
                <Spinner />
            </div>
        )
    }

    return (
        <div className="px-6 flex flex-col items-center justify-center gap-4">
            <div className="flex flex-col items-end justify-center w-full gap-3">
                <TextArea label="Nota" onChangeText={e => setNote(e)} />
                <Button size={"sm"} disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')} iconPosition={"off"} variant={"outline"} color={"blue"} label="Salva nota" onClick={() => {
                    const newNotes = [...notes ?? []]
                    newNotes.push({
                        note: note!,
                        authorUserId: keycloak.subject!,
                        date: new Date().toISOString().replace('Z', '+00:00')
                    })
                    dispatch(updateNotes({ contactId: contactsState.getContactResponse?.id!, notes: [...newNotes] }))
                }} />
            </div>
            {
                contactsState.updateNotesStatus === 'loading' ?
                    <Spinner /> :
                    <div className="py-4 border-t border-t-neutral-100 w-full flex flex-col items-center gap-2">
                        {notes?.map((note, index) =>
                            <div className="rounded-lg border w-full border-neutral-100 bg-white hover:bg-neutral-50 shadow-xs hover:shadow-none p-3 flex flex-col items-center justify-center gap-3">
                                <div className="flex items-start justify-between gap-1 w-full">
                                    <div className="flex items-center gap-2">
                                        <Avatar
                                            type="single"
                                            imageUrl={usersState.findUsersAvatarsListResponse.find(avatar => avatar.userId === note.authorUserId)?.objectId ?? ''}
                                            shape="circle"
                                            size="xs"
                                            fullName={usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId) ?
                                                (usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId)?.name ?? '') + (usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId)?.surname ?? '') :
                                                'Admin'}
                                            altTextInitials={
                                                usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId) ?
                                                    (usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId)?.name[0] ?? '') + (usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId)?.surname[0] ?? '') :
                                                    'AD'
                                            }
                                        />
                                        <span className="text-text-sm font-bold text-neutral-800">
                                            {usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId) ?
                                                (usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId)?.name ?? '') + ' ' + (usersState.findUsersByUserIdResponse.find(user => user.userId === note.authorUserId)?.surname ?? '')
                                                : 'Admin'}
                                        </span>
                                        <span className="text-text-xs text-neutral-600">
                                            •
                                        </span>
                                        <span className="text-text-xs text-neutral-700">
                                            {formatDate(new Date(note.date), 'dd/MM/yyyy - hh:mm')}
                                        </span>
                                    </div>
                                    {
                                        keycloak.subject === note.authorUserId &&
                                        <Button
                                            size={"sm"}
                                            iconPosition={"only"}
                                            variant={"link"}
                                            color={"gray"}
                                            icon={<TrashIcon color={""} size={0} />}
                                            onClick={() => {
                                                const notesCopy = [...notes]
                                                dispatch(updateNotes({ contactId: contactsState.getContactResponse?.id!, notes: notesCopy.filter((_, _index) => _index !== index) }))
                                            }}
                                        />
                                    }
                                </div>
                                <span className="text-text-sm w-full font-medium text-left text-neutral-700">
                                    {note.note}
                                </span>
                            </div>
                        )}
                    </div>
            }
        </div>
    )
}