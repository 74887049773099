import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../lib/redux/hooks";
import { formatFileSize } from "../../../../lib/utils";
import { colors } from "../../../../ui/colors";
import { ArrowRightIcon } from "../../../../ui/icons/arrowRight";
import { FileXlsIcon } from "../../../../ui/icons/fileXls";
import { UploadIcon } from "../../../../ui/icons/upload";
import Button from "../../../../ui/molecules/button";
import ProgressBar from "../../../../ui/molecules/progressBar";
import FileUploader from "../../../../ui/organisms/fileUploader";
import { TargetListPageEnum } from "../../dto";
import { setTargetListPage } from "../../slice";

interface Props {
  step: number;
  setStep: (value: number) => void;
  handleChange: (e: File[]) => void;
  error: boolean;
  file: File | null;
}
export function UploadFileForCSV(props: Props) {
  const dispatch = useAppDispatch();

  const totalDuration = 2;
  const [timeLeft, setTimeLeft] = useState(totalDuration);

  useEffect(() => {
    if (props.file !== null) {
      setTimeLeft(totalDuration);
      let interval: NodeJS.Timeout;

      const randomInterval = (): number => {
        return Math.random() * (0.5 - 0.2) + 0.2;
      };

      const updateLoading = () => {
        const decrement = Math.random() * 0.5;
        setTimeLeft((prevTimeLeft) => {
          const newTimeLeft = prevTimeLeft - decrement;
          if (newTimeLeft <= 0) {
            clearInterval(interval);
            return 0;
          }
          return parseFloat(newTimeLeft.toFixed(1));
        });
      };

      interval = setInterval(updateLoading, randomInterval() * 1000);

      return () => clearInterval(interval);
    }
  }, [props.file]);

  const progressPercent =
    timeLeft > 0 ? ((totalDuration - timeLeft) / totalDuration) * 100 : 100;

  return (
    <div className="h-full max-h-full overflow-auto">
      <div className="p-4 rounded-t-2xl bg-brandPrimary-50 border-y border-y-neutral-100 flex items-center justify-between sticky top-0">
        <div className="flex items-center gap-2">
          <span
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
            onClick={() => dispatch(setTargetListPage(TargetListPageEnum.ADD))}
          >
            Seleziona il tipo di importazione
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span className="text-text-md font-bold text-neutral-800">
            Aggiungi da file CSV
          </span>
        </div>
        <Button
          onClick={() => props.setStep(1)}
          disabled={!props.file && timeLeft !== 0}
          size={"sm"}
          iconPosition={"right"}
          variant={"solid"}
          color={"blue"}
          label="Importa CSV"
          icon={<UploadIcon color={""} size={0} />}
        />
      </div>
      <div className="p-4 flex flex-col gap-6 w-full">
        <FileUploader
          id={"target-list-add-contact-csv"}
          style={"large"}
          onChange={(e) => props.handleChange(e)}
          error={props.error}
        />
        {props.file !== null && (
          <div className="p-6 gap-4 flex flex-col bg-neutral-50">
            <div className="flex gap-4">
              <FileXlsIcon color={colors.green[500]} size={40} />
              <div className="flex flex-col justify-between">
                <span className="text-text-lg font-bold text-neutral-800">
                  {props.file.name}
                </span>
                <span className="text-text-sm text-neutral-600">
                  {formatFileSize(props.file.size)} •{" "}
                  {timeLeft > 0 ? timeLeft + " sec al termine" : "terminato"}
                </span>
              </div>
            </div>
            <div>
              <ProgressBar value={parseFloat(progressPercent.toFixed(1))} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
