import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";

import { CalendarIcon } from "../../../ui/icons/calendar";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { ContactType, FindAllContactsFilters } from "../dto";
import { Calendar } from "../../../ui/molecules/calendar";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function ContactCreationFilter(props: Props) {
    const { updateFilters, filters } = props

    const [sign, setSign] = useState<'=' | '<' | '>' | '><' | undefined>(undefined);
    const [firstInput, setFirstInput] = useState<string | undefined>(undefined);
    const [secondInput, setSecondInput] = useState<string | undefined>(undefined);

    const signOptions = [
        { label: "Uguale a", value: "=" },
        { label: "Maggiore di", value: ">" },
        { label: "Minore di", value: "<" },
        { label: "Compreso tra", value: "><" },
    ];

    useEffect(() => {
        let value = sign === undefined ? undefined : sign + (firstInput ? ':' + firstInput : '') + (sign === '><' && secondInput ? ':' + secondInput : '')
        let label = signOptions.find(opt => opt.value === sign)?.label + (firstInput ? ' ' + firstInput : '') + (sign === '><' && secondInput ? ' e ' + secondInput : '')
        if (value === undefined && filters.creationDate === undefined) {
            return
        }
        if (value === undefined) {
            updateFilters({ creationDate: undefined })
        } else {
            updateFilters({ creationDate: { value: value, label: label } })
        }
    }, [sign, firstInput, secondInput])

    const handleClear = () => {
        setFirstInput(undefined);
        setSecondInput(undefined);
        setSign(undefined)
        updateFilters({ creationDate: undefined })
    };

    useEffect(() => {
        if (filters.creationDate === undefined) {
            setFirstInput(undefined);
            setSecondInput(undefined);
            setSign(undefined)
        }
    }, [filters.creationDate])

    return (
        <AccordionFilter
            key={'person-filter-creation-of-contact'}
            label={"Inserito il"}
            icon={<CalendarIcon color={""} size={0} />}
            showClear={filters.creationDate !== undefined}
            options={filters.creationDate !== undefined ? [filters.creationDate] : []}
            clearAction={handleClear}
            deleteFilter={handleClear}
        >
            <div className="flex flex-col gap-4">
                <SelectCustom
                    isSearchable={false}
                    onChange={(e) => {
                        if (e !== undefined && typeof e === 'string') {
                            setSign(e as '<' | '>' | '><' | undefined);
                        }
                    }}
                    defaultValue={sign}
                    placeholder={"Uguale, minore, maggiore, compreso"}
                    options={signOptions}
                />
                {
                    sign !== undefined && (
                        <div className="flex flex-col gap-2">
                            <Calendar
                                type="date"
                                startDate={firstInput ? new Date(firstInput) : null}
                                setStartDate={function (date: Date | null): void {
                                    setFirstInput(format(new Date(date ?? new Date()), 'yyyy-MM-dd'));
                                }}
                            />
                            {/* <Input
                                placeholder={signOptions.find((rev) => rev.value === sign)?.label}
                                type="date"
                                key={"people-filter-creation-contact-date-input-0"}
                                defaultValue={firstInput}
                                onChangeText={(e) => {
                                    if (isValid(new Date(e)))
                                        if (e !== undefined && typeof e === 'string')
                                            setFirstInput(format(new Date(e), 'yyyy-MM-dd'));
                                        else
                                            setFirstInput(undefined);
                                }}
                            /> */}
                            {
                                sign === '><' && (
                                    <Calendar
                                        type="date"
                                        startDate={secondInput ? new Date(secondInput) : null}
                                        setStartDate={function (date: Date | null): void {
                                            setSecondInput(format(new Date(date ?? new Date()), 'yyyy-MM-dd'));
                                        }}
                                    />
                                    // <Input
                                    //     key={"people-filter-creation-contact-date-input-1"}
                                    //     defaultValue={secondInput}
                                    //     placeholder={"Compreso tra"}
                                    //     type="date"
                                    //     onChangeText={(e) => {
                                    //         if (isValid(new Date(e)))
                                    //             if (e !== undefined && typeof e === 'string')
                                    //                 setSecondInput(format(new Date(e), 'yyyy-MM-dd'));
                                    //             else
                                    //                 setSecondInput(undefined);
                                    //     }}
                                    // />
                                )}
                        </div>
                    )}
            </div>
        </AccordionFilter >
    );
}
