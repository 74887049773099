import { useEffect, useState } from "react";
import { PhoneCallIcon } from "../../../ui/icons/phoneCall";
import Input from "../../../ui/molecules/input";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { useDebounce } from "../../hooks/debounce";
import { ContactType, FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
    contactType: ContactType
}

export function PhoneFilter(props: Props) {
    const { updateFilters, filters, contactType } = props

    const [value, setValue] = useState(
        contactType === ContactType.PERSON ? filters.personPhone?.value ?? "" : filters.companyPhone?.value ?? ""
    );

    const debounceCompanyFilter = useDebounce((value) => {
        if (contactType === ContactType.PERSON) {
            updateFilters(value ? { personPhone: { label: value, value: value } } : { personPhone: undefined });
        } else {
            updateFilters(value ? { companyPhone: { label: value, value: value } } : { companyPhone: undefined });
        }
    }, 1000);

    useEffect(() => {
        setValue(contactType === ContactType.PERSON ? filters.personPhone?.value ?? "" : filters.companyPhone?.value ?? "");
    }, [filters.personPhone, filters.companyPhone, contactType]);

    return (
        <AccordionFilter
            key={'person-filter-phone'}
            label={contactType === ContactType.PERSON ? "Cellulare" : "Telefono"}
            icon={<PhoneCallIcon color={""} size={0} />}
            showClear={filters.personPhone !== undefined || filters.companyPhone !== undefined}
            options={filters.personPhone !== undefined && contactType === ContactType.PERSON ? [filters.personPhone] : filters.companyPhone !== undefined && contactType === ContactType.COMPANY ? [filters.companyPhone] : undefined}
            clearAction={() => {
                if (contactType === ContactType.PERSON)
                    updateFilters({ personPhone: undefined })
                else
                    updateFilters({ companyPhone: undefined })
            }}
            deleteFilter={() => {
                if (contactType === ContactType.PERSON)
                    updateFilters({ personPhone: undefined })
                else
                    updateFilters({ companyPhone: undefined })
            }}
        >
            <Input
                key={'find-person-filter-phone'}
                placeholder={contactType === ContactType.PERSON ? "Cerca cellulare" : "Cerca telefono"}
                onChangeText={(value) => {
                    setValue(value);
                    debounceCompanyFilter(value);
                }}
                value={value === undefined ? "" : value}
            />
        </AccordionFilter>
    )
}