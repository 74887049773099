import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { BannersList } from "../../../ui/organisms/banner/bannerList"
import { findCompany, setDeleteCompanyStatus, setEditCompanyStatus, setSelectedIdsCompanies, setShowPopupCompanies } from "../../database/companies/slice"
import { findPerson, setDeletePersonStatus, setEditPersonStatus, setNewPersonResponse, setNewPersonStatus, setSelectedIdsPeople, setShowPopupPeople } from "../../database/people/slice"
import { getContact, setChangeContactStateStatus } from "../contacts/slice"
import { findTargetListById } from "../slice"

export function BannerTargetListBins() {
    const targetListState = useAppSelector(state => state.targetList)
    const companiesState = useAppSelector(state => state.companies)
    const contactsState = useAppSelector((state) => state.contacts);
    const peopleState = useAppSelector(state => state.people)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.changeContactStateStatus === "failed" ||
            contactsState.changeContactStateStatus === "successfully"
        ) {
            if (contactsState.changeContactStateStatus === "successfully") {
                dispatch(getContact(contactsState.getContactResponse?.id!))
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setChangeContactStateStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.changeContactStateStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteCompanyStatus === "failed" ||
            companiesState.deleteCompanyStatus === "successfully"
        ) {
            dispatch(setSelectedIdsCompanies([]))
            dispatch(setShowPopupCompanies(false))
            if (companiesState.deleteCompanyStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deletePersonStatus === "failed" ||
            peopleState.deletePersonStatus === "successfully"
        ) {
            dispatch(setSelectedIdsPeople([]))
            dispatch(setShowPopupPeople(false))
            if (peopleState.deletePersonStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!));
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                dispatch(findTargetListById(targetListState.selectedTargetBins!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    return (
        <BannersList banners={[
            {
                type: "success",
                visible: peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists,
                label: "Contatto aggiunto correttamente",
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                },
            },
            {
                type: "warning",
                visible: peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true,
                label: "Il contatto che stai provando ad inserire esiste ed è associato all'azienda",
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                },
            },
            {
                type: "error",
                visible: peopleState.newPersonStatus === "failed",
                label: peopleState.newPersonResponseMessage,
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                },
            },
            {
                type: "success",
                visible: peopleState.deletePersonStatus === "successfully",
                label: "Persona eliminata.",
                closeAction: () => dispatch(setDeletePersonStatus("idle")),
            },
            {
                type: "error",
                visible: peopleState.deletePersonStatus === "failed",
                label: "Si è verificato un errore durante l'eliminazione della persona.",
                closeAction: () => dispatch(setDeletePersonStatus("idle")),
            },
            {
                type: "success",
                visible: companiesState.deleteCompanyStatus === "successfully",
                label: "Azienda eliminata.",
                closeAction: () => dispatch(setDeleteCompanyStatus("idle")),
            },
            {
                type: "error",
                visible: companiesState.deleteCompanyStatus === "failed",
                label: "Si è verificato un errore durante l'eliminazione dell'azienda.",
                closeAction: () => dispatch(setDeleteCompanyStatus("idle")),
            },
            {
                type: "success",
                visible: peopleState.editPersonStatus === "successfully",
                label: "Persona aggiornata.",
                closeAction: () => dispatch(setEditPersonStatus("idle")),
            },
            {
                type: "error",
                visible: peopleState.editPersonStatus === "failed",
                label: "Si è verificato un errore durante la modifica della persona.",
                closeAction: () => dispatch(setEditPersonStatus("idle")),
            },
            {
                type: "success",
                visible: companiesState.editCompanyStatus === "successfully",
                label: "Azienda aggiornata.",
                closeAction: () => dispatch(setEditCompanyStatus("idle")),
            },
            {
                type: "error",
                visible: companiesState.editCompanyStatus === "failed",
                label: "Si è verificato un errore durante la modifica dell'azienda.",
                closeAction: () => dispatch(setEditCompanyStatus("idle")),
            },
            {
                type: "success",
                visible: contactsState.changeContactStateStatus === "successfully",
                label: "Esito aggiornato.",
                closeAction: () => dispatch(setChangeContactStateStatus("idle")),
            },
            {
                type: "error",
                visible: contactsState.changeContactStateStatus === "failed",
                label: "Si è verificato un errore durante la modifica dell'esito.",
                closeAction: () => dispatch(setChangeContactStateStatus("idle")),
            }
        ]
        } />
    )
}