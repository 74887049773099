import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../lib/filters/dto";
import { useContacts } from "../../lib/hooks/contacts/contacts";
import { Preferences } from "../../lib/preferences";
import { PreferencesSection } from "../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Area } from "../../lib/utils";
import { BuildingIcon } from "../../ui/icons/building";
import { DownloadIcon } from "../../ui/icons/download";
import { TrashIcon } from "../../ui/icons/trash";
import { UsersIcon } from "../../ui/icons/users";
import { Layout } from "../../ui/layout";
import ButtonDropdown from "../../ui/molecules/buttonDropdown";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { TabMenu } from "../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../ui/organisms/tabs/tabPages";
import { EditCompany } from "../database/companies/editCompany/index";
import { Companies } from "../database/companies/list";
import { bulkCSVCompanies, deleteBulkCompanies, deleteCompany, getCSVCompanies, setEmployeeToDelete, setFindEmployeesResponse, setFindEmployeesStatus, setSelectedCompany, setSelectedIdsCompanies, setShowPopupCompanies } from "../database/companies/slice";
import { EditPerson } from "../database/people/editPerson";
import { People } from "../database/people/list";
import { bulkCSVPeople, deleteBulkPeople, deletePerson, getCSVPeople, setSelectedIdsPeople, setSelectedPerson, setShowPopupPeople } from "../database/people/slice";
import { setBulkCSVModal, setBulkDeleteModal, setTabValue } from "../database/slice";
import { setSelectedContact } from "../targetList/contacts/slice";
import { BannerVerifyBins } from "./banner";

export function VerifyBins() {
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.people)
    const companiesState = useAppSelector(state => state.companies)

    const [direction, setDirection] = useState<'left' | 'right'>("left")

    const peopleFilters = useContacts(Area.DATABASE, 'verify-list', ContactType.PERSON)
    const companiesFilters = useContacts(Area.DATABASE, 'verify-list', ContactType.COMPANY)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setSelectedCompany(undefined))
        dispatch(setSelectedPerson(undefined))
        dispatch(setSelectedContact(undefined))
        dispatch(setSelectedIdsPeople([]))
        dispatch(setSelectedIdsCompanies([]))
    }, []);

    useEffect(() => {
        dispatch(setFindEmployeesResponse([]));
        dispatch(setFindEmployeesStatus("idle"));
    }, [databaseState.tabValue]);

    useEffect(() => {
        if (window.location.pathname.includes("people")) {
            dispatch(setTabValue(0));
        }
        if (window.location.pathname.includes("companies")) {
            dispatch(setTabValue(1));
        }
    }, []);

    useEffect(() => {
        if (databaseState.tabValue === 0) {
            navigate(window.location.pathname.replace(/\/specifics|\/companies/, "/people"));
        }
        if (databaseState.tabValue === 1) {
            navigate(window.location.pathname.replace(/\/specifics|\/people/, "/companies"));
        }
    }, [databaseState.tabValue]);

    return (
        <Layout
            headerLabel={"Cestino da verificare"}
            breadcrumbItems={['Cestino Da Verificare']}
            menuItem={MenuItems.VERIFY_BINS}
            headerChildren={
                <div className="flex items-center justify-between">
                    <TabMenu
                        direction={direction}
                        setDirection={(value) => setDirection(value)}
                        setValue={(e) => {
                            dispatch(setTabValue(e));
                        }}
                        value={databaseState.tabValue}
                        items={[
                            {
                                icon: <UsersIcon color={""} size={0} />,
                                label: "Persone",
                            },
                            {
                                icon: <BuildingIcon color={""} size={0} />,
                                label: "Aziende",
                            },
                        ]}
                    />
                    <div className="flex gap-2">
                        {
                            ((companiesFilters.contacts.total !== 0 && databaseState.tabValue === 1) || (databaseState.tabValue === 0 && peopleFilters.contacts.total !== 0)) &&
                            <ButtonDropdown
                                disabled={(databaseState.tabValue === 0 && peopleState.selectedIds.length <= 0) || (databaseState.tabValue === 1 && companiesState.selectedIds.length <= 0)}
                                options={[
                                    {
                                        label: 'Esporta tutti',
                                        onClick: () => dispatch(setBulkCSVModal(true))
                                    }
                                ]}
                                size={"sm"}
                                iconPosition={"left"}
                                color={"blue"}
                                onClick={() => databaseState.tabValue === 0 ? dispatch(getCSVPeople(peopleState.selectedIds)) : dispatch(getCSVCompanies(companiesState.selectedIds))}
                                label="Esporta"
                                icon={<DownloadIcon color={""} size={0} />}
                            />
                        }
                        {
                            ((companiesFilters.contacts.total !== 0 && databaseState.tabValue === 1) || (databaseState.tabValue === 0 && peopleFilters.contacts.total !== 0)) &&
                            !window.location.pathname.includes('black-list') &&
                            <ButtonDropdown
                                disabled={(databaseState.tabValue === 0 && peopleState.selectedIds.length <= 0) || (databaseState.tabValue === 1 && companiesState.selectedIds.length <= 0)}
                                icon={<TrashIcon color={""} size={0} />}
                                options={[
                                    {
                                        label: 'Elimina tutti',
                                        onClick: () => {
                                            dispatch(setBulkDeleteModal(true))
                                        }
                                    }
                                ]}
                                onClick={() => databaseState.tabValue === 0 ? dispatch(setShowPopupPeople(true)) : dispatch(setShowPopupCompanies(true))}
                                label="Elimina"
                                size={"sm"}
                                iconPosition={"left"}
                                color={"blue"}
                            />
                        }
                    </div>
                </div>
            }
        >
            <div className="flex max-w-full w-full max-h-full h-full overflow-hidden p-4">
                <BannerVerifyBins
                    reloadCompanies={function (): void {
                        companiesFilters.findContacts()
                    }}
                    reloadPeople={function (): void {
                        peopleFilters.findContacts()
                    }}
                    companiesResetFilters={function (): void {
                        companiesFilters.resetAllFilters()
                    }}
                    peopleResetFilters={function (): void {
                        peopleFilters.resetAllFilters()
                    }}
                />
                <div className="flex max-w-full w-full h-full rounded-xl flex-col relative rounded-t-xl bg-white shadow-sm ">
                    <div className="max-h-full max-w-full h-full rounded-xl">
                        <TabPages
                            direction={direction}
                            setDirection={(value) => setDirection(value)}
                            pages={[
                                <People
                                    status={peopleFilters.status}
                                    contacts={peopleFilters.contacts as unknown as FindContactsDatabaseResponse<ContactType.PERSON>}
                                    key={0}
                                    resetFilters={function (): void {
                                        peopleFilters.resetAllFilters()
                                    }}
                                    filters={peopleFilters.filters}
                                    updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                                        peopleFilters.updateFilters(newFilters)
                                    }} setPage={function (page: number): void {
                                        peopleFilters.changePage(page)
                                    }} />,
                                <Companies
                                    status={companiesFilters.status}
                                    contacts={companiesFilters.contacts as unknown as FindContactsDatabaseResponse<ContactType.COMPANY>}
                                    resetFilters={function (): void {
                                        companiesFilters.resetAllFilters()
                                    }}
                                    filters={companiesFilters.filters}
                                    updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                                        companiesFilters.updateFilters(newFilters)
                                    }} setPage={function (page: number): void {
                                        companiesFilters.changePage(page)
                                    }}
                                    key={1} />
                            ]}
                            value={databaseState.tabValue}
                            setValue={(e) => {
                                dispatch(setTabValue(e));
                                dispatch(setSelectedContact(undefined))
                                dispatch(setSelectedCompany(undefined));
                                dispatch(setSelectedPerson(undefined));
                            }}
                        />
                    </div>
                </div>
                <Preferences section={databaseState.tabValue === 0 ? PreferencesSection.personPreferences : PreferencesSection.companyPreferences} key={databaseState.tabValue === 0 ? PreferencesSection.personPreferences : PreferencesSection.companyPreferences} />
                <ConfirmModal
                    loading={peopleState.deletePersonStatus === "loading"}
                    title={
                        peopleState.selectedIds.length > 1 ?
                            "Elimina persone" :
                            "Elimina persone"
                    }
                    label={
                        peopleState.selectedIds.length > 1 ?
                            "Sei sicuro di voler eliminare queste persone?" :
                            "Sei sicuro di voler eliminare questa persona?"
                    }
                    confirmAction={function (): void {
                        dispatch(
                            deletePerson(peopleState.selectedIds)
                        );
                    }}
                    onClose={function (): void {
                        dispatch(setSelectedIdsPeople([]));
                        dispatch(setShowPopupPeople(false));
                    }}
                    visible={peopleState.showPopup}
                />
                <ConfirmModal
                    loading={companiesState.deleteCompanyStatus === "loading"}
                    title={
                        companiesState.selectedIds.length > 1 ?
                            "Elimina aziende" :
                            "Elimina azienda"
                    }
                    label={
                        companiesState.selectedIds.length > 1 ?
                            "Sei sicuro di voler eliminare queste aziende?" :
                            "Sei sicuro di voler eliminare questa azienda?"
                    }
                    confirmAction={function (): void {
                        dispatch(
                            deleteCompany(companiesState.selectedIds)
                        );
                    }}
                    onClose={function (): void {
                        dispatch(setSelectedIdsCompanies([]));
                        dispatch(setShowPopupCompanies(false));
                    }}
                    visible={companiesState.showPopup}
                />
                <div
                    style={{ height: "calc(100% - 144px)" }}
                    className={clsx(
                        "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
                        {
                            hidden: peopleState.selectedPerson === undefined,
                        }
                    )}
                >
                    <div className="bg-white shadow-md h-full">
                        <EditPerson isInTargetList reloadContacts={() => peopleFilters.findContacts()} />
                    </div>
                </div>
                <div
                    style={{ height: "calc(100% - 144px)" }}
                    className={clsx(
                        "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all",
                        {
                            hidden: companiesState.selectedCompany === undefined,
                        }
                    )}
                >
                    <div className="bg-white shadow-md h-full">
                        <EditCompany isInTargetList reloadContacts={() => companiesFilters.findContacts()} />
                    </div>
                </div>
            </div>
            <ConfirmModal
                loading={companiesState.deleteBulkStatus === "loading" || peopleState.deleteBulkStatus === 'loading'}
                title={databaseState.tabValue === 1 ? 'Elimina aziende' : 'Elimina persone'}
                label={
                    databaseState.tabValue === 1 ?
                        "Sei sicuro di voler eliminare tutte le aziende trovate?" :
                        "Sei sicuro di voler eliminare tutte le persone trovate?"
                }
                confirmAction={function (): void {
                    if (databaseState.tabValue === 1) {
                        dispatch(deleteBulkCompanies(companiesFilters.filters))
                    } else {
                        dispatch(deleteBulkPeople(peopleFilters.filters))
                    }
                }}
                onClose={function (): void {
                    dispatch(setBulkDeleteModal(false))
                }}
                visible={databaseState.bulkDeleteModal}
            />
            <ConfirmModal
                title={"Elimina persona"}
                label={"Sei sicuro di voler eliminare questa persona?"}
                confirmAction={
                    () => dispatch(deletePerson([companiesState.employeeToDelete!]))
                }
                onClose={() => dispatch(setEmployeeToDelete(undefined))}
                visible={companiesState.employeeToDelete !== undefined}
            />
            <ConfirmModal
                loading={companiesState.companiesBulkCSVStatus === "loading" || peopleState.peopleBulkCSVStatus === 'loading'}
                title={databaseState.tabValue === 1 ? 'Esporta aziende' : 'Esporta persone'}
                label={
                    databaseState.tabValue === 1 ?
                        "Sei sicuro di voler esportare tutte le aziende trovate?" :
                        "Sei sicuro di voler esportare tutte le persone trovate?"
                }
                confirmAction={function (): void {
                    if (databaseState.tabValue === 1) {
                        dispatch(bulkCSVCompanies(companiesFilters.filters))
                    } else {
                        dispatch(bulkCSVPeople(peopleFilters.filters))
                    }
                }}
                onClose={function (): void {
                    dispatch(setBulkCSVModal(false))
                }}
                visible={databaseState.bulkCSVModal}
            />
        </Layout>
    )
}