import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseStatuses } from '../types';
import { TargetSection } from '../utils';
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse, SectionTargetContacts, TargetResponse } from './dto';
import { NewContactsListService } from './service';

const emptyResponsePerson: TargetResponse<ContactType.PERSON> = {
    response: {
        data: [],
        total: 0,
        page: 0,
        perPage: 25,
        prev: false,
        next: false,
        totalPage: 0
    },
    status: 'idle'
}

const emptyResponseCompany: TargetResponse<ContactType.COMPANY> = {
    response: {
        data: [],
        total: 0,
        page: 0,
        perPage: 25,
        prev: false,
        next: false,
        totalPage: 0
    },
    status: 'idle'
}

const initialState: SectionTargetContacts = {
    target: {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
    "add-target-tl": {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
    "target-bin": {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
};

export const findContactsTarget = createAsyncThunk(
    "contacts/findContacts",
    async (request: { filters: FindAllContactsFilters, section: TargetSection, contactType: ContactType }, thunkApi): Promise<{ response: FindContactsTargetResponse<ContactType>, section: TargetSection, contactType: ContactType }> => {
        const ContactsService = NewContactsListService();

        const response = await ContactsService.findContactsTarget(request.filters, request.contactType);

        return {
            response: response,
            section: request.section,
            contactType: request.contactType
        };
    }
);

const contactsSlice = createSlice({
    name: 'targetContacts',
    initialState,
    reducers: {
        setFindContactsStatus: (
            state,
            action: PayloadAction<{ section: TargetSection; status: PromiseStatuses, contactType: ContactType }>
        ) => {
            const { section, status, contactType } = action.payload;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response: state[section][contactType].response,
                    status,
                }
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(findContactsTarget.fulfilled, (state, action) => {
            const { section, response, contactType } = action.payload;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response,
                    status: 'successfully',
                }
            };
        });

        builder.addCase(findContactsTarget.pending, (state, action) => {
            const { section, contactType } = action.meta.arg;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response: {
                        data: [],
                        total: 0,
                        page: 0,
                        perPage: 25,
                        prev: false,
                        next: false,
                        totalPage: 0
                    },
                    status: 'loading',
                }
            };
        });

        builder.addCase(findContactsTarget.rejected, (state, action) => {
            const { section, contactType } = action.meta.arg;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response: {
                        data: [],
                        total: 0,
                        page: 0,
                        perPage: 25,
                        prev: false,
                        next: false,
                        totalPage: 0
                    },
                    status: 'failed',
                }
            };
        });
    },
});

export const { setFindContactsStatus } = contactsSlice.actions;
export default contactsSlice.reducer;
