import clsx from "clsx";
import { createPortal } from "react-dom";
import { XIcon } from "../../../ui/icons/x";
import Button from "../../../ui/molecules/button";
import { EventDTO } from "../dto";
import { DetailedListEvent } from "../events/detailedListEvent";
import { format } from "date-fns";

type Props = {
    visible: boolean
    events: EventDTO[]
    setVisibile: (visible: boolean) => void
    cleanEvents: () => void
}
export function ModalDetailsList(props: Props) {
    const { visible, setVisibile, events, cleanEvents } = props

    return createPortal(
        <div
            onClick={() => {
                setVisibile(false)
                cleanEvents()
            }}
            className={clsx(
                "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
                {
                    block: visible,
                    hidden: !visible
                }
            )}
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="transition-all flex flex-col opacity-100 rounded-xl bg-white shadow-sm max-w-[35%]"
            >
                <div className="border-b flex items-center justify-between h-16 border-b-neutral-200 w-full px-6">
                    {visible && <span className="text-heading-sm font-semibold text-neutral-800">Eventi del {format(new Date(events[0].startDate), 'dd/MM/yyyy')}</span>}
                    <Button onClick={() => { setVisibile(false); cleanEvents() }} size={"sm"} iconPosition={"only"} variant={"outline"} color={"blue"} icon={<XIcon color={""} size={0} />} />
                </div>
                <div className="px-6 py-8 flex flex-col gap-4 max-h-[50vh] overflow-y-auto">
                    {events.map(event => <DetailedListEvent event={event} />)}
                </div>
            </div>
        </div >
        , document.body)
}