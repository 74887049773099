import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { setCantDeleteProject, setCantDeleteTarget, setShowCantDeleteModal } from "../slice";
import Button from "../../../ui/molecules/button";
import { XCircleIcon } from "../../../ui/icons/xCircle";
import { colors } from "../../../ui/colors";

export function CantDeleteModal() {
    const dispatch = useAppDispatch()
    const usersState = useAppSelector(state => state.users)

    return (
        <div
            onClick={() => {
                dispatch(setShowCantDeleteModal(false))
                dispatch(setCantDeleteProject([]))
                dispatch(setCantDeleteTarget([]))
            }}
            className={clsx(
                "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
                {
                    block: usersState.showCantDeleteModal,
                    hidden: !usersState.showCantDeleteModal,
                }
            )}
        >
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className="transition-all max-w-[30%] flex flex-col items-center gap-5 p-[20px] bg-white rounded-[12px] opacity-100 shadow-sm min-w-[400px]"
            >
                <XCircleIcon color={colors.red[500]} size={64} />
                <span className="text-heading-xs text-neutral-800 font-bold">
                    {"Impossibile eliminare l'utente"}
                </span>
                <span className="text-text-md font-normal text-center text-neutral-600">
                    {"Non è stato possibile eliminare l'utente perché associato  "}
                    {usersState.cantDeleteProject.length > 0 ? ' ai seguenti progetti: ' : ' alle seguenti liste target: '}
                    {usersState.cantDeleteProject.map((project, index) => (project + (index === usersState.cantDeleteProject.length - 1 ? '' : ', ')))}
                    {usersState.cantDeleteTarget.map((project, index) => (project + (index === usersState.cantDeleteTarget.length - 1 ? '' : ', ')))}
                    .
                </span>
                <div className="w-full flex items-center justify-center flex-1 gap-3">
                    <Button
                        size={"md"}
                        iconPosition={"off"}
                        variant={"solid"}
                        color={"red"}
                        label="Chiudi"
                        onClick={() => {
                            dispatch(setShowCantDeleteModal(false))
                            dispatch(setCantDeleteProject([]))
                            dispatch(setCantDeleteTarget([]))
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
