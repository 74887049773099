import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../lib/filters/dto";
import { peopleColumns } from "../../../../lib/preferences/dto";
import { updatePreferences } from "../../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../ui/icons/circle";
import { EditIcon } from "../../../../ui/icons/edit";
import { LinkedinIcon } from "../../../../ui/icons/linkedin";
import { TrashIcon } from "../../../../ui/icons/trash";
import { UserPlusIcon } from "../../../../ui/icons/userPlus";
import Checkbox from "../../../../ui/molecules/checkbox";
import Pills from "../../../../ui/molecules/pills";
import { Spinner } from "../../../../ui/molecules/spinner";
import { Dropdown } from "../../../../ui/organisms/dropdown";
import { CustomTable } from "../../../../ui/organisms/table";
import { resetEditCompanyRequest } from "../../companies/slice";
import { setPersonToAddCompany, setSelectedIdsPeople, setSelectedPerson, setShowPopupPeople, setTabValueDetailsPerson } from "../slice";

interface Props {
    filters: FindAllContactsFilters
    updateFilters: (value: Partial<FindAllContactsFilters>) => void
    contacts: FindContactsDatabaseResponse<ContactType.PERSON>
}

export function PeopleTable(props: Props) {
    const { filters, updateFilters, contacts } = props
    const peopleState = useAppSelector(state => state.people)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()
    return (
        <CustomTable
            tableHeight="calc(100% - 48px)"
            prevOrderHeads={[
                <th className="">
                    <Checkbox
                        shape={"square"}
                        checked={
                            contacts.data.every((person) => peopleState.selectedIds.includes(person.id))!
                        }
                        onCheck={function (value: boolean): void {
                            if (
                                contacts.data
                                    .every((person) => peopleState.selectedIds.includes(person.id))
                            ) {
                                let newSelected = [...peopleState.selectedIds];
                                contacts.data.forEach((person) => {
                                    newSelected = newSelected.filter(
                                        (id) => person.id !== id
                                    );
                                });
                                dispatch(setSelectedIdsPeople([...newSelected]));
                            } else {
                                dispatch(setSelectedIdsPeople([
                                    ...peopleState.selectedIds,
                                    ...contacts.data
                                        .filter(
                                            (person) =>
                                                !peopleState.selectedIds.includes(person.id)
                                        )
                                        .map((person) => person.id)!,
                                ]));
                            }
                        }}
                    />
                </th>
            ]}
            postOrderHeads={[<th></th>]}
            preferences={preferencesState.findAllResponse?.data[0].personPreferences?.map(pref => ({ ...pref, order: peopleColumns.find(col => col.column === pref.column)?.order })) ?? []}
            setPreferences={(items) => {
                dispatch(
                    updatePreferences({
                        data: {
                            owner: preferencesState.findAllResponse?.data[0].owner!,
                            personPreferences: items,
                            companyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .companyPreferences!,
                            targetListPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPreferences!,
                            targetListCompanyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListCompanyPreferences!,
                            targetListPersonPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPersonPreferences!
                        },
                        id: preferencesState.findAllResponse?.data[0].id!,
                    })
                );
            }}
            setOrderFilters={(order) => {
                updateFilters({ order: !filters.order, sort: order })
            }}
            rows={contacts.data.map(d => ({
                key: d.id, data: [
                    {
                        item: <td className="text-text-sm font-semibold text-neutral-600">
                            <Checkbox
                                shape={"square"}
                                checked={peopleState.selectedIds.includes(d.id)}
                                onCheck={function (value: boolean): void {
                                    if (peopleState.selectedIds.includes(d.id)) {
                                        dispatch(setSelectedIdsPeople([
                                            ...peopleState.selectedIds.filter((id) => id !== d.id),
                                        ]));
                                    } else {
                                        dispatch(setSelectedIdsPeople([...peopleState.selectedIds, d.id]));
                                    }
                                }}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },
                    {
                        item: <td >
                            <div
                                onClick={() => {
                                    dispatch(setSelectedPerson(d.id))
                                    dispatch(setTabValueDetailsPerson('form'))
                                }}
                                className="crop-text text-text-sm cursor-pointer font-semibold text-neutral-800 flex flex-row gap-1 items-center"
                                style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Contatto')?.width ?? 150 }}
                            >
                                {d.name !== null ? d.name : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />} {d.surname !== null ? d.surname : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Contatto'
                    },
                    {
                        item: <td >
                            {
                                d.toVerifyFields?.includes('phone') ?
                                    <Pills
                                        size={"xs"}
                                        label={d.phones && d.phones[0] ? (d.phones[0] + (d.phones.length > 1 ? (', ..., + ' + (d.phones.length - 1)) : '')) : '-'}
                                        outline={true}
                                        emphasis={true}
                                        color={"red"}
                                    /> :
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={"tel:" + d.phones}
                                    >
                                        <div className="crop-text text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Telefono')?.width ?? 150 }}>
                                            {d.phones ?? <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                                        </div>
                                    </a>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Telefono'
                    },
                    {
                        item: <td >
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={"mailto:" + d.emails}
                                className="w-min"
                            >
                                <div className="crop-text text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Email')?.width ?? 200 }}>
                                    {d.emails ?? <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                                </div>
                            </a>
                        </td>,
                        group: 'sort',
                        key: 'Email'
                    },
                    {
                        item: <td >
                            {
                                d.toVerifyFields?.includes('company') ?
                                    <Pills
                                        size={"xs"}
                                        label={d.companyName ?? '-'}
                                        outline={true}
                                        emphasis={true}
                                        color={"red"}
                                    /> :
                                    <div
                                        onClick={() => {
                                            dispatch(setSelectedPerson(d.id))
                                            dispatch(setTabValueDetailsPerson('company'))
                                            dispatch(resetEditCompanyRequest())
                                        }}
                                        className="block cursor-pointer crop-text text-text-sm font-semibold text-brandPrimary-500"
                                        style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Azienda')?.width ?? 150 }}
                                    >
                                        {d.companyName ?? <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                                    </div>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Azienda'
                    },
                    {
                        item: <td >
                            <div>
                                <Pills
                                    size={"xs"}
                                    label={d.active ? "Attivo" : "Inattivo"}
                                    outline={false}
                                    emphasis={false}
                                    color={d.active ? "green" : "blue"}
                                />
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Stato'
                    },
                    {
                        item: <td >
                            {
                                d.linkedinProfile ?
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={d.linkedinProfile}
                                        className="w-full flex"
                                    >
                                        <LinkedinIcon color={colors.neutral[400]} size={20} />
                                    </a> : <span className="text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Linkedin')?.width ?? 150 }}>{<Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}</span>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Linkedin'
                    },
                    {
                        item: <td >
                            <div className="crop-text text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Ruolo')?.width ?? 150 }}>{d.role ?? <Pills size={"xs"} label={"-"} outline={false} emphasis={false} color={"red"} />}</div>
                        </td>,
                        group: 'sort',
                        key: 'Ruolo'
                    },
                    {
                        item: <td >
                            <div className="crop-text max-w-[150px] text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].personPreferences?.find(col => col.column === 'Reparto')?.width ?? 150 }}>
                                {d.department ?? <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Reparto'
                    },
                    {
                        item: <td >
                            <div className="w-full flex justify-center">
                                {d.privacy ? (
                                    <CheckCircleIcon color={colors.neutral[400]} size={20} />
                                ) : (
                                    <CircleIcon color={colors.neutral[400]} size={20} />
                                )}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Privacy'
                    },
                    {
                        item: <td className="w-[20px] cursor-pointer z-20">
                            {peopleState.findPersonStatus === "loading" ||
                                peopleState.deletePersonStatus === "loading" ? (
                                <Spinner size={20} />
                            ) : (
                                <Dropdown
                                    items={[
                                        {
                                            label: "Modifica",
                                            icon: <EditIcon color={""} size={0} />,
                                            onClick: () => {
                                                dispatch(setSelectedPerson(d.id));
                                                dispatch(setTabValueDetailsPerson('form'));
                                                dispatch(resetEditCompanyRequest())
                                            },
                                        },
                                        d.companyId === null
                                            ? {
                                                label: "Associa azienda",
                                                icon: <UserPlusIcon color={""} size={0} />,
                                                onClick: () =>
                                                    dispatch(setPersonToAddCompany(d)),
                                            }
                                            : null,
                                        window.location.pathname.includes('black-list') ?
                                            null :
                                            {
                                                label: "Cancella",
                                                icon: <TrashIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(setSelectedIdsPeople([d.id]))
                                                    dispatch(setShowPopupPeople(true))
                                                },
                                            },
                                    ]}
                                />
                            )}
                        </td>,
                        group: 'postSort',
                        key: 'menu'
                    }
                ]
            })) ?? []}
        />
    )
}