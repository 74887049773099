import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromiseStatuses } from '../types';
import { DatabaseSection } from '../utils';
import { ContactType, DatabaseResponse, FindAllContactsFilters, FindContactsDatabaseResponse, SectionDatabaseContacts } from './dto';
import { NewContactsListService } from './service';

const emptyResponsePerson: DatabaseResponse<ContactType.PERSON> = {
    response: {
        data: [],
        total: 0,
        page: 0,
        perPage: 25,
        prev: false,
        next: false,
        totalPage: 0
    },
    status: 'idle'
}

const emptyResponseCompany: DatabaseResponse<ContactType.COMPANY> = {
    response: {
        data: [],
        total: 0,
        page: 0,
        perPage: 25,
        prev: false,
        next: false,
        totalPage: 0
    },
    status: 'idle'
}

const initialState: SectionDatabaseContacts = {
    database: {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
    "add-target-db": {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
    "black-list": {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
    "verify-list": {
        PERSON: emptyResponsePerson,
        COMPANY: emptyResponseCompany
    },
};

export const findContactsDatabase = createAsyncThunk(
    "contacts/findContactsDatabase",
    async (request: { filters: FindAllContactsFilters, section: DatabaseSection, contactType: ContactType }, thunkApi): Promise<{ response: FindContactsDatabaseResponse<ContactType>, section: DatabaseSection, contactType: ContactType }> => {
        const ContactsService = NewContactsListService();

        const response = await ContactsService.findContactsDatabase(request.filters, request.contactType);

        return {
            response: response,
            section: request.section,
            contactType: request.contactType
        };
    }
);

const contactsSlice = createSlice({
    name: 'databaseContacts',
    initialState,
    reducers: {
        setFindContactsStatus: (
            state,
            action: PayloadAction<{ section: DatabaseSection; status: PromiseStatuses, contactType: ContactType }>
        ) => {
            const { section, status, contactType } = action.payload;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response: state[section][contactType].response,
                    status,
                }
            };
        },
    },
    extraReducers(builder) {
        builder.addCase(findContactsDatabase.fulfilled, (state, action) => {
            const { section, response, contactType } = action.payload;
            state[section] = {
                ...state[section],
                [contactType]: {
                    response,
                    status: 'successfully',
                }
            };
        });

        builder.addCase(findContactsDatabase.pending, (state, action) => {
            const { section, contactType } = action.meta.arg;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response: {
                        data: [],
                        total: 0,
                        page: 0,
                        perPage: 25,
                        prev: false,
                        next: false,
                        totalPage: 0
                    },
                    status: 'loading',
                }
            };
        });

        builder.addCase(findContactsDatabase.rejected, (state, action) => {
            const { section, contactType } = action.meta.arg;

            state[section] = {
                ...state[section],
                [contactType]: {
                    response: {
                        data: [],
                        total: 0,
                        page: 0,
                        perPage: 25,
                        prev: false,
                        next: false,
                        totalPage: 0
                    },
                    status: 'failed',
                }
            };
        });
    },
});

export const { setFindContactsStatus } = contactsSlice.actions;
export default contactsSlice.reducer;
