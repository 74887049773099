import clsx from "clsx";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { PlusIcon } from "../../../../ui/icons/plus";
import Button from "../../../../ui/molecules/button";
import { otpRequest } from "./slice";
import { Spinner } from "../../../../ui/molecules/spinner";

interface Props {
    visible: boolean
    onClose: () => void
}
export function SendOtpModal(prsops: Props) {
    const { visible, onClose } = prsops

    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)

    const dispatch = useAppDispatch()

    return createPortal(
        <div
            onClick={() => onClose()}
            className={clsx(
                "transition-all fixed bg-opacity-30 z-[999] bg-neutral-950 backdrop-blur-sm top-0 left-0 right-0 bottom-0 h-full w-full flex items-center justify-center",
                {
                    block: visible,
                    hidden: !visible,
                }
            )}
        >
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className="transition-all relative bg-white rounded-[12px] opacity-100 shadow-sm min-w-[600px]"
            >
                <div
                    onClick={() => onClose()}
                    className="absolute cursor-pointer rotate-45 top-[-16px] right-[-16px] border border-brandPrimary-600 h-8 w-8 rounded-full flex items-center justify-center bg-brandPrimary-50"
                >
                    <PlusIcon color={colors.brandPrimary[600]} size={16} />
                </div>
                <div className="p-6 text-heading-xs font-semibold text-center">
                    Richiedi Consenso Privacy
                </div>
                <div className="px-6 py-8 flex flex-col justify-center items-center gap-6 border-t-neutral-200 border-t">
                    <p className="text-text-md font-medium text-neutral-600 text-center">
                        Vuoi richiedere il consenso alla Privacy per
                        <span className="font-bold text-neutral-800">
                            { } {peopleState.findPersonResponse?.name} {peopleState.findPersonResponse?.surname}
                        </span>?
                        <br />
                        Clicca su '<span className="font-bold text-neutral-800">Invia OTP</span>' per inviare un codice univoco.
                    </p>
                    {
                        otpState.otpRequestStatus === 'loading' ?
                            <div><Spinner /></div> :
                            <Button size={"md"} iconPosition={"off"} variant={"solid"} color={"green"} label="Invia OTP"
                                onClick={() => {
                                    dispatch(otpRequest(peopleState.findPersonResponse?.phones[0]!))
                                }}
                            />
                    }
                </div>
            </div>
        </div>
        , document.body);
}