import { useEffect, useState } from "react"
import { GlobeIcon } from "../../../ui/icons/globe"
import Input from "../../../ui/molecules/input"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { useDebounce } from "../../hooks/debounce"
import { FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function CapFilter(props: Props) {
    const { updateFilters, filters } = props

    const [value, setValue] = useState(
        filters.companyZipCode?.value ?? ""
    );

    const debounceCompanyFilter = useDebounce((value) => {
        updateFilters(value ? { companyZipCode: { label: value, value: ("~:" + value) } } : { companyZipCode: undefined });

    }, 1000);

    useEffect(() => {
        setValue(filters.companyZipCode?.value.substring(2) ?? "");
    }, [filters.companyZipCode]);

    return (
        <AccordionFilter
            key={'person-filter-cap'}
            label={"Cap azienda"}
            icon={<GlobeIcon color={""} size={0} />}
            showClear={filters.companyZipCode !== undefined}
            hideDelete
            options={filters.companyZipCode !== undefined ? [filters.companyZipCode] : undefined}
            clearAction={function (): void {
                updateFilters({ companyZipCode: undefined })
            }}
            deleteFilter={function (option): void {
                updateFilters({ companyZipCode: undefined })
            }}
        >
            <Input
                key={'find-person-filter-zipcode'}
                placeholder="Cerca per cap"
                onChangeText={(value) => {
                    setValue(value);
                    debounceCompanyFilter(value);
                }}
                value={value === undefined ? "" : value}
            />
        </AccordionFilter>
    )
}