import { useDebounce } from "../../../lib/hooks/debounce";
import { keycloak } from "../../../lib/keycloak";
import { setOptionsActive } from "../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import { MoreHorizontalIcon } from "../../../ui/icons/moreHorizontal";
import { SearchIcon } from "../../../ui/icons/search";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { TargetListStatusEnum } from "../dto";
import {
  setTargetListFilterCommercial,
  setTargetListFilterName,
  setTargetListFilterSector,
  setTargetListFilterState,
  setTargetListFilterTeamLeader,
  setTargetListsFilterCategory,
} from "../slice";

export function TargetListsFilters() {
  const targetListsState = useAppSelector((state) => state.targetList);
  const atecosState = useAppSelector((state) => state.atecos);
  const preferencesState = useAppSelector((state) => state.preferences);
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const debounce = useDebounce(
    (value) => dispatch(setTargetListFilterName(value)),
    1000
  );

  return (
    <div
      className="px-4"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        width: "100%",
        height: "64px",
        flexShrink: 0
      }}
    >
      <Input
        startIcon={<SearchIcon color={""} size={0} />}
        placeholder="Cerca nelle liste"
        onChangeText={(value) => debounce(value)}
        defaultValue={targetListsState.filters.name}
      />
      {!(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
        <SelectCustom
          isClearable
          placeholder="Team Leaders"
          options={
            usersState.findTeamLeadersResponse?.data.map((data) => ({
              label: data.name + " " + data.surname,
              value: data.id,
            }))!
          }
          onChange={(value) => dispatch(setTargetListFilterTeamLeader(value))}
          defaultValue={targetListsState.filters.teamLeader}
        />
      }
      {!(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
        <SelectCustom
          isClearable
          placeholder="Commerciali"
          options={
            usersState.findCommercialsResponse?.data.map((data) => ({
              label: data.name + " " + data.surname,
              value: data.id,
            }))!
          }
          onChange={(value) => dispatch(setTargetListFilterCommercial(value))}
          defaultValue={targetListsState.filters.commercial}
        />
      }
      <SelectCustom
        isClearable
        placeholder="Settore"
        options={groupBy(atecosState.findAllAtecosResponse, "sector").map(
          (sector) => ({ label: sector.key, value: sector.key })
        )}
        onChange={(value) => dispatch(setTargetListFilterSector(value))}
        defaultValue={targetListsState.filters.sector}
      />
      <SelectCustom
        isClearable
        placeholder="Categoria"
        options={groupBy(atecosState.findAllAtecosResponse, "category").map(
          (sector) => ({ label: sector.key, value: sector.key })
        )}
        onChange={(value) => dispatch(setTargetListsFilterCategory(value))}
        defaultValue={targetListsState.filters.category}
      />
      <SelectCustom
        isClearable
        placeholder="Stato"
        options={[
          { label: "Da iniziare", value: TargetListStatusEnum.TOSTART },
          { label: "In corso", value: TargetListStatusEnum.INPROGRESS },
          { label: "Sospeso", value: TargetListStatusEnum.SUSPENDED },
          { label: "Concluso", value: TargetListStatusEnum.CONCLUDED },
        ]}
        onChange={(value) => dispatch(setTargetListFilterState(value))}
        defaultValue={targetListsState.filters.state}
      />
      <div>
        <Button
          size={"sm"}
          iconPosition={"only"}
          variant={preferencesState.optionsActive ? "outline" : "ghost"}
          color={preferencesState.optionsActive ? "blue" : "gray"}
          onClick={() =>
            dispatch(setOptionsActive(!preferencesState.optionsActive))
          }
          icon={<MoreHorizontalIcon color={""} size={0} />}
        />
      </div>
    </div>
  );
}
