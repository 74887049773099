import clsx from 'clsx';
import { addDays, addMinutes, areIntervalsOverlapping, format, isBefore, startOfDay, startOfWeek } from 'date-fns';
import { it } from 'date-fns/locale/it';
import { useEffect, useRef, useState } from 'react';
import { EventDTO, EventsFiltersDTO } from '../dto';
import { WeekEvent } from '../events/weekEvent';
import './../style.css';

interface Props {
    filters: EventsFiltersDTO;
    events: EventDTO[]
    setModalVisible: (value: boolean) => void;
    setEventDetails: (events: EventDTO[]) => void;
    setEvent: (event: EventDTO) => void;
}

export function WeekCalendar(props: Props) {
    const { filters, events, setEventDetails, setModalVisible, setEvent } = props;
    const weekStart = startOfWeek(filters.fromDateTime!, { weekStartsOn: 1 });

    const daysOfWeek = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

    const timeSlots = Array.from({ length: 48 }, (_, i) => addMinutes(startOfDay(weekStart), i * 30));

    const tableRef = useRef<HTMLTableElement | null>(null)

    const [currentTimeIndex, setCurrentTimeIndex] = useState<number | null>(null);
    const [currentTime, setCurrentTime] = useState(new Date());

    const getEventPosition = (event: { start: Date; end: Date }, otherEventsOfTheDay: { start: Date; end: Date }[]) => {
        const start = new Date(event.start)
        const end = new Date(event.end)
        const startIndex = (start.getHours() * 2) + (start.getMinutes() / 30);
        const endIndex = (end.getHours() * 2) + (end.getMinutes() / 30);
        let overlaps = 0
        let offset = 0
        otherEventsOfTheDay.forEach(otherEvent => {
            if (areIntervalsOverlapping(event, otherEvent, { inclusive: true })) {
                overlaps++
                if (isBefore(start, otherEvent.start))
                    offset++
            }
        })
        let width = `${95 / (overlaps + 1)}%`
        return {
            rowStart: startIndex.toFixed(0),
            rowSpan: endIndex - startIndex,
            width,
            offset: `${(95 / (overlaps + 1) * offset)}%`
        };
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        const currentTime = new Date();
        const currentTimeIndex = (currentTime.getHours() * 2) + (currentTime.getMinutes() / 30);

        if (tableRef.current) {
            const row = tableRef.current.querySelector(`tr:nth-child(${Math.floor(currentTimeIndex) + 2})`);
            if (row) {
                row.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, []);

    useEffect(() => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();

        const fraction = minutes / 30;
        const index = (hours * 2) + fraction;

        setCurrentTimeIndex(index);
    }, [currentTime]);

    return (
        <div className="relative w-full h-full overflow-auto">
            <table ref={tableRef} className="w-full h-full calendar-table border-collapse">
                <thead className="sticky top-0">
                    <tr className='border-collapse'>
                        <th className="w-[72px] border-r border-neutral-200"></th>
                        {daysOfWeek.map((day, index) => (
                            <th
                                key={day.toString()}
                                className={clsx("border-neutral-200 shadow-sm h-full w-full text-center capitalize", {
                                    'border-x': index !== daysOfWeek.length - 1,
                                    'border-l': index === daysOfWeek.length - 1,
                                })}>
                                <div className=''>
                                    {format(day, 'EEE', { locale: it })}
                                    <span className='text-neutral-800'>
                                        {format(day, ' d', { locale: it })}
                                    </span>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {timeSlots.map((time, index) => (
                        <tr key={index} className="h-12">
                            <td className="border-0 relative">
                                <div className='text-text-xs text-neutral-400 absolute top-[-8px] right-3 font-semibold text-right h-full'>
                                    {index % 2 === 0 ? format(time, 'h aa') : ''}
                                </div>
                            </td>
                            {daysOfWeek.map((day) => (
                                <td key={day.toString()} className="border relative">
                                    {events.filter(_event => format(_event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')).map((event, i) => {
                                        const { rowStart, rowSpan, width, offset } = getEventPosition({ start: event.startDate, end: event.endDate }, events.filter(_event => format(_event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')).filter(_event => _event.id !== event.id).map(_event => ({ start: _event.startDate, end: _event.endDate })));
                                        if (rowStart === index.toFixed(0)) {
                                            return (
                                                <WeekEvent setEvent={event => setEvent(event)} width={width} event={event} height={`${rowSpan * 100}%`} offset={offset} />
                                            );
                                        }
                                    })}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {currentTimeIndex !== null && (
                <div
                    className="absolute left-0 right-0 z-20 flex items-center justify-end gap-2"
                    style={{
                        top: `${currentTimeIndex * 48}px`,
                        width: '100%',
                    }}
                >
                    <span className='whitespace-nowrap text-brandPrimary-500 text-[10px] font-semibold bg-white'>
                        {format(currentTime, 'h:mm aa')}
                    </span>
                    <div className='flex items-center' style={{ width: 'calc(100% - 68px)' }}>
                        <div className='h-2 w-2 rounded bg-brandPrimary-500' />
                        <div className='w-full h-[1px] bg-brandPrimary-500' />
                    </div>
                </div>
            )}
        </div >
    );
}
