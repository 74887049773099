import { cdAxios } from "../axios";
import { keycloak } from "../keycloak";
import { FindAllPreferencesResponse } from "./dto";
import { CreatePreferenceRequest } from "./dto";
import { PreferencesService } from "./service";

export class PreferencesServiceImpl implements PreferencesService {
  public findAllPreferences(
    owner: string
  ): Promise<FindAllPreferencesResponse> {
    return cdAxios
      .get("/api/preferences", {
        params: {
          owner: owner,
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createPreferences(data: CreatePreferenceRequest): Promise<void> {
    return cdAxios
      .post("/api/preferences", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public updatePreferences(
    data: CreatePreferenceRequest,
    id: string
  ): Promise<void> {
    return cdAxios
      .put("/api/preferences/" + id, {
        ...data,
        companyPreferences: data.companyPreferences.map(data => ({ column: data.column, visible: data.visible, width: data.width })),
        personPreferences: data.personPreferences.map(data => ({ column: data.column, visible: data.visible, width: data.width })),
        targetListPreferences: data.targetListPreferences.map(data => ({ column: data.column, visible: data.visible, width: data.width })),
        targetListCompanyPreferences: data.targetListCompanyPreferences.map(data => ({ column: data.column, visible: data.visible, width: data.width })),
        targetListPersonPreferences: data.targetListPersonPreferences.map(data => ({ column: data.column, visible: data.visible, width: data.width })),
      }, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
