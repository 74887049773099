import clsx from "clsx";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Filters } from "../../../../lib/filters";
import { PeopleHorizontalFilters } from "../../../../lib/filters/accordion/horizontalFiltersPerson";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../../lib/types";
import { Spinner } from "../../../../ui/molecules/spinner";
import { setTabValue } from "../../slice";
import { PeopleList } from "./list";
import {
  personCount,
} from "../slice";

interface Props {
  resetFilters: () => void
  filters: FindAllContactsFilters
  updateFilters: (newFilters: Partial<FindAllContactsFilters>) => void
  setPage: (page: number) => void
  status: PromiseStatuses
  contacts: FindContactsDatabaseResponse<ContactType.PERSON>
}

export function People(props: Props) {
  const { resetFilters, filters, updateFilters, setPage, status, contacts } = props
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      peopleState.importCSVStatus === "failed" ||
      peopleState.importCSVStatus === "successfully"
    ) {
      navigate("/database/people");
      dispatch(setTabValue(0));
    }
  }, [peopleState.importCSVStatus]);

  useEffect(() => {
    if (status === "successfully") {
      dispatch(personCount())
    }
  }, [status]);

  return (
    <div className="max-h-full w-full flex" style={{ height: 'calc(100%)' }}>
      <div className="flex w-full">
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters contactType={ContactType.PERSON} filters={filters} resetFilters={() => resetFilters()} updateFilters={(newFilters: Partial<FindAllContactsFilters>) => updateFilters(newFilters)} isInTargetList={false} />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {status === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full">
              <PeopleHorizontalFilters updateFilters={value => updateFilters(value)} filters={filters} isInTargetList={false} />
              <PeopleList contacts={contacts} setPage={page => setPage(page)} updateFilters={value => updateFilters(value)} filters={filters} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
