import { addWeeks, endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setFilters } from '../../../pages/calendar/slice';

export const useWeekCalendar = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.events.filters['week']);

    const changeInterval = (action: 'next' | 'prev' | 'today') => {
        let week = new Date(filters.fromDateTime!);
        let fromDateTime = startOfWeek(week, { weekStartsOn: 1 }).toISOString()
        let toDateTime = endOfWeek(week, { weekStartsOn: 1 }).toISOString()
        if (action === 'next') {
            week = addWeeks(week, 1);
            fromDateTime = startOfWeek(week, { weekStartsOn: 1 }).toISOString()
            toDateTime = endOfWeek(week).toISOString()

        } else if (action === 'prev') {
            week = subWeeks(week, 1);
            fromDateTime = startOfWeek(week, { weekStartsOn: 1 }).toISOString()
            toDateTime = endOfWeek(week, { weekStartsOn: 1 }).toISOString()
        } else {
            week = new Date();
            fromDateTime = startOfWeek(week, { weekStartsOn: 1 }).toISOString()
            toDateTime = endOfWeek(week, { weekStartsOn: 1 }).toISOString()
        }
        dispatch(setFilters({ period: 'week', filters: { fromDateTime, toDateTime } }));
    }

    return {
        filters,
        changeInterval
    };
};
