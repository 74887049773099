import clsx from "clsx";
import { Filters } from "../../../../../lib/filters";
import { PeopleHorizontalFilters } from "../../../../../lib/filters/accordion/horizontalFiltersPerson";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto";
import { useAppSelector } from "../../../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../../../lib/types";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { TargetPeopleList } from "./list";

interface Props {
  showPopup: boolean
  setShowPopup: (action: boolean) => void
  selectedIds: string[]
  setSelectedIds: (action: string[]) => void
  filters: FindAllContactsFilters
  setFilters: (action: Partial<FindAllContactsFilters>) => void
  setPage: (action: number) => void
  resetFilters: () => void
  contacts: FindContactsTargetResponse<ContactType.PERSON>
  status: PromiseStatuses
  reloadContacts: () => void
}

export function TargetListPeopleList(props: Props) {
  const { selectedIds, setSelectedIds, setShowPopup, showPopup, setFilters, setPage, resetFilters, filters, contacts, status, reloadContacts } = props
  const databaseState = useAppSelector((state) => state.database);

  return (
    <div className="max-h-full rounded-2xl w-full flex h-full">
      <div className="flex rounded-2xl w-full">
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters contactType={ContactType.PERSON} isInTargetList={true} resetFilters={() => resetFilters()} filters={filters} updateFilters={arg => setFilters(arg)} />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {status === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full">
              <PeopleHorizontalFilters
                updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                  setFilters(newFilters)
                }}
                filters={filters}
                isInTargetList={true}
              />
              <TargetPeopleList
                reloadContacts={() => reloadContacts()}
                contacts={contacts}
                selectedIds={selectedIds} setSelectedIds={arg => setSelectedIds(arg)} setShowPopup={arg => setShowPopup(arg)} showPopup={showPopup} filters={filters}
                setFilters={function (action: Partial<FindAllContactsFilters>): void {
                  setFilters(action);
                }} setPage={function (action: number): void {
                  setPage(action);
                }} resetFilters={function (): void {
                  resetFilters()
                }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
