import { ContactType } from "../../lib/filters/dto";
import { useCalendar } from "../../lib/hooks/calendar/calendar";
import { Layout } from "../../ui/layout";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { recallSetter } from "../targetList/contacts/recall/useRecall";
import { ModalDetailsList } from "./calendars/modalDetailList";
import { EventsModal } from "./modals";
import { BannerEvents } from "./modals/banner";

export function Calendar() {
    const calendar = useCalendar()

    return (
        <Layout menuItem={MenuItems.CALENDAR} breadcrumbItems={['Calendario']}>
            <BannerEvents reloadEvents={() => calendar.loadEvents()} />
            <div className="w-full p-6">
                <div className="w-full bg-white rounded-2xl border border-neutral-200 flex flex-col">
                    {calendar.filterComponents}
                    {calendar.calendarComponent}
                </div>
            </div>
            <ModalDetailsList
                events={calendar.eventsDetails}
                cleanEvents={() => calendar.setEventDetails([])}
                visible={calendar.visible}
                setVisibile={function (visible: boolean): void {
                    calendar.setVisible(visible)
                }}
            />
            {
                calendar.event !== null &&
                <EventsModal
                    eventType={calendar.event.type}
                    contactType={ContactType.PERSON}
                    type={'edit'}
                    data={recallSetter(calendar.event)!}
                />
            }
        </Layout>
    );
}
