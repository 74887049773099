import qs from "qs";
import { cdAxios } from "../../lib/axios";
import { keycloak } from "../../lib/keycloak";
import { CreateEventDTO, EventResponseDTO, EventsFiltersDTO } from "./dto";
import { EventsService } from "./service";

export class EventsServiceImpl implements EventsService {

  public findAllEvents(filters: EventsFiltersDTO): Promise<EventResponseDTO> {
    return cdAxios
      .get("/api/events", {
        params: filters,
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createEvent(request: CreateEventDTO): Promise<void> {
    return cdAxios
      .post("/api/events", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public updateEvent(request: string, id: string): Promise<void> {
    return cdAxios
      .put("/api/events/" + id,
        {
          notes: request
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            ContentType: "application/json",
            "Access-Control-Allow-Headers":
              "accept, authorization, content-type, x-requested-with",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET",
          },
        })
      .then((response) => response.data);
  }

  public rescheduleEvent(startDate: string, endDate: string, notification: number, id: string): Promise<void> {
    return cdAxios
      .put("/api/events/" + id + "/reschedule",
        {
          startDate,
          endDate,
          notification
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            ContentType: "application/json",
            "Access-Control-Allow-Headers":
              "accept, authorization, content-type, x-requested-with",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET",
          },
        })
      .then((response) => response.data)
  }

  public deleteEvent(id: string): Promise<void> {
    return cdAxios
      .delete("/api/events/" + id,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            ContentType: "application/json",
            "Access-Control-Allow-Headers":
              "accept, authorization, content-type, x-requested-with",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET",
          },
        })
      .then((response) => response.data)
  }
}
