import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { PromiseStatuses } from "../../lib/types";
import { EventResponseDTO, EventsFiltersDTO } from "./dto";
import { NewEventsService } from "./service";

interface EventsState {
    filters: {
        week: EventsFiltersDTO,
        month: EventsFiltersDTO
    }
    events: {
        week: {
            status: PromiseStatuses
            data?: EventResponseDTO
        },
        month: {
            status: PromiseStatuses
            data?: EventResponseDTO
        }
    }
    period: 'month' | 'week'
}

const initialState: EventsState = {
    filters: {
        week: {
            page: 0,
            pageSize: 0,
            sort: '1',
            sortBy: "start_date",
            fromDateTime: startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
            toDateTime: endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString()
        },
        month: {
            page: 0,
            pageSize: 0,
            sort: '1',
            sortBy: "start_date",
            fromDateTime: startOfMonth(new Date()).toISOString(),
            toDateTime: endOfMonth(new Date()).toISOString()
        }
    },
    events: {
        week: {
            status: 'idle'
        },
        month: {
            status: 'idle'
        }
    },
    period: 'month'
};

export const findEvents = createAsyncThunk(
    'events/find',
    async (request: { filters: EventsFiltersDTO, period: 'month' | 'week' }, thunkApi): Promise<{ response: EventResponseDTO, period: 'month' | 'week' }> => {
        const CalendarService = NewEventsService()

        const response = await CalendarService.findAllEvents(request.filters);

        return {
            response,
            period: request.period
        }
    }
)

const eventsSlice = createSlice(
    {
        name: "events",
        initialState,
        reducers: {
            setFilters: (
                state,
                action: PayloadAction<{ period: 'month' | 'week'; filters: Partial<EventsFiltersDTO> }>
            ) => {
                const { period, filters, } = action.payload;
                state.filters[period] = {
                    ...state.filters[period],
                    ...filters
                };
            },
            setPeriod: (state, action) => {
                state.period = action.payload;
            },
            setFindEventsStatus: (
                state,
                action: PayloadAction<{ period: 'month' | 'week', status: PromiseStatuses }>
            ) => {
                const { period, status } = action.payload;
                state.events[period] = {
                    ...state.events[period],
                    status,
                    data: state.events[period].data
                }
            },
        },
        extraReducers(builder) {
            builder.addCase(findEvents.fulfilled, (state, action) => {
                const { period, response } = action.payload;
                state.events[period] = {
                    ...state.events[period],
                    data: response,
                    status: 'successfully',
                };
            });

            builder.addCase(findEvents.pending, (state, action) => {
                const { period } = action.meta.arg;

                state.events[period] = {
                    ...state.events[period],
                    data: {
                        data: [],
                        paginationData: {
                            total: 0,
                            page: 0,
                            perPage: 0,
                            prev: 0,
                            next: 0,
                            totalPage: 0
                        }
                    },
                    status: 'loading',
                };
            });

            builder.addCase(findEvents.rejected, (state, action) => {
                const { period } = action.meta.arg;

                state.events[period] = {
                    ...state.events[period],
                    data: {
                        data: [],
                        paginationData: {
                            total: 0,
                            page: 0,
                            perPage: 0,
                            prev: 0,
                            next: 0,
                            totalPage: 0
                        }
                    },
                    status: 'failed',
                };
            });
        },
    }
)

export const {
    setFilters,
    setPeriod,
    setFindEventsStatus
} = eventsSlice.actions;

export default eventsSlice.reducer;