import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { encodeBase64, groupBy } from "../../../lib/utils";
import { MailIcon } from "../../../ui/icons/mail";
import { SmartphoneIcon } from "../../../ui/icons/smartphone";
import { Layout } from "../../../ui/layout";
import Avatar from "../../../ui/molecules/avatar";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { Spinner } from "../../../ui/molecules/spinner";
import Toggle from "../../../ui/molecules/toggle";
import { BannersList } from "../../../ui/organisms/banner/bannerList";
import FileUploader from "../../../ui/organisms/fileUploader";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { setNewProjectCommercialIds, setNewProjectCustomerId, setNewProjectTeamLeaderId } from "../../projects/slice";
import { UserRole, UserRoleMap } from "../dto";
import {
  findClients,
  findCollaborators,
  newUser,
  setCreateUserCanAccessDatabase,
  setCreateUserCollaborators,
  setCreateUserCompanyAtecoCode,
  setCreateUserCompanyName,
  setCreateUserCompanyRevenue,
  setCreateUserCompanySector,
  setCreateUserCompanyVat,
  setCreateUserCompanyWebsite,
  setCreateUserCustomerId,
  setCreateUserEmail,
  setCreateUserIsAutonomous,
  setCreateUserName,
  setCreateUserPhone,
  setCreateUserRole,
  setCreateUserStatus,
  setCreateUserSurname,
  setValidateNewUserCompanyName,
  setValidateNewUserCompanyVat,
  setValidateNewUserEmail,
  setValidateNewUserName,
  setValidateNewUserPhone,
  setValidateNewUserRole,
  setValidateNewUserStatus,
  setValidateNewUserSurname,
  validateNewUser
} from "../slice";

export function NewUser() {
  const usersState = useAppSelector((state) => state.users);
  const atecosState = useAppSelector((state) => state.atecos);
  const projectsState = useAppSelector((state) => state.projects);

  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  let formData = new FormData();

  const [file, setFile] = useState<File | null>(null);
  const [file64, setFile64] = useState<string>("");

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (usersState.newUserStatus === "failed") {
      timeoutId = setTimeout(() => {
        dispatch(setCreateUserStatus("idle"));
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [usersState.newUserStatus]);

  useEffect(() => {
    if (usersState.validateNewUserStatus === "successfully") {
      if (file !== null) formData.append("file", file);
      formData.append("user", JSON.stringify(usersState.newUserRequest));
      dispatch(newUser(formData));
      dispatch(setValidateNewUserStatus("idle"));
    }
  }, [usersState.validateNewUserStatus]);

  useEffect(() => {
    if (usersState.newUserRequest.role !== UserRole.commercial) {
      dispatch(setCreateUserIsAutonomous(false));
    }
    if (usersState.newUserRequest.role !== UserRole.customer) {
      dispatch(setCreateUserCanAccessDatabase(null));
    }
  }, [usersState.newUserRequest.role]);

  useEffect(() => {
    if (usersState.newUserStatus === "successfully") {
      if (!usersState.createFromProject)
        navigation("/users");
      else {
        switch (usersState.createFromProject) {
          case UserRole.commercial:
            dispatch(setNewProjectCommercialIds([...projectsState.newProjectRequest.commercialIds, usersState.newUserResponse]))
            break;
          case UserRole.team_leader:
            dispatch(setNewProjectTeamLeaderId(usersState.newUserResponse))
            break;
          case UserRole.customer:
            dispatch(setNewProjectCustomerId(usersState.newUserResponse))
            break;
          default:
            break;
        }
        navigation('/new-project')
      }
    }
  }, [usersState.newUserStatus, dispatch]);

  useEffect(() => {
    dispatch(findCollaborators());
    dispatch(findClients());
    dispatch(setCreateUserEmail(""));
    dispatch(setCreateUserRole(undefined));
    dispatch(setCreateUserCompanyAtecoCode(undefined));
    dispatch(setCreateUserCompanyName(undefined));
    dispatch(setCreateUserCompanyRevenue(undefined));
    dispatch(setCreateUserCompanySector(undefined));
    dispatch(setCreateUserCompanyVat(undefined));
    dispatch(setCreateUserCustomerId(undefined));
    dispatch(setCreateUserCollaborators([]));
    dispatch(setCreateUserCanAccessDatabase(null));
    dispatch(setCreateUserName(""));
    dispatch(setCreateUserSurname(""));
    dispatch(setCreateUserPhone(""));
    dispatch(setCreateUserStatus("idle"));
    dispatch(setValidateNewUserRole(false));
    dispatch(setValidateNewUserName(false));
    dispatch(setValidateNewUserSurname(false));
    dispatch(setValidateNewUserEmail(false));
    dispatch(setValidateNewUserPhone(false));
    dispatch(setValidateNewUserCompanyVat(false));
    dispatch(setValidateNewUserCompanyName(false));
    setFile(null);
    setFile64("");
  }, []);

  useEffect(() => {
    if (usersState.newUserRequest.companyAtecoCode === undefined) {
      dispatch(setCreateUserCompanySector(undefined));
    } else {
      const sector = atecosState.findAllAtecosResponse.find(
        (_ateco) =>
          _ateco.atecoCode === usersState.newUserRequest.companyAtecoCode
      )?.sector;
      if (sector && usersState.newUserRequest.companySector !== sector) {
        dispatch(setCreateUserCompanySector(sector));
      }
    }
  }, [usersState.newUserRequest.companyAtecoCode]);

  useEffect(() => {
    dispatch(setCreateUserRole(usersState.createFromProject))
  }, [usersState.createFromProject])

  const filteredAtecos = atecosState.findAllAtecosResponse.filter(
    (ateco) =>
      usersState.newUserRequest.companyAtecoCode !== undefined
        ? usersState.newUserRequest.companyAtecoCode === ateco.atecoCode
        : ateco
  );


  return (
    <Layout
      menuItem={MenuItems.USERS}
      breadcrumbItems={["Utenti", "Nuovo utente"]}
      headerLabel={"Nuovo utente"}
      headerBackPath={usersState.createFromProject ? '/new-project' : "/users"}
      headerChildren={
        <div className="flex items-center h-[100%] w-[100%] justify-end">
          {usersState.newUserStatus === "loading" ||
            usersState.validateNewUserStatus === "loading" ? (
            <Spinner />
          ) : (
            <Button
              size={"sm"}
              iconPosition={"off"}
              label="Salva utente"
              variant={"solid"}
              color={"blue"}
              onClick={() => {
                dispatch(validateNewUser(usersState.newUserRequest));
              }}
            />
          )}
        </div>
      }
    >
      <div className="gap-[24px] h-full max-h-full flex flex-col w-[100%] p-[16px]">
        <BannersList banners={[{
          type: "error",
          visible: usersState.newUserStatus === "failed",
          label: "Si è verificato un errore durante la creazione dell'utente",
          closeAction: () => dispatch(setCreateUserStatus("idle")),
        }]} />
        <div className="p-[24px] gap-[20px] h-full max-h-full overflow-auto flex flex-col bg-white shadow-sm rounded-[12px]">
          <div className="flex gap-[24px] w-[100%] items-start">
            <div className="w-[240px] text-label-md font-medium text-neutral-600">
              Ruolo <span className="text-red-600"> *</span>
            </div>
            <div className="flex gap-[16px] w-[100%]">
              <SelectCustom
                isClearable
                disabled={usersState.createFromProject !== undefined}
                defaultValue={usersState.newUserRequest.role}
                error={usersState.createUserErrors.role}
                errorLabel="Inserire un ruolo."
                placeholder={"Seleziona un ruolo"}
                key={"new-user-roles-list"}
                onChange={(value) => {
                  dispatch(setCreateUserRole(value));
                  dispatch(setValidateNewUserRole(false));
                }}
                options={[
                  {
                    label: UserRoleMap.get(UserRole.collaborator)!,
                    value: UserRole.collaborator,
                  },
                  {
                    label: UserRoleMap.get(UserRole.commercial)!,
                    value: UserRole.commercial,
                  },
                  {
                    label: UserRoleMap.get(UserRole.customer)!,
                    value: UserRole.customer,
                  },
                  {
                    label: UserRoleMap.get(UserRole.team_leader)!,
                    value: UserRole.team_leader,
                  },
                  {
                    label: UserRoleMap.get(UserRole.admin)!,
                    value: UserRole.admin,
                  }
                ]}
              />
              {usersState.newUserRequest?.role === UserRole.commercial && (
                <div className="min-w-[150px] flex">
                  <Toggle
                    key={"create-user-is-autonomous-list"}
                    onChange={(e) => dispatch(setCreateUserIsAutonomous(e))}
                    checked={usersState.newUserRequest.isAutonomous}
                    placeholder="È autonomo"
                  />
                </div>
              )}
              {usersState.newUserRequest?.role === UserRole.customer && (
                <div className="min-w-[280px] flex">
                  <Toggle
                    key={"create-user-can-access-db"}
                    onChange={(e) => dispatch(setCreateUserCanAccessDatabase(e))}
                    checked={usersState.newUserRequest.canAccessDatabase !== null ? usersState.newUserRequest.canAccessDatabase : false}
                    placeholder="Può accedere alle liste target"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-[24px] w-[100%] items-start">
            <div className="w-[240px] text-label-md font-medium text-neutral-600">
              Email <span className="text-red-600"> *</span>
            </div>
            <div className="flex gap-[16px] w-[100%]">
              <Input
                supportingText={
                  usersState.createUserErrors.email
                    ? usersState.createUserErrors.errorLabel
                    : undefined
                }
                error={usersState.createUserErrors.email}
                startIcon={<MailIcon size={0} color="" />}
                placeholder="Email"
                onChangeText={(value) => {
                  dispatch(setCreateUserEmail(value));
                  if (usersState.createUserErrors.email)
                    dispatch(setValidateNewUserEmail(false));
                }}
                value={usersState.newUserRequest?.email}
              />
            </div>
          </div>
          <div className="flex gap-[24px] w-[100%] items-start">
            <div className="w-[240px] text-label-md font-medium text-neutral-600">
              Nome <span className="text-red-600"> *</span>
            </div>
            <div className="flex gap-[16px] w-[100%]">
              <Input
                supportingText={
                  usersState.createUserErrors.name
                    ? "Inserire il nome."
                    : undefined
                }
                error={usersState.createUserErrors.name}
                placeholder="Nome"
                onChangeText={(value) => {
                  dispatch(setCreateUserName(value));
                  if (usersState.createUserErrors.name)
                    dispatch(setValidateNewUserName(false));
                }}
                value={usersState.newUserRequest?.name}
              />
              <Input
                supportingText={
                  usersState.createUserErrors.surname
                    ? "Inserire il cognome."
                    : undefined
                }
                error={usersState.createUserErrors.surname}
                placeholder="Cognome"
                onChangeText={(value) => {
                  dispatch(setCreateUserSurname(value));
                  if (usersState.createUserErrors.surname)
                    dispatch(setValidateNewUserSurname(false));
                }}
                value={usersState.newUserRequest?.surname}
              />
            </div>
          </div>
          <div className="flex gap-[24px] w-[100%] items-start">
            <div className="w-[240px] text-label-md font-medium text-neutral-600">
              Telefono <span className="text-red-600"> *</span>
            </div>
            <div className="flex gap-[16px] w-[100%]">
              <Input
                supportingText={
                  usersState.createUserErrors.phone
                    ? "Inserire un numero di telefono valido."
                    : undefined
                }
                error={usersState.createUserErrors.phone}
                placeholder="Telefono"
                startIcon={<SmartphoneIcon color={""} size={0} />}
                onChangeText={(value) => {
                  dispatch(setCreateUserPhone(value));
                  if (usersState.createUserErrors.phone)
                    dispatch(setValidateNewUserPhone(false));
                }}
                value={usersState.newUserRequest?.phone}
              />
            </div>
          </div>
          {usersState.newUserRequest.role === UserRole.customer && (
            <>
              <div className="flex gap-[24px] w-[100%] items-start">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  Collaboratori
                </div>
                <div className="flex gap-[16px] w-[100%]">
                  <SelectCustom
                    placeholder={"Seleziona i collaboratori"}
                    onChange={(value) =>
                      dispatch(setCreateUserCollaborators(value))
                    }
                    isMulti
                    isClearable
                    defaultValue={usersState.newUserRequest.collaborators}
                    noOptionsMessage="Nessun collaboratore trovato"
                    key={"new-user-collaborators-list"}
                    options={
                      usersState.findCollaboratorsResponse?.data
                        .filter(
                          (collaborator) =>
                            collaborator.customerId === null ||
                            collaborator.customerId === ""
                        )
                        .map((value) => ({
                          label: value.name + " " + value.surname,
                          value: value.id,
                        }))!
                    }
                  />
                </div>
              </div>
              <div className="flex gap-[24px] w-[100%] items-start">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  Nome azienda <span className="text-red-600"> *</span>
                </div>
                <div className="flex gap-[16px] w-[100%]">
                  <Input
                    supportingText={
                      usersState.createUserErrors.companyName
                        ? "Inserire un nome valido.."
                        : undefined
                    }
                    error={usersState.createUserErrors.companyName}
                    placeholder="Nome azienda"
                    onChangeText={(value) => {
                      dispatch(setCreateUserCompanyName(value));
                      if (usersState.createUserErrors.companyName)
                        dispatch(setValidateNewUserCompanyName(false));
                    }}
                    value={usersState.newUserRequest?.companyName}
                  />
                </div>
              </div>
              <div className="flex gap-[24px] w-[100%] items-start">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  P. IVA azienda
                </div>
                <div className="flex gap-[16px] w-[100%]">
                  <Input
                    supportingText={
                      usersState.createUserErrors.companyVat
                        ? "Inserire una partita IVA valida."
                        : undefined
                    }
                    error={usersState.createUserErrors.companyVat}
                    placeholder="P. IVA"
                    onChangeText={(value) => {
                      dispatch(setCreateUserCompanyVat(value));
                      if (usersState.createUserErrors.companyVat)
                        dispatch(setValidateNewUserCompanyVat(false));
                    }}
                    value={usersState.newUserRequest?.companyVat}
                  />
                </div>
              </div>
              <div className="flex gap-[24px] w-[100%] items-start">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  Codice ATECO
                </div>
                <div className="flex gap-[16px] w-[100%]">
                  <SelectCustom
                    placeholder={"Cerca per ATECO"}
                    onChange={(value) =>
                      dispatch(setCreateUserCompanyAtecoCode(value))
                    }
                    isClearable
                    key={"new-user-ateco-code"}
                    defaultValue={usersState.newUserRequest.companyAtecoCode}
                    noOptionsMessage="Non sono presenti ateco."
                    options={atecosState.findAllAtecosResponse.map(
                      (ateco) => ({
                        value: ateco.atecoCode,
                        label: ateco.atecoCode + " - " + ateco.atecoDescription,
                      })
                    )}
                  />
                </div>
              </div>
              <div className="flex gap-[24px] w-[100%] items-start">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  Settore
                </div>
                <div className="flex gap-[16px] w-[100%]">
                  <SelectCustom
                    placeholder={"Seleziona il settore"}
                    disabled
                    key={"new-user-sector"}
                    defaultValue={usersState.newUserRequest.companySector}
                    options={groupBy(filteredAtecos, "sector").map(
                      (sector) => ({ value: sector.key, label: sector.key })
                    )}
                  />
                </div>
              </div>
              <div className="flex gap-[24px] w-[100%] items-start">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  Fatturato azienda
                </div>
                <div className="flex gap-[16px] w-[100%]">
                  <NumericFormat
                    prefix="€ "
                    value={usersState.newUserRequest.companyRevenue}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    customInput={Input}
                    id={"new-user-request-company-revenue"}
                    key={"new-user-request-company-revenue"}
                    placeholder={"Fatturato"}
                    onValueChange={(e) => {
                      dispatch(setCreateUserCompanyRevenue(e.floatValue));
                    }}
                  />
                </div>
              </div>
              <div className="flex gap-[24px] w-[100%]">
                <div className="w-[240px] text-label-md font-medium text-neutral-600">
                  Sito web
                </div>
                <Input
                  placeholder="Sito web dell'azienda"
                  onChangeText={(value) => {
                    dispatch(setCreateUserCompanyWebsite(value));
                  }}
                  value={usersState.newUserRequest?.companyWebsite}
                />
              </div>
            </>
          )}
          {usersState.newUserRequest.role === UserRole.collaborator && (
            <div className="flex gap-[24px] w-[100%] items-start">
              <div className="w-[240px] text-label-md font-medium text-neutral-600">
                Cliente
              </div>
              <div className="flex gap-[16px] w-[100%]">
                <SelectCustom
                  placeholder={"Seleziona il cliente"}
                  key={"new-user-customers-list"}
                  isClearable
                  defaultValue={usersState.newUserRequest.customerId}
                  onChange={(value) => dispatch(setCreateUserCustomerId(value))}
                  options={
                    usersState.findClientsResponse?.data.map((value) => ({
                      label: value.name + " " + value.surname,
                      value: value.id,
                    }))!
                  }
                />
              </div>
            </div>
          )}
          <div className="flex gap-[24px] w-[100%] items-start">
            <div className="w-[240px] text-label-md font-medium text-neutral-600">
              Immagine del profilo
            </div>
            <div className="flex gap-[16px] w-[100%]">
              <Avatar
                type="single"
                size={"2xl"}
                shape={"circle"}
                imageUrl={file64}
                fullName={(usersState.newUserRequest.name || "") + " " +
                  (usersState.newUserRequest.surname || "")}
                altTextInitials={
                  (usersState.newUserRequest.name[0] || "") +
                  (usersState.newUserRequest.surname[0] || "")
                }
              />
              <FileUploader
                id={"new-user-file-uploader"}
                style={"small"}
                onChange={(e) => {
                  setFile(e[0]);
                  e[0].arrayBuffer().then((_e) => setFile64(encodeBase64(_e)));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
