import axios from "axios";
import { keycloak } from "../keycloak";
import qs from "qs";

export const cdAxios = axios.create({
    paramsSerializer: (params) => {
        return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
        });
    },
    headers: {
        Authorization: 'Bearer ' + keycloak.token,
        Accept: '*/*',
        ContentType: 'application/json',
    }
});