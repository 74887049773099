import { useCallback, useEffect } from 'react';
import { ContactType, FindAllContactsFilters } from '../../filters/dto';
import { resetFilters, setFilters, setPage } from '../../filters/filtersSlice';
import { findContactsTarget } from '../../filters/targetContactsSlice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { TargetSection } from "../../utils";

export const useContactsTarget = (section: TargetSection, targetList: string | undefined, contactType: ContactType) => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.filters[section][contactType]);
    const contacts = useAppSelector(state => state.targetContacts[section][contactType]);

    const findContacts = useCallback(() => {
        if (targetList) {
            dispatch(findContactsTarget({ filters: { ...filters, targetList: [{ value: targetList, label: '' }] }, section, contactType }));
        }
    }, [dispatch, filters, section, targetList]);

    useEffect(() => {
        if (targetList) {
            dispatch(resetFilters({ section, contactType, targetList }));
        }
    }, [dispatch, section, targetList]);

    useEffect(() => {
        if (targetList) {
            findContacts();
        }
    }, [findContacts]);

    const updateFilters = (newFilters: Partial<FindAllContactsFilters>) => {
        dispatch(setFilters({ section, filters: newFilters, contactType, targetList: targetList ?? null }));
    };

    const resetAllFilters = () => {
        dispatch(resetFilters({ section, contactType, targetList: targetList ?? null }));
    };

    const changePage = (page: number) => {
        dispatch(setPage({ section, page, contactType }));
    };

    return {
        filters: filters,
        contacts: contacts.response,
        status: contacts.status,
        updateFilters,
        resetAllFilters,
        changePage,
        findContacts
    };
};
