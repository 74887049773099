import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import EventsReducer from "../../pages/calendar/slice";
import CompaniesReducer from "../../pages/database/companies/slice";
import OtpReducer from "../../pages/database/people/privacy/slice";
import PeopleReducer from "../../pages/database/people/slice";
import DatabaseReducer from "../../pages/database/slice";
import ParticularitiesReducer from "../../pages/particularities/slice";
import MailsReducer from "../../pages/projects/mails/slice";
import ProjectsReducer from "../../pages/projects/slice";
import RecallsSlice from '../../pages/targetList/contacts/recall/slice';
import ContactsSlice from "../../pages/targetList/contacts/slice";
import TargetListReducer from "../../pages/targetList/slice";
import UsersReducer from "../../pages/users/slice";
import LayoutReducer from "../../ui/layout/slice";
import AtecosReducer from "../atecos/slice";
import DatabaseContactsReducer from "../filters/databaseContactsSlice";
import FiltersSlice from "../filters/filtersSlice";
import TargetContactsReducer from "../filters/targetContactsSlice";
import KeycloakReducer from "../keycloak/slice";
import PreferencesReducer from "../preferences/slice";

export const store = configureStore({
  reducer: {
    keycloak: KeycloakReducer,
    layout: LayoutReducer,
    users: UsersReducer,
    database: DatabaseReducer,
    people: PeopleReducer,
    companies: CompaniesReducer,
    atecos: AtecosReducer,
    preferences: PreferencesReducer,
    projects: ProjectsReducer,
    targetList: TargetListReducer,
    mails: MailsReducer,
    particularities: ParticularitiesReducer,
    otp: OtpReducer,
    targetContacts: TargetContactsReducer,
    databaseContacts: DatabaseContactsReducer,
    filters: FiltersSlice,
    contacts: ContactsSlice,
    recalls: RecallsSlice,
    events: EventsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
