import { Router } from "@remix-run/router";
import { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { BlackList } from "../../pages/blackList";
import { Calendar } from "../../pages/calendar";
import { Dashboard } from "../../pages/dashboard";
import { Database } from "../../pages/database";
import { ImportCompanies } from "../../pages/database/companies/import";
import { ImportPeople } from "../../pages/database/people/import";
import { Particularities } from "../../pages/particularities";
import { Projects } from "../../pages/projects";
import { EditProject } from "../../pages/projects/editProject";
import { NewProject } from "../../pages/projects/newProject";
import { TargetListBins } from "../../pages/targetList/bins";
import { EditTargetListPage } from "../../pages/targetList/editTargetList";
import { TargetLists } from "../../pages/targetList/list";
import { EditUser } from "../../pages/users/forms/editUser";
import { NewUser } from "../../pages/users/forms/newUser";
import { Users } from "../../pages/users/list";
import { VerifyBins } from "../../pages/verifyBins";
import { setMenuItems } from "../../ui/layout/slice";
import { Spinner } from "../../ui/molecules/spinner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { keycloak } from "../keycloak";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

const adminRouter = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/verify-bins/:tab",
    element: <VerifyBins />,
  },
  {
    path: "/database/:tab",
    element: <Database />,
  },
  {
    path: "/import-people",
    element: <ImportPeople />,
  },
  {
    path: "/import-companies",
    element: <ImportCompanies />,
  },
  {
    path: "/target-list",
    element: <TargetLists />,
  },
  {
    path: "/edit-target-list/:id/:tab",
    element: <EditTargetListPage />,
  },
  {
    path: "/target-list-bins/:id",
    element: <TargetListBins />,
  },
  {
    path: "/new-project",
    element: <NewProject />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/edit-user/:id",
    element: <EditUser />,
  },
  {
    path: "/edit-project/:id/:projectTab/:targetId?/:targetTab?",
    element: <EditProject />,
  },
  {
    path: "/new-user",
    element: <NewUser />,
  },
  {
    path: "/particularities",
    element: <Particularities />,
  },
  {
    path: "/black-list/:tab",
    element: <BlackList />,
  },
  {
    path: "/pipeline",
    element: <Dashboard />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
  {
    path: "/report",
    element: <Dashboard />,
  },
  {
    path: "/settings",
    element: <Dashboard />,
  },
]);

const teamLeaderRouter = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/import-people",
    element: <ImportPeople />,
  },
  {
    path: "/import-companies",
    element: <ImportCompanies />,
  },
  {
    path: "/black-list/:tab",
    element: <BlackList />,
  },
  {
    path: "/verify-bins/:tab",
    element: <VerifyBins />,
  },
  {
    path: "/database/:tab",
    element: <Database />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/edit-user/:id",
    element: <EditUser />,
  },
  {
    path: "/target-list-bins/:id",
    element: <TargetListBins />,
  },
  {
    path: "/target-list",
    element: <TargetLists />,
  },
  {
    path: "/edit-target-list/:id/:tab",
    element: <EditTargetListPage />,
  },
  {
    path: "/new-project",
    element: <NewProject />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/edit-project/:id/:projectTab/:targetId?/:targetTab?",
    element: <EditProject />,
  },
  {
    path: "/particularities",
    element: <Particularities />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
]);

const commercialRouter = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/black-list/:tab",
    element: <BlackList />,
  },
  {
    path: "/verify-bins/:tab",
    element: <VerifyBins />,
  },
  {
    path: "/database/:tab",
    element: <Database />,
  }, {
    path: "/target-list-bins/:id",
    element: <TargetListBins />,
  },
  {
    path: "/target-list",
    element: <TargetLists />,
  },
  {
    path: "/edit-target-list/:id/:tab",
    element: <EditTargetListPage />,
  },
  {
    path: "/new-project",
    element: <NewProject />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/edit-project/:id/:projectTab/:targetId?/:targetTab?",
    element: <EditProject />,
  },
  {
    path: "/particularities",
    element: <Particularities />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
]);

const customerRouter = createBrowserRouter([
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/target-list-bins/:id",
    element: <TargetListBins />,
  },
  {
    path: "/target-list",
    element: <TargetLists />,
  },
  {
    path: "/edit-target-list/:id/:tab",
    element: <EditTargetListPage />,
  },
  {
    path: "/new-project",
    element: <NewProject />,
  },
  {
    path: "/projects",
    element: <Projects />,
  },
  {
    path: "/edit-project/:id/:projectTab/:targetId?/:targetTab?",
    element: <EditProject />,
  },
  {
    path: "/calendar",
    element: <Calendar />,
  },
]);

export function KeycloakRouter() {
  const roles = useAppSelector((state) => state.keycloak.userRoles);
  const usersState = useAppSelector(state => state.users)

  const dispatch = useAppDispatch();

  const [router, setRouter] = useState<Router | undefined>(undefined);

  useEffect(() => {
    if (
      roles !== undefined &&
      roles.length > 0 &&
      keycloak.token !== undefined
    ) {
      if (keycloak.hasRealmRole("admin")) {
        setRouter(adminRouter);
        dispatch(
          setMenuItems([
            { item: MenuItems.DASHBOARD, section: 1 },
            { item: MenuItems.DATABASE, section: 1 },
            { item: MenuItems.TARGET_LIST, section: 1 },
            { item: MenuItems.PROJECTS, section: 1 },
            { item: MenuItems.USERS, section: 1 },
            { item: MenuItems.PARTICULARITIES, section: 1 },
            { item: MenuItems.PIPELINE, section: 2 },
            { item: MenuItems.CALENDAR, section: 2 },
            { item: MenuItems.REPORT, section: 2 },
            { item: MenuItems.BLACK_LIST, section: 3 },
            { item: MenuItems.VERIFY_BINS, section: 3 },
            { item: MenuItems.SETTINGS, section: 4 },
          ])
        );
      } else if (keycloak.hasRealmRole("team_leader")) {
        setRouter(teamLeaderRouter);
        dispatch(
          setMenuItems([
            { item: MenuItems.DASHBOARD, section: 1 },
            { item: MenuItems.DATABASE, section: 1 },
            { item: MenuItems.USERS, section: 1 },
            { item: MenuItems.PROJECTS, section: 1 },
            { item: MenuItems.TARGET_LIST, section: 1 },
            { item: MenuItems.PARTICULARITIES, section: 1 },
            { item: MenuItems.BLACK_LIST, section: 3 },
            { item: MenuItems.VERIFY_BINS, section: 3 },
            { item: MenuItems.CALENDAR, section: 2 },
          ])
        );
      } else if (keycloak.hasRealmRole("commercial")) {
        setRouter(commercialRouter);
        if (!usersState.findMeResponse?.isAutonomous)
          dispatch(
            setMenuItems([
              { item: MenuItems.DASHBOARD, section: 1 },
              { item: MenuItems.PROJECTS, section: 1 },
              { item: MenuItems.TARGET_LIST, section: 1 },
              { item: MenuItems.CALENDAR, section: 2 },
            ])
          );
        else
          dispatch(
            setMenuItems([
              { item: MenuItems.DASHBOARD, section: 1 },
              { item: MenuItems.DATABASE, section: 1 },
              { item: MenuItems.PROJECTS, section: 1 },
              { item: MenuItems.TARGET_LIST, section: 1 },
              { item: MenuItems.PARTICULARITIES, section: 1 },
              { item: MenuItems.BLACK_LIST, section: 2 },
              { item: MenuItems.VERIFY_BINS, section: 2 },
              { item: MenuItems.CALENDAR, section: 2 },
            ])
          );
      } else if (keycloak.hasRealmRole("customer") || keycloak.hasRealmRole("collaborator")) {
        setRouter(customerRouter);
        dispatch(setMenuItems([
          { item: MenuItems.DASHBOARD, section: 1, },
          { item: MenuItems.TARGET_LIST, section: 2 },
          { item: MenuItems.PROJECTS, section: 2 }
        ]));
      } else {
        return;
      }
    }
  }, [roles, keycloak.token]);

  if (router === undefined) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return <RouterProvider router={router} />;
}
