import { eachWeekOfInterval, format, getWeek } from "date-fns";
import { it } from "date-fns/locale/it";
import { ChevronIcon } from "../../../ui/icons/chevron";
import Button from "../../../ui/molecules/button";
import { EventsFiltersDTO } from "../dto";
import { ChangePeriod } from "./changePeriod";

interface Props {
    filters: EventsFiltersDTO
    changePeriod: (period: 'month' | 'week') => void
    changeInterval: (action: "next" | "prev" | "today") => void
}

export function MonthCalendarFilters(props: Props) {
    const { filters, changePeriod, changeInterval } = props

    const getWeeksOfMonth = () => {
        const weeks = eachWeekOfInterval({ start: filters.fromDateTime!, end: filters.toDateTime! }, { weekStartsOn: 1 })
            .map(date => getWeek(date, { weekStartsOn: 1 }));

        return weeks;
    };

    return (
        <div className="p-6 flex gap-2 items-center justify-between">
            <div className="flex gap-4 items-center">
                <span className="capitalize text-heading-sm font-bold text-neutral-800">
                    {filters.fromDateTime && format(new Date(filters.fromDateTime), 'MMMM yyyy', { locale: it })}
                </span>
                <div className="rounded border border-neutral-200 px-[6px] text-text-sm font-semibold text-neutral-600">
                    Sett {getWeeksOfMonth()[0] + " - " + getWeeksOfMonth()[getWeeksOfMonth().length - 1]}
                </div>
            </div>
            <div className="flex gap-3 items-center">
                <ChangePeriod period="month" changePeriod={period => changePeriod(period)} />
                <div className="shadow-xs rounded-lg border border-neutral-200 flex items-center h-full transition-all">
                    <Button onClick={() => changeInterval('prev')} size={"md"} iconPosition={"only"} variant={"ghost"} color={"gray"} icon={<ChevronIcon color={""} size={0} direction="l" />} />
                    <div onClick={() => changeInterval('today')} className="text-label-sm h-full cursor-pointer font-semibold text-neutral-800 border-x border-x-neutral-200 flex items-center px-3">Oggi</div>
                    <Button onClick={() => changeInterval('next')} size={"md"} iconPosition={"only"} variant={"ghost"} color={"gray"} icon={<ChevronIcon color={""} size={0} direction="r" />} />
                </div>
            </div>
        </div >
    )
}