import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { groupBy } from "../../../../lib/utils"
import { TrashIcon } from "../../../../ui/icons/trash"
import Button from "../../../../ui/molecules/button"
import { SelectCustom } from "../../../../ui/molecules/select"
import { setNewTargetListAtecos } from "../../../targetList/slice"

interface OptionType {
    label: string, value: string
}

export function AtecosSegment() {
    const targetListState = useAppSelector(state => state.targetList)
    const atecosState = useAppSelector(state => state.atecos)

    const defaultAtecos = atecosState.findAllAtecosResponse.map((sector) => ({ label: sector.atecoCode + ' - ' + sector.atecoDescription, value: sector.atecoCode }))

    const defaultSectors = groupBy(
        atecosState.findAllAtecosResponse,
        "sector"
    ).map((sector) => ({ label: sector.key, value: sector.key }))

    const defaultCategories = groupBy(
        atecosState.findAllAtecosResponse
        , "category"
    ).map((category) => ({ label: category.key, value: category.key }))

    const [options, setOptions] = useState<{ ateco: OptionType[], sector: OptionType[], category: OptionType[] }[]>([{ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories }])

    useEffect(() => {
        setOptions([{ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories }])
    }, [])

    const dispatch = useAppDispatch()

    function getFilteredOptions(category: string | null, sector: string | null) {
        const filteredAtecos = atecosState.findAllAtecosResponse.filter((ateco) => {
            return (
                (!category || ateco.category === category) &&
                (!sector || ateco.sector === sector)
            );
        });

        const atecoOptions = filteredAtecos.map((ateco) => ({
            label: ateco.atecoCode + ' - ' + ateco.atecoDescription,
            value: ateco.atecoCode,
        }));

        const sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
            label: sector.key,
            value: sector.key,
        }));

        const categoryOptions = groupBy(filteredAtecos, "category").map((category) => ({
            label: category.key,
            value: category.key,
        }));

        return { atecoOptions, sectorOptions, categoryOptions };
    }

    function handleChangeAtecos(e: string | undefined, index: number) {
        const atecos = [...targetListState.newTargetListRequest.atecos];
        const selectedAteco = atecosState.findAllAtecosResponse.find(
            (ateco) => ateco.atecoCode === e
        );

        if (e) {
            atecos[index] = {
                atecoCode: e,
                sector: selectedAteco?.sector ?? null,
                category: selectedAteco?.category ?? null,
                atecoDescription: null
            };
        } else {
            atecos[index] = { atecoCode: null, sector: null, category: null, atecoDescription: null };
        }

        dispatch(setNewTargetListAtecos([...atecos]));

        const { atecoOptions, sectorOptions, categoryOptions } = getFilteredOptions(
            selectedAteco?.category ?? null,
            selectedAteco?.sector ?? null
        );

        const optionsCopy = [...options];
        optionsCopy[index] = { ateco: atecoOptions, sector: sectorOptions, category: categoryOptions };
        setOptions(optionsCopy);
    }

    function handleChangeSectors(e: string | undefined, index: number) {
        const atecos = [...targetListState.newTargetListRequest.atecos];
        const selectedSector = e || null;

        atecos[index] = {
            ...atecos[index],
            sector: selectedSector,
            atecoCode: null,
        };

        const { atecoOptions, categoryOptions } = e
            ? getFilteredOptions(atecos[index].category, selectedSector)
            : { atecoOptions: defaultAtecos, categoryOptions: defaultCategories };

        const optionsCopy = [...options];
        optionsCopy[index] = { ...optionsCopy[index], ateco: atecoOptions, category: categoryOptions };
        setOptions(optionsCopy);

        dispatch(setNewTargetListAtecos([...atecos]));
    }

    function handleChangeCategories(e: string | undefined, index: number) {
        const atecos = [...targetListState.newTargetListRequest.atecos];
        const selectedCategory = e || null;

        atecos[index] = {
            ...atecos[index],
            category: selectedCategory,
            atecoCode: null,
        };

        let atecoOptions, sectorOptions;

        if (selectedCategory === null) {
            atecoOptions = defaultAtecos;
            sectorOptions = defaultSectors;

            if (!defaultSectors.map((sector) => sector.value).includes(atecos[index].sector!)) {
                atecos[index].sector = null;
            }
        } else {
            const filteredAtecos = atecosState.findAllAtecosResponse.filter(
                (ateco) => ateco.category === selectedCategory
            );
            atecoOptions = groupBy(filteredAtecos, "atecoCode").map((ateco) => ({
                label: ateco.key,
                value: ateco.key,
            }));
            sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
                label: sector.key,
                value: sector.key,
            }));

            if (!sectorOptions.map((sector) => sector.value).includes(atecos[index].sector!)) {
                atecos[index].sector = null;
            }
        }

        const optionsCopy = [...options];
        optionsCopy[index] = {
            ...optionsCopy[index],
            ateco: atecoOptions,
            sector: sectorOptions,
        };
        setOptions(optionsCopy);

        dispatch(setNewTargetListAtecos([...atecos]));
    }

    return (
        <div className="flex flex-col gap-3">
            {
                targetListState.newTargetListRequest.atecos.map((ateco, index) =>
                    <div className="flex gap-4 items-end">
                        <SelectCustom
                            isClearable
                            placeholder={"Indifferente"}
                            options={options[index].ateco}
                            label="Cod. ATECO"
                            defaultValue={ateco.atecoCode ?? undefined}
                            onChange={(e) => {
                                if (typeof e === 'string' || !e)
                                    handleChangeAtecos(e, index)
                            }}
                        />
                        <SelectCustom
                            isClearable
                            placeholder={"Indifferente"}
                            options={options[index].sector}
                            label="Settore"
                            defaultValue={ateco.sector ?? undefined}
                            onChange={(e) => {
                                if (typeof e === 'string' || !e)
                                    handleChangeSectors(e, index)
                            }}
                        />
                        <SelectCustom
                            isClearable
                            placeholder={"Indifferente"}
                            options={options[index].category}
                            label="Categoria"
                            defaultValue={ateco.category ?? undefined}
                            onChange={(e) => {
                                if (typeof e === 'string' || !e)
                                    handleChangeCategories(e, index)
                            }}
                        />
                        <Button
                            size={"md"}
                            iconPosition={"only"}
                            variant={"link"}
                            icon={<TrashIcon color={""} size={0} />}
                            color={"blue"}
                            onClick={() => {
                                if (targetListState.newTargetListRequest.atecos.length === 1) {
                                    dispatch(setNewTargetListAtecos([{ atecoCode: null, atecoDescription: null, sector: null, category: null }]))
                                    setOptions([{ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories }])
                                } else {
                                    const atecosCopy = [...targetListState.newTargetListRequest.atecos]
                                    const atecosRequest = [...atecosCopy.slice(0, index), ...atecosCopy.slice(index + 1)]
                                    dispatch(setNewTargetListAtecos([...atecosRequest]))
                                    const optionsCopy = [...options]
                                    const optionsRequest = [...optionsCopy.slice(0, index), ...optionsCopy.slice(index + 1)]
                                    setOptions([...optionsRequest])
                                }
                            }}
                        />
                    </div>
                )
            }
            <div className="w-full h-[1px] bg-neutral-200" />
            <span
                onClick={() => {
                    const atecosCopy = [...targetListState.newTargetListRequest.atecos];
                    atecosCopy.push({ atecoCode: null, atecoDescription: null, sector: null, category: null });
                    dispatch(setNewTargetListAtecos(atecosCopy));

                    const optionsCopy = [...options];
                    optionsCopy.push({
                        ateco: defaultAtecos,
                        sector: defaultSectors,
                        category: defaultCategories,
                    });
                    setOptions(optionsCopy);
                }}
                className="text-text-sm text-right cursor-pointer font-semibold text-brandPrimary-600"
            >
                Aggiungi nuovo segmento target
            </span>
        </div>
    )
}