import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { setShowRecallContactModal } from "../../targetList/contacts/slice";
import { BannersList } from "../../../ui/organisms/banner/bannerList";
import { setDeleteRecallStatus, setUpdateRecallStatus } from "../../targetList/contacts/recall/slice";

interface Props {
    reloadEvents: () => void;
}

export function BannerEvents(props: Props) {
    const { reloadEvents } = props;
    const dispatch = useAppDispatch()

    const recallsState = useAppSelector((state) => state.recalls)

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            recallsState.updateRecallStatus === "failed" ||
            recallsState.updateRecallStatus === "successfully"
        ) {
            dispatch(setShowRecallContactModal(false));
            reloadEvents();
            timeoutId = setTimeout(() => {
                dispatch(setUpdateRecallStatus("idle"));
            }, 2000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [recallsState.updateRecallStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            recallsState.deleteRecall === "failed" ||
            recallsState.deleteRecall === "successfully"
        ) {
            reloadEvents();
            timeoutId = setTimeout(() => {
                dispatch(setDeleteRecallStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [recallsState.deleteRecall]);

    return (
        <BannersList banners={[
            {
                label: "Si è verificato un errore durante la modifica del richiamo.",
                visible: recallsState.updateRecallStatus === "failed",
                closeAction: () => dispatch(setUpdateRecallStatus("idle")),
                type: "error",
            },
            {
                label: "Pianificazione richiamo modificata con successo.",
                visible: recallsState.updateRecallStatus === "successfully",
                closeAction: () => dispatch(setUpdateRecallStatus("idle")),
                type: "success",
            },
        ]
        } />
    )
}