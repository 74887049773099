import { SmartphoneIcon } from "../../../ui/icons/smartphone"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { ContactType, FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function PrivacyFilter(props: Props) {
    const { updateFilters, filters } = props

    const options = [
        { value: 'true', label: 'Sì' },
        { value: 'false', label: 'No' }
    ]

    return (
        <AccordionFilter
            key={'person-filter-privacy'}
            label={"Privacy"}
            icon={<SmartphoneIcon color={""} size={0} />}
            showClear={filters.privacy !== undefined}
            options={filters.privacy !== undefined ? [filters.privacy] : []}
            clearAction={function (): void {
                updateFilters({ privacy: undefined })
            }}
            deleteFilter={function (option): void {
                updateFilters({ privacy: undefined })
            }}
        >
            <SelectCustom
                isSearchable={false}
                isClearable
                onChange={e => {
                    if (e === undefined) {
                        updateFilters({ privacy: undefined })
                    } else if (typeof e === 'string') {
                        updateFilters({ privacy: { value: e, label: options.find(opt => opt.value === e)?.label! } })
                    }
                }}
                defaultValue={filters.privacy?.value}
                placeholder={"Privacy"}
                options={options}
            />
        </AccordionFilter>
    )
}