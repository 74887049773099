import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ContactDTO, ContactType, FindAllContactsFilters } from '../../../lib/filters/dto';
import { PromiseStatuses } from '../../../lib/types';
import { ImportCSVResponseDTO } from '../../database/dto';
import { ContactStateEnum, NoteDTO } from '../dto';
import { ContactsCounterDTO, ContactsIdsResponseDTO } from "./dto";
import { NewContactsService } from './service';

interface ContactState {
    deleteBulkStatus: PromiseStatuses
    addContactsBulkStatus: PromiseStatuses
    importContactStatus: PromiseStatuses;
    importContactResponse?: ImportCSVResponseDTO;
    restoreContactsStatus: PromiseStatuses
    updateNotesStatus: PromiseStatuses
    changeContactStateStatus: PromiseStatuses;
    addContactsStatus: PromiseStatuses;
    removeContactsStatus: PromiseStatuses;
    getContactStatus: PromiseStatuses;
    getContactResponse?: ContactDTO<ContactType>
    selectedContact?: string
    getCounterStatus: PromiseStatuses
    getCounterResponse?: ContactsCounterDTO[]
    getContactsIdsStatus: PromiseStatuses
    getContactsIdsResponse?: ContactsIdsResponseDTO[]
    softBulkStatus: PromiseStatuses
    showRecallContactModal: boolean
}

const initialState: ContactState = {
    deleteBulkStatus: 'idle',
    addContactsBulkStatus: 'idle',
    getCounterStatus: 'idle',
    importContactStatus: 'idle',
    getContactStatus: 'idle',
    restoreContactsStatus: 'idle',
    updateNotesStatus: 'idle',
    changeContactStateStatus: 'idle',
    addContactsStatus: 'idle',
    removeContactsStatus: 'idle',
    getContactsIdsStatus: 'idle',
    showRecallContactModal: false,
    softBulkStatus: 'idle'
};

export const deleteBulk = createAsyncThunk(
    "contacts/deleteBulk",
    async (request: { targetListId: string, filters: FindAllContactsFilters, contactType: ContactType }, thunkApi): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.deleteBulk(request.filters, request.targetListId, request.contactType);
    }
);

export const softBulk = createAsyncThunk(
    "contacts/softBulk",
    async (request: { targetListId: string, contactType: ContactType }, thunkApi): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.softBulk(request.targetListId, request.contactType);
    }
);

export const addContactsBulk = createAsyncThunk(
    "contacts/addContactsBulk",
    async (request: { targetListId: string, filters: FindAllContactsFilters, contactType: ContactType }, thunkApi): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.addContactsBulk(request.targetListId, request.filters, request.contactType);
    }
);

export const getContactsIds = createAsyncThunk(
    "contacts/getContactsIds",
    async (targetListId: string, thunkApi): Promise<ContactsIdsResponseDTO[]> => {
        const ContactsService = NewContactsService();
        const personPromise = await ContactsService.getContactIds(targetListId, ContactType.PERSON);
        const companyPromise = await ContactsService.getContactIds(targetListId, ContactType.COMPANY);

        return Promise.all([{ dto: personPromise, contactType: ContactType.PERSON }, { dto: companyPromise, contactType: ContactType.COMPANY }])
    }
);

export const getCounters = createAsyncThunk(
    "contacts/getCounters",
    async (targetListId: string, thunkApi): Promise<ContactsCounterDTO[]> => {
        const ContactsService = NewContactsService();
        const personPromise = await ContactsService.getCounters(targetListId, ContactType.PERSON);
        const companyPromise = await ContactsService.getCounters(targetListId, ContactType.COMPANY);

        return Promise.all([{ dto: personPromise, contactType: ContactType.PERSON }, { dto: companyPromise, contactType: ContactType.COMPANY }])
    }
);

export const importContactCSV = createAsyncThunk(
    "contacts/importContactCSV",
    async (
        request: { data: FormData; id: string, contactType: ContactType },
        thunkApi
    ): Promise<ImportCSVResponseDTO> => {
        const ContactsService = NewContactsService();

        return ContactsService.importContactCSV(
            request.data,
            request.contactType
        );
    }
);

export const restoreContacts = createAsyncThunk(
    "contacts/restoreContacts",
    async (
        contactIds: string[],
        thunkApi
    ): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.restoreContacts(
            contactIds
        );
    }
);

export const getContact = createAsyncThunk(
    "contacts/getContact",
    async (
        contactId: string,
        thunkApi
    ): Promise<ContactDTO<ContactType>> => {
        const ContactsService = NewContactsService();

        return ContactsService.getContact(
            contactId
        );
    }
);

export const findContant = createAsyncThunk(
    "contacts/findContant",
    async (
        contactIds: string[],
        thunkApi
    ): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.restoreContacts(
            contactIds
        );
    }
);

export const updateNotes = createAsyncThunk(
    "contacts/updateNotes",
    async (
        request: {
            contactId: string;
            notes: NoteDTO[]
        },
        thunkApi
    ): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.updateNotes(
            request.contactId,
            request.notes
        );
    }
);

export const changeContactState = createAsyncThunk(
    "contacts/changeContactState",
    async (
        request: {
            contactState?: ContactStateEnum;
            contactId: string;
            stateInfo: null | string
        },
        thunkApi
    ): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.changeContactStatus(
            request.contactId,
            request.contactState,
            request.stateInfo
        );
    }
);

export const addContacts = createAsyncThunk(
    "contacts/addContacts",
    async (
        data: {
            targetListId: string;
            type: ContactType;
            contactsId: string[];
        },
        thunkApi
    ): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.addContacts(
            data.targetListId,
            data.type,
            data.contactsId
        );
    }
);

export const removeContacts = createAsyncThunk(
    "contacts/removeContacts",
    async (
        contactsId: string[],
        thunkApi
    ): Promise<void> => {
        const ContactsService = NewContactsService();

        return ContactsService.removeContacts(
            contactsId
        );
    }
);

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setShowRecallContactModal: (state, action) => {
            state.showRecallContactModal = action.payload
        },
        setDeleteBulkContactsStatus: (state, action) => {
            state.deleteBulkStatus = action.payload
        },
        setSoftBulkContactsStatus: (state, action) => {
            state.softBulkStatus = action.payload
        },
        setAddContactsBulkStatus: (state, action) => {
            state.addContactsBulkStatus = action.payload
        },
        setGetContactsIdsResponse: (state, action) => {
            state.getContactsIdsResponse = action.payload
        },
        setGetContactsIdsStatus: (state, action) => {
            state.getContactsIdsStatus = action.payload
        },
        setGetTargetListCounterResponse: (state, action) => {
            state.getCounterResponse = action.payload
        },
        setGetTargetListCounterStatus: (state, action) => {
            state.getCounterStatus = action.payload
        },
        setImportContactCSVStatus: (state, action) => {
            state.importContactStatus = action.payload;
        },
        setImportContactCSVResponse: (state, action) => {
            state.importContactResponse = action.payload;
        },
        setSelectedContact: (state, action) => {
            state.selectedContact = action.payload
        },
        setGetContactStatus: (state, action) => {
            state.getContactStatus = action.payload
        },
        setGetContactResponse: (state, action) => {
            state.getContactResponse = action.payload
        },
        setRestoreContactsStatus: (state, action) => {
            state.restoreContactsStatus = action.payload
        },
        setUpdateNotesStatus: (state, action) => {
            state.updateNotesStatus = action.payload
        },
        setChangeContactStateStatus: (state, action) => {
            state.changeContactStateStatus = action.payload;
        },
        setAddContactsStatus: (state, action) => {
            state.addContactsStatus = action.payload;
        },
        setRemoveContactsStatus: (state, action) => {
            state.removeContactsStatus = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(deleteBulk.pending, (state) => {
                state.deleteBulkStatus = "loading";
            })
            .addCase(deleteBulk.fulfilled, (state) => {
                state.deleteBulkStatus = "successfully";
            })
            .addCase(deleteBulk.rejected, (state) => {
                state.deleteBulkStatus = "failed";
            })
            .addCase(addContactsBulk.pending, (state) => {
                state.addContactsBulkStatus = "loading";
            })
            .addCase(addContactsBulk.fulfilled, (state) => {
                state.addContactsBulkStatus = "successfully";
            })
            .addCase(addContactsBulk.rejected, (state) => {
                state.addContactsBulkStatus = "failed";
            })
            .addCase(getContactsIds.pending, (state) => {
                state.getContactsIdsStatus = "loading";
            })
            .addCase(getContactsIds.fulfilled, (state, action) => {
                state.getContactsIdsStatus = "successfully";
                state.getContactsIdsResponse = action.payload
            })
            .addCase(getContactsIds.rejected, (state) => {
                state.getContactsIdsStatus = "failed";
            })
            .addCase(getCounters.pending, (state) => {
                state.getCounterStatus = "loading";
            })
            .addCase(getCounters.fulfilled, (state, action) => {
                state.getCounterStatus = "successfully";
                state.getCounterResponse = action.payload
            })
            .addCase(getCounters.rejected, (state) => {
                state.getCounterStatus = "failed";
            })
            .addCase(importContactCSV.pending, (state) => {
                state.importContactStatus = "loading";
            })
            .addCase(importContactCSV.fulfilled, (state, action) => {
                state.importContactStatus = "successfully";
                state.importContactResponse = action.payload;
            })
            .addCase(importContactCSV.rejected, (state) => {
                state.importContactStatus = "failed";
            })
            .addCase(getContact.pending, (state) => {
                state.getContactStatus = "loading";
            })
            .addCase(getContact.fulfilled, (state, action) => {
                state.getContactStatus = "successfully";
                state.getContactResponse = action.payload
            })
            .addCase(getContact.rejected, (state) => {
                state.getContactStatus = "failed";
            })
            .addCase(restoreContacts.pending, (state) => {
                state.restoreContactsStatus = "loading";
            })
            .addCase(restoreContacts.fulfilled, (state) => {
                state.restoreContactsStatus = "successfully";
            })
            .addCase(restoreContacts.rejected, (state) => {
                state.restoreContactsStatus = "failed";
            })
            .addCase(updateNotes.pending, (state) => {
                state.updateNotesStatus = "loading";
            })
            .addCase(updateNotes.fulfilled, (state) => {
                state.updateNotesStatus = "successfully";
            })
            .addCase(updateNotes.rejected, (state) => {
                state.updateNotesStatus = "failed";
            })
            .addCase(changeContactState.pending, (state) => {
                state.changeContactStateStatus = "loading";
            })
            .addCase(changeContactState.fulfilled, (state, action) => {
                state.changeContactStateStatus = "successfully";
            })
            .addCase(changeContactState.rejected, (state) => {
                state.changeContactStateStatus = "failed";
            })
            .addCase(addContacts.pending, (state) => {
                state.addContactsStatus = "loading";
            })
            .addCase(addContacts.fulfilled, (state, action) => {
                state.addContactsStatus = "successfully";
            })
            .addCase(addContacts.rejected, (state) => {
                state.addContactsStatus = "failed";
            })
            .addCase(removeContacts.pending, (state) => {
                state.removeContactsStatus = "loading";
            })
            .addCase(removeContacts.fulfilled, (state, action) => {
                state.removeContactsStatus = "successfully";
            })
            .addCase(removeContacts.rejected, (state) => {
                state.removeContactsStatus = "failed";
            })
            .addCase(softBulk.pending, (state) => {
                state.softBulkStatus = "loading";
            })
            .addCase(softBulk.fulfilled, (state) => {
                state.softBulkStatus = "successfully";
            })
            .addCase(softBulk.rejected, (state) => {
                state.softBulkStatus = "failed";
            })
    },
});

export const {
    setSelectedContact,
    setGetContactResponse,
    setGetContactStatus,
    setRestoreContactsStatus,
    setUpdateNotesStatus,
    setAddContactsStatus,
    setRemoveContactsStatus,
    setChangeContactStateStatus,
    setImportContactCSVStatus,
    setImportContactCSVResponse,
    setGetTargetListCounterResponse,
    setGetTargetListCounterStatus,
    setGetContactsIdsResponse,
    setGetContactsIdsStatus,
    setAddContactsBulkStatus,
    setDeleteBulkContactsStatus,
    setShowRecallContactModal,
    setSoftBulkContactsStatus
} = contactsSlice.actions;
export default contactsSlice.reducer;
