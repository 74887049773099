import { roleDepartment } from "../../atecos/dto"
import { groupBy } from "../../utils"
import { PersonalCardIcon } from "../../../ui/icons/personalCard"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { ContactType, FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function RoleFilter(props: Props) {
    const { updateFilters, filters } = props

    const filteredRoles = roleDepartment
        .filter(department => filters.personDepartment.length > 0 ? filters.personDepartment.map(_department => _department.value).includes(department.department) : department)


    return (
        <AccordionFilter
            key={'person-filter-role'}
            label={"Ruolo"}
            icon={<PersonalCardIcon color={""} size={0} />}
            showClear={filters.personRole.length > 0}
            options={filters.personRole}
            clearAction={function (): void {
                updateFilters({ personRole: [] })
            }}
            deleteFilter={function (option): void {
                updateFilters({ personRole: (filters.personRole.filter(name => name.value !== option.value)) })
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        updateFilters({ personRole: e.map(option => ({ value: option, label: option })) })
                    }
                }}
                defaultValue={filters.personRole.map(option => option.value)}
                placeholder={"Cerca per ruolo"}
                options={groupBy(filteredRoles, 'role').map(sector => ({ value: sector.key, label: sector.key }))}
                noOptionsMessage="Non sono presenti ruoli."
            />
        </AccordionFilter>
    )
}