import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactType, FindContactsTargetResponse } from "../../lib/filters/dto";
import { useContacts } from "../../lib/hooks/contacts/contacts";
import { keycloak } from "../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Area } from "../../lib/utils";
import { PlusIcon } from "../../ui/icons/plus";
import { TrashIcon } from "../../ui/icons/trash";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { BannersList } from "../../ui/organisms/banner/bannerList";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { TabMenu } from "../../ui/organisms/tabs/tabMenu";
import { setSelectedCompany } from "../database/companies/slice";
import { setSelectedPerson } from "../database/people/slice";
import { setBulkDeleteModal, setSoftDeleteModal } from "../database/slice";
import { deleteBulk, setRemoveContactsStatus, setSelectedContact, softBulk } from "./contacts/slice";
import { TargetListPageEnum } from "./dto";
import { ActionsCompaniesTargetList } from "./editTargetListDetails/companies/list/actions";
import { EditTargetList } from "./editTargetListDetails/index";
import { ActionsPeopleTargetList } from "./editTargetListDetails/people/list/actions";
import { findTargetListById, setEditTargetListTabValue, setSelectedTargetBins, setTargetListPage, validateEditTargetList } from "./slice";

export function EditTargetListPage() {
  const targetListsState = useAppSelector((state) => state.targetList);
  const databaseState = useAppSelector((state) => state.database);
  const contactsState = useAppSelector((state) => state.contacts);
  const usersState = useAppSelector((state) => state.users);
  const [direction, setDirection] = useState<"left" | "right">("left");
  const [selectedPeopleIds, setSelectedPeopleIds] = useState<string[]>([]);
  const [showPopupPeople, setShowPopupPeople] = useState<boolean>(false);
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<string[]>([]);
  const [showPopupCompanies, setShowPopupCompanies] = useState<boolean>(false);

  const peopleFilters = useContacts(Area.TARGET, 'target', ContactType.PERSON, targetListsState.findTargetListResponse?.id!);
  const companiesFilters = useContacts(Area.TARGET, 'target', ContactType.COMPANY, targetListsState.findTargetListResponse?.id!);

  const dispatch = useAppDispatch();
  const navigation = useNavigate()

  useEffect(() => {
    dispatch(findTargetListById(targetListsState.selectedTargetList!));
    dispatch(setSelectedCompany(undefined))
    dispatch(setSelectedPerson(undefined))
    dispatch(setSelectedContact(undefined))
  }, []);

  useEffect(() => {
    if (targetListsState.selectedTargetBins !== undefined) {
      navigation("/target-list-bins/" + targetListsState.selectedTargetBins);
      dispatch(setSelectedTargetBins(undefined));
    }
  }, [targetListsState.selectedTargetBins]);

  return (
    <Layout
      headerLabel={targetListsState.findTargetListResponse?.name!}
      headerBackPath="/target-list"
      breadcrumbItems={[
        "Liste target",
        targetListsState.findTargetListResponse?.name!,
        (targetListsState.editTargetListTabValue === 0 ? 'Specifiche' : targetListsState.editTargetListTabValue === 1 ? 'Persone' : 'Aziende')
      ]}
      menuItem={MenuItems.TARGET_LIST}
      headerChildren={
        <div className="flex items-center justify-between">
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => {
              dispatch(setEditTargetListTabValue(e));
              if (e === 1 || e === 2) {
                dispatch(setTargetListPage(TargetListPageEnum.LIST));
              }
            }}
            value={targetListsState.editTargetListTabValue}
            items={[
              {
                icon: <></>,
                label: "Specifiche lista",
              },
              {
                icon: <></>,
                label:
                  "Persone (" +
                  contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.inList.total
                  +
                  ")",
              },
              {
                icon: <></>,
                label:
                  "Aziende (" +
                  contactsState.getCounterResponse?.find(count => count.contactType === ContactType.COMPANY)?.dto.inList.total
                  +
                  ")",
              },
            ]}
          />
          <div className="flex items-center gap-2">
            {
              targetListsState.editTargetListTabValue === 1 && targetListsState.targetListPage === TargetListPageEnum.LIST &&
              <ActionsPeopleTargetList
                isInBin={false}
                targetList={targetListsState.findTargetListResponse!}
                contacts={peopleFilters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
                filters={peopleFilters.filters} selectedIds={selectedPeopleIds} setShowPopup={(action) => setShowPopupPeople(action)} />
            }
            {
              targetListsState.editTargetListTabValue === 2 && targetListsState.targetListPage === TargetListPageEnum.LIST &&
              <ActionsCompaniesTargetList
                isInBin={false}
                targetList={targetListsState.findTargetListResponse!}
                contacts={companiesFilters.contacts as unknown as FindContactsTargetResponse<ContactType.COMPANY>}
                filters={companiesFilters.filters} selectedIds={selectedCompaniesIds} setShowPopup={(action) => setShowPopupCompanies(action)} />
            }
            {
              targetListsState.targetListPage === TargetListPageEnum.LIST && targetListsState.editTargetListTabValue === 0 && (usersState.findMeResponse?.isAutonomous || usersState.findMeResponse?.isAutonomous === null) &&
              <div className="flex items-center justify-end w-full h-full">
                <Button
                  size={"sm"}
                  iconPosition={"left"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<TrashIcon color={""} size={0} />}
                  label="Visualizza cestini"
                  onClick={() => dispatch(setSelectedTargetBins(targetListsState.findTargetListResponse?.id!))}
                />
              </div>
            }
            <div className="flex justify-end items-center gap-2">
              {
                (targetListsState.editTargetListTabValue === 0 &&
                  !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                  !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous)) &&
                <Button
                  size={"sm"}
                  iconPosition={"off"}
                  label="Aggiorna"
                  variant={"solid"}
                  color={"blue"}
                  onClick={() => {
                    dispatch(
                      validateEditTargetList(targetListsState.editTargetListRequest)
                    );
                  }}
                />
              }
              {/* {
                !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous) &&
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                ((targetListsState.editTargetListTabValue === 1 && peopleFilters.contacts.total > 0) || (targetListsState.editTargetListTabValue === 2 && companiesFilters.contacts.total > 0)) &&
                (
                  targetListsState.targetListPage !== TargetListPageEnum.CSV &&
                  targetListsState.targetListPage !== TargetListPageEnum.DB &&
                  targetListsState.targetListPage !== TargetListPageEnum.TL &&
                  targetListsState.targetListPage !== TargetListPageEnum.ADD && (
                    <Button
                      size={"sm"}
                      iconPosition={"left"}
                      variant={"outline"}
                      color={"blue"}
                      icon={<TrashIcon color={""} size={0} />}
                      label={'Cestina tutti'}
                      onClick={() =>
                        dispatch(setSoftDeleteModal(true))
                      }
                    />
                  ))} */}
              {/* {
                !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous) &&
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                ((targetListsState.editTargetListTabValue === 1 && peopleFilters.contacts.total > 0) || (targetListsState.editTargetListTabValue === 2 && companiesFilters.contacts.total > 0)) &&
                (
                  targetListsState.targetListPage !== TargetListPageEnum.CSV &&
                  targetListsState.targetListPage !== TargetListPageEnum.DB &&
                  targetListsState.targetListPage !== TargetListPageEnum.TL &&
                  targetListsState.targetListPage !== TargetListPageEnum.ADD && (
                    <Button
                      size={"sm"}
                      iconPosition={"left"}
                      variant={"outline"}
                      color={"blue"}
                      icon={<TrashIcon color={""} size={0} />}
                      label={'Elimina tutti'}
                      onClick={() =>
                        dispatch(setBulkDeleteModal(true))
                      }
                    />
                  ))} */}
              {/* {
                !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous) &&
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                (targetListsState.editTargetListTabValue !== 0 &&
                  targetListsState.targetListPage !== TargetListPageEnum.CSV &&
                  targetListsState.targetListPage !== TargetListPageEnum.DB &&
                  targetListsState.targetListPage !== TargetListPageEnum.TL &&
                  targetListsState.targetListPage !== TargetListPageEnum.ADD && (
                    <Button
                      size={"sm"}
                      iconPosition={"left"}
                      variant={"solid"}
                      color={"blue"}
                      icon={<PlusIcon color={""} size={0} />}
                      label={
                        targetListsState.editTargetListTabValue === 2 ? "Aggiungi aziende" : "Aggiungi persone"
                      }
                      onClick={() =>
                        dispatch(setTargetListPage(TargetListPageEnum.ADD))
                      }
                    />
                  ))} */}
            </div>
          </div>
        </div>
      }
    >
      <BannersList banners={[
        {
          type: "success",
          visible: contactsState.removeContactsStatus === "successfully",
          label: "Contatto rimosso dalla lista.",
          closeAction: () => dispatch(setRemoveContactsStatus("idle")),
        },
        {
          type: "error",
          visible: contactsState.removeContactsStatus === "failed",
          label: "Si è verificato un errore durante la rimozione del contatto dalla lista target.",
          closeAction: () => dispatch(setRemoveContactsStatus("idle")),
        }
      ]
      } />
      <EditTargetList
        reloadCompanies={() => companiesFilters.findContacts()}
        reloadPeople={() => peopleFilters.findContacts()}
        peopleContacts={peopleFilters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
        peopleStatus={peopleFilters.status}
        companiesContacts={companiesFilters.contacts as unknown as FindContactsTargetResponse<ContactType.COMPANY>}
        companiesStatus={companiesFilters.status}
        companiesFilters={companiesFilters.filters}
        setCompaniesFilters={act => companiesFilters.updateFilters(act)}
        setCompaniesPage={act => companiesFilters.changePage(act)}
        resetCompaniesFilters={() => companiesFilters.resetAllFilters()}
        peopleFilters={peopleFilters.filters}
        setPeopleFilters={act => peopleFilters.updateFilters(act)}
        setPeoplePage={act => peopleFilters.changePage(act)}
        resetPeopleFilters={() => peopleFilters.resetAllFilters()}
        selectedIdsPeople={selectedPeopleIds}
        setSelectedIdsPeople={arg => setSelectedPeopleIds(arg)}
        setShowPopupPeople={arg => setShowPopupPeople(arg)}
        showPopupPeople={showPopupPeople}
        selectedIdsCompanies={selectedCompaniesIds}
        setSelectedIdsCompanies={arg => setSelectedCompaniesIds(arg)}
        setShowPopupCompanies={arg => setShowPopupCompanies(arg)}
        showPopupCompanies={showPopupCompanies}
      />
      <ConfirmModal
        loading={contactsState.deleteBulkStatus === "loading"}
        title={targetListsState.editTargetListTabValue === 2 ? 'Elimina aziende' : 'Elimina persone'}
        label={
          targetListsState.editTargetListTabValue === 2 ?
            "Sei sicuro di voler eliminare tutte le aziende trovate?" :
            "Sei sicuro di voler eliminare tutte le persone trovate?"
        }
        confirmAction={function (): void {
          const filters = targetListsState.editTargetListTabValue === 2 ? companiesFilters.filters : peopleFilters.filters
          const contactType = targetListsState.editTargetListTabValue === 2 ? ContactType.COMPANY : ContactType.PERSON
          dispatch(deleteBulk({ targetListId: targetListsState.findTargetListResponse?.id!, filters, contactType }))
        }}
        onClose={function (): void {
          dispatch(setBulkDeleteModal(false))
        }}
        visible={databaseState.bulkDeleteModal}
      />
      <ConfirmModal
        loading={contactsState.softBulkStatus === "loading"}
        title={targetListsState.editTargetListTabValue === 2 ? 'Cestina aziende' : 'Cestina persone'}
        label={
          targetListsState.editTargetListTabValue === 2 ?
            "Sei sicuro di voler cestinare tutte le aziende trovate?" :
            "Sei sicuro di voler cestinare tutte le persone trovate?"
        }
        confirmAction={function (): void {
          const contactType = targetListsState.editTargetListTabValue === 2 ? ContactType.COMPANY : ContactType.PERSON
          dispatch(softBulk({ targetListId: targetListsState.findTargetListResponse?.id!, contactType }))
        }}
        onClose={function (): void {
          dispatch(setSoftDeleteModal(false))
        }}
        visible={databaseState.softDeleteModal}
      />
    </Layout>
  );
}
