import { useEffect } from "react";
import { FindAllContactsFilters } from "../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { BannersList } from "../../ui/organisms/banner/bannerList";
import { findCompany, setEditCompanyStatus } from "../database/companies/slice";
import { setOtpRequestStatus } from "../database/people/privacy/slice";
import { findPerson, setEditPersonStatus, setNewPersonResponse, setNewPersonStatus } from "../database/people/slice";

interface Props {
    peopleFilters: FindAllContactsFilters
    reloadPeople: () => void
    companiesFilters: FindAllContactsFilters
    reloadCompanies: () => void
}

export function BannerBlackList(props: Props) {
    const { peopleFilters: filters, reloadPeople: reloadContacts, companiesFilters, reloadCompanies } = props
    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))

                reloadContacts()
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    return (
        <BannersList
            banners={[
                {
                    label: "Contatto aggiunto correttamente",
                    visible:
                        peopleState.newPersonStatus === "successfully" &&
                        !peopleState.newPersonResponse?.alreadyExists,
                    closeAction: () => {
                        dispatch(setNewPersonStatus("idle"));
                        dispatch(setNewPersonResponse(undefined));
                    },
                    type: "success",
                },
                {
                    label:
                        "Il contatto che stai provando ad inserire esiste ed è associato all'azienda",
                    visible:
                        peopleState.newPersonStatus === "successfully" &&
                        peopleState.newPersonResponse?.alreadyExists === true,
                    closeAction: () => {
                        dispatch(setNewPersonStatus("idle"));
                        dispatch(setNewPersonResponse(undefined));
                    },
                    type: "warning",
                },
                {
                    label: peopleState.newPersonResponseMessage,
                    visible: peopleState.newPersonStatus === "failed",
                    closeAction: () => {
                        dispatch(setNewPersonStatus("idle"));
                        dispatch(setNewPersonResponse(undefined));
                    },
                    type: "error",
                },
                {
                    label: "Persona aggiornata.",
                    visible: peopleState.editPersonStatus === "successfully",
                    closeAction: () => dispatch(setEditPersonStatus("idle")),
                    type: "success",
                },
                {
                    label: "Si è verificato un errore durante l'invio dell'OTP.",
                    visible: otpState.otpRequestStatus === "failed",
                    closeAction: () => dispatch(setOtpRequestStatus("idle")),
                    type: "error",
                },
                {
                    label: "Si è verificato un errore durante la modifica della persona.",
                    visible: peopleState.editPersonStatus === "failed",
                    closeAction: () => dispatch(setEditPersonStatus("idle")),
                    type: "error",
                },
                {
                    label: "Azienda aggiornata.",
                    visible: companiesState.editCompanyStatus === "successfully",
                    closeAction: () => dispatch(setEditCompanyStatus("idle")),
                    type: "success",
                },
                {
                    label: "Si è verificato un errore durante la modifica dell'azienda.",
                    visible: companiesState.editCompanyStatus === "failed",
                    closeAction: () => dispatch(setEditCompanyStatus("idle")),
                    type: "error",
                },
            ]}
        />
    )
}