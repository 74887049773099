import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Button from "../../ui/molecules/button";
import { Spinner } from "../../ui/molecules/spinner";

import { ContactType } from "../../lib/filters/dto";
import { SelectProject } from "./selectProject";
import { copyTargetList, findTargetListById, setCopyTargetListStatus, setTargetListToCopy } from "./slice";
import { getContactsIds } from "./contacts/slice";

export function CopyTargetListToProjectModal() {
  const targetListState = useAppSelector((state) => state.targetList);
  const contactsState = useAppSelector((state) => state.contacts);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (targetListState.targetListToCopy) {
      dispatch(findTargetListById(targetListState.targetListToCopy))
      dispatch(getContactsIds(targetListState.targetListToCopy))

    }
  }, [targetListState.targetListToCopy])

  const [value, setValue] = useState<{ label: string, value: string } | undefined>(undefined)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (targetListState.copyTargetListStatus === 'successfully') {
      window.location.replace('/edit-project/' + value?.value + '/target-lists')
      dispatch(setTargetListToCopy(undefined))
    }
    if (targetListState.copyTargetListStatus === 'successfully' || targetListState.copyTargetListStatus === 'failed')
      timeoutId = setTimeout(() => {
        dispatch(setCopyTargetListStatus('idle'))
      }, 5000);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListState.copyTargetListStatus])

  return (
    <div
      onClick={() => dispatch(setTargetListToCopy(undefined))}
      className={clsx(
        "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
        {
          block: targetListState.targetListToCopy,
          hidden: !targetListState.targetListToCopy,
        }
      )}
    >
      {
        targetListState.findTargetListStatus === "loading" ||
          targetListState.copyTargetListStatus === "loading" ? (
          <Spinner />
        ) : (
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className="transition-all flex flex-col items-center gap-8 p-[20px] bg-white rounded-[12px] opacity-100 shadow-sm min-w-[400px]"
          >
            <div className="flex flex-col items-center gap-2">
              <span className="text-text-lg font-semibold text-neutral-800">
                Copia lista target
              </span>
              <span className="text-text-md text-center font-normal text-neutral-600">
                Vuoi copiare questa lista target in un altro progetto?
                <br /> Seleziona il progetto in cui copiarla e clicca "Conferma" per continuare.
              </span>
            </div>
            <div className="flex flex-col w-full h-full gap-3">
              <SelectProject
                project={value}
                setProject={function (value?: { label: string; value: string; }): void {
                  setValue(value)
                }}
              />
            </div>
            <div className="w-full flex flex-1 gap-3 mt-3">
              <div className="w-1/2">
                <Button
                  fullWidth
                  size={"sm"}
                  iconPosition={"off"}
                  variant={"outline"}
                  color={"gray"}
                  label="Annulla"
                  onClick={() => dispatch(setTargetListToCopy(undefined))}
                />
              </div>
              <div className="w-1/2">
                <Button
                  disabled={value === undefined}
                  fullWidth
                  size={"sm"}
                  iconPosition={"off"}
                  variant={"solid"}
                  color={"blue"}
                  label="Conferma"
                  onClick={() => {
                    dispatch(copyTargetList({
                      data: {
                        projectId: value?.value!,
                        name: targetListState.findTargetListResponse?.name!,
                        commercialIds: targetListState.findTargetListResponse?.commercials.map(commercial => commercial.id)!,
                        departments: targetListState.findTargetListResponse?.departments!,
                        roles: targetListState.findTargetListResponse?.roles!,
                        revenue: targetListState.findTargetListResponse?.revenue!,
                        employeesNumber: targetListState.findTargetListResponse?.employeesNumber!,
                        atecos: targetListState.findTargetListResponse?.atecos!,
                        particularityId: targetListState.findTargetListResponse?.particularity?.id!
                      },
                      people: contactsState.getContactsIdsResponse?.find(contacts => contacts.contactType === ContactType.PERSON)?.dto.map(contact => contact.contactId)!,
                      companies: contactsState.getContactsIdsResponse?.find(contacts => contacts.contactType === ContactType.COMPANY)?.dto.map(contact => contact.contactId)!,
                    }))
                  }}
                />
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
