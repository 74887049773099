import { FindAllContactsFilters } from "../../../lib/filters/dto";
import { BulkCSVCompaniesFilters, ImportCSVResponseDTO } from "../dto";
import { CompanyDTO, EditCompanyRequest } from "./dto";
import { CompaniesServiceImpl } from "./serviceImpl";

export interface CompaniesService {
    deleteCompany(id: string[]): Promise<void>
    findCompanyById(id: string): Promise<CompanyDTO>
    editCompany(id: string, data: EditCompanyRequest): Promise<void>
    importContact(data: FormData): Promise<ImportCSVResponseDTO>
    getCSV(ids: string[]): Promise<string>
    countCompanies(): Promise<{ toActivate: number }>
    bulkCSV(request: FindAllContactsFilters): Promise<string>
    deleteBulk(filters: FindAllContactsFilters): Promise<void>
}

export function NewCompaniesService(): CompaniesService {
    return new CompaniesServiceImpl();
}
