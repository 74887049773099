import { ReactElement } from "react";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../lib/filters/dto";
import { useAppSelector } from "../../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../../lib/types";
import { TargetListPageEnum } from "../../../targetList/dto";
import { ImportFromCSV } from "../../contacts/csv";
import { ImportModalContacts } from "../../contacts/csv/modal";
import { ImportFromDatabase } from "./database";
import { ImportType } from "./importType";
import { TargetListCompaniesList } from "./list/index";
import { ImportFromTargetList } from "./targetList";

interface Props {
  showPopup: boolean
  setShowPopup: (action: boolean) => void
  selectedIds: string[]
  setSelectedIds: (action: string[]) => void
  filters: FindAllContactsFilters
  setFilters: (action: Partial<FindAllContactsFilters>) => void
  setPage: (action: number) => void
  resetFilters: () => void
  contacts: FindContactsTargetResponse<ContactType.COMPANY>
  status: PromiseStatuses
  reloadContacts: () => void
}

export function TargetListCompanies(props: Props) {
  const { selectedIds, setSelectedIds, setShowPopup, showPopup, filters, setFilters, setPage, resetFilters, contacts, status, reloadContacts } = props
  const targetListsState = useAppSelector((state) => state.targetList);

  const pageMap = new Map<TargetListPageEnum, ReactElement>([
    [TargetListPageEnum.ADD, <ImportType />],
    [TargetListPageEnum.CSV, <ImportFromCSV contactType={ContactType.COMPANY} />],
    [TargetListPageEnum.DB, <ImportFromDatabase />],
    [TargetListPageEnum.TL, <ImportFromTargetList />],
    [TargetListPageEnum.LIST, <TargetListCompaniesList
      reloadContacts={() => reloadContacts()}
      contacts={contacts}
      status={status}
      showPopup={showPopup}
      setShowPopup={arg => setShowPopup(arg)}
      selectedIds={selectedIds}
      setSelectedIds={arg => setSelectedIds(arg)}
      filters={filters}
      setFilters={function (action: Partial<FindAllContactsFilters>): void {
        setFilters(action)
      }}
      setPage={function (action: number): void {
        setPage(action)
      }}
      resetFilters={function (): void {
        resetFilters()
      }} />],
  ]);

  return (
    <div className="h-full max-h-full">
      {pageMap.get(targetListsState.targetListPage)}
      <ImportModalContacts contactType={ContactType.COMPANY} />
    </div>
  );
}
