import { ReactElement } from "react"
import { EventDTO, EventType } from "../dto"
import { PlanRecallModal } from "../../targetList/contacts/recall/planRecallModal"
import { ContactType } from "../../../lib/filters/dto"
import { EventProps } from "./dto"

export function EventsModal(props: EventProps) {
    const eventComponentMap: Record<EventType, React.FC<any>> = {
        [EventType.CALL]: PlanRecallModal,
        [EventType.MEETING]: PlanRecallModal,
    };

    const Component = eventComponentMap[props.eventType];
    return <Component {...props} />;
}