import clsx from "clsx";
import { useEffect, useState } from "react";
import { ContactType } from "../../../../lib/filters/dto";
import useCreateBorder from "../../../../lib/hooks/createBorder";
import { keycloak } from "../../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { LockIcon } from "../../../../ui/icons/lock";
import { PlusIcon } from "../../../../ui/icons/plus";
import { UserPlusIcon } from "../../../../ui/icons/userPlus";
import Avatar from "../../../../ui/molecules/avatar";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { TabMenu } from "../../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../../ui/organisms/tabs/tabPages";
import { SendEmailModal } from "../../../projects/mails/sendEmailModal";
import { SentMailsList } from "../../../projects/mails/sentMailsList";
import { findEmailsHistory, setEmailsHistoryFilters } from "../../../projects/mails/slice";
import { ContactForm } from "../../../targetList/contacts/edit/form";
import { EditContactNotes } from "../../../targetList/contacts/edit/notes";
import { getContact, setSelectedContact } from "../../../targetList/contacts/slice";
import { getUsersByUserIds } from "../../../users/slice";
import {
  editCompany,
  findCompany,
  setFindCompanyResponse,
  setFindCompanyStatus,
  setSelectedCompany
} from "../../companies/slice";
import { PersonPrivacy } from "../privacy";
import {
  editPerson,
  findPerson,
  setEditPersonCompanyId,
  setEditPersonDepartment,
  setEditPersonEmail,
  setEditPersonLinkedinProfile,
  setEditPersonName,
  setEditPersonPhone,
  setEditPersonPrivacy,
  setEditPersonRole,
  setEditPersonSurname,
  setEditPersonToVerifyFields,
  setFindPersonStatus,
  setPersonToAddCompany,
  setSelectedPerson,
  setTabValueDetailsPerson,
  setValidateUpdatePersonEmail,
  setValidateUpdatePersonName,
  setValidateUpdatePersonPhone,
  setValidateUpdatePersonStatus,
  setValidateUpdatePersonSurname,
  validateUpdatePerson
} from "../slice";
import { PersonCompany } from "./company";
import { EditPersonForm } from "./form";
import { RotateIcon } from "../../../../ui/icons/rotate";
import { TabValuePersonNumberToStringMap, TabValuePersonStringToNumberMap } from "../dto";
import { Recall } from "../../../targetList/contacts/recall";

interface Props {
  isInTargetList: boolean
  reloadContacts: () => void
}

export function EditPerson(props: Props) {
  const { isInTargetList, reloadContacts } = props
  const peopleState = useAppSelector((state) => state.people);
  const contactsState = useAppSelector((state) => state.contacts);
  const companiesState = useAppSelector((state) => state.companies);
  const targetListsState = useAppSelector((state) => state.targetList);
  const [direction, setDirection] = useState<"left" | "right">("left");

  const dispatch = useAppDispatch();
  const borderStyle = useCreateBorder(isInTargetList)

  useEffect(() => {
    if (contactsState.selectedContact !== undefined) {
      dispatch(setSelectedContact(undefined))
      dispatch(getContact(contactsState.selectedContact));
    }
  }, [contactsState.selectedContact]);

  useEffect(() => {
    if (peopleState.selectedPerson !== undefined) {
      dispatch(setSelectedCompany(undefined))
      dispatch(findPerson(peopleState.selectedPerson));
    }
  }, [peopleState.selectedPerson]);

  useEffect(() => {
    if (peopleState.errors.validateUpdate.status === 'successfully') {
      dispatch(setValidateUpdatePersonStatus('idle'))
      dispatch(
        editPerson({
          data: peopleState.editPersonRequest,
          id: peopleState.findPersonResponse?.id!,
        })
      );
    }
  }, [peopleState.errors.validateUpdate.status])

  const mailsState = useAppSelector(state => state.mails)

  useEffect(() => {
    if (peopleState.findPersonStatus === "successfully") {
      dispatch(setEditPersonName(peopleState.findPersonResponse?.name));
      dispatch(setEditPersonSurname(peopleState.findPersonResponse?.surname));
      dispatch(setEditPersonRole(peopleState.findPersonResponse?.role));
      dispatch(setEditPersonToVerifyFields(peopleState.findPersonResponse?.toVerifyFields))
      dispatch(setEditPersonDepartment(peopleState.findPersonResponse?.department));
      dispatch(setEditPersonPrivacy(peopleState.findPersonResponse?.privacy));
      dispatch(setEditPersonPhone(peopleState.findPersonResponse?.phones));
      dispatch(setEditPersonEmail(peopleState.findPersonResponse?.emails));
      dispatch(setEditPersonLinkedinProfile(peopleState.findPersonResponse?.linkedinProfile));
      dispatch(setEditPersonCompanyId(peopleState.findPersonResponse?.companyId));

      dispatch(setValidateUpdatePersonStatus('idle'))
      dispatch(setValidateUpdatePersonEmail(false))
      dispatch(setValidateUpdatePersonName(false))
      dispatch(setValidateUpdatePersonPhone(false))
      dispatch(setValidateUpdatePersonSurname(false))

      if (peopleState.findPersonResponse?.companyId)
        dispatch(findCompany(peopleState.findPersonResponse?.companyId!));
      else {
        dispatch(setFindCompanyResponse(undefined))
      }
      if (isInTargetList) {
        if (peopleState.findPersonResponse?.emails && targetListsState.editTargetListTabValue === 1 && peopleState.selectedPerson)
          dispatch(setEmailsHistoryFilters({ ...mailsState.emailsHistoryFilters, toEmail: peopleState.findPersonResponse?.emails }))
        dispatch(getUsersByUserIds(contactsState.getContactResponse?.notes?.map(note => note.authorUserId) ?? []))
      }
      dispatch(setFindPersonStatus("idle"))
      dispatch(setFindCompanyStatus("idle"))
    }
  }, [peopleState.findPersonStatus]);

  useEffect(() => {
    if (mailsState.emailsHistoryFilters.toEmail.length > 0 && peopleState.selectedPerson)
      dispatch(findEmailsHistory(mailsState.emailsHistoryFilters))
  }, [mailsState.emailsHistoryFilters.toEmail])

  return (
    <div className="h-full overflow-auto">
      {peopleState.findPersonStatus === "loading" ||
        peopleState.editPersonStatus === "loading" ||
        companiesState.editCompanyStatus === "loading" ? (
        <Spinner />
      ) : (
        <div className={borderStyle}>
          <div className="px-6 pt-4 flex items-center justify-between">
            <Avatar
              type="info"
              imageUrl=""
              firstRow={
                (peopleState.findPersonResponse?.name ?? "-") +
                " " +
                (peopleState.findPersonResponse?.surname ?? "-")
              }
              secondRow={peopleState.findPersonResponse?.role}
              size="md"
              altTextInitials={
                (peopleState.findPersonResponse?.name && peopleState.findPersonResponse?.name[0] && peopleState.findPersonResponse?.name[0]?.toUpperCase()) ??
                "" +
                (peopleState.findPersonResponse?.surname && peopleState.findPersonResponse?.surname[0] && peopleState.findPersonResponse?.surname[0]?.toUpperCase()) ??
                ""
              }
            />
            <div className="flex items-start gap-4">
              <div
                className="cursor-pointer rotate-45"
                onClick={() => { dispatch(setSelectedPerson(undefined)); dispatch(setSelectedContact(undefined)) }}
              >
                <PlusIcon color={colors.neutral[500]} size={20} />
              </div>
            </div>
          </div>
          {
            isInTargetList && <ContactForm contactData={peopleState.findPersonResponse} contactType={ContactType.PERSON} />
          }
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => dispatch(setTabValueDetailsPerson(TabValuePersonNumberToStringMap.get(e) ?? 'form'))}
            value={TabValuePersonStringToNumberMap.get(peopleState.tabValueDetailsPerson) ?? 0}
            items={[
              {
                icon: <></>,
                label: "Anagrafica",
              },
              {
                icon: <></>,
                label: "Azienda",
              },
              {
                icon: <RotateIcon color={""} size={0} />,
                label: "Richiami",
                hide: !isInTargetList
              },
              {
                icon: <></>,
                label: "Note",
                hide: !isInTargetList
              },
              {
                icon: <></>,
                label: "Cronologia email",
                hide: !isInTargetList
              },
              {
                icon: <LockIcon color={""} size={0} />,
                label: "Privacy",
              },
            ]}
          />
          <div>
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <EditPersonForm key={0} isInTargetList={isInTargetList} />,
                <PersonCompany isInTargetList={isInTargetList} key={1} />,
                isInTargetList ? <Recall contactType={ContactType.PERSON} key={5} /> : null,
                isInTargetList ? <EditContactNotes reloadContacts={() => reloadContacts()} key={2} /> : null,
                isInTargetList ? <SentMailsList key={3} /> : null,
                <PersonPrivacy key={4} />,
              ]}
              setValue={(e) => dispatch(setTabValueDetailsPerson(TabValuePersonNumberToStringMap.get(e) ?? 'form'))}
              value={TabValuePersonStringToNumberMap.get(peopleState.tabValueDetailsPerson) ?? 0}
            />
          </div>
          <div className={clsx("p-6 justify-end items-center gap-2", {
            'hidden': peopleState.tabValueDetailsPerson === 'notes' || peopleState.tabValueDetailsPerson === 'emails' || peopleState.tabValueDetailsPerson === 'recall',
            'flex': peopleState.tabValueDetailsPerson !== 'notes' && peopleState.tabValueDetailsPerson !== 'emails' && peopleState.tabValueDetailsPerson !== 'recall'
          })}>
            <Button
              size={"sm"}
              iconPosition={"off"}
              variant={"outline"}
              color={"blue"}
              label="Annulla"
              onClick={() => { dispatch(setSelectedPerson(undefined)); dispatch(setSelectedContact(undefined)) }}
            />
            {
              peopleState.tabValueDetailsPerson !== 'privacy' && (
                peopleState.tabValueDetailsPerson === 'company' || (peopleState.tabValueDetailsPerson === 'form' && peopleState.findPersonResponse?.companyId) ?
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"solid"}
                    disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                    color={"blue"}
                    label="Salva"
                    onClick={() => {
                      if (peopleState.findPersonResponse?.companyId)
                        dispatch(
                          editCompany({
                            data: companiesState.editCompanyRequest,
                            id: peopleState.findPersonResponse?.companyId,
                          })
                        );
                      dispatch(validateUpdatePerson(peopleState.editPersonRequest))
                    }}
                  /> :
                  <Button
                    size={"sm"}
                    disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                    iconPosition={"left"}
                    icon={<UserPlusIcon color={""} size={0} />}
                    variant={"solid"}
                    color={"blue"}
                    label="Associa azienda"
                    onClick={() => dispatch(setPersonToAddCompany(peopleState.findPersonResponse))}
                  />
              )}
          </div>
        </div>
      )}
      <SendEmailModal />
    </div>
  );
}
