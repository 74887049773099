import { Preferences } from "../../../../lib/preferences";
import { PreferencesSection } from "../../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { Spinner } from "../../../../ui/molecules/spinner";
import { BannersList } from "../../../../ui/organisms/banner/bannerList";
import { NotFound } from "../../../../ui/organisms/notFound";
import { TargetListsFilters } from "../../../targetList/list/filters";
import { TargetListsList } from "../../../targetList/list/list";
import {
  setCopyTargetListStatus,
  setDeleteTargetListStatus
} from "../../../targetList/slice";

export function TargetLists() {
  const projectsState = useAppSelector((state) => state.projects);
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();

  return (
    <div className="p-4 h-full max-h-full">
      <div className="flex flex-col bg-white h-full max-h-full rounded-2xl shadow-sm">
        <BannersList banners={
          [
            {
              type: "success",
              visible: targetListsState.deleteTargetListStatus === "successfully",
              label: "Lista target eliminata.",
              closeAction: () => dispatch(setDeleteTargetListStatus("idle")),
            },
            {
              type: "error",
              visible: targetListsState.deleteTargetListStatus === "failed",
              label: "Si è verificato un errore durante l'eliminazione della lista target.",
              closeAction: () => dispatch(setDeleteTargetListStatus("idle")),
            },
            {
              type: "success",
              visible: targetListsState.copyTargetListStatus === "successfully",
              label: "Lista target copiata correttamente.",
              closeAction: () => dispatch(setCopyTargetListStatus("idle")),
            },
            {
              type: "error",
              visible: targetListsState.copyTargetListStatus === "failed",
              label: "Si è verificato un errore durante la copia della lista target.",
              closeAction: () => dispatch(setCopyTargetListStatus("idle")),
            }
          ]
        } />
        {projectsState.findProjectStatus === "loading" ? (
          <div className="w-full h-[50%] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div style={{ height: 'calc(100%)' }}>
            <TargetListsFilters />
            {targetListsState.findAllTargetListsResponse === undefined ||
              !targetListsState.findAllTargetListsResponse?.data ||
              targetListsState.findAllTargetListsResponse?.data?.length === 0 ? (
              <div style={{ height: 'calc(100% - 64px)' }}>
                <NotFound />
              </div>
            ) : (
              <TargetListsList />
            )}
          </div>
        )}
        <Preferences section={PreferencesSection.targetListPreferences} key={PreferencesSection.targetListPreferences} />
      </div>
    </div>
  );
}
