import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../ui/organisms/confirmModal.tsx";
import { Pagination } from "../../../ui/organisms/pagination";
import {
  deleteUser,
  setFindAllUsersFiltersItemsPerPage,
  setFindAllUsersFiltersPage
} from "../slice";
import { CantDeleteModal } from "./cantDeleteModal";
import { UsersTable } from "./table";

export function UsersList() {
  const usersState = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (usersState.deleteUserStatus === "successfully" || usersState.deleteUserStatus === 'failed' || usersState.showCantDeleteModal) {
      setIdToDelete(undefined);
    }
  }, [usersState.deleteUserStatus, usersState.showCantDeleteModal]);

  if (
    usersState.findAllStatus === "loading" ||
    usersState.findUsersAvatarsListStatus === "loading"
  ) {
    return (
      <div className="w-full flex items-center justify-center h-[45%]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-[100%] flex flex-col" style={{ height: 'calc(100% - 64px)' }}>
      <UsersTable setIdToDelete={function (arg?: string): void {
        setIdToDelete(arg)
      }} />
      <Pagination
        itemsPerPage={usersState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setFindAllUsersFiltersItemsPerPage(value))}
        found={usersState.findAllResponse?.total || 0}
        pages={usersState.findAllResponse?.totalPage || 1}
        currentPage={usersState.filters.page}
        setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))}
      />
      <CantDeleteModal />
      <ConfirmModal
        title={"Elimina utente"}
        label={"Sei sicuro di voler eliminare questo utente?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteUser(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
