import qs from "qs";
import { cdAxios } from "../../../lib/axios";
import { keycloak } from "../../../lib/keycloak";
import { BulkCSVCompaniesFilters, ImportCSVResponseDTO } from "../dto";
import {
  CompanyDTO,
  EditCompanyRequest
} from "./dto";
import { CompaniesService } from "./service";
import { ContactType, FindAllContactsFilters } from "../../../lib/filters/dto";
import { contactsFiltersAdapter } from "../../../lib/utils";

export class CompaniesServiceImpl implements CompaniesService {
  public deleteCompany(id: string[]): Promise<void> {
    return cdAxios
      .delete("/api/companies", {
        data: id,
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public countCompanies(): Promise<{ toActivate: number }> {
    return cdAxios
      .get("/api/companies/count", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findCompanyById(id: string): Promise<CompanyDTO> {
    return cdAxios
      .get("/api/companies/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public editCompany(id: string, data: EditCompanyRequest): Promise<void> {
    return cdAxios
      .put("/api/companies/" + id, { ...data, emails: data.emails.length === 1 && data.emails[0] === '' ? [] : data.emails }, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public importContact(data: FormData): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .post("/api/companies/csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public getCSV(ids: string[]): Promise<string> {
    return cdAxios
      .get("/api/companies/csv", {
        params: {
          ids: ids,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/octet-stream",
        },
      })
      .then((response) => response.data);
  }

  public bulkCSV(
    filters: FindAllContactsFilters
  ): Promise<string> {
    return cdAxios
      .get("/api/companies/bulk-csv", {
        params: contactsFiltersAdapter(filters, ContactType.COMPANY),
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public deleteBulk(
    filters: FindAllContactsFilters
  ): Promise<void> {
    return cdAxios
      .delete("/api/companies/bulk", {
        params: { ...contactsFiltersAdapter(filters, ContactType.COMPANY), page: 0, itemsPerPage: 0 },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
