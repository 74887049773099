import clsx from "clsx";
import { EventDTO } from "../dto";
import { DetailedListEvent } from "./detailedListEvent";

interface Props {
    visible: boolean;
    event: EventDTO;
    isLastColumn: boolean;
    mouseY: number;
    mouseX: number;
}

export function EventTooltip({ visible, event, isLastColumn, mouseY, mouseX }: Props) {
    return (
        <div
            className={clsx(
                "fixed z-20 bg-white border border-gray-300 rounded shadow p-2 w-52",
                {
                    block: visible,
                    hidden: !visible,
                }
            )}
            style={{
                top: isLastColumn ? mouseY + 15 : mouseY,
                left: isLastColumn ? undefined : mouseX + 15,
            }}
        >
            <DetailedListEvent event={event} />
        </div>
    );
}

