import { NewProjectsService } from "../../../pages/projects/service";
import { TargetListPageEnum } from "../../../pages/targetList/dto";
import { RocketIcon } from "../../../ui/icons/rocket";
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { useAppSelector } from "../../redux/hooks";
import { FindAllContactsFilters } from "../dto";

interface Props {
  updateFilters: (filter: Partial<FindAllContactsFilters>) => void
  filters: FindAllContactsFilters
}

export function ProjectFilter(props: Props) {
  const { updateFilters, filters } = props
  const targetListState = useAppSelector((state) => state.targetList);

  const filterProjects = async (inputValue: string) => {
    return await NewProjectsService().findAllProjects({
      commercial: [],
      name: inputValue,
      sector: [],
      startDate: [],
      category: [],
      state: [],
      teamLeaderId: undefined,
      order: true,
      sort: "name",
      itemsPerPage: 50,
      page: 0,
    }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string; }[]>((resolve) => {
      resolve(filterProjects(inputValue));
    });

  return (
    <AccordionFilter
      disabled={
        window.location.pathname.includes("target") &&
        targetListState.targetListPage !== TargetListPageEnum.DB
      }
      key={"person-filter-project"}
      label={"Progetto"}
      icon={<RocketIcon color={""} size={0} />}
      showClear={
        filters.project.length > 0 &&
        (!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListState.targetListPage === TargetListPageEnum.DB))
      }
      options={filters.project}
      clearAction={function (): void {
        updateFilters({ project: [] })
      }}
      deleteFilter={function (option): void {
        updateFilters({
          project: filters.project.filter(
            (project) => project.value !== option.value
          )
        })
      }}
    >
      <SelectCustomAsync
        isMulti
        //@ts-ignore
        onChange={e => updateFilters({ project: e })}
        defaultValue={filters.project}
        noOptionsMessage="Non sono presenti progetti."
        loadOptions={promiseOptions}
        defaultOptions
        isClearable={false}
        placeholder={"Cerca per progetto"}
      />
    </AccordionFilter>
  );
}
