import { GlobeIcon } from "../../../ui/icons/globe";
import { SelectCustom } from "../../../ui/molecules/select";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { ContactType, FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function StateFilter(props: Props) {
    const { updateFilters, filters } = props

    const defaultOptions = [
        { label: 'Italia', value: 'Italia', },
        { label: 'Francia', value: 'Francia' },
        { label: 'Germania', value: 'Germania' },
        { label: "Stati Uniti d'America", value: 'Stati Uniti' },
        { label: 'Regno Unito', value: 'Regno Unito' }
    ];

    // const fetchCountries = async (inputValue: string): Promise<{ value: string, label: string }[]> => {
    //     if (!inputValue) {
    //         return defaultOptions;
    //     }

    //     try {
    //         const response = await axios.get('https://api.mapbox.com/search/geocode/v6/forward', {
    //             params: {
    //                 q: inputValue,
    //                 access_token: 'pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw',
    //                 types: ['country'],
    //                 language: 'it'
    //             },
    //             paramsSerializer: params => {
    //                 return qs.stringify(params, {
    //                     arrayFormat: 'repeat',
    //                     encode: false
    //                 });
    //             }
    //         });

    //         const countries = response.data.features.map((feature: PlaceFeatureDTO) => ({
    //             label: feature.properties.name,
    //             value: feature.properties.name
    //         }));

    //         setIsoCountries(response.data.features.map((feature: PlaceFeatureDTO) => ({ code: feature.properties.context.country.country_code, value: feature.properties.name })))

    //         return countries;
    //     } catch (error) {
    //         console.error("Errore durante il fetch delle nazioni: ", error);
    //         return [];
    //     }
    // };

    // const fetchCities = async (inputValue: string): Promise<{ value: string, label: string }[]> => {
    //     if (!inputValue) {
    //         return defaultOptions;
    //     }

    //     try {
    //         const response = await axios.get('https://api.mapbox.com/search/geocode/v6/forward', {
    //             params: {
    //                 q: inputValue,
    //                 access_token: 'pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw',
    //                 types: ['place'],
    //                 language: 'it',
    //                 country: isoCountries.find(country => country.value === filters.state?.value)?.code
    //             },
    //             paramsSerializer: params => {
    //                 return qs.stringify(params, {
    //                     arrayFormat: 'repeat',
    //                     encode: false
    //                 });
    //             }
    //         });

    //         const countries = response.data.features.map((feature: PlaceFeatureDTO) => ({
    //             label: feature.properties.name,
    //             value: feature.properties.name
    //         }));

    //         return countries;
    //     } catch (error) {
    //         console.error("Errore durante il fetch delle nazioni: ", error);
    //         return [];
    //     }
    // };

    return (
        <AccordionFilter
            key={'person-filter-state'}
            label={"Sede azienda"}
            icon={<GlobeIcon color={""} size={0} />}
            showClear={filters.companyCountry !== undefined}
            options={filters.companyCountry !== undefined ? [filters.companyCountry] : []}
            clearAction={function (): void {
                updateFilters({ companyCountry: undefined })
            }}
            deleteFilter={function (option): void {
                updateFilters({ companyCountry: undefined })
            }}
        >
            <div className="flex flex-col gap-3">
                <SelectCustom
                    isSearchable={false}
                    isClearable
                    onChange={e => {
                        if (e === undefined) {
                            updateFilters({ companyCountry: undefined })
                        } else if (typeof e === 'string') {
                            updateFilters({ companyCountry: { value: e, label: defaultOptions.find(opt => opt.value === e)?.label! } })
                        }
                    }}
                    noOptionsMessage="Stato non trovato"
                    placeholder="Seleziona una nazione..."
                    defaultValue={filters.companyCountry?.value}
                    options={defaultOptions}
                />
            </div>
        </AccordionFilter>
    )
}