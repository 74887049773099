import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { BannersList } from "../../../../ui/organisms/banner/bannerList";
import { setShowRecallContactModal } from "../slice";
import { findRecalls, setCreateRecallStatus, setDeleteRecallStatus, setUpdateRecallStatus } from "./slice";
import { setTabValueDetailsPerson } from "../../../database/people/slice";
import { setTabValueDetailsCompany } from "../../../database/companies/slice";

export function BannerEvents() {
    const dispatch = useAppDispatch()

    const recallsState = useAppSelector((state) => state.recalls)
    const contactsState = useAppSelector((state) => state.contacts)

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            recallsState.createRecallStatus === "failed" ||
            recallsState.createRecallStatus === "successfully"
        ) {
            dispatch(setShowRecallContactModal(false));
            dispatch(setTabValueDetailsPerson('recall'));
            dispatch(setTabValueDetailsCompany(2));
            dispatch(findRecalls({
                page: 0,
                pageSize: 0,
                sort: "",
                sortBy: "",
                fromDateTime: null,
                toDateTime: null,
                contactId: contactsState.getContactResponse?.id
            }))
            timeoutId = setTimeout(() => {
                dispatch(setCreateRecallStatus("idle"));
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [recallsState.createRecallStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            recallsState.updateRecallStatus === "failed" ||
            recallsState.updateRecallStatus === "successfully"
        ) {
            dispatch(setShowRecallContactModal(false));
            dispatch(findRecalls({
                page: 0,
                pageSize: 0,
                sort: "",
                sortBy: "",
                fromDateTime: null,
                toDateTime: null,
                contactId: contactsState.getContactResponse?.id
            }))
            timeoutId = setTimeout(() => {
                dispatch(setUpdateRecallStatus("idle"));
            }, 2000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [recallsState.updateRecallStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            recallsState.deleteRecall === "failed" ||
            recallsState.deleteRecall === "successfully"
        ) {
            dispatch(findRecalls({
                page: 0,
                pageSize: 0,
                sort: "",
                sortBy: "",
                fromDateTime: null,
                toDateTime: null,
                contactId: contactsState.getContactResponse?.id
            }))
            timeoutId = setTimeout(() => {
                dispatch(setDeleteRecallStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [recallsState.deleteRecall]);

    return (
        <BannersList banners={[
            {
                label: "Si è verificato un errore durante la pianificazione del richiamo.",
                visible: recallsState.createRecallStatus === "failed",
                closeAction: () => dispatch(setCreateRecallStatus("idle")),
                type: "error",
            },
            {
                label: "Pianificazione richiamo inserita con successo.",
                visible: recallsState.createRecallStatus === "successfully",
                closeAction: () => dispatch(setCreateRecallStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante la modifica del richiamo.",
                visible: recallsState.updateRecallStatus === "failed",
                closeAction: () => dispatch(setUpdateRecallStatus("idle")),
                type: "error",
            },
            {
                label: "Pianificazione richiamo modificata con successo.",
                visible: recallsState.updateRecallStatus === "successfully",
                closeAction: () => dispatch(setUpdateRecallStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante l'eliminazione del richiamo.",
                visible: recallsState.deleteRecall === "failed",
                closeAction: () => dispatch(setDeleteRecallStatus("idle")),
                type: "error",
            },
            {
                label: "Richiamo eliminato con successo.",
                visible: recallsState.deleteRecall === "successfully",
                closeAction: () => dispatch(setDeleteRecallStatus("idle")),
                type: "success",
            },
        ]
        } />
    )
}