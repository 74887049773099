import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { GlobeIcon } from "../../../../../ui/icons/globe";
import Checkbox from "../../../../../ui/molecules/checkbox";
import { Pagination } from "../../../../../ui/organisms/pagination";
import { ContactType, FindContactsTargetResponse } from "../../../../../lib/filters/dto";

interface Props {
  contacts: FindContactsTargetResponse<ContactType.COMPANY>
  selectedIds: string[];
  setSelectedIds: (values: string[]) => void;
  setPage: (page: number) => void;
}
export function ImportFromDatabaseList(props: Props) {
  const { selectedIds, setSelectedIds, setPage, contacts } = props;
  const contactsIds = useAppSelector(state => state.contacts.getContactsIdsResponse)

  const isAlreadyIn = (id: string): boolean => {
    return contactsIds?.find(contacts => contacts.contactType === ContactType.COMPANY)?.dto.find(company => company.contactId === id) !== undefined
  };

  return (
    <div className="w-full" style={{ height: 'calc(100% - 64px)' }}>
      <div className="overflow-auto" style={{ height: 'calc(100% - 48px)' }}>
        <table className="w-full">
          <thead className="bg-neutral-800 h-[48px] font-semibold text-neutral-500 uppercase text-text-xs sticky top-0 z-10">
            <tr className="bg-neutral-50">
              <th className="text-left w-10">
                <Checkbox
                  shape={"square"}
                  disabled={
                    contacts.data.every(
                      (company) => isAlreadyIn(company.contact.id)
                    )!
                  }
                  checked={
                    contacts.data
                      .filter((company) => !isAlreadyIn(company.contact.id))
                      .every((company) => selectedIds.includes(company.contact.id))! ||
                    contacts.data.every(
                      (company) => isAlreadyIn(company.contact.id)
                    )!
                  }
                  onCheck={function (value: boolean): void {
                    if (
                      contacts.data
                        .filter((company) => !isAlreadyIn(company.contact.id))
                        .every((company) => selectedIds.includes(company.contact.id))
                    ) {
                      let newSelected = [...selectedIds];
                      contacts.data.forEach(
                        (company) => {
                          newSelected = newSelected.filter(
                            (id) => company.contact.id !== id
                          );
                        }
                      );
                      setSelectedIds([...newSelected]);
                    } else {
                      setSelectedIds([
                        ...selectedIds,
                        ...contacts.data
                          .filter(
                            (company) =>
                              !selectedIds.includes(company.contact.id) &&
                              !isAlreadyIn(company.contact.id)
                          )
                          .map((company) => company.contact.id)!,
                      ]);
                    }
                  }}
                />
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">azienda</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">email</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">telefono</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">indirizzo</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">cap</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">città</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">provincia</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">regione</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">stato</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">fatturato</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">P. IVA</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">ATECO</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">settore</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">categoria</div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">
                  <GlobeIcon color={colors.neutral[400]} size={16} />
                </div>
              </th>
              <th className="text-left">
                <div className="flex gap-[4px]">dipendenti</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.data.map(
              (company) => (
                <tr
                  className={clsx(
                    "h-[56px] border-b-[1px] border-b-neutral-100",
                    {
                      "bg-brandPrimary-100": selectedIds.includes(company.contact.id),
                      "bg-white":
                        !selectedIds.includes(company.contact.id) &&
                        !isAlreadyIn(company.contact.id),
                      "bg-brandPrimary-200 opacity-55":
                        !selectedIds.includes(company.contact.id) &&
                        isAlreadyIn(company.contact.id),
                    }
                  )}
                  key={company.contact.id}
                >
                  <td>
                    <Checkbox
                      shape={"square"}
                      disabled={isAlreadyIn(company.contact.id)}
                      checked={
                        selectedIds.includes(company.contact.id) ||
                        isAlreadyIn(company.contact.id)
                      }
                      onCheck={function (value: boolean): void {
                        if (selectedIds.includes(company.contact.id)) {
                          setSelectedIds([
                            ...selectedIds.filter((id) => id !== company.contact.id),
                          ]);
                        } else {
                          setSelectedIds([...selectedIds, company.contact.id]);
                        }
                      }}
                    />
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-brandPrimary-600">
                    {company.contact.name ?? "-"}
                  </td>
                  <td>
                    {company.contact.emails ? (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={"mailto:" + company.contact.emails}
                      >
                        <div className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                          {company.contact.emails}
                        </div>
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {company.contact.phones ? (
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={"tel:" + company.contact.phones[0].phone}
                      >
                        <div className="max-w-[100px] crop-text text-text-sm font-semibold text-neutral-600"></div>
                        {company.contact.phones[0].phone + (company.contact.phones.length > 1 ? (', ..., + ' + (company.contact.phones.length - 1)) : '')}
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.address ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.zipCode ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.city ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.province ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.countryRegion ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.state ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.revenue ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.vat ?? "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.atecos ? company.contact.atecos[0]?.atecoCode : "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.atecos ? company.contact.atecos[0]?.sector : "-"}
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.atecos ? company.contact.atecos[0]?.category : "-"}
                  </td>
                  <td>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={company.contact.website ?? undefined}
                    >
                      <GlobeIcon
                        color={
                          company.contact.website
                            ? colors.neutral[400]
                            : colors.neutral[200]
                        }
                        size={20}
                      />
                    </a>
                  </td>
                  <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                    {company.contact.employeesNumber ?? "-"}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        itemsPerPage={50}
        setItemsPerPage={value => { }}
        fixedPagination
        found={contacts.total || 0}
        key={"pagination-companies-bottom"}
        pages={contacts.totalPage || 0}
        currentPage={
          contacts.page || 0
        }
        setPage={(page) => {
          setPage(page);
        }}
      />
    </div>
  );
}
