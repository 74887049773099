import { useEffect } from "react";
import { keycloak } from "./app/lib/keycloak";
import {
  setKeycloakInitStatus,
  setKeycloakUserInfo,
  setRoles,
} from "./app/lib/keycloak/slice";
import {
  findAllAtecos,
  findAllProjectSuspended,
  findAllTargetListSuspended,
  setFindAllTargetListsSuspendedStatus,
} from "./app/lib/atecos/slice";
import { useAppDispatch, useAppSelector } from "./app/lib/redux/hooks";
import { KeycloakRouter } from "./app/lib/router";
import {
  checkPreferences,
} from "./app/pages/database/dto";
import { targetListCompanyColumns } from "./app/lib/preferences/dto";
import { targetListPersonColumns } from "./app/lib/preferences/dto";
import { targetListColumns } from "./app/lib/preferences/dto";
import { companyColumns } from "./app/lib/preferences/dto";
import { peopleColumns } from "./app/lib/preferences/dto";
import { ProjectChangeStatusModal } from "./app/pages/projects/changeStatusModal";
import { setProjectChangeStateStatus as setChangeStateStatusProjects } from "./app/pages/projects/slice";
import { TargetListChangeStatusModal } from "./app/pages/targetList/changeStatusModal";
import { setTargetListChangeStateStatus } from "./app/pages/targetList/slice";
import { findMe } from "./app/pages/users/slice";
import { Spinner } from "./app/ui/molecules/spinner";
import { BannersList } from "./app/ui/organisms/banner/bannerList";
import { findAllPreferences, setFindAllPreferencesStatus, createPreferences, updatePreferences, setCreatePreferencesStatus, setUpdatePreferencesStatus } from "./app/lib/preferences/slice";

function App() {
  const dispatch = useAppDispatch();
  const atecosState = useAppSelector((state) => state.atecos);
  const preferencesState = useAppSelector((state) => state.preferences);
  const targetListsState = useAppSelector((state) => state.targetList);
  const projectsState = useAppSelector((state) => state.projects);
  const usersState = useAppSelector((state) => state.users);

  useEffect(() => {
    if (window.location.pathname === "/")
      window.location.replace(window.location.origin + "/dashboard");
  }, [window.location.pathname]);

  const keycloakState = useAppSelector((state) => state.keycloak);

  keycloak.onAuthSuccess = () => {
    keycloak.loadUserInfo().then((e) => dispatch(setKeycloakUserInfo(e)));
    if (keycloak.hasRealmRole("company")) {
      keycloak.logout({ redirectUri: window.location.origin });
    }
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles));
    dispatch(setKeycloakInitStatus("successfully"));
    dispatch(findAllPreferences(keycloak.subject!));
    dispatch(findAllAtecos());
    dispatch(findAllTargetListSuspended());
    dispatch(findMe())
  };

  useEffect(() => {
    if (
      preferencesState.findAllStatus === "successfully" &&
      preferencesState.findAllResponse !== undefined
    ) {
      dispatch(setFindAllPreferencesStatus("idle"));
      if (preferencesState.findAllResponse.data.length === 0) {
        dispatch(
          createPreferences({
            owner: "",
            personPreferences: peopleColumns,
            companyPreferences: companyColumns,
            targetListPreferences: targetListColumns,
            targetListCompanyPreferences: targetListCompanyColumns,
            targetListPersonPreferences: targetListPersonColumns
          })
        );
      }
      else if (
        !checkPreferences(preferencesState.findAllResponse.data[0].companyPreferences, companyColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].personPreferences, peopleColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].targetListPreferences, targetListColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].targetListCompanyPreferences, targetListCompanyColumns) ||
        !checkPreferences(preferencesState.findAllResponse.data[0].targetListPersonPreferences, targetListPersonColumns)
      ) {
        dispatch(updatePreferences({
          data: {
            owner: preferencesState.findAllResponse?.data[0].owner!,
            personPreferences: checkPreferences(preferencesState.findAllResponse.data[0].personPreferences, peopleColumns) ? preferencesState.findAllResponse.data[0].personPreferences : peopleColumns,
            companyPreferences: checkPreferences(preferencesState.findAllResponse.data[0].companyPreferences, companyColumns) ? preferencesState.findAllResponse.data[0].companyPreferences : companyColumns,
            targetListPreferences: checkPreferences(preferencesState.findAllResponse.data[0].targetListPreferences, targetListColumns) ? preferencesState.findAllResponse.data[0].targetListPreferences : targetListColumns,
            targetListPersonPreferences: checkPreferences(preferencesState.findAllResponse.data[0].targetListPersonPreferences, targetListPersonColumns) ? preferencesState.findAllResponse.data[0].targetListPersonPreferences : targetListPersonColumns,
            targetListCompanyPreferences: checkPreferences(preferencesState.findAllResponse.data[0].targetListCompanyPreferences, targetListCompanyColumns) ? preferencesState.findAllResponse.data[0].targetListCompanyPreferences : targetListCompanyColumns
          },
          id: preferencesState.findAllResponse?.data[0].id!,
        }))
      }
    }
  }, [preferencesState.findAllStatus]);

  useEffect(() => {
    if (
      atecosState.findAllTargetListSuspendedStatus === "successfully" &&
      atecosState.findAllTargetListSuspendedResponse !== undefined &&
      atecosState.findAllTargetListSuspendedResponse.total === 0
    ) {
      dispatch(findAllProjectSuspended());
      dispatch(setFindAllTargetListsSuspendedStatus("idle"));
    }
  }, [atecosState.findAllTargetListSuspendedStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      targetListsState.changeStateStatus === "failed" ||
      targetListsState.changeStateStatus === "successfully"
    ) {
      dispatch(setFindAllTargetListsSuspendedStatus("idle"));
      if (targetListsState.changeStateStatus === "successfully") {
        dispatch(findAllProjectSuspended());
      }
      timeoutId = setTimeout(() => {
        dispatch(setTargetListChangeStateStatus("idle"));
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [targetListsState.changeStateStatus]);

  useEffect(() => {
    if (
      preferencesState.createStatus === "successfully" ||
      preferencesState.updateStatus === "successfully"
    ) {
      dispatch(setCreatePreferencesStatus("idle"));
      dispatch(setUpdatePreferencesStatus("idle"));
      dispatch(findAllPreferences(keycloak.subject!));
    }
  }, [preferencesState.createStatus, preferencesState.updateStatus]);

  keycloak.onAuthRefreshError = () => {
    keycloak.logout();
  };

  if (
    preferencesState.findAllResponse === undefined ||
    preferencesState.findAllResponse.data[0]?.companyPreferences === undefined ||
    preferencesState.findAllResponse.data[0]?.personPreferences === undefined ||
    preferencesState.findAllResponse.data[0]?.targetListCompanyPreferences === undefined ||
    preferencesState.findAllResponse.data[0]?.targetListPersonPreferences === undefined ||
    preferencesState.findAllResponse.data[0]?.targetListPreferences === undefined
  ) {
    return <></>
  }

  if (
    keycloakState.keycloakInitStatus === "idle" ||
    atecosState.findAllTargetListSuspendedStatus === "loading" ||
    atecosState.findAllProjectSuspendedStatus === "loading" ||
    usersState.findMeStatus === 'loading'
  ) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="App">
      <BannersList
        banners={[
          {
            label: "Liste target aggiornate.",
            visible: targetListsState.changeStateStatus === "successfully",
            closeAction: () => dispatch(setTargetListChangeStateStatus("idle")),
            type: "success",
          },
          {
            label: "Si è verificato un errore durante l'aggiornamento delle liste target.",
            visible: targetListsState.changeStateStatus === "failed",
            closeAction: () => dispatch(setTargetListChangeStateStatus("idle")),
            type: "error",
          },
          {
            label: "Progetti aggiornati.",
            visible: projectsState.changeStateStatus === "successfully",
            closeAction: () => dispatch(setChangeStateStatusProjects("idle")),
            type: "success",
          },
          {
            label: "Si è verificato un errore durante l'aggiornamento dei progetti.",
            visible: projectsState.changeStateStatus === "failed",
            closeAction: () => dispatch(setChangeStateStatusProjects("idle")),
            type: "error",
          },
        ]}
      />
      <KeycloakRouter />
      <TargetListChangeStatusModal />
      <ProjectChangeStatusModal />
    </div>
  );
}

export default App;
