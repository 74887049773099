import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto"
import { keycloak } from "../../../../../lib/keycloak"
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks"
import { DownloadIcon } from "../../../../../ui/icons/download"
import { PlusIcon } from "../../../../../ui/icons/plus"
import { TrashIcon } from "../../../../../ui/icons/trash"
import { UserPlusIcon } from "../../../../../ui/icons/userPlus"
import Button from "../../../../../ui/molecules/button"
import ButtonDropdown from "../../../../../ui/molecules/buttonDropdown"
import { bulkCSVPeople, getCSVPeople } from "../../../../database/people/slice"
import { setBulkDeleteModal, setSoftDeleteModal } from "../../../../database/slice"
import { restoreContacts } from "../../../contacts/slice"
import { TargetListDTO, TargetListPageEnum } from "../../../dto"
import { setSelectedTargetBins, setTargetListPage } from "../../../slice"

interface Props {
    isInBin: boolean
    selectedIds: string[]
    setShowPopup: (show: boolean) => void
    filters: FindAllContactsFilters
    contacts: FindContactsTargetResponse<ContactType.PERSON>
    targetList: TargetListDTO
}

export function ActionsPeopleTargetList(props: Props) {
    const { selectedIds, setShowPopup, filters, contacts, targetList, isInBin } = props
    const dispatch = useAppDispatch()

    const usersState = useAppSelector(state => state.users)

    return (
        <div className="flex items-center gap-2">
            {isInBin && selectedIds.length > 0 &&
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                <Button
                    size={"sm"}
                    label="Ripristina"
                    iconPosition={"left"}
                    variant={"outline"}
                    color={"blue"}
                    icon={<UserPlusIcon color={""} size={0} />}
                    onClick={() => dispatch(restoreContacts(selectedIds))}
                />
            }
            {
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                !isInBin &&
                <ButtonDropdown
                    options={[
                        {
                            disabled: (
                                (keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous) ||
                                (keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator'))
                            ),
                            label: 'Cestina tutti',
                            onClick: () => dispatch(setSoftDeleteModal(true))
                        }
                    ]}
                    size={"sm"}
                    iconPosition={"left"}
                    color={"blue"}
                    onClick={() => dispatch(setSelectedTargetBins(targetList?.id!))}
                    label="Visualizza cestini"
                    icon={<TrashIcon color={""} size={0} />}
                />
            }
            {
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && contacts.total !== 0 &&
                <ButtonDropdown
                    disabled={selectedIds.length <= 0}
                    options={[
                        {
                            label: 'Esporta tutti',
                            onClick: () => dispatch(bulkCSVPeople(filters))
                        }
                    ]}
                    size={"sm"}
                    iconPosition={"left"}
                    color={"blue"}
                    onClick={() => dispatch(getCSVPeople(selectedIds))}
                    label="Esporta"
                    icon={<DownloadIcon color={""} size={0} />}
                />
            }
            {
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && contacts.total !== 0 &&
                <ButtonDropdown
                    disabled={selectedIds.length <= 0}
                    icon={<TrashIcon color={""} size={0} />}
                    options={[
                        {
                            label: 'Elimina tutti',
                            onClick: () => {
                                dispatch(setBulkDeleteModal(true))
                            }
                        }
                    ]}
                    onClick={() => setShowPopup(true)}
                    label="Elimina"
                    size={"sm"}
                    iconPosition={"left"}
                    color={"blue"}
                />
            }
            {
                !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous) &&
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"solid"}
                    color={"blue"}
                    icon={<PlusIcon color={""} size={0} />}
                    label={
                        "Aggiungi persone"
                    }
                    onClick={() =>
                        dispatch(setTargetListPage(TargetListPageEnum.ADD))
                    }
                />
            }
        </div>
    )
}