import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../../lib/types";
import { CreateEventDTO, EventResponseDTO, EventsFiltersDTO } from "../../../calendar/dto";
import { NewEventsService } from "../../../calendar/service";

interface RecallState {
    deleteRecall: PromiseStatuses
    findRecallStatus: PromiseStatuses
    updateRecallStatus: PromiseStatuses
    operationType: 'save' | 'edit'
    findRecallResponse?: EventResponseDTO
    createRecallStatus: PromiseStatuses
    errors: {
        create: {
            date: boolean
            time: boolean
            notification: boolean
            status: PromiseStatuses
        }
    }
}

const initialState: RecallState = {
    deleteRecall: "idle",
    updateRecallStatus: "idle",
    operationType: 'save',
    findRecallStatus: "idle",
    createRecallStatus: "idle",
    errors: {
        create: {
            date: false,
            time: false,
            notification: false,
            status: 'idle'
        }
    }
};

export const validateRecall = createAsyncThunk(
    "recalls/validateRecall",
    async (request: {
        date: Date | null
        time: Date | null
        notification: number
    }, thunkApi): Promise<void> => {
        let isValid = true;

        thunkApi.dispatch(setErrorsCreateDate(false));
        thunkApi.dispatch(setErrorsCreateTime(false));
        thunkApi.dispatch(setErrorsCreateNotification(false));
        thunkApi.dispatch(setErrorsCreateStatus("idle"));

        if (request.date === null) {
            thunkApi.dispatch(setErrorsCreateDate(true));
            isValid = false;
        }

        if (request.time === null) {
            thunkApi.dispatch(setErrorsCreateTime(true));
            isValid = false;
        }

        if (request.notification === undefined) {
            thunkApi.dispatch(setErrorsCreateNotification(true));
            isValid = false;
        }

        if (!isValid) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
);

export const findRecalls = createAsyncThunk(
    "recalls/findRecalls",
    async (filters: EventsFiltersDTO, thunkApi): Promise<EventResponseDTO> => {
        const RecallsService = NewEventsService();

        return RecallsService.findAllEvents(filters);
    }
);

export const createRecalls = createAsyncThunk(
    "recalls/createRecalls",
    async (request: CreateEventDTO, thunkApi): Promise<void> => {
        const RecallsService = NewEventsService();

        return RecallsService.createEvent(request);
    }
);

export const deleteRecall = createAsyncThunk(
    "recalls/deleteRecall",
    async (request: string, thunkApi): Promise<void> => {
        const RecallsService = NewEventsService();

        return RecallsService.deleteEvent(request);
    }
);

export const updateRecall = createAsyncThunk(
    "recalls/updateRecall",
    async (request: { data: CreateEventDTO, id: string }, thunkApi): Promise<void[]> => {
        const RecallsService = NewEventsService();

        let promises: Promise<void>[] = [];
        promises.push(RecallsService.updateEvent(request.data.notes, request.id))
        promises.push(RecallsService.rescheduleEvent(request.data.startDate, request.data.endDate, request.data.notification, request.id))
        return Promise.all(promises)
    }
);

const recallSlice = createSlice({
    name: 'recalls',
    initialState,
    reducers: {
        setFindRecallsStatus: (state, action) => {
            state.findRecallStatus = action.payload;
        },
        setFindRecallsResponse: (state, action) => {
            state.findRecallResponse = action.payload;
        },
        setCreateRecallStatus: (state, action) => {
            state.createRecallStatus = action.payload;
        },
        setErrorsCreateDate: (state, action) => {
            state.errors.create.date = action.payload;
        },
        setErrorsCreateTime: (state, action) => {
            state.errors.create.time = action.payload;
        },
        setErrorsCreateNotification: (state, action) => {
            state.errors.create.notification = action.payload;
        },
        setErrorsCreateStatus: (state, action) => {
            state.errors.create.status = action.payload;
        },
        setOperationType: (state, action) => {
            state.operationType = action.payload;
        },
        setUpdateRecallStatus: (state, action) => {
            state.updateRecallStatus = action.payload;
        },
        setDeleteRecallStatus: (state, action) => {
            state.deleteRecall = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findRecalls.pending, (state) => {
                state.findRecallStatus = "loading";
            })
            .addCase(findRecalls.fulfilled, (state, action) => {
                state.findRecallStatus = "successfully";
                state.findRecallResponse = action.payload;
            })
            .addCase(findRecalls.rejected, (state) => {
                state.findRecallStatus = "failed";
            })
            .addCase(createRecalls.pending, (state) => {
                state.createRecallStatus = "loading";
            })
            .addCase(createRecalls.fulfilled, (state) => {
                state.createRecallStatus = "successfully";
            })
            .addCase(createRecalls.rejected, (state) => {
                state.createRecallStatus = "failed";
            })
            .addCase(validateRecall.pending, (state) => {
                state.errors.create.status = "loading";
            })
            .addCase(validateRecall.fulfilled, (state) => {
                state.errors.create.status = "successfully";
            })
            .addCase(validateRecall.rejected, (state) => {
                state.errors.create.status = "failed";
            })
            .addCase(updateRecall.pending, (state) => {
                state.updateRecallStatus = "loading";
            })
            .addCase(updateRecall.fulfilled, (state) => {
                state.updateRecallStatus = "successfully";
            })
            .addCase(updateRecall.rejected, (state) => {
                state.updateRecallStatus = "failed";
            })
            .addCase(deleteRecall.pending, (state) => {
                state.deleteRecall = "loading";
            })
            .addCase(deleteRecall.fulfilled, (state) => {
                state.deleteRecall = "successfully";
            })
            .addCase(deleteRecall.rejected, (state) => {
                state.deleteRecall = "failed";
            })
    },
});

export const {
    setFindRecallsStatus,
    setFindRecallsResponse,
    setCreateRecallStatus,
    setErrorsCreateDate,
    setErrorsCreateTime,
    setErrorsCreateNotification,
    setErrorsCreateStatus,
    setOperationType,
    setUpdateRecallStatus,
    setDeleteRecallStatus
} = recallSlice.actions;
export default recallSlice.reducer;
