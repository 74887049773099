import { ContactDTO, ContactType, FindAllContactsFilters } from "../../../lib/filters/dto";
import { ImportCSVResponseDTO } from "../../database/dto";
import { ContactStateEnum, NoteDTO } from "../dto";
import { ContactIdsDTO, CounterDTO } from "./dto";
import { ContactsServiceImpl } from "./serviceImpl";

export interface ContactsService {
  deleteBulk(
    filters: FindAllContactsFilters,
    targetList: string,
    contactType: ContactType
  ): Promise<void>
  addContactsBulk(
    targetListId: string,
    filters: FindAllContactsFilters,
    contactType: ContactType
  ): Promise<void>
  getContactIds(
    targetListId: string, contactType: ContactType
  ): Promise<ContactIdsDTO[]>
  getCounters(
    targetListId: string, contactType: ContactType
  ): Promise<CounterDTO>
  importContactCSV(
    data: FormData,
    type: ContactType,
  ): Promise<ImportCSVResponseDTO>
  getContact(
    contactId: string,
  ): Promise<ContactDTO<ContactType>>
  restoreContacts(
    contactIds: string[],
  ): Promise<void>
  updateNotes(
    contactId: string,
    note: NoteDTO[]
  ): Promise<void>
  changeContactStatus(
    contactId: string,
    contactState?: ContactStateEnum,
    stateInfo?: string | null
  ): Promise<void>;
  addContacts(
    targetListId: string,
    type: ContactType,
    contactIds: string[]
  ): Promise<void>
  removeContacts(
    contactIds: string[]
  ): Promise<void>;
  softBulk(
    targetList: string,
    contactType: ContactType
  ): Promise<void>
}

export function NewContactsService(): ContactsService {
  return new ContactsServiceImpl();
}
