import { createSlice } from "@reduxjs/toolkit";

interface DatabaseState {
  tabValue: number;
  filtersActive: boolean;
  bulkDeleteModal: boolean
  bulkCSVModal: boolean
  softDeleteModal: boolean
}

const initialState: DatabaseState = {
  tabValue: 0,
  filtersActive: false,
  bulkDeleteModal: false,
  bulkCSVModal: false,
  softDeleteModal: false
};

const databaseSlice = createSlice({
  name: "database/slice",
  initialState,
  reducers: {
    setTabValue: (state, action) => {
      state.tabValue = action.payload;
    },
    setFiltersActive: (state, action) => {
      state.filtersActive = action.payload;
    },
    setBulkDeleteModal: (state, action) => {
      state.bulkDeleteModal = action.payload;
    },
    setBulkCSVModal: (state, action) => {
      state.bulkCSVModal = action.payload;
    },
    setSoftDeleteModal: (state, action) => {
      state.softDeleteModal = action.payload
    }
  },
});

export const { setTabValue, setFiltersActive, setBulkDeleteModal, setBulkCSVModal, setSoftDeleteModal } =
  databaseSlice.actions;

export default databaseSlice.reducer;
