import clsx from 'clsx';
import { it } from 'date-fns/locale/it';
import { useState } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { colors } from '../../colors';
import { CalendarIcon } from '../../icons/calendar';
import { ChevronIcon } from '../../icons/chevron';
import './style.css';
import { ClockIcon } from '../../icons/clock';

registerLocale('it', it);

interface Props {
    startDate: Date | null;
    setStartDate: (date: Date | null) => void;
    label?: string;
    error?: boolean;
    disabled?: boolean;
    supportingText?: string
    type: 'date' | 'time'
}

export function Calendar(props: Props) {
    const { startDate, setStartDate, label, error, disabled, supportingText, type } = props;
    const [open, setOpen] = useState(false);
    const customLocale = {
        ...it,
        localize: {
            ...it.localize,
            day: (n: number) => ['L', 'M', 'M', 'G', 'V', 'S', 'D'][n]
        }
    };

    registerLocale('it-custom', customLocale);
    //@ts-ignore
    const MyContainer = ({ className, children }) => (
        <CalendarContainer className={className + ' bg-white border border-neutral-100 p-3 shadow-sm gap-1 flex rounded-lg'}>
            <div style={{ position: "relative", backgroundColor: 'white' }}>{children}</div>
        </CalendarContainer>
    )

    return (
        <div className='flex flex-col items-start gap-1 w-full'>
            <span className='input-label'>{label}</span>
            <div className={clsx('h-10 flex flex-row-reverse bg-white border rounded-md px-6 py-0 w-full', {
                'border-red-300 focus:border-red-300': error,
                'border-neutral-400  focus:border-neutral-400': !error,
                'bg-neutral-100': disabled,
            })}>
                <DatePicker
                    calendarStartDay={0}
                    locale="it-custom"
                    selected={startDate}
                    onChange={(date) => { setStartDate(date); setOpen(false) }}
                    dateFormat={type === 'date' ? "dd/MM/yyyy" : 'HH:mm'}
                    showPopperArrow={false}
                    timeIntervals={15}
                    placeholderText={type === 'date' ? 'gg/mm/aaaa' : 'hh:mm'}
                    wrapperClassName='w-full flex justify-center'
                    calendarClassName="bg-white"
                    calendarContainer={MyContainer}
                    calendarIconClassName='p-0'
                    showTimeSelect={type === 'time'}
                    showTimeSelectOnly={type === 'time'}
                    open={open}
                    disabled={disabled}
                    onClickOutside={() => setOpen(false)}
                    showIcon
                    toggleCalendarOnIconClick
                    className="h-full font-[Manrope] w-full border-0 outline-none p-0"
                    renderDayContents={(day, date) => {
                        return <div className='h-6 w-6 rounded-full flex items-center justify-center m-0'>{day}</div>;
                    }}
                    icon={type === 'date' ?
                        <CalendarIcon color={colors.neutral[500]} size={20} onClick={(e) => setOpen(open ? false : true)} /> :
                        <ClockIcon color={colors.neutral[500]} size={20} onClick={(e) => setOpen(open ? false : true)} />
                    }
                    renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                        <div className='bg-white flex items-center justify-between'>
                            <span className='ps-1 capitalize font-[Manrope] text-text-md font-semibold text-black'>{date.toLocaleString('it-IT', { month: 'long', year: 'numeric' })}</span>
                            <div className='flex gap-1'>
                                <button onClick={decreaseMonth}><ChevronIcon color={colors.neutral[500]} size={24} direction='l' /></button>
                                <button onClick={increaseMonth}><ChevronIcon color={colors.neutral[500]} size={24} direction='r' /></button>
                            </div>
                        </div>
                    )}
                    dayClassName={(date) =>
                        date.getDay() === 6 ? "text-red-500" : ""
                    }
                />
            </div>
            {supportingText && (
                <span
                    className={clsx('text-text-sm', {
                        'text-red-500': error,
                        'text-neutral-400': !error,
                    })}
                >
                    {supportingText}
                </span>
            )}
        </div>
    );
}
