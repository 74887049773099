import { eachWeekOfInterval, endOfWeek, format, getMonth, getWeek, startOfWeek } from "date-fns";
import { it } from "date-fns/locale/it";
import { ChevronIcon } from "../../../ui/icons/chevron";
import Button from "../../../ui/molecules/button";
import { EventsFiltersDTO } from "../dto";
import { ChangePeriod } from "./changePeriod";
import { get } from "http";

interface Props {
    filters: EventsFiltersDTO
    changePeriod: (period: 'month' | 'week') => void
    changeInterval: (action: "next" | "prev" | "today") => void
}

export function WeekCalendarFilters(props: Props) {
    const { filters, changePeriod, changeInterval } = props

    const getWeekInfo = (date: Date) => {
        const weekStart = startOfWeek(date, { weekStartsOn: 1 });
        const weekEnd = endOfWeek(date, { weekStartsOn: 1 });

        const startMonth = getMonth(weekStart);
        const endMonth = getMonth(weekEnd);

        const weekNumber = getWeek(date, { weekStartsOn: 1 });

        return {
            weekNumber,
            months: startMonth === endMonth
                ? [format(weekStart, 'MMMM yyyy', { locale: it })]
                : [format(weekStart, (weekStart.getFullYear() === weekEnd.getFullYear() ? 'MMMM' : 'MMMM yyyy'), { locale: it }), format(weekEnd, 'MMMM yyyy', { locale: it })]
        };
    };
    return (
        <div className="p-6 flex gap-2 items-center justify-between">
            <div className="flex gap-4 items-center">
                <span className="capitalize text-heading-sm font-bold text-neutral-800">
                    {getWeekInfo(new Date(filters.fromDateTime!)).months.join(" - ")}
                </span>
                <div className="rounded border border-neutral-200 px-[6px] text-text-sm font-semibold text-neutral-600">
                    Sett {getWeekInfo(new Date(filters.fromDateTime!)).weekNumber}
                </div>
            </div>
            <div className="flex gap-3 items-center">
                <ChangePeriod period="week" changePeriod={period => changePeriod(period)} />
                <div className="shadow-xs rounded-lg border border-neutral-200 flex items-center h-full transition-all">
                    <Button onClick={() => changeInterval('prev')} size={"md"} iconPosition={"only"} variant={"ghost"} color={"gray"} icon={<ChevronIcon color={""} size={0} direction="l" />} />
                    <div onClick={() => changeInterval('today')} className="text-label-sm h-full cursor-pointer font-semibold text-neutral-800 border-x border-x-neutral-200 flex items-center px-3">Oggi</div>
                    <Button onClick={() => changeInterval('next')} size={"md"} iconPosition={"only"} variant={"ghost"} color={"gray"} icon={<ChevronIcon color={""} size={0} direction="r" />} />
                </div>
            </div>
        </div >
    )
}