export const signOptions = [
  { label: "Uguale a", value: "=" },
  { label: "Maggiore di", value: ">" },
  { label: "Minore di", value: "<" },
  { label: "Compreso tra", value: "><" },
];

export function checkPreferences(actual: { column: string, visible: boolean, hideable: boolean, width?: number | null }[] | null, ordinary: { column: string, visible: boolean, hideable: boolean, width?: number | null }[]): boolean {
  if (actual === null) {
    return false
  }
  if (actual.length !== ordinary.length) {
    return false
  } else {
    const sortedActualArray = [...actual].sort((a, b) => a.column.localeCompare(b.column));
    const sortedOrdinaryArray = [...ordinary].sort((a, b) => a.column.localeCompare(b.column));

    if (!sortedActualArray.map(val => val.column).every((value, index) => value === sortedOrdinaryArray.map(val => val.column)[index]))
      return false;


    return true
  }
}

export interface ImportCSVResponseDTO {
  base64CsvMissed: string
  ids: number[];
  missed: number[];
}

export interface BulkCSVFilters {
  filter?: string
  active?: "true" | "false"
  name: { value: string; label: string }[];
  atecos?: { value: string; label: string };
  sector: { value: string; label: string }[];
  category: { value: string; label: string }[];
  department: { value: string; label: string }[];
  role: { value: string; label: string }[];
  revenue?: { value: string; label: string };
  hasSmartphone?: { value: string; label: string };
  privacy?: { value: string; label: string };
  employees?: { value: string; label: string };
  state?: { value: string; label: string };
  zipCode?: { value: string; label: string };
  countryRegion: { value: string; label: string }[];
  city?: { value: string; label: string };
  targetList: { value: string; label: string }[];
  project: { value: string; label: string }[];
  outcome: { value: string; label: string }[];
  particularity: { value: string; label: string }[];
  smartphone?: { value: string; label: string };
  creationContactDate?: { value: string; label: string };
  lastContactDate?: { value: string; label: string };
  outcomeInfo: { value: string; label: string }[];
  province: { value: string, label: string }[];
}

export interface BulkCSVCompaniesFilters {
  filter?: string
  active?: "true" | "false"
  name: { value: string; label: string }[];
  atecos?: { value: string; label: string };
  sector: { value: string; label: string }[];
  category: { value: string; label: string }[];
  department: { value: string; label: string }[];
  role: { value: string; label: string }[];
  revenue?: { value: string; label: string };
  hasSmartphone?: { value: string; label: string };
  privacy?: { value: string; label: string };
  employees?: { value: string; label: string };
  state?: { value: string; label: string };
  zipCode?: { value: string; label: string };
  countryRegion: { value: string; label: string }[];
  city?: { value: string; label: string };
  targetList: { value: string; label: string }[];
  project: { value: string; label: string }[];
  outcome: { value: string; label: string }[];
  particularity: { value: string; label: string }[];
  phone?: { value: string; label: string };
  creationContactDate?: { value: string; label: string };
  lastContactDate?: { value: string; label: string };
  outcomeInfo: { value: string; label: string }[];
  province: { value: string; label: string }[];
}

export interface SearchPlacesDTO {
  type: string;
  features: PlaceFeatureDTO[];
  attribution: string;
}

export interface PlaceFeatureDTO {
  type: "Feature";
  id: string;
  geometry: {
    type: "Point";
    coordinates: number[];
  };
  properties: {
    mapbox_id: string;
    feature_type: string;
    full_address: string;
    name: string;
    name_preferred: string;
    coordinates: {
      longitude: string;
      latitude: string;
    };
    bbox: number[];
    context: {
      country: {
        mapbox_id: string;
        name: string;
        country_code: string;
        country_code_alpha_3: string;
        wikidata_id: string;
        translations: {
          it: {
            language: string;
            name: string;
          };
        };
      };
    };
  };
}
