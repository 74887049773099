import { NewParticularitiesService } from "../../../pages/particularities/service";
import { MaximizeIcon } from "../../../ui/icons/maximize";
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { ContactType, FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function ParticularitiesFilters(props: Props) {
    const { updateFilters, filters } = props

    const filterParticularity = async (inputValue: string) => {
        return await NewParticularitiesService().findAllParticularities({
            order: true,
            sort: 'name',
            itemsPerPage: 25,
            page: 0,
            name: ""
        }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ value: string; label: string; }[]>((resolve) => {
            resolve(filterParticularity(inputValue));
        });

    return (
        <AccordionFilter
            key={"person-filter-particularities"}
            label={"Particolarità"}
            icon={<MaximizeIcon color={""} size={0} />}
            showClear={filters.particularity.length > 0}
            clearAction={() => updateFilters({ particularity: [] })}
            deleteFilter={(option) => updateFilters({ particularity: filters.particularity.filter(name => name.value !== option.value) })}
            options={filters.particularity}
        >
            <SelectCustomAsync
                isMulti
                //@ts-ignore
                onChange={e => updateFilters({ particularity: e })}
                defaultValue={filters.particularity}
                noOptionsMessage="Non sono presenti particolarità."
                loadOptions={promiseOptions}
                defaultOptions
                isClearable={false}
                placeholder={"Cerca per particolarità"}
            />
        </AccordionFilter>
    )
}