import { CompanyDTO } from "../../pages/database/companies/dto";
import { PersonDTO } from "../../pages/database/people/dto";
import { ContactStateEnum, NoteDTO } from "../../pages/targetList/dto";
import { ItemsPerPageValues } from "../../ui/organisms/pagination/dto";
import { PromiseStatuses } from "../types";

type ParameterMap = {
    'COMPANY': CompanyDTO
    'PERSON': PersonDTO
}

export interface SectionFilters {
    'database': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
    'target': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
    'add-target-db': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
    'add-target-tl': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
    'target-bin': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
    'black-list': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
    'verify-list': { PERSON: FindAllContactsFilters, COMPANY: FindAllContactsFilters };
}

export interface DatabaseResponse<T extends ContactType> { response: FindContactsDatabaseResponse<T>, status: PromiseStatuses }
export interface SectionDatabaseContacts {
    'database': { PERSON: DatabaseResponse<ContactType.PERSON>, COMPANY: DatabaseResponse<ContactType.COMPANY> }
    'add-target-db': { PERSON: DatabaseResponse<ContactType.PERSON>, COMPANY: DatabaseResponse<ContactType.COMPANY> }
    'black-list': { PERSON: DatabaseResponse<ContactType.PERSON>, COMPANY: DatabaseResponse<ContactType.COMPANY> }
    'verify-list': { PERSON: DatabaseResponse<ContactType.PERSON>, COMPANY: DatabaseResponse<ContactType.COMPANY> }
}

export interface TargetResponse<T extends ContactType> { response: FindContactsTargetResponse<T>, status: PromiseStatuses }
export interface SectionTargetContacts {
    'target': { PERSON: TargetResponse<ContactType.PERSON>, COMPANY: TargetResponse<ContactType.COMPANY> }
    'add-target-tl': { PERSON: TargetResponse<ContactType.PERSON>, COMPANY: TargetResponse<ContactType.COMPANY> }
    'target-bin': { PERSON: TargetResponse<ContactType.PERSON>, COMPANY: TargetResponse<ContactType.COMPANY> }
}

export enum ContactType {
    COMPANY = 'COMPANY',
    PERSON = 'PERSON',
}

export interface FindAllContactsFilters {
    outcome: { value: string; label: string; }[];
    outcomeInfo: { value: string; label: string; }[];
    companyName: { value: string; label: string; }[];
    companyAtecoCode?: { value: string; label: string; }[];
    companyRevenue?: { value: string; label: string; };
    companyEmployees?: { value: string; label: string; };
    companySector: { value: string; label: string; }[];
    companyCategory: { value: string; label: string; }[];
    companyPhone?: { value: string; label: string; };
    companyCountry?: { value: string; label: string; };
    companyRegion: { value: string; label: string; }[];
    companyProvince: { value: string; label: string; }[];
    companyCity?: { value: string; label: string; };
    companyZipCode?: { value: string; label: string; };
    companySearch?: string
    personDepartment: { value: string; label: string; }[];
    personRole: { value: string; label: string; }[];
    personHasSmartphone?: { value: string; label: string; };
    personPhone?: { value: string; label: string; };
    privacy?: { value: string; label: string; };
    particularity: { value: string; label: string; }[];
    creationDate?: { value: string; label: string; };
    lastContactDate?: { value: string; label: string; };
    personName?: string;
    personEmail?: string;
    targetListBin?: boolean;
    personHasCompany?: "true" | "false";
    targetList: { value: string; label: string; }[];
    active?: "true" | "false";
    blackListBin?: boolean;
    toVerifyBin?: boolean;
    project: { value: string; label: string; }[];
    missingFields: { value: string, label: string }[]

    //Pagination
    order: boolean;
    sort?: string;
    itemsPerPage: ItemsPerPageValues;
    page: number;
}

export interface ContactDTO<T extends ContactType> {
    id: string
    createdAt: Date | null,
    updatedAt: Date | null,
    deletedAt: Date | null,
    contact: ParameterMap[T],
    contactId: string
    type: T,
    targetListId: string
    outcome: ContactStateEnum
    outcomeInfo: string,
    lastContactDate: Date | null,
    callAttempts: null | number,
    notes: NoteDTO[] | null
}

export interface FindContactsTargetResponse<T extends ContactType> {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: ContactDTO<T>[]
}

export interface FindContactsDatabaseResponse<T extends ContactType> {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: ParameterMap[T][]
}

