import clsx from "clsx"
import { Filters } from "../../../lib/filters"
import { CompaniesHorizontalFilters } from "../../../lib/filters/accordion/horizontalFiltersCompany"
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../lib/filters/dto"
import { useAppSelector } from "../../../lib/redux/hooks"
import { PromiseStatuses } from "../../../lib/types"
import { Spinner } from "../../../ui/molecules/spinner"
import { TargetCompaniesList } from "../editTargetListDetails/companies/list/list"

interface Props {
    showPopup: boolean
    setShowPopup: (action: boolean) => void
    selectedIds: string[]
    setSelectedIds: (action: string[]) => void
    filters: FindAllContactsFilters
    setFilters: (action: Partial<FindAllContactsFilters>) => void
    setPage: (action: number) => void
    resetFilters: () => void
    contacts: FindContactsTargetResponse<ContactType.COMPANY>
    status: PromiseStatuses
    reloadContacts: () => void
}

export function TargetCompaniesBins(props: Props) {
    const { selectedIds, setSelectedIds, setShowPopup, showPopup, setFilters, filters, setPage, resetFilters, contacts, status, reloadContacts } = props
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.companies)

    return (
        <div className="max-h-full w-full flex h-full">
            <div className="flex w-full">
                <div
                    className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
                        flex: databaseState.filtersActive,
                        hidden: !databaseState.filtersActive,
                    })}
                >
                    <Filters
                        contactType={ContactType.COMPANY}
                        isInTargetList
                        resetFilters={function (): void {
                            resetFilters()
                        }}
                        filters={filters}
                        updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                            setFilters(newFilters)
                        }} />
                </div>
                <div
                    className={clsx({
                        "w-full": !databaseState.filtersActive,
                        "w-4/5": databaseState.filtersActive,
                    })}
                >
                    {status === "loading" ? (
                        <div className="w-full h-[45%] flex items-center justify-center">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="h-full">
                            <CompaniesHorizontalFilters updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                                setFilters(newFilters)
                            }} filters={filters} isInTargetList={true} />
                            <TargetCompaniesList
                                reloadContacts={() => reloadContacts()}
                                contacts={contacts} status={status}
                                showPopup={showPopup} setShowPopup={arg => setShowPopup(arg)} selectedIds={selectedIds} setSelectedIds={arg => setSelectedIds(arg)}
                                filters={filters} setFilters={function (action: Partial<FindAllContactsFilters>): void {
                                    setFilters(action)
                                }} setPage={function (action: number): void {
                                    setPage(action)
                                }} resetFilters={function (): void {
                                    resetFilters()
                                }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}