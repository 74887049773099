import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../lib/types";
import { CustomMailCreateDTO, CustomMailDTO, CustomMailUpdateDTO, EmailSettingsDTO, EmailsHistoryFilters, EmailsMessagesDTO, FindAllCustomMailsResponseDTO, findEmailByIdResponseDTO, Mail, MailFilters, SaveResponseDTO } from "./dto";
import { NewMailService } from "./service";
import { AxiosError } from "axios";

interface MailState {
    mailFilters: MailFilters
    emailsHistoryFilters: EmailsHistoryFilters
    findAllMailsResponse: FindAllCustomMailsResponseDTO
    findAllMailsStatus: PromiseStatuses
    findMailByIdResponse: CustomMailDTO | undefined
    findMailByIdStatus: PromiseStatuses
    saveMailResponse: SaveResponseDTO
    saveMailStatus: PromiseStatuses
    sendEmailSettingsStatus: PromiseStatuses
    updateMailStatus: PromiseStatuses
    removeMailStatus: PromiseStatuses
    sendCustomMailStatus: PromiseStatuses
    findEmailByIdStatus: PromiseStatuses
    findEmailByIdResponse: findEmailByIdResponseDTO
    customMailCreateRequest: CustomMailCreateDTO
    customMailUpdateRequest: CustomMailUpdateDTO
    mailToSendDetails: Mail
    mailToSendObject: string
    mailToSendContent: string
    openEmailSettingsModal: boolean
    openSendEmailModal: boolean
    emailSettingsTab: number
    findEmailsHistoryResponse: { messages: EmailsMessagesDTO[] } | undefined
    findEmailsHistoryStatus: PromiseStatuses
    selectedTemplateId: string | undefined
    openTemplateId: string | undefined
    templateToRemoveId: string | undefined
    sendVerificationMailStatus: PromiseStatuses
    sendVerificationMailMessage: string
}

const initialState: MailState = {
    mailFilters: {
        page: 0,
        pageSize: 10,
        sortBy: '1',
        projectId: "",
    },
    emailsHistoryFilters: {
        fromEmail: "",
        toEmail: "",
        object: "",
        limit: 10
    },
    findAllMailsResponse: {
        data: [],
        next: false,
        page: 0,
        perPage: 0,
        prev: false,
        total: 0,
        totalPage: 0
    },
    findAllMailsStatus: "idle",
    findMailByIdResponse: undefined,
    findMailByIdStatus: "idle",
    saveMailResponse: {
        id: ''
    },
    saveMailStatus: "idle",
    updateMailStatus: "idle",
    removeMailStatus: "idle",
    sendCustomMailStatus: "idle",
    findEmailByIdStatus: "idle",
    findEmailByIdResponse: {
        from_email: "",
        msg_id: "",
        subject: "",
        to_email: "",
        status: "",
        template_id: "",
        asm_group_id: 0,
        teammate: "",
        api_key_id: "",
        events: []
    },
    customMailCreateRequest: {
        fromEmail: "",
        fromEmailName: "",
        templateHTML: "",
        templateText: "",
        subject: "",
        projectId: "",
        templateName: ""
    },
    customMailUpdateRequest: {
        fromEmail: "",
        fromEmailName: "",
        templateHTML: "",
        templateText: "",
        subject: "",
        templateName: "",
        projectId: ''
    },
    mailToSendDetails: {
        to: "",
        name: "",
        cc: "",
        ccn: "",
        body: {},
    },
    openEmailSettingsModal: false,
    openSendEmailModal: false,
    emailSettingsTab: 0,
    findEmailsHistoryResponse: undefined,
    findEmailsHistoryStatus: 'idle',
    sendEmailSettingsStatus: "idle",
    selectedTemplateId: undefined,
    openTemplateId: undefined,
    templateToRemoveId: undefined,
    mailToSendObject: "",
    mailToSendContent: "",
    sendVerificationMailStatus: 'idle',
    sendVerificationMailMessage: ''
}

export const findAllCustomMails = createAsyncThunk(
    'mails/findAllMails', async (filters: MailFilters): Promise<FindAllCustomMailsResponseDTO> => {
        const mailService = NewMailService()

        return mailService.findAllCustomMails(filters)
    }
)

export const sendVerificationMail = createAsyncThunk(
    'mails/sendVerificationMail', async (request: string, thunkApi): Promise<void> => {
        const mailService = NewMailService()

        return mailService.sendVerificationMail(request).catch((error) => {
            throw thunkApi.rejectWithValue(error);
        });
    }
)

export const findMailByID = createAsyncThunk(
    'mails/findMailById', async (id: string): Promise<CustomMailDTO> => {
        const mailService = NewMailService()

        return mailService.findMailByID(id)
    }
)

export const findEmailByID = createAsyncThunk(
    'mails/findEmailById', async (id: string): Promise<findEmailByIdResponseDTO> => {
        const mailService = NewMailService()

        return mailService.findEmailById(id)
    }
)

export const findEmailsHistory = createAsyncThunk(
    'mails/findHistory', async (filters: EmailsHistoryFilters): Promise<{ messages: EmailsMessagesDTO[] }> => {
        const mailService = NewMailService()

        return mailService.findEmailsHistory(filters)
    }
)

export const saveMail = createAsyncThunk(
    'mails/saveMail', async (request: CustomMailCreateDTO): Promise<SaveResponseDTO> => {
        const mailService = NewMailService()

        return mailService.saveMail(request)
    }
)

export const updateMail = createAsyncThunk(
    'mails/updateMail', async (request: { id: string, request: CustomMailUpdateDTO }): Promise<string> => {
        const mailService = NewMailService()

        return mailService.updateMail(request.id, request.request)
    }
)

export const sendCustomMail = createAsyncThunk(
    'mails/sendCustomMail', async (request: { customMailId: string, request: Mail }): Promise<string> => {
        const mailService = NewMailService()

        return mailService.sendCustomMail(request.customMailId, request.request)
    }
)

export const removeMail = createAsyncThunk(
    'mails/removeMail', async (id: string): Promise<string> => {
        const mailService = NewMailService()

        return mailService.removeMail(id)
    }
)

const mailSlice = createSlice({
    name: "mails/slice",
    initialState: initialState,
    reducers: {
        setEmailSettingsModalOpen: (state, action) => {
            state.openEmailSettingsModal = action.payload
        },
        setSendEmailModalOpen: (state, action) => {
            state.openSendEmailModal = action.payload
        },
        setMailDetailsTo: (state, action) => {
            state.mailToSendDetails.to = action.payload
        },
        setMailDetailsCc: (state, action) => {
            state.mailToSendDetails.cc = action.payload
        },
        setMailDetailsCcn: (state, action) => {
            state.mailToSendDetails.ccn = action.payload
        },
        setMailDetailsName: (state, action) => {
            state.mailToSendDetails.name = action.payload
        },
        setMailDetailsBody: (state, action) => {
            state.mailToSendDetails.body = action.payload
        },
        /* setMailDetailsAttachments: (state, action) => {
            state.mailToSendDetails.attachments = action.payload
        }, */
        setMailDetailsObject: (state, action) => {
            state.mailToSendObject = action.payload
        },
        setMailDetailsContent: (state, action) => {
            state.mailToSendContent = action.payload
        },
        setFindAllCustomMailsStatus: (state, action) => {
            state.findAllMailsStatus = action.payload
        },
        setSaveMailStatus: (state, action) => {
            state.saveMailStatus = action.payload
        },
        setUpdateMailStatus: (state, action) => {
            state.updateMailStatus = action.payload
        },
        setRemoveMailStatus: (state, action) => {
            state.removeMailStatus = action.payload
        },
        setSendCustomMailStatus: (state, action) => {
            state.sendCustomMailStatus = action.payload
        },
        setFindEmailByIdStatus: (state, action) => {
            state.findEmailByIdStatus = action.payload
        },
        resetCreateCustomMailRequest: (state) => {
            state.customMailCreateRequest.subject = ''
            state.customMailCreateRequest.templateHTML = ''
            state.customMailCreateRequest.templateName = ''
            state.customMailCreateRequest.templateText = ''
        },
        resetMailDetails: (state) => {
            state.mailToSendContent = ''
            state.mailToSendObject = ''
            state.mailToSendDetails.to = ''
            state.mailToSendDetails.cc = ''
            state.mailToSendDetails.ccn = ''
            state.mailToSendDetails.body = {}
        },
        setEmailsSettingsTab: (state, action) => {
            state.emailSettingsTab = action.payload
        },
        setEmailsHistoryFilters: (state, action) => {
            state.emailsHistoryFilters = action.payload
        },
        setFindEmailsHistoryStatus: (state, action) => {
            state.findEmailsHistoryStatus = action.payload
        },
        setFindMailByIdResponse: (state, action) => {
            state.findMailByIdResponse = action.payload
        },
        setCustomMailCreateRequest: (state, action) => {
            state.customMailCreateRequest = action.payload
        },
        setCustomMailUpdateRequest: (state, action) => {
            state.customMailUpdateRequest = action.payload
        },
        setSelectedTemplateId: (state, action) => {
            state.selectedTemplateId = action.payload
        },
        setOpenTemplateId: (state, action) => {
            state.openTemplateId = action.payload
        },
        setTemplateToRemoveId: (state, action) => {
            state.templateToRemoveId = action.payload
        },
        setSendVerificationMailStatus: (state, action) => {
            state.sendVerificationMailStatus = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(findAllCustomMails.fulfilled, (state, action) => {
            state.findAllMailsResponse = action.payload
            state.findAllMailsStatus = 'successfully'
        })
            .addCase(findAllCustomMails.pending, (state) => {
                state.findAllMailsStatus = 'loading'
            })
            .addCase(findAllCustomMails.rejected, (state) => {
                state.findAllMailsStatus = 'failed'
            })
            .addCase(findMailByID.fulfilled, (state, action) => {
                state.findMailByIdResponse = action.payload
                state.findMailByIdStatus = 'successfully'
            })
            .addCase(findMailByID.pending, (state) => {
                state.findMailByIdStatus = 'loading'
            })
            .addCase(findMailByID.rejected, (state) => {
                state.findMailByIdStatus = 'failed'
            })
            .addCase(findEmailByID.fulfilled, (state, action) => {
                state.findEmailByIdResponse = action.payload
                state.findEmailByIdStatus = 'successfully'
            })
            .addCase(findEmailByID.pending, (state) => {
                state.findEmailByIdStatus = 'loading'
            })
            .addCase(findEmailByID.rejected, (state) => {
                state.findEmailByIdStatus = 'failed'
            })
            .addCase(findEmailsHistory.fulfilled, (state, action) => {
                state.findEmailsHistoryResponse = action.payload
                state.findEmailsHistoryStatus = 'successfully'
            })
            .addCase(findEmailsHistory.pending, (state) => {
                state.findEmailsHistoryStatus = 'loading'
            })
            .addCase(findEmailsHistory.rejected, (state) => {
                state.findEmailsHistoryStatus = 'failed'
            })
            .addCase(updateMail.fulfilled, (state, action) => {
                state.updateMailStatus = 'successfully'
            })
            .addCase(updateMail.pending, (state) => {
                state.updateMailStatus = 'loading'
            })
            .addCase(updateMail.rejected, (state) => {
                state.updateMailStatus = 'failed'
            })
            .addCase(saveMail.fulfilled, (state, action) => {
                state.saveMailResponse = action.payload
                state.saveMailStatus = 'successfully'
            })
            .addCase(saveMail.pending, (state) => {
                state.saveMailStatus = 'loading'
            })
            .addCase(saveMail.rejected, (state) => {
                state.saveMailStatus = 'failed'
            })
            .addCase(removeMail.fulfilled, (state, action) => {
                state.removeMailStatus = 'successfully'
            })
            .addCase(removeMail.pending, (state) => {
                state.removeMailStatus = 'loading'
            })
            .addCase(removeMail.rejected, (state) => {
                state.removeMailStatus = 'failed'
            })
            .addCase(sendCustomMail.fulfilled, (state, action) => {
                state.sendCustomMailStatus = 'successfully'
            })
            .addCase(sendCustomMail.pending, (state) => {
                state.sendCustomMailStatus = 'loading'
            })
            .addCase(sendCustomMail.rejected, (state) => {
                state.sendCustomMailStatus = 'failed'
            })
            .addCase(sendVerificationMail.fulfilled, (state) => {
                state.sendVerificationMailStatus = 'successfully'
            })
            .addCase(sendVerificationMail.pending, (state) => {
                state.sendVerificationMailStatus = 'loading'
            })
            .addCase(sendVerificationMail.rejected, (state, action) => {
                state.sendVerificationMailStatus = 'failed'
                const error = action.payload as AxiosError
                const errorList = error.response?.data!
                if (errorList === 'email already verified') {
                    state.sendVerificationMailMessage = "L'email è già stata verificata"
                } else {
                    state.sendVerificationMailMessage = "Errore durante l'invio dell'email"
                }
            })

    }
})

export const {
    setEmailSettingsModalOpen,
    setEmailsSettingsTab,
    setFindEmailsHistoryStatus,
    setFindMailByIdResponse,
    setSendEmailModalOpen,
    setMailDetailsBody,
    setMailDetailsCc,
    setMailDetailsCcn,
    setMailDetailsName,
    setMailDetailsTo,
    setMailDetailsContent,
    setMailDetailsObject,
    setFindAllCustomMailsStatus,
    setSaveMailStatus,
    setUpdateMailStatus,
    setRemoveMailStatus,
    setSendCustomMailStatus,
    resetCreateCustomMailRequest,
    resetMailDetails,
    setCustomMailCreateRequest,
    setCustomMailUpdateRequest,
    setOpenTemplateId,
    setSelectedTemplateId,
    setTemplateToRemoveId,
    setEmailsHistoryFilters,
    setSendVerificationMailStatus
} = mailSlice.actions

export default mailSlice.reducer