import { CreateEventDTO, EventResponseDTO, EventsFiltersDTO } from "./dto";
import { EventsServiceImpl } from "./serviceImpl";

export interface EventsService {
  findAllEvents(filters: EventsFiltersDTO): Promise<EventResponseDTO>
  createEvent(request: CreateEventDTO): Promise<void>
  updateEvent(request: string, id: string): Promise<void>
  rescheduleEvent(startDate: string, endDate: string, notification: number, id: string): Promise<void>
  deleteEvent(id: string): Promise<void>
}

export function NewEventsService(): EventsService {
  return new EventsServiceImpl();
}
