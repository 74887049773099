import { cdAxios } from "../../../../lib/axios";
import { keycloak } from "../../../../lib/keycloak";
import { OTPService } from "./service";

export class OTPServiceImpl implements OTPService {

  public otpRequest(phoneNumber: string): Promise<void> {
    return cdAxios
      .post("/api/notifications/verify/request", {
        phoneNumber: phoneNumber
      }, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public otpVerify(phoneNumber: string, code: string): Promise<void> {
    return cdAxios
      .post("/api/notifications/verify", {
        phoneNumber: phoneNumber,
        code: code
      }, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
