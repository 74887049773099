import Papa from "papaparse";
import { ReactElement, useEffect, useState } from "react";
import { ContactType } from "../../../../lib/filters/dto";
import { AssociateCompaniesForCSV } from "./associateCompanies";
import { AssociatePeopleForCSV } from "./associatePeople";
import { UploadFileForCSV } from "./uploadFile";
import { BannersList } from "../../../../ui/organisms/banner/bannerList";

interface Props {
  contactType: ContactType
}

export function ImportFromCSV(props: Props) {
  const { contactType } = props
  const [step, setStep] = useState<number>(0);

  const [error, setError] = useState(false);
  const [errorHeaders, setErrorHeaders] = useState(false);
  const [name, setName] = useState("");
  const [delimiter, setDelimiter] = useState("");
  const [fields, setFields] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [rows, setRows] = useState<Record<string, any>[]>([]);

  const handleFileChange = (e: File[]) => {
    const file = e[0];
    setError(false);
    if (file) {
      if (file.type !== "text/csv") {
        setError(true);
        setName("");
        setDelimiter("");
        setFields([]);
        setRows([]);
        setFile(null);
      } else {
        setName(file.name);
        Papa.parse(file, {
          complete: (result) => {
            if (result.meta.fields?.includes('')) {
              setErrorHeaders(true);
              return;
            }
            setDelimiter(result.meta.delimiter);
            setFields(result.meta.fields ?? []);
            //@ts-ignore
            setRows(result.data);
            setFile(file);
          },
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true,
        });
      }
    }
    // }
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (errorHeaders) {
      timeoutId = setTimeout(() => {
        setErrorHeaders(false)
      }, 5000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [errorHeaders]);

  const csvStepMap = new Map<number, ReactElement>([
    [
      0,
      <UploadFileForCSV
        step={step}
        setStep={(e) => setStep(e)}
        handleChange={(e) => handleFileChange(e)}
        error={error}
        file={file}
      />,
    ],
    [
      1,
      contactType === ContactType.COMPANY ?
        <AssociateCompaniesForCSV
          step={step}
          setStep={(e) => setStep(e)}
          name={name}
          delimiter={delimiter}
          fields={fields}
          file={file}
          rows={rows}
        /> :
        <AssociatePeopleForCSV
          step={step}
          setStep={(e) => setStep(e)}
          name={name}
          delimiter={delimiter}
          fields={fields}
          file={file}
          rows={rows}
        />,
    ],
  ]);
  return <>
    <BannersList banners={[{
      label: "Il file CSV contiene intestazioni vuote",
      visible: errorHeaders,
      closeAction: function (): void {
        setErrorHeaders(false);
      },
      type: "error"
    }]}
    />
    {csvStepMap.get(step)}</>;
}
