import clsx from "clsx";

interface Props {
    changePeriod: (period: 'month' | 'week') => void
    period: 'month' | 'week'
}
export function ChangePeriod(props: Props) {
    const { changePeriod, period } = props

    return (
        <div className="shadow-xs rounded-lg border border-neutral-200 flex gap-2 p-1 transition-all">
            <div
                className={clsx("text-label-sm font-semibold border border-transparent px-2 py-[6px] rounded-lg cursor-pointer text-neutral-600",
                    {
                        'text-neutral-800 bg-neutral-50 border-neutral-100': period === 'week'
                    }
                )}
                onClick={() => changePeriod('week')}
            >
                Settimana
            </div>
            <div
                className={clsx("text-label-sm font-semibold border border-transparent px-2 py-[6px] rounded-lg cursor-pointer text-neutral-600",
                    {
                        'text-neutral-800 bg-neutral-50 border-neutral-100': period === 'month'
                    }
                )}
                onClick={() => changePeriod('month')}
            >
                Mese
            </div>
        </div>
    )
}