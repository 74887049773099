import { FindAllPreferencesResponse } from "./dto";
import { CreatePreferenceRequest } from "./dto";
import { PreferencesServiceImpl } from "./serviceImpl";

export interface PreferencesService {
  findAllPreferences(owner: string): Promise<FindAllPreferencesResponse>;
  createPreferences(data: CreatePreferenceRequest): Promise<void>;
  updatePreferences(data: CreatePreferenceRequest, id: string): Promise<void>;
}

export function NewPreferencesService(): PreferencesService {
  return new PreferencesServiceImpl();
}
