import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
import { keycloak } from "../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { groupBy } from "../../../lib/utils";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import TextArea from "../../../ui/molecules/textArea";
import { BannersList } from "../../../ui/organisms/banner/bannerList";
import { ProjectStateEnum } from "../dto";
import { setEditCustomerInProject, setEditProjectCommercialIds, setEditProjectDescription, setEditProjectStatus, setNewProjectName, setNewProjectTeamLeaderId } from "../slice";

export function Form() {
    const atecosState = useAppSelector(state => state.atecos)
    const projectsState = useAppSelector(state => state.projects)
    const usersState = useAppSelector(state => state.users)

    const dispatch = useAppDispatch()
    const customer = usersState.findClientsResponse?.data.find(client => client.id === projectsState.editCustomerInProjectRequest.customerId)

    return (
        <div className="gap-[24px] h-full max-h-full flex flex-col w-[100%] p-[16px]">
            <BannersList banners={[
                {
                    type: "error",
                    visible: projectsState.editProjectStatus === "failed",
                    label: "Si è verificato un errore durante la modifica del progetto",
                    closeAction: () => dispatch(setEditProjectStatus("idle")),
                }
            ]} />
            <div className="p-[24px] h-full overflow-auto gap-[24px] flex flex-col bg-white shadow-sm rounded-[12px]">
                <div className="flex gap-6 items-flex-start">
                    <Input
                        label="Nome del progetto"
                        disabled
                        value={projectsState.findProjectResponse?.name}
                        onChangeText={e => dispatch(setNewProjectName(e))}
                        error={projectsState.validateNewProject.errors.name}
                        supportingText={projectsState.validateNewProject.errors.name ? "Inserire il nome del progetto." : ''}
                    />
                    {
                        keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') ?
                            <Input
                                key={'commercials'}
                                label="Commerciali"
                                disabled
                                value={projectsState.findProjectResponse?.commercials.map(commercial => (commercial.name + ' ' + commercial.surname)).join(',')}
                                placeholder={"Commerciali"}
                            /> :
                            <SelectCustom
                                isMulti
                                defaultValue={projectsState.editProjectRequest.commercialIds ?? projectsState.findProjectResponse?.commercials.map(commercial => commercial.id)}
                                placeholder={"Seleziona i commerciali"}
                                onChange={(e) => dispatch(setEditProjectCommercialIds(e))}
                                options={usersState.findCommercialsResponse?.data.map(commercial => ({ label: commercial.name + ' ' + commercial.surname, value: commercial.id }))!}
                                label="Commerciali"
                                disabled={!usersState.findMeResponse?.isAutonomous && keycloak.hasRealmRole("commercial")}
                            />
                    }
                    {
                        keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') ?
                            <Input
                                key={'teamleaders'}
                                label="Team Leader"
                                disabled
                                value={projectsState.findProjectResponse?.teamLeader.name + ' ' + projectsState.findProjectResponse?.teamLeader.surname}
                                placeholder={"Team Leader"}
                            /> :
                            <SelectCustom
                                disabled
                                placeholder={"Seleziona il team leader"}
                                options={usersState.findTeamLeadersResponse?.data.map(teamLeader => ({ label: teamLeader.name + ' ' + teamLeader.surname, value: teamLeader.id }))!}
                                defaultValue={projectsState.findProjectResponse?.teamLeader.id}
                                onChange={e => dispatch(setNewProjectTeamLeaderId(e))}
                                label="Team Leader"
                                error={projectsState.validateNewProject.errors.teamLeaderId}
                                errorLabel={projectsState.validateNewProject.errors.teamLeaderId ? "Selezionare il team leader." : ''}
                            />
                    }
                </div>
                <div className="h-[1px] w-full bg-neutral-200" />
                <div className="flex gap-6 items-start">
                    {
                        keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') ?
                            <Input
                                key={'teamleaders'}
                                label="Ragione Sociale"
                                disabled
                                value={projectsState.findProjectResponse?.customer.companyName}
                                placeholder={"Cliente"}
                            /> :
                            <SelectCustom
                                key={projectsState.findProjectResponse?.customer ? projectsState.findProjectResponse?.customer.id : 'no-customer'}
                                disabled={projectsState.findProjectResponse?.customer !== null}
                                placeholder={"Seleziona il cliente"}
                                defaultValue={
                                    projectsState.findProjectResponse?.customer === null ? '' : projectsState.findProjectResponse?.customer.id ?? customer?.id
                                }
                                onChange={(e) => dispatch(setEditCustomerInProject(e))}
                                options={usersState.findClientsResponse?.data.map(client => ({
                                    label: client.companyName ?? '',
                                    value: client.id ?? ''
                                }))!}
                                label="Ragione Sociale"
                            />
                    }
                    <Input
                        label="P. IVA"
                        disabled
                        value={projectsState.findProjectResponse?.customer === null && customer === undefined ? '' : customer?.companyVat ?? projectsState.findProjectResponse?.customer.companyVat}
                        placeholder="P. IVA"
                    />
                    <Input
                        label="Referente"
                        placeholder="Referente"
                        disabled
                        value={projectsState.findProjectResponse?.customer !== null && projectsState.findProjectResponse ? (projectsState.findProjectResponse.customer.name + ' ' + projectsState.findProjectResponse.customer.surname) : customer ? (customer?.name + ' ' + customer?.surname) : ''}
                    />
                </div>
                <div className="flex gap-6 items-start">
                    <SelectCustom
                        key={projectsState.findProjectResponse?.customer ? projectsState.findProjectResponse?.customer.companyAtecoCode : 'no-customer-ateco'}
                        placeholder={"Cod. Ateco"}
                        disabled
                        defaultValue={projectsState.findProjectResponse?.customer === null && customer === undefined ? '' : customer?.companyAtecoCode ?? projectsState.findProjectResponse?.customer.companyAtecoCode}
                        options={atecosState.findAllAtecosResponse.map(ateco => ({ value: ateco.atecoCode, label: ateco.atecoCode + ' - ' + ateco.atecoDescription }))}
                        label="Cod. Ateco"
                    />
                    <SelectCustom
                        key={projectsState.findProjectResponse?.customer ? projectsState.findProjectResponse?.customer.companySector : 'no-customer-sector'}
                        placeholder={"Settore"}
                        disabled
                        defaultValue={projectsState.findProjectResponse?.customer === null && customer === undefined ? '' : customer?.companySector ?? projectsState.findProjectResponse?.customer.companySector}
                        options={groupBy(atecosState.findAllAtecosResponse, 'sector').map(sector => ({ label: sector.key, value: sector.key }))}
                        label="Settore"
                    />
                    <NumericFormat
                        prefix="€ "
                        value={projectsState.findProjectResponse?.customer === null && customer === undefined ? '' : customer?.companyRevenue ?? projectsState.findProjectResponse?.customer.companyRevenue}
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        customInput={Input}
                        id={"new-user-request-company-revenue"}
                        key={"new-user-request-company-revenue"}
                        placeholder={"Fatturato"}
                        label="Fatturato"
                        disabled
                    />
                </div>
                <div className="flex gap-6 items-start">
                    <SelectCustom placeholder={""} defaultValue={ProjectStateEnum.TOSTART} options={[{ label: "Da iniziare", value: ProjectStateEnum.TOSTART }]} disabled label="Stato" />
                    <Input disabled label="Data inizio" value={projectsState.findProjectResponse?.startDate ? format(new Date(projectsState.findProjectResponse?.startDate), 'dd/MM/yyyy') : ''} />
                    <Input disabled label="Data fine" placeholder="Data fine" value={projectsState.findProjectResponse?.endDate ? format(new Date(projectsState.findProjectResponse?.endDate), 'dd/MM/yyyy') : ''} />
                </div>
                <TextArea
                    label="Descrizione"
                    defaultValue={projectsState.editProjectRequest.description ?? projectsState.findProjectResponse?.description}
                    onChangeText={e => dispatch(setEditProjectDescription(e))}
                    disabled={!usersState.findMeResponse?.isAutonomous && keycloak.hasRealmRole("commercial")}
                />
            </div>
        </div>
    )
}