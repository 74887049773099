import { decode, encode } from "base64-arraybuffer";
import { ContactType, FindAllContactsFilters } from "./filters/dto";

export async function toBase64(file: File): Promise<string> {
  const bytes = new Uint8Array(await file.arrayBuffer());

  let binary = "";

  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export const breadcrumbsLabel = new Map<string, string>([
  ["dashboard", "Dashboard"],
  ["database", "Database"],
  ["target-list", "Liste Target"],
  ["projects", "Progetti"],
  ["users", "Utenti"],
  ["pipeline", "Pipeline"],
  ["calendar", "Calendario"],
  ["report", "Report"],
  ["settings", "Impostazioni"],
]);

type Grouped<T> = { [key: string]: T[] };

export function groupBy<T>(
  array: T[],
  key: keyof T
): { key: string; values: T[] }[] {
  const groupedObject = array.reduce((result, currentValue) => {
    const groupKey = currentValue[key] as unknown as string;
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(currentValue);
    return result;
  }, {} as Grouped<T>);

  return groupedObjectToArray(groupedObject);
}

function groupedObjectToArray<T>(
  groupedObject: Grouped<T>
): { key: string; values: T[] }[] {
  return Object.keys(groupedObject).map((key) => ({
    key,
    values: groupedObject[key],
  }));
}

export const generatePagination = (currentPage: number, totalPages: number) => {
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
  if (currentPage <= 3) {
    return [1, 2, 3, "...", totalPages - 1, totalPages];
  }
  if (currentPage >= totalPages - 2) {
    return [1, 2, "...", totalPages - 2, totalPages - 1, totalPages];
  }
  return [
    1,
    "...",
    currentPage - 1,
    currentPage,
    currentPage + 1,
    "...",
    totalPages,
  ];
};

export const encodeBase64 = (file: ArrayBuffer) => {
  return "data:" + file + ";base64," + encode(file);
};

export function formatFileSize(bytes: number): string {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
}

export function estimateUploadTime(
  fileSizeBytes: number,
  uploadSpeedBps: number
) {
  const fileSizeBits = fileSizeBytes * 8;
  const timeInSeconds = fileSizeBits / uploadSpeedBps;
  return formatTime(timeInSeconds);
}

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes > 0) {
    return `${minutes} min ${remainingSeconds} sec`;
  }
  return `${remainingSeconds} sec`;
};

export function downloadCSVfromBase64(base64: string, fileName: string) {
  const blob = new Blob([decode(base64)], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.download = fileName + ".csv";
  a.click();
  window.URL.revokeObjectURL(url);
}

export enum Area { 'DATABASE', 'TARGET' }
export type DatabaseSection = 'database' | 'add-target-db' | 'black-list' | 'verify-list';
export type TargetSection = 'target' | 'add-target-tl' | 'target-bin'

export function contactsFiltersAdapter(filters: FindAllContactsFilters, contactType: ContactType) {
  return (
    {
      contactType: contactType,
      itemsPerPage: filters.itemsPerPage,
      page: filters.page,
      sort: filters.sort,
      order: filters.order,
      targetListBin: filters.targetListBin,
      blackListBin: filters.blackListBin,
      toVerifyBin: filters.toVerifyBin,
      privacy: filters.privacy?.value,
      companyRevenue: filters.companyRevenue?.value,
      companyEmployees: filters.companyEmployees?.value,
      personName: filters.personName,
      personEmail: filters.personEmail,
      personHasCompany: filters.personHasCompany,
      active: filters.active,
      personHasSmartphone: filters.personHasSmartphone?.value,
      companyPhone: filters.companyPhone?.value,
      personPhone: filters.personPhone?.value,
      creationDate: filters.creationDate?.value,
      lastContactDate: filters.lastContactDate?.value,
      outcomeInfo: filters.outcomeInfo.map((value) => value.value),
      outcome: filters.outcome.map((outcome) => outcome.value),
      companyAtecoCode: filters.companyAtecoCode?.map((ateco) => ateco.value),
      companyCategory: filters.companyCategory.map((category) => category.value),
      particularity: filters.particularity.map((particolarity) => particolarity.value),
      personDepartment: filters.personDepartment.map((department) => department.value),
      companyName: filters.companyName.map((name) => name.label),
      personRole: filters.personRole.map((role) => role.value),
      companySearch: filters.companySearch,
      companySector: filters.companySector.map((sector) => sector.value),
      companyCountry: filters.companyCountry?.value,
      companyCity: filters.companyCity?.value,
      companyRegion: filters.companyRegion.map(
        (countryRegion) => countryRegion.value
      ),
      companyZipCode: filters.companyZipCode?.value,
      targetList: filters.targetList.map((target) => target.value),
      project: filters.project.map((project) => project.value),
      companyProvince: filters.companyProvince.map(province => province.value),
      missingFields: filters.missingFields.map(missingFiled => missingFiled.value)
    }
  )
}