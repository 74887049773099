import clsx from "clsx";
import { useAppSelector } from "../redux/hooks";
import { ContactStateEnum } from "../../pages/targetList/dto";

export default function useCreateBorder(isInTargetList: boolean): string {
    const contactsState = useAppSelector(state => state.contacts)
    const outcome = contactsState.getContactResponse?.outcome

    return (
        clsx("h-full", {
            "border-t-green-500 border-t-4":
                isInTargetList &&
                outcome === ContactStateEnum.LEADTAKEN,
            "border-t-orange-500 border-t-4":
                isInTargetList &&
                outcome === ContactStateEnum.TORECALL,
            "border-t-red-500 border-t-4":
                isInTargetList &&
                outcome === ContactStateEnum.KO,
            "border-t-neutral-500 border-t-4":
                isInTargetList &&
                outcome === ContactStateEnum.INCORRECT,
            "border-t-brandPrimary-500 border-t-4":
                isInTargetList &&
                outcome === ContactStateEnum.DRAFT,
        })
    )
}
