import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { SelectCustomAsync } from "../../../../ui/molecules/asyncSelect";
import { NewParticularitiesService } from "../../../particularities/service";
import { setNewTargetListParticularity } from "../../../targetList/slice";

interface OptionType {
  value: string;
  label: string;
}

const SelectParticularities: React.FC = () => {
  const filterParticularities = async (inputValue: string) => {
    return await NewParticularitiesService().findAllParticularities({
      itemsPerPage: 25,
      page: 0,
      name: inputValue,
    }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string; }[]>((resolve) => {
      resolve(filterParticularities(inputValue));
    });

  const dispatch = useAppDispatch()

  const particularitiesState = useAppSelector(state => state.particularities)

  const [defaultValue, setDefaultValue] = useState<{ label: string, value: string } | undefined>()

  useEffect(() => {
    if (particularitiesState.createParticularityStatus === 'successfully') {
      promiseOptions('')
        .then(particularities => {
          const newParticularity = particularities.find(value => value.value === particularitiesState.createParticularityResponse)
          setDefaultValue(newParticularity)
          dispatch(setNewTargetListParticularity(particularitiesState.createParticularityResponse))
        })
    }
  }, [particularitiesState.createParticularityStatus])

  return (
    <div>
      <SelectCustomAsync
        label="Particolarità"
        loadOptions={promiseOptions}
        defaultOptions
        noOptionsMessage="Non sono ancora presenti particolarità"
        defaultValue={defaultValue}
        isClearable
        onChange={(e) => {
          dispatch(
            // @ts-ignore
            setNewTargetListParticularity(e ? e.value : undefined)
          );
          //@ts-ignore
          setDefaultValue(e)
        }}
        placeholder="Indifferente"
        error={false}
        errorLabel="Errore durante la selezione"
      />
    </div>
  );
};

export default SelectParticularities;
