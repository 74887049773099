import clsx from "clsx";
import { setFiltersActive } from "../../../pages/database/slice";
import { colors } from "../../../ui/colors";
import { FiltersLinesIcon } from "../../../ui/icons/filtersLines";
import { MoreHorizontalIcon } from "../../../ui/icons/moreHorizontal";
import { SearchIcon } from "../../../ui/icons/search";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { useDebounce } from "../../hooks/debounce";
import { setOptionsActive } from "../../preferences/slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { FindAllContactsFilters } from "../dto";

const optionsMissingFields = [
  { value: "vat", label: "P. IVA" },
  { value: "phones", label: "Telefono" },
  { value: "emails", label: "Email" },
  { value: "website", label: "Sito web" },
  { value: "atecos", label: "Codice Ateco" },
  { value: "atecos.atecoCode", label: "Codice Ateco" },
  { value: "atecos.category", label: "Categoria" },
  { value: "atecos.sector", label: "Settore" },
  { value: "revenue", label: "Fatturato" },
  { value: "employeesNumber", label: "Dipendenti" },
  { value: "zipCode", label: "Cap" },
  { value: "countryRegion", label: "Regione" },
  { value: "state", label: "Stato" },
  { value: "city", label: "Città" }
];

interface Props {
  updateFilters: (newFilters: Partial<FindAllContactsFilters>) => void;
  filters: FindAllContactsFilters;
  isInTargetList?: boolean
}

export function CompaniesHorizontalFilters(props: Props) {
  const { updateFilters, filters, isInTargetList } = props;
  const companiesState = useAppSelector((state) => state.companies);
  const databaseState = useAppSelector((state) => state.database);
  const preferencesState = useAppSelector((state) => state.preferences);
  const dispatch = useAppDispatch();
  const debounceCompanyFilter = useDebounce(
    (value) => updateFilters({ companySearch: value }),
    1000
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "8px",
        width: '100%',
        height: "64px",
        padding: "0 16px",
        borderBottom: "1px solid " + colors.neutral[200],
      }}
    >
      <Input
        key={'find person-horizontal-filter-companies'}
        startIcon={<SearchIcon color={""} size={0} />}
        placeholder="Cerca azienda"
        onChangeText={(value) => debounceCompanyFilter(value)}
        defaultValue={filters.companySearch}
      />
      {
        !isInTargetList &&
        <SelectCustom
          isSearchable={false}
          isClearable
          isMulti
          placeholder="Campi vuoti"
          options={optionsMissingFields}
          onChange={(value) =>
            //@ts-ignore
            updateFilters({ missingFields: value.map(option => ({ value: option, label: option })) })
          }
          defaultValue={filters.missingFields.map((label) => label.value)}
        />
      }
      {
        !window.location.pathname.includes('black-list') &&
        <div
          onClick={() => {
            if (filters.active === "false") {
              updateFilters({ active: undefined });
            } else {
              updateFilters({ active: "false" });
            }
          }}
          className={clsx("cursor-pointer flex items-center h-8 px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-200", {
            'border-[2px] border-brandPrimary-800': filters.active === "false"
          })}
        >
          <span className="text-brandPrimary-800 text-label-sm font-medium whitespace-nowrap">
            Inattive {companiesState.companiesCountResponse.toActivate}
          </span>
        </div>
      }
      <div>
        <Button
          size={"sm"}
          iconPosition={"off"}
          label="Filtri / Espandi"
          variant={databaseState.filtersActive ? "outline" : "ghost"}
          color={databaseState.filtersActive ? "blue" : "gray"}
          onClick={() =>
            dispatch(setFiltersActive(!databaseState.filtersActive))
          }
          icon={<FiltersLinesIcon color={""} size={0} />}
        />
      </div>
      <div>
        <Button
          size={"sm"}
          iconPosition={"only"}
          variant={preferencesState.optionsActive ? "outline" : "ghost"}
          color={preferencesState.optionsActive ? "blue" : "gray"}
          onClick={() =>
            dispatch(setOptionsActive(!preferencesState.optionsActive))
          }
          icon={<MoreHorizontalIcon color={""} size={0} />}
        />
      </div>
    </div >
  );
}
