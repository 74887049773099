import { format, isValid } from "date-fns";
import { useEffect, useState } from "react";
import { CalendarIcon } from "../../../ui/icons/calendar";
import Input from "../../../ui/molecules/input";
import { SelectCustom } from "../../../ui/molecules/select";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { ContactType, FindAllContactsFilters } from "../dto";
import { Calendar } from "../../../ui/molecules/calendar";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function DateOfContactFilter(props: Props) {
    const { updateFilters, filters } = props

    const [sign, setSign] = useState<'=' | '<' | '>' | '><' | undefined>(undefined);
    const [firstInput, setFirstInput] = useState<string | undefined>(undefined);
    const [secondInput, setSecondInput] = useState<string | undefined>(undefined);

    const signOptions = [
        { label: "Uguale a", value: "=" },
        { label: "Maggiore di", value: ">" },
        { label: "Minore di", value: "<" },
        { label: "Compreso tra", value: "><" },
    ];

    useEffect(() => {
        let value = sign === undefined ? undefined : sign + (firstInput ? ':' + firstInput : '') + (sign === '><' && secondInput ? ':' + secondInput : '')
        let label = signOptions.find(opt => opt.value === sign)?.label + (firstInput ? ' ' + firstInput : '') + (sign === '><' && secondInput ? ' e ' + secondInput : '')
        if (value === undefined && filters.companyRevenue === undefined) {
            return
        }
        if (value === undefined) {
            updateFilters({ lastContactDate: undefined })
        } else {
            updateFilters({ lastContactDate: { label: label, value: value } })
        }
    }, [sign, firstInput, secondInput])

    const handleClear = () => {
        setFirstInput(undefined);
        setSecondInput(undefined);
        setSign(undefined)
        updateFilters({ lastContactDate: undefined })
    };

    useEffect(() => {
        if (filters.lastContactDate === undefined) {
            setFirstInput(undefined);
            setSecondInput(undefined);
            setSign(undefined)
        }
    }, [filters.lastContactDate])

    return (
        <AccordionFilter
            key={'person-filter-date-of-contact'}
            label={"Data di contatto"}
            icon={<CalendarIcon color={""} size={0} />}
            showClear={filters.lastContactDate !== undefined}
            options={filters.lastContactDate !== undefined ? [filters.lastContactDate] : []}
            clearAction={handleClear}
            deleteFilter={handleClear}
        >
            <div className="flex flex-col gap-4">
                <SelectCustom
                    isSearchable={false}
                    onChange={(e) => {
                        if (e !== undefined && typeof e === 'string') {
                            setSign(e as '<' | '>' | '><' | undefined);
                        }
                    }}
                    defaultValue={sign}
                    placeholder={"Uguale, minore, maggiore, compreso"}
                    options={signOptions}
                />
                {
                    sign !== undefined && (
                        <div className="flex flex-col gap-2">
                            <Calendar
                                type="date"
                                startDate={firstInput ? new Date(firstInput) : null}
                                setStartDate={function (date: Date | null): void {
                                    setFirstInput(format(new Date(date ?? new Date()), 'yyyy-MM-dd'));
                                }}
                            />
                            {
                                sign === '><' && (
                                    <Calendar
                                        type="date"
                                        startDate={secondInput ? new Date(secondInput) : null}
                                        setStartDate={function (date: Date | null): void {
                                            setSecondInput(format(new Date(date ?? new Date()), 'yyyy-MM-dd'));
                                        }}
                                    />
                                )}
                        </div>
                    )}
            </div>
        </AccordionFilter >
    );
}
