import clsx from "clsx";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Button from "../../ui/molecules/button";
import { Spinner } from "../../ui/molecules/spinner";

import { keycloak } from "../../lib/keycloak";
import {
  findAllProjectSuspended,
  setFindAllTargetListsSuspendedStatus,
} from "../../lib/atecos/slice";
import Pills from "../../ui/molecules/pills";
import { TargetListStatusEnum } from "./dto";
import { changeStatus, setTargetListChangeStateStatus } from "./slice";

interface Props {
  show: boolean;
  setShow: (show: boolean) => void;
}
export function TargetListChangeStatusModal() {
  const targetListState = useAppSelector((state) => state.targetList);
  const atecosState = useAppSelector((state) => state.atecos);

  const [request, setRequest] = useState<
    {
      id: string;
      state:
        | undefined
        | TargetListStatusEnum.CONCLUDED
        | TargetListStatusEnum.INPROGRESS;
    }[]
  >(
    atecosState.findAllTargetListSuspendedResponse?.data.map((target) => ({
      id: target.id,
      state: TargetListStatusEnum.INPROGRESS,
    }))!
  );
  const dispatch = useAppDispatch();

  return (
    <div
      onClick={() => dispatch(setFindAllTargetListsSuspendedStatus("idle"))}
      className={clsx(
        "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
        {
          block:
            (keycloak.hasRealmRole("admin") ||
              keycloak.hasRealmRole("teamLeader")) &&
            atecosState.findAllTargetListSuspendedStatus ===
              "successfully" &&
            atecosState.findAllTargetListSuspendedResponse?.total !== 0,
          hidden: !(
            (keycloak.hasRealmRole("admin") ||
              keycloak.hasRealmRole("teamLeader")) &&
            atecosState.findAllTargetListSuspendedStatus ===
              "successfully" &&
            atecosState.findAllTargetListSuspendedResponse?.total !== 0
          ),
        }
      )}
    >
      {targetListState.changeStateStatus === "loading" ||
      atecosState.findAllTargetListSuspendedStatus === "loading" ? (
        <Spinner />
      ) : (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className="transition-all flex flex-col items-center gap-8 p-[20px] bg-white rounded-[12px] opacity-100 shadow-sm min-w-[400px]"
        >
          <div className="flex flex-col items-center gap-2">
            <span className="text-text-lg font-semibold text-neutral-800">
              {"Liste target sospese"}
            </span>
            <span className="text-text-md text-center font-normal text-neutral-600">
              Abbiamo notato che ci sono liste target inattive da 5 giorni.
              <br /> Seleziona "Concluso" per terminarne le attività.
            </span>
          </div>
          <div className="flex flex-col w-full h-full gap-3">
            {atecosState.findAllTargetListSuspendedResponse?.data.map(
              (target, index) => (
                <div
                  key={target.id}
                  className={clsx("flex items-center justify-between", {
                    "border-b border-b-neutral-300 pb-3 border-dashed":
                      index !==
                      atecosState.findAllTargetListSuspendedResponse
                        ?.total! -
                        1,
                  })}
                >
                  <span className="text-text-md font-semibold text-neutral-600">
                    {target.name}
                  </span>
                  <div className="flex items-center gap-2">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const newRequest = [...request];
                        const index = newRequest.findIndex(
                          (value) => value.id === target.id
                        );
                        newRequest[index] = {
                          ...newRequest[index],
                          state: TargetListStatusEnum.INPROGRESS,
                        };
                        setRequest([...newRequest]);
                      }}
                    >
                      <Pills
                        size={"sm"}
                        label={"In corso"}
                        outline={false}
                        emphasis={
                          request.find((_target) => target.id === _target.id)
                            ?.state === TargetListStatusEnum.INPROGRESS
                        }
                        color={"blue"}
                      />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        const newRequest = [...request];
                        const index = newRequest.findIndex(
                          (value) => value.id === target.id
                        );
                        newRequest[index] = {
                          ...newRequest[index],
                          state: TargetListStatusEnum.CONCLUDED,
                        };
                        setRequest([...newRequest]);
                      }}
                    >
                      <Pills
                        size={"sm"}
                        label={"Concluso"}
                        outline={false}
                        emphasis={
                          request.find((_target) => target.id === _target.id)
                            ?.state === TargetListStatusEnum.CONCLUDED
                        }
                        color={"green"}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <div className="w-full flex flex-1 gap-3 mt-3">
            <div className="w-1/2">
              <Button
                fullWidth
                size={"sm"}
                iconPosition={"off"}
                variant={"outline"}
                color={"gray"}
                label="Annulla"
                onClick={() =>
                  dispatch(setFindAllTargetListsSuspendedStatus("idle"))
                }
              />
            </div>
            <div className="w-1/2">
              <Button
                fullWidth
                size={"sm"}
                iconPosition={"off"}
                variant={"solid"}
                color={"blue"}
                label="Conferma"
                onClick={() => dispatch(changeStatus(request))}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
