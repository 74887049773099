import clsx from "clsx";
import { keycloak } from "../../../lib/keycloak";
import { targetListColumns } from "../../../lib/preferences/dto";
import { updatePreferences } from "../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { CopyIcon } from "../../../ui/icons/copy";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { UsersIcon } from "../../../ui/icons/users";
import { XCircleIcon } from "../../../ui/icons/xCircle";
import Avatar from "../../../ui/molecules/avatar";
import Pills from "../../../ui/molecules/pills";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { CustomTable } from "../../../ui/organisms/table";
import { targertListsStatePillMap } from "../dto";
import { setEditTargetListTabValue, setSelectedTargetBins, setSelectedTargetList, setTargetListsFilterOrder, setTargetListsFilterSort, setTargetListToCopy } from "../slice";

export interface Props {
    setIdToDelete: (id: string | undefined) => void
}
export function TargetListTable(props: Props) {
    const { setIdToDelete } = props
    const targetListState = useAppSelector(state => state.targetList)
    const usersState = useAppSelector(state => state.users)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()

    return (
        <CustomTable
            tableHeight='calc(100% - 48px)'
            prevOrderHeads={[]}
            postOrderHeads={[
                <th className={clsx("text-left", {
                    hidden: (keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && !usersState.findMeResponse?.canAccessDatabase,
                })}>
                    <div className="flex gap-[4px] w-[20px]" />
                </th>
            ]}
            preferences={preferencesState.findAllResponse?.data[0].targetListPreferences?.map(pref => ({ ...pref, order: targetListColumns.find(col => col.column === pref.column)?.order })) ?? []}
            setPreferences={(items) => {
                dispatch(
                    updatePreferences({
                        data: {
                            owner: preferencesState.findAllResponse?.data[0].owner!,
                            personPreferences: preferencesState.findAllResponse?.data[0]
                                .personPreferences!,
                            companyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .companyPreferences!,
                            targetListPreferences: items,
                            targetListCompanyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListCompanyPreferences!,
                            targetListPersonPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPersonPreferences!
                        },
                        id: preferencesState.findAllResponse?.data[0].id!,
                    })
                );
            }}
            setOrderFilters={(order) => {
                dispatch(setTargetListsFilterOrder(!targetListState.filters.order))
                dispatch(setTargetListsFilterSort(order))
            }}
            rows={targetListState.findAllTargetListsResponse?.data.map(d => ({
                key: d?.id, data: [
                    {
                        item: <td
                            onClick={() => {
                                if (!(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) || (((keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && usersState.findMeResponse?.canAccessDatabase))) {
                                    dispatch(setSelectedTargetList(d.id))
                                    dispatch(setEditTargetListTabValue(0))

                                }
                            }
                            }
                            className={clsx("text-text-sm crop-text font-semibold text-neutral-600", {
                                'cursor-pointer': !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) || (((keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && usersState.findMeResponse?.canAccessDatabase))
                            })}>
                            <div
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListPreferences?.find(col => col.column === 'Nome')?.width ?? 200 }}
                                className={clsx("text-text-sm crop-text font-semibold text-neutral-600", {
                                    'cursor-pointer': !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) || (((keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && usersState.findMeResponse?.canAccessDatabase))
                                })}
                            >
                                {d.name}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Nome'
                    },
                    {
                        item: <td>
                            <div
                                className="text-text-sm crop-text text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListPreferences?.find(col => col.column === 'Progetto')?.width ?? 200 }}
                            >
                                {d.project.name ?? ' - '}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Progetto'
                    },
                    {
                        item: <td className="text-text-sm text-neutral-600 flex items-center gap-2 h-14">
                            {
                                d.project.teamLeader === null ?
                                    <div>-</div> :
                                    <div className="text-text-sm text-neutral-600 flex items-center gap-2 h-14">
                                        <Avatar
                                            type="single"
                                            size="sm"
                                            shape="circle"
                                            imageUrl={
                                                usersState.findUsersAvatarsListResponse.find(
                                                    (avatar) => avatar.userId === d?.project?.teamLeader?.id
                                                )?.objectId ?? ""
                                            }
                                            fullName={d.project.teamLeader?.name + " " +
                                                d.project.teamLeader?.surname}
                                            altTextInitials={
                                                d.project.teamLeader?.name[0] +
                                                d.project.teamLeader?.surname[0]
                                            }
                                        />
                                        <span>
                                            {(d.project.teamLeader?.name ?? "-") +
                                                " " +
                                                (d.project.teamLeader?.surname ?? "-")}
                                        </span>
                                    </div>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Team Leader'
                    },
                    {
                        item: <td className="text-text-sm text-neutral-600">
                            <Avatar
                                type="group"
                                size="sm"
                                length={2}
                                imageUrls={d.commercials.map((commercial) => ({
                                    altTextInitials: commercial.name[0] + commercial.surname[0],
                                    fullNames: commercial.name + commercial.surname,
                                    url:
                                        usersState.findUsersAvatarsListResponse.find(
                                            (avatar) => avatar.userId === commercial.id
                                        )?.objectId ?? "",
                                }))}
                            />
                        </td>,
                        group: 'sort',
                        key: 'Commerciali'
                    },
                    {
                        item: <td>
                            <div
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListPreferences?.find(col => col.column === 'Settore')?.width ?? 100 }} className="text-text-sm text-neutral-600"
                            >
                                {
                                    d.atecos &&
                                        d.atecos.map(ateco => ateco.sector) &&
                                        d.atecos.map(ateco => ateco.sector).length > 0 ?
                                        <Pills
                                            size={"xs"}
                                            label={
                                                d.atecos.map(atecos => atecos.sector)[0] +
                                                (d.atecos.map(atecos => atecos.sector).length > 1
                                                    ? " +" + (d.atecos.length - 1)
                                                    : "")
                                            }
                                            outline={false}
                                            emphasis={false}
                                            color={"blue"}
                                        /> :
                                        "-"
                                }
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Settore'
                    },
                    {
                        item: <td className="text-text-sm text-neutral-600">
                            <div className="flex items-center justify-center">
                                <Pills
                                    size={"xs"}
                                    label={d.contacts?.toString() ?? "0"}
                                    outline={false}
                                    emphasis={false}
                                    color={"blue"}
                                />
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Contatti'
                    },
                    {
                        item: <td className="text-text-sm text-neutral-600">
                            <div className="flex items-center justify-center">
                                <Pills
                                    size={"xs"}
                                    label={d.leads?.toString() ?? "0"}
                                    outline={false}
                                    emphasis={false}
                                    color={"blue"}
                                />
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Lead'
                    },
                    {
                        item: <td className="text-text-sm text-neutral-600">
                            <div className="flex items-center justify-center">
                                <Pills
                                    size={"xs"}
                                    label={d.drafts?.toString() ?? "0"}
                                    outline={false}
                                    emphasis={false}
                                    color={"blue"}
                                />
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Da lavorare'
                    },
                    {
                        item: <td className="text-text-sm text-neutral-600">
                            <Pills
                                size={"xs"}
                                label={targertListsStatePillMap.get(d.state)?.label!}
                                outline={false}
                                emphasis={false}
                                color={targertListsStatePillMap.get(d.state)?.color!}
                            />
                        </td>,
                        group: 'sort',
                        key: 'Stato'
                    },
                    {
                        item: <td className={clsx("w-[20px] cursor-pointer", { hidden: (keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && !usersState.findMeResponse?.canAccessDatabase })}>
                            <Dropdown
                                items={[
                                    {
                                        label: "Modifica",
                                        icon: <EditIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setSelectedTargetList(d.id))
                                            dispatch(setEditTargetListTabValue(0))
                                        }
                                    },
                                    {
                                        label: "Mostra contatti",
                                        icon: <UsersIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setSelectedTargetList(d.id))
                                            dispatch(setEditTargetListTabValue(1))
                                        }
                                    },
                                    (usersState.findMeResponse?.isAutonomous || usersState.findMeResponse?.isAutonomous === null) ?
                                        {
                                            label: "Visualizza cestini",
                                            icon: <TrashIcon color={""} size={0} />,
                                            onClick: () => {
                                                dispatch(setSelectedTargetBins(d.id))
                                            }
                                        } : null,
                                    ((keycloak.hasRealmRole('customer') || ((keycloak.hasRealmRole('commercial') || keycloak.hasRealmRole('collaborator')) && !usersState.findMeResponse?.isAutonomous)) ? null : {
                                        label: "Copia in un altro progetto",
                                        icon: <CopyIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setTargetListToCopy(d.id))
                                        }
                                    }),
                                    (keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') ? null : {
                                        label: "Cancella",
                                        icon: <XCircleIcon color={""} size={0} />,
                                        onClick: () => setIdToDelete(d.id),
                                    }),
                                ]}
                            />
                        </td>,
                        group: 'postSort',
                        key: 'menu'
                    }
                ]
            })) ?? []}
        />
    )
}