import { ReactElement, useEffect, useState } from "react";
import { ContactStateEnum, outcomeLabelMap, stateInfoMap } from "../../../pages/targetList/dto";
import { CheckCircleIcon } from "../../../ui/icons/checkCircle";
import { SelectCustom } from "../../../ui/molecules/select";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function OutcomeFilter(props: Props) {
    const { updateFilters, filters } = props

    const [outComes, setOutComes] = useState<ContactStateEnum[]>([])
    const [info, setInfo] = useState<string[]>([])
    const [visibleOptions, setVisibleOptions] = useState<string[]>([])

    function generateOutcomeForFilters() {
        const outcomes = Object.values(ContactStateEnum);
        const outcomeLabelMap = new Map<ContactStateEnum, string>([
            [ContactStateEnum.DRAFT, "In attesa"],
            [ContactStateEnum.LEADTAKEN, "Lead preso"],
            [ContactStateEnum.TORECALL, "Da richiamare"],
            [ContactStateEnum.KO, "Ko"],
            [ContactStateEnum.INCORRECT, "Info errate"],
        ]);

        let filterOption: { label: ReactElement; value: string }[] = []
        outcomes.forEach(out => {
            filterOption.push({ label: <div className="text-text-sm font-semibold text-neutral-800">{outcomeLabelMap.get(out)!}</div>, value: out.toString() })
            const microOutcomes = stateInfoMap(out);
            microOutcomes.forEach((micro) => {
                let microLabel = micro;
                filterOption.push({ label: <div className="text-text-sm text-neutral-600 pl-4">{microLabel}</div>, value: micro })
            });

        })
        return filterOption
    }

    useEffect(() => {
        if (info.length === 0 && filters.outcomeInfo.length === 0) {
            return
        } else
            updateFilters({ outcomeInfo: info.map(i => ({ label: i, value: i })) })
    }, [info])

    useEffect(() => {
        if (outComes.length === 0 && filters.outcome.length === 0) {
            return
        } else
            updateFilters({ outcome: outComes.map(i => ({ label: i, value: i })) })
    }, [outComes])

    return (
        <AccordionFilter
            key={"person-filter-outcome-info"}
            label={"Esiti"}
            icon={<CheckCircleIcon color={""} size={0} />}
            showClear={visibleOptions.length > 0}
            clearAction={() => { updateFilters({ outcome: [], outcomeInfo: [] }); setVisibleOptions([]) }}
            deleteFilter={(option) => {
                updateFilters({ outcome: filters.outcome.filter(name => name.value !== option.value), outcomeInfo: filters.outcomeInfo.filter(name => name.value !== option.value) })

            }}
            options={[...filters.outcomeInfo, ...filters.outcome.map(out => ({ value: out.value, label: outcomeLabelMap.get(out.value as ContactStateEnum)! })) ?? []]}
        >
            <SelectCustom
                placeholder={"Seleziona un esito"}
                hideSelectedOptions={false}
                options={generateOutcomeForFilters()}
                isMulti
                defaultValue={visibleOptions}
                onChange={e => {
                    if (e?.length === 0) {
                        setOutComes([])
                        setInfo([])
                        setVisibleOptions([])
                        return
                    }
                    if (e !== undefined && typeof e !== 'string') {
                        if (visibleOptions.length < e.length) {
                            const index = e.length - 1
                            const lastAdded = e[index]
                            if (lastAdded === ContactStateEnum.DRAFT || lastAdded === ContactStateEnum.LEADTAKEN) {
                                const outcomesCopy = [...outComes]
                                outcomesCopy.push(lastAdded)
                                setOutComes([...outcomesCopy])

                                const visibleOptionsCopy = [...visibleOptions]
                                visibleOptionsCopy.push(lastAdded)
                                setVisibleOptions([...visibleOptionsCopy])
                            } else if (lastAdded === ContactStateEnum.INCORRECT || lastAdded === ContactStateEnum.KO || lastAdded === ContactStateEnum.TORECALL) {
                                const infos = [...stateInfoMap(lastAdded)]
                                const infosCopy = [...info]
                                setInfo([...infosCopy, ...infos.filter(val => !infosCopy.includes(val))])

                                const visibleOptionsCopy = [...visibleOptions]
                                setVisibleOptions([...visibleOptionsCopy, ...infos.filter(val => !infosCopy.includes(val))])
                            } else {
                                const infosCopy = [...info]
                                infosCopy.push(lastAdded)
                                setInfo([...infosCopy])

                                const visibleOptionsCopy = [...visibleOptions]
                                visibleOptionsCopy.push(lastAdded)
                                setVisibleOptions([...visibleOptionsCopy])
                            }
                        }
                        else {
                            const removed = visibleOptions.find(el => !e.includes(el))
                            if (removed === ContactStateEnum.DRAFT || removed === ContactStateEnum.LEADTAKEN) {
                                const outcomesCopy = [...outComes]
                                setOutComes([...outcomesCopy.filter(el => el !== removed)])

                                const visibleOptionsCopy = [...visibleOptions]
                                setVisibleOptions([...visibleOptionsCopy.filter(el => el !== removed)])
                            } else {
                                const infosCopy = [...info]
                                setInfo([...infosCopy.filter(el => el !== removed)])

                                const visibleOptionsCopy = [...visibleOptions]
                                setVisibleOptions([...visibleOptionsCopy.filter(el => el !== removed)])
                            }
                        }
                    }
                }}
            />
        </AccordionFilter>
    )
}