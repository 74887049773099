import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { setFilters } from '../../../pages/calendar/slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

export const useMonthCalendar = () => {
    const dispatch = useAppDispatch();
    const filters = useAppSelector(state => state.events.filters['month']);

    const changeInterval = (action: 'next' | 'prev' | 'today') => {
        let month = new Date(filters.fromDateTime!);
        let fromDateTime = startOfMonth(month).toISOString()
        let toDateTime = endOfMonth(month).toISOString()
        if (action === 'next') {
            month = addMonths(month, 1);
            fromDateTime = startOfMonth(month).toISOString()
            toDateTime = endOfMonth(month).toISOString()

        } else if (action === 'prev') {
            month = subMonths(month, 1);
            fromDateTime = startOfMonth(month).toISOString()
            toDateTime = endOfMonth(month).toISOString()
        } else {
            month = new Date();
            fromDateTime = startOfMonth(month).toISOString()
            toDateTime = endOfMonth(month).toISOString()
        }
        dispatch(setFilters({ period: 'month', filters: { fromDateTime, toDateTime } }));
    }

    return {
        filters,
        changeInterval
    };
};
