import { differenceInCalendarDays } from "date-fns";
import { EventDTO } from "../../../calendar/dto";


export function recallSetter(recall?: EventDTO) {
    if (!recall) return null
    let differenceInDay = differenceInCalendarDays(recall.startDate, recall.createdAt)
    let notificationInterval = '0'
    let notificationValue = recall.notification
    if (recall.notification % 1440 === 0) {
        notificationInterval = '2'
        notificationValue = recall.notification / 1440
    }
    else if (recall.notification % 60 === 0) {
        notificationInterval = '1'
        notificationValue = recall.notification / 60
    }
    else {
        notificationInterval = '0'
        notificationValue = recall.notification
    }

    let notificationType = '0'
    if (recall.notification === 0)
        notificationType = '0'
    else if (recall.notification === 15)
        notificationType = '1'
    else if (recall.notification === 60)
        notificationType = '2'
    else if (recall.notification === 1440)
        notificationType = '3'
    else notificationType = '4'

    let selectionRange = '0'
    if (differenceInDay === 1)
        selectionRange = '1'
    else if (differenceInDay === 3) {
        selectionRange = '2'
    }
    else if (differenceInDay === 7) {
        selectionRange = '3'
    } else selectionRange = '0'

    return ({
        selectionRange: selectionRange,
        notificationType: notificationType,
        notificationInterval: notificationType === '4' ? notificationInterval : '0',
        notificationValue,
        date: new Date(recall.startDate),
        time: new Date(recall.startDate),
        note: recall.notes,
        id: recall.id
    })
};
