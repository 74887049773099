import { formatDate } from "date-fns";
import { numericFormatter } from "react-number-format";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto";
import { keycloak } from "../../../../../lib/keycloak";
import { updatePreferences } from "../../../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../../../lib/types";
import { colors } from "../../../../../ui/colors";
import { EditIcon } from "../../../../../ui/icons/edit";
import { GlobeIcon } from "../../../../../ui/icons/globe";
import { TrashIcon } from "../../../../../ui/icons/trash";
import { UserPlusIcon } from "../../../../../ui/icons/userPlus";
import Button from "../../../../../ui/molecules/button";
import Checkbox from "../../../../../ui/molecules/checkbox";
import Pills from "../../../../../ui/molecules/pills";
import { Dropdown } from "../../../../../ui/organisms/dropdown";
import { CustomTable } from "../../../../../ui/organisms/table";
import { findEmployees, setSelectedCompany, setSelectedCompanyNameForEmployees, setTabValueDetailsCompany } from "../../../../database/companies/slice";
import { buildAddress } from "../../../../database/companies/list/table";
import { targetListCompanyColumns } from "../../../../../lib/preferences/dto";
import { restoreContacts, setSelectedContact } from "../../../contacts/slice";
import { contactsStatePillMap, NoteDTO } from "../../../dto";

interface Props {
    contacts: FindContactsTargetResponse<ContactType.COMPANY>
    status: PromiseStatuses
    selectedIds: string[]
    setSelectedIds: (arg: string[]) => void
    setShowPopup: (arg: boolean) => void
    filters: FindAllContactsFilters
    setFilters: (arg: Partial<FindAllContactsFilters>) => void
}

export function TargetCompaniesTable(props: Props) {
    const { selectedIds, setSelectedIds, setShowPopup, filters, setFilters, contacts } = props
    const preferencesState = useAppSelector(state => state.preferences)
    const dispatch = useAppDispatch()

    function lastNote(notes: NoteDTO[] | null): string {
        if (!notes || notes.length <= 0) {
            return '-'
        } if (notes && notes.length > 0) {
            return notes[notes?.length - 1].note
        }
        else return '-'
    }

    return (
        <CustomTable
            tableHeight='calc(100% - 48px)'
            prevOrderHeads={[
                <th className="text-left w-10">
                    <Checkbox
                        shape={"square"}
                        checked={
                            selectedIds.length ===
                            contacts.data.length
                        }
                        onCheck={function (value: boolean): void {
                            if (
                                selectedIds.length ===
                                contacts.data.length
                            ) {
                                setSelectedIds([]);
                            } else {
                                setSelectedIds(
                                    contacts.data?.map(
                                        (company) => company.id
                                    )!
                                );
                            }
                        }}
                    />
                </th>
            ]}
            postOrderHeads={[
                <th className="text-left">
                    <div className="flex gap-[4px]">
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px] w-[20px]" />
                </th>
            ]}
            preferences={preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.map(pref => ({ ...pref, order: targetListCompanyColumns.find(col => col.column === pref.column)?.order })) ?? []}
            setPreferences={(items) => {
                dispatch(
                    updatePreferences({
                        data: {
                            owner: preferencesState.findAllResponse?.data[0].owner!,
                            personPreferences: preferencesState.findAllResponse?.data[0]
                                .personPreferences!,
                            companyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .companyPreferences!,
                            targetListPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPreferences!,
                            targetListCompanyPreferences: items,
                            targetListPersonPreferences: preferencesState.findAllResponse?.data[0]
                                .targetListPersonPreferences!
                        },
                        id: preferencesState.findAllResponse?.data[0].id!,
                    })
                );
            }}
            setOrderFilters={(order) => {
                setFilters({ order: !filters.order, sort: order })
            }}
            rows={contacts.data.map(d => ({
                key: d.id, data: [
                    {
                        item: <td className="text-text-sm font-semibold text-neutral-600">
                            <Checkbox
                                shape={"square"}
                                checked={selectedIds.includes(d.id)}
                                onCheck={function (value: boolean): void {
                                    if (selectedIds.includes(d.id)) {
                                        setSelectedIds([
                                            ...selectedIds.filter((id) => id !== d.id),
                                        ]);
                                    } else {
                                        setSelectedIds([...selectedIds, d.id]);
                                    }
                                }}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },

                    {
                        item: <td
                            onClick={() => {
                                dispatch(setTabValueDetailsCompany(0))
                                dispatch(setSelectedCompany(d.contact.id))
                                dispatch(setSelectedContact(d.id))
                                dispatch(findEmployees(d.contact.employeesId));
                                dispatch(
                                    setSelectedCompanyNameForEmployees(d.contact.name)
                                );
                            }}
                        >
                            <div
                                className="cursor-pointer text-text-sm crop-text font-semibold text-brandPrimary-600"
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Nome azienda')?.width ?? 150 }}
                            >
                                {d.contact.name}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Nome azienda'
                    },
                    {
                        item: <td>
                            <div
                                className="text-text-sm crop-text text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Data di contatto')?.width ?? 150 }}
                            >
                                {d.lastContactDate ? formatDate(new Date(d.lastContactDate!), 'dd/MM/yyyy hh:mm') : "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: "Data di contatto"
                    },
                    {
                        item: <td>
                            {d.contact.phones ? (
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={"tel:" + d.contact.phones[0]?.phone}
                                >
                                    <div className="text-text-sm font-semibold text-neutral-600 crop-text" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Telefono')?.width ?? 100 }}>
                                        {d.contact.phones ? (d.contact.phones[0]?.phone + (d.contact.phones.length > 1 ? (', ..., + ' + (d.contact.phones.length - 1)) : '')) : '-'}
                                    </div>
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>,
                        group: 'sort',
                        key: 'Telefono'
                    },
                    {
                        item: <td>
                            <div
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Esito')?.width ?? 150 }}>
                                {
                                    d.outcome ?
                                        <Pills
                                            size={"sm"}
                                            label={contactsStatePillMap.get(d.outcome)?.label! + (d.outcomeInfo ? (" | " + d.outcomeInfo) : '') + ((d.outcomeInfo === 'Richiamo' || d.outcomeInfo === 'Decisore assente') && d.callAttempts && d.callAttempts > 0 ? (" (" + d.callAttempts + ")") : '')} outline={false}
                                            emphasis={false}
                                            color={contactsStatePillMap.get(d.outcome)?.color ?? 'blue'}
                                        />
                                        : '-'
                                }
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Esito'
                    },
                    {
                        item: <td>
                            <div className="text-text-sm crop-text text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Note')?.width ?? 100 }}>
                                {lastNote(d.notes)}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Note'
                    },
                    {
                        item: <td>
                            {d.contact.emails ? (
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={"mailto:" + d.contact.emails}
                                >
                                    <div className="text-text-sm font-semibold text-neutral-600 crop-text" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Email')?.width ?? 200 }}>
                                        {d.contact.emails && d.contact.emails[0] ? (d.contact.emails[0] + (d.contact.emails.length > 1 ? (', ..., + ' + (d.contact.emails.length - 1)) : '')) : '-'}
                                    </div>
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>,
                        group: 'sort',
                        key: 'Email'
                    },
                    {
                        item: <td>
                            <div
                                className="px-[4px] crop-text text-text-md font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Dipendenti')?.width ?? 100 }}
                            >
                                {d.contact.employeesNumber !== null ? d.contact.employeesNumber : "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Dipendenti'
                    },
                    {
                        item: <td>
                            <div
                                className="px-[4px] crop-text text-text-md font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'P. IVA')?.width ?? 100 }}
                            >
                                {d.contact.vat ?? "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'P. IVA'
                    },
                    {
                        item: <td>
                            <div
                                className="crop-text text-text-sm font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Sede azienda')?.width ?? 200 }}
                            >
                                {buildAddress(d.contact.address, d.contact.city, d.contact.countryRegion, d.contact.state, d.contact.zipCode)}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Sede azienda'
                    },
                    {
                        item: <td>
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={d.contact.website ?? undefined}
                                className="flex items-center justify-center"
                            >
                                <GlobeIcon
                                    color={
                                        d.contact.website
                                            ? colors.neutral[400]
                                            : colors.neutral[200]
                                    }
                                    size={20}
                                />
                            </a>
                        </td>,
                        group: 'sort',
                        key: 'Sito web'
                    },
                    {
                        item: <td>
                            <div
                                className="px-[4px] crop-text text-text-md font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Cod. ATECO')?.width ?? 100 }}
                            >
                                {d.contact.atecos ? d.contact.atecos[0]?.atecoCode : "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Cod. ATECO'
                    },
                    {
                        item: <td>
                            <div
                                className="text-text-sm crop-text text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Fatturato')?.width ?? 100 }}
                            >
                                {d.contact.revenue !== null ? numericFormatter(d.contact.revenue.toString(), { thousandSeparator: '.', decimalSeparator: ',', fixedDecimalScale: true, decimalScale: 2, prefix: '€ ' }) : '-'}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Fatturato'
                    },
                    {
                        item: <td>
                            <div
                                className="px-[4px] crop-text text-text-md font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Settore')?.width ?? 150 }}
                            >
                                {d.contact.atecos ? d.contact.atecos[0]?.sector : "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Settore'
                    },
                    {
                        item: <td>
                            <div
                                className="px-[4px] crop-text text-text-md font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'CAP')?.width ?? 100 }}
                            >
                                {d.contact.zipCode ?? "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'CAP'
                    },
                    {
                        item: <td>
                            <div className="px-[4px] crop-text text-text-md font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListCompanyPreferences?.find(col => col.column === 'Categoria')?.width ?? 150 }}>
                                {d.contact.atecos ? d.contact.atecos[0]?.category : "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Categoria'
                    },
                    {
                        item: <td className="">
                            <Button
                                size={"sm"}
                                iconPosition={"off"}
                                disabled={!d.contact.employeesId}
                                variant={"outline"}
                                color={"blue"}
                                label="Mostra dipendenti"
                                onClick={() => {
                                    dispatch(setSelectedCompany(d.contact.id))
                                    dispatch(setSelectedContact(d.id))
                                    dispatch(findEmployees(d.contact.employeesId));
                                    dispatch(
                                        setSelectedCompanyNameForEmployees(d.contact.name)
                                    );
                                    dispatch(setTabValueDetailsCompany(1))
                                }}
                            />
                        </td>,
                        group: 'postSort',
                        key: 'employees'
                    },
                    {
                        item: <td className="w-[20px] cursor-pointer">
                            <Dropdown
                                items={[
                                    {
                                        label: "Modifica",
                                        icon: <EditIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setTabValueDetailsCompany(0))
                                            dispatch(setSelectedCompany(d.contact.id))
                                            dispatch(setSelectedContact(d.id))
                                            dispatch(findEmployees(d.contact.employeesId));
                                            dispatch(
                                                setSelectedCompanyNameForEmployees(d.contact.name)
                                            );
                                        },
                                    },
                                    (!(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                                        d.deletedAt !== null ?
                                        {
                                            label: "Ripristina",
                                            icon: <UserPlusIcon color={""} size={0} />,
                                            onClick: () => {
                                                dispatch(restoreContacts([d.id]))
                                            },
                                        } : null),
                                    ((keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) ? null : {
                                        label: "Cancella",
                                        icon: <TrashIcon color={""} size={0} />,
                                        onClick: () => {
                                            setSelectedIds([d.id]);
                                            setShowPopup(true);
                                        },
                                    }),
                                ]}
                            />
                        </td>,
                        group: 'postSort',
                        key: 'menu'
                    }
                ]
            })) ?? []}
        />
    )
}