import clsx from 'clsx';
import { eachDayOfInterval, eachWeekOfInterval, endOfMonth, endOfWeek, format, isSameMonth, startOfMonth, startOfWeek } from 'date-fns';
import { EventDTO, EventsFiltersDTO } from '../dto';
import './../style.css';
import { MonthEvent } from '../events/monthEvent';

interface Props {
    filters: EventsFiltersDTO;
    events: EventDTO[]
    setModalVisible: (value: boolean) => void;
    setEventDetails: (events: EventDTO[]) => void;
    setEvent: (event: EventDTO) => void;
}

export function MonthCalendar(props: Props) {
    const { filters, events, setModalVisible, setEventDetails, setEvent } = props;
    const getWeeksOfMonth = () => {
        return eachWeekOfInterval(
            {
                start: startOfWeek(startOfMonth(filters.fromDateTime!), { weekStartsOn: 1 }),
                end: endOfWeek(endOfMonth(filters.toDateTime!), { weekStartsOn: 1 })
            },
            { weekStartsOn: 1 }
        );
    };

    const getDaysOfWeek = (weekStart: Date) => {
        return eachDayOfInterval({
            start: weekStart,
            end: endOfWeek(weekStart, { weekStartsOn: 1 }),
        });
    };

    return (
        <div className="w-full flex flex-col">
            <table className="calendar-table">
                <thead>
                    <tr className='border-collapse'>
                        <th className='border-r border-neutral-200'>Lun</th>
                        <th className='border-x border-neutral-200'>Mar</th>
                        <th className='border-x border-neutral-200'>Mer</th>
                        <th className='border-x border-neutral-200'>Gio</th>
                        <th className='border-x border-neutral-200'>Ven</th>
                        <th className='border-x border-neutral-200'>Sab</th>
                        <th className='border-l border-neutral-200'>Dom</th>
                    </tr>
                </thead>
                <tbody>
                    {getWeeksOfMonth().map((weekStart, index) => (
                        <tr
                            key={index}
                            className={clsx('border-collapse h-[138px]', {
                                'border border-x-0': index !== 0 && index !== getWeeksOfMonth().length - 1,
                                'border-0': index === 0 || index === getWeeksOfMonth().length - 1,
                            })}
                        >
                            {getDaysOfWeek(weekStart).map((day, _index) => (
                                <td
                                    key={day.toString()}
                                    className={clsx(
                                        "p-2 ps-2 align-top border text-label-sm font-semibold h-full overflow-hidden", {
                                        'border-l-0': _index === 0,
                                        'border-r-0': _index === getDaysOfWeek(weekStart).length - 1,
                                        'bg-neutral-50 text-neutral-400': !isSameMonth(day, filters.fromDateTime!),
                                        'text-neutral-800': isSameMonth(day, filters.fromDateTime!),
                                    })}
                                >
                                    <div className='flex flex-col items-start justify-start gap-[6px] h-full max-h-full'>
                                        <div className='h-6 flex items-center'>
                                            {format(day, 'd')}
                                        </div>
                                        {
                                            events
                                                .filter(event => format(event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')).length > 0 && (
                                                events.filter(event => format(event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')).length <= 3 ?
                                                    events
                                                        .filter(event => format(event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd'))
                                                        .map((event, i) => {
                                                            return (
                                                                <MonthEvent setEvent={event => setEvent(event)} event={event} key={i} />
                                                            )
                                                        })
                                                    :
                                                    <>
                                                        {
                                                            events
                                                                .filter(event => format(event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd'))
                                                                .slice(0, 2)
                                                                .map((event, i) => {
                                                                    return (
                                                                        <MonthEvent setEvent={event => setEvent(event)} event={event} key={i} />
                                                                    )
                                                                })
                                                        }
                                                        <div
                                                            onClick={() => {
                                                                setModalVisible(true)
                                                                setEventDetails(events.filter(event => format(event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')) ?? [])
                                                            }}
                                                            className='cursor-pointer font-semibold text-neutral-600 text-text-xs'>
                                                            {events.filter(event => format(event.startDate, 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')).length - 2} more...
                                                        </div>
                                                    </>)
                                        }
                                    </div>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}
