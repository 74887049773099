import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { signOptions } from "../../../pages/database/dto";
import { UsersIcon } from "../../../ui/icons/users";
import { SelectCustom } from "../../../ui/molecules/select";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function EmployeesNumberFilter(props: Props) {
    const { updateFilters, filters } = props

    const [sign, setSign] = useState<'=' | '<' | '>' | '><' | undefined>(undefined);
    const [firstInput, setFirstInput] = useState<number | undefined>(undefined);
    const [secondInput, setSecondInput] = useState<number | undefined>(undefined);

    useEffect(() => {
        let value = sign === undefined ? undefined : sign + (firstInput?.toString() ? ':' + firstInput.toString() : '') + (sign === '><' && secondInput?.toString() ? ':' + secondInput.toString() : '')
        let label = signOptions.find(opt => opt.value === sign)?.label + (firstInput ? ' ' + firstInput.toLocaleString('it-IT', { useGrouping: true }) : '') + (sign === '><' && secondInput ? ' e ' + secondInput.toLocaleString('it-IT', { useGrouping: true }) : '')
        if (value === undefined && filters.companyEmployees === undefined) {
            return
        }
        if (value === undefined) {
            updateFilters({ companyEmployees: undefined })
        } else {
            updateFilters({ companyEmployees: { label: label, value: value } })
        }
    }, [sign, firstInput, secondInput])

    const handleClear = () => {
        setFirstInput(undefined);
        setSecondInput(undefined);
        setSign(undefined)
        updateFilters({ companyEmployees: undefined })
    };

    return (
        <AccordionFilter
            key={'person-filter-employees-number'}
            label={"Dipendenti"}
            icon={<UsersIcon color={""} size={0} />}
            showClear={filters.companyEmployees !== undefined}
            options={filters.companyEmployees !== undefined ? [filters.companyEmployees] : []}
            clearAction={handleClear}
            deleteFilter={handleClear}
        >
            <div className="flex flex-col gap-4">
                <SelectCustom
                    isSearchable={false}
                    onChange={(e) => {
                        if (e !== undefined && typeof e === 'string') {
                            setSign(e as '=' | '<' | '>' | '><' | undefined)
                        }
                    }}
                    defaultValue={sign}
                    placeholder={"Uguale, minore, maggiore, compreso"}
                    options={signOptions}
                />
                {sign !== undefined && (
                    <div className="flex gap-2">
                        <NumericFormat
                            key={"people-filter-employees-input-1"}
                            defaultValue={firstInput?.toLocaleString('it-IT', { useGrouping: true })}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                            autoComplete="off"
                            onValueChange={(e) => {
                                setFirstInput(e.floatValue);
                            }}
                            className="h-[44px] ring-0 outline-none focus:border-neutral-300 focus:bg-white bg-white border-[1px] border-neutral-300 rounded-lg w-full placeholder:text-label-sm placeholder:text-neutral-400 text-label-sm text-neutral-600 font-medium"
                            id={"people-filter-employees-input-1"}
                            placeholder={signOptions.find(rev => rev.value === sign)?.label}
                        />
                        {
                            sign === '><' &&
                            <NumericFormat
                                key={"people-filter-employees-input-2"}
                                autoComplete="off"
                                defaultValue={secondInput?.toLocaleString('it-IT', { useGrouping: true })}
                                thousandSeparator="."
                                decimalSeparator=","
                                onValueChange={(e) => {
                                    setSecondInput(e.floatValue);
                                }}
                                className="h-[44px] ring-0 outline-none focus:border-neutral-300 active:bg-white autofill:bg-white focus:bg-white bg-white border-[1px] border-neutral-300 rounded-lg w-full placeholder:text-label-sm placeholder:text-neutral-400 text-label-sm text-neutral-600 font-medium"
                                id={"people-filter-employees-input-2"}
                                placeholder={"Compreso tra"}
                            />
                        }
                    </div>
                )}
            </div>
        </AccordionFilter>
    );
}
