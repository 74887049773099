import clsx from "clsx";
import { useState } from "react";
import { Filters } from "../../../../../lib/filters";
import { CompaniesHorizontalFilters } from "../../../../../lib/filters/accordion/horizontalFiltersCompany";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../../lib/filters/dto";
import { useContacts } from "../../../../../lib/hooks/contacts/contacts";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Area } from "../../../../../lib/utils";
import { FiltersLinesIcon } from "../../../../../ui/icons/filtersLines";
import { PlusCircleIcon } from "../../../../../ui/icons/plusCircle";
import { UploadIcon } from "../../../../../ui/icons/upload";
import Button from "../../../../../ui/molecules/button";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../../../ui/organisms/confirmModal.tsx";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { setFiltersActive } from "../../../../database/slice";
import { addContacts, addContactsBulk } from "../../../contacts/slice";
import { ImportFromDatabaseList } from "./list";

export function ImportFromDatabase() {
  const targetListState = useAppSelector((state) => state.targetList);
  const databaseState = useAppSelector((state) => state.database);
  const contactsState = useAppSelector((state) => state.contacts);

  const filters = useContacts(Area.DATABASE, 'add-target-db', ContactType.COMPANY)

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  return (
    <div className="flex h-full max-h-full border-t">
      <div
        className={clsx("transition-all duration-300 w-[20%] flex-shrink-0", {
          block: databaseState.filtersActive,
          hidden: !databaseState.filtersActive,
        })}
      >
        <Filters
          contactType={ContactType.COMPANY}
          isInTargetList={false}
          resetFilters={function (): void {
            filters.resetAllFilters()
          }}
          filters={filters.filters}
          updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
            filters.updateFilters(newFilters)
          }}
        />
      </div>
      <div
        className={clsx('h-full max-h-full', {
          "w-full": !databaseState.filtersActive,
          "w-4/5": databaseState.filtersActive,
        })}
      >
        {filters.status === "loading" ? (
          <div className="w-full h-[45%] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="h-full max-h-full">
            <div className="bg-white rounded-t-2xl h-16 flex items-center justify-between px-4">
              <span className="text-heading-xs font-semibold text-neutral-800">
                {"Database > Aziende"}
              </span>
              <div className="flex items-center gap-2">
                {selectedIds.length > 0 && (
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    label={"Importa " + selectedIds.length + " selezionati"}
                    color={"blue"}
                    icon={<UploadIcon color={""} size={0} />}
                    onClick={() => {
                      dispatch(
                        addContacts({
                          targetListId:
                            targetListState.findTargetListResponse?.id!,
                          type: ContactType.COMPANY,
                          contactsId: selectedIds,
                        })
                      );
                    }}
                  />
                )}
                <Button
                  size={"sm"}
                  iconPosition={"left"}
                  label="Importa tutti"
                  variant={"outline"}
                  color={"blue"}
                  icon={<PlusCircleIcon color={""} size={0} />}
                  onClick={() => {
                    setConfirmModal(true)
                  }}
                />
                <Button
                  size={"sm"}
                  iconPosition={"off"}
                  label="Filtri / Espandi"
                  variant={databaseState.filtersActive ? "outline" : "ghost"}
                  color={databaseState.filtersActive ? "blue" : "gray"}
                  icon={<FiltersLinesIcon color={""} size={0} />}
                  onClick={() => {
                    dispatch(setFiltersActive(!databaseState.filtersActive));
                  }}
                />
              </div>
            </div>
            <CompaniesHorizontalFilters updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
              filters.updateFilters(newFilters)
            }} filters={filters.filters} isInTargetList={true} />
            {filters.contacts.data.length === 0 ||
              filters.contacts.data === null ? (
              <div style={{ height: 'calc(100% - 64px - 64px)' }}>
                <NotFound />
              </div>
            ) : (
              <ImportFromDatabaseList
                filters={filters.filters}
                selectedIds={selectedIds}
                setSelectedIds={(values) => setSelectedIds(values)}
                contacts={filters.contacts as unknown as FindContactsDatabaseResponse<ContactType.COMPANY>}
                setFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                  filters.updateFilters(newFilters)
                }} changePage={function (page: number): void {
                  filters.changePage(page)
                }} />
            )}
          </div>
        )}
      </div>
      <ConfirmModal
        loading={contactsState.addContactsBulkStatus === 'loading'}
        title={"Importare tutti i contatti?"}
        label={"Tutte le aziende corrispondenti ai filtri selezionati verranno aggiunte. Sei sicuro di voler procedere?"}
        confirmAction={function (): void {
          dispatch(addContactsBulk({ targetListId: targetListState.findTargetListResponse?.id!, filters: filters.filters, contactType: ContactType.COMPANY }))
        }}
        onClose={function (): void {
          setConfirmModal(false)
        }}
        visible={confirmModal}
      />
    </div>
  );
}
