import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useEffect, useRef, useState } from "react";
import { colors } from "../../colors";
import { MoveIcon } from "../../icons/move";
import { SortIcon } from "../../icons/sort";
import { DatabasePreferences } from "../../../lib/preferences/dto";

interface Props {
    id: UniqueIdentifier
    order?: string
    width?: number | null
    preferences: DatabasePreferences[]
    setOrder: (order: string) => void
    setPreferences: (arg: DatabasePreferences[]) => void
}

export function SortableItem(props: Props) {
    const { order, id, width, preferences, setPreferences, setOrder } = props
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform ? { ...transform, scaleX: 1 } : null),
        transition,
        width: width ?? 200
    };

    const resizeTimeout = useRef<NodeJS.Timeout | null>(null);
    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (divRef.current) {
            setNodeRef(divRef.current);
        }
    }, [setNodeRef]);

    const [widthResize, setWidthResize] = useState<number>(width ?? 200);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        const divElement = divRef.current;
        if (!divElement) return;

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const newWidth = entry.contentRect.width;

                if (resizeTimeout.current) {
                    clearTimeout(resizeTimeout.current);
                }

                resizeTimeout.current = setTimeout(() => {
                    setWidthResize(newWidth);
                }, 300);
            }
        });

        resizeObserver.observe(divElement);

        return () => {
            resizeObserver.disconnect();
            if (resizeTimeout.current) {
                clearTimeout(resizeTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        if (divRef.current?.clientWidth !== undefined) {
            if (isFirstLoad) {
                setIsFirstLoad(false)
            } else {
                const preferencesCopy = [...preferences]
                const index = preferencesCopy.findIndex(pref => pref.column === id)
                preferencesCopy[index] = { ...preferencesCopy[index], width: divRef.current?.clientWidth }
                setPreferences([...preferencesCopy])
            }
        }
    }, [divRef.current?.clientWidth])

    return (
        <div
            key={id + '-row'}
            ref={(node) => {
                divRef.current = node;
                setNodeRef(node);
            }}
            style={style}
            className="flex gap-[4px] justify-start items-center resize-x overflow-auto crop-text pr-4 min-w-[170px]"
        >
            <div className="cursor-move" {...attributes} {...listeners}>
                <MoveIcon color={colors.neutral[600]} size={10} />
            </div>
            <div className="flex gap-1">
                {id}
                {
                    order &&
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            order && setOrder(order)
                        }}
                        className="cursor-pointer hover: opacity-70"
                    >
                        <SortIcon />
                    </div>
                }
            </div>
        </div>
    );
}
