import { keycloak } from "../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { CheckCircleIcon } from "../../ui/icons/checkCircle";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { Dropdown } from "../../ui/organisms/dropdown";
import { CustomTable } from "../../ui/organisms/table";
import { ParticularityStatusEnum, ParticularityStatusMap } from "./dto";
import { setFindAllParticularitiesOrder, setFindAllParticularitiesSort, setSelectedParticularity } from "./slice";

interface Props {
    setIdToDelete: (arg?: string) => void
}

export function ParticularityTable(props: Props) {
    const { setIdToDelete } = props
    const particularitiesState = useAppSelector(state => state.particularities)
    const dispatch = useAppDispatch()

    return (
        <CustomTable
            tableHeight='calc(100% - 48px)'
            prevOrderHeads={[
                <th className="text-left pl-4">
                    <div className="flex gap-[4px] px-[4px] py-3">
                        Nome
                        <div
                            onClick={() => {
                                dispatch(setFindAllParticularitiesSort("name"));
                                dispatch(
                                    setFindAllParticularitiesOrder(
                                        !particularitiesState.filters.order
                                    )
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px] px-[4px] py-3">
                        Stato
                        <div
                            onClick={() => {
                                dispatch(setFindAllParticularitiesSort("status"));
                                dispatch(
                                    setFindAllParticularitiesOrder(
                                        !particularitiesState.filters.order
                                    )
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left pr-4 pl-[4px]">
                    <div className="flex gap-[4px] px-[4px] w-[20px]" />
                </th>
            ]}
            postOrderHeads={[]}
            preferences={[]}
            setPreferences={(items) => { }}
            setOrderFilters={(order) => { }}
            rows={particularitiesState.findAllResponse?.data.map(d => ({
                onClick: () => {
                    dispatch(
                        setSelectedParticularity({
                            id: d.id,
                            name: d.name,
                        })
                    );
                },
                key: d.id,
                data: [
                    {
                        item: <td className="pr-[4px] pl-4 text-text-sm font-semibold text-neutral-600">
                            {d.name}
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },
                    {
                        item: <td className="px-[4px]">
                            <Pills
                                size={"xs"}
                                label={
                                    ParticularityStatusMap.get(d.status)?.label!
                                }
                                outline={true}
                                emphasis={false}
                                color={
                                    ParticularityStatusMap.get(d.status)?.color!
                                }
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Nome referente'
                    },
                    {
                        item: <td
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                            className="w-[20px] cursor-pointer">
                            {(keycloak.hasRealmRole("admin") ||
                                d.creatorUserId === keycloak.subject) && (
                                    <Dropdown
                                        items={[
                                            keycloak.hasRealmRole("admin") ||
                                                d.status !== ParticularityStatusEnum.ACTIVE
                                                ? {
                                                    label: "Modifica",
                                                    icon: <EditIcon color={""} size={0} />,
                                                    onClick: () => {
                                                        dispatch(
                                                            setSelectedParticularity({
                                                                id: d.id,
                                                                name: d.name,
                                                            })
                                                        );
                                                    },
                                                }
                                                : null,
                                            keycloak.hasRealmRole("admin") ||
                                                d.status !== ParticularityStatusEnum.ACTIVE
                                                ? {
                                                    label: "Cambia stato",
                                                    icon: <CheckCircleIcon color={""} size={0} />,
                                                    onClick: () => {
                                                        dispatch(
                                                            setSelectedParticularity({
                                                                id: d.id,
                                                                name: d.name,
                                                            })
                                                        );
                                                    },
                                                }
                                                : null,
                                            keycloak.hasRealmRole("admin")
                                                ? {
                                                    label: "Cancella",
                                                    icon: <TrashIcon color={""} size={0} />,
                                                    onClick: () => setIdToDelete(d.id),
                                                }
                                                : null,
                                        ]}
                                    />
                                )}
                        </td>,
                        group: 'preSort',
                        key: 'Esito'
                    },
                ]
            })) ?? []}
        />
    )
}