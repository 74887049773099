import { useEffect, useRef, useState } from "react"
import { colors } from "../../../ui/colors"
import { CodeIcon } from "../../../ui/icons/code"
import { XCircleIcon } from "../../../ui/icons/xCircle"
import Input from "../../../ui/molecules/input"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { useAppSelector } from "../../redux/hooks"
import { FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
    atecoSign?: string
    setAtecoSign: (ateco?: string) => void
    inputs: string[]
    setInputs: (input: string[]) => void
}

export function AtecoFilter(props: Props) {
    const { updateFilters, filters, atecoSign, setAtecoSign, inputs, setInputs } = props
    const atecosState = useAppSelector(state => state.atecos)

    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [secondInput, setSecondInput] = useState<string[]>(['']);
    const [emptyAtecos, setEmptyAtecos] = useState<{ label: string, value: string }[] | undefined>(undefined)
    const [suggestions, setSuggestions] = useState<{ label: string, value: string }[]>([]);
    const [suggestionsSecondInput, setSuggestionsSecondInput] = useState<{ label: string, value: string }[]>([]);
    const [showSuggestionsSecondInput, setShowSuggestionsSecondInput] = useState<boolean>(false);

    const menuRef = useRef<HTMLTableCellElement>(null);

    const signOptions = [
        { label: "Uguale a", value: "=" },
        { label: "Inizia per", value: "~" },
        { label: "Compreso tra", value: "><" },
    ];

    const defaultAtecos = atecosState.findAllAtecosResponse.map((sector) => ({ label: sector.atecoCode + ' - ' + sector.atecoDescription, value: sector.atecoCode }))

    // const handleClickOutside = (event: MouseEvent) => {
    //     if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
    //         setOpenVariables(false);
    //     }
    // };

    // useEffect(() => {
    //     if (openVariables) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [openVariables]);

    //TODO SWITCH
    // useEffect(() => {
    //     if (isSelectingSecondInput) {
    //         setIsSelectingSecondInput(false);
    //         return;
    //     }

    //     if (secondInput && secondInput.length > 0) {
    //         setSuggestionsSecondInput(
    //             defaultAtecos.filter(ateco =>
    //                 ateco.label.toLowerCase().startsWith(secondInput.toLowerCase())
    //             )
    //         );
    //         setShowSuggestionsSecondInput(true);
    //     } else {
    //         setSuggestionsSecondInput([]);
    //         setShowSuggestionsSecondInput(false);
    //     }
    // }, [secondInput]);

    const handleClear = () => {
        setInputs([""]);
        setActiveIndex(null);
        setSecondInput(['']);
        setAtecoSign(undefined);
        setEmptyAtecos(undefined);
        setSuggestions([]);
        setSuggestionsSecondInput([])
        setShowSuggestionsSecondInput(false)
        updateFilters({ companyAtecoCode: undefined });
    };


    const handleInputChange = (index: number, value: string) => {
        const newInputs = [...inputs];
        newInputs[index] = value;

        if (newInputs.length === 1 && index === 0 && value === "") {
            setInputs([""]);
        } else {
            setInputs(newInputs);
        }

        setSuggestions(
            value.length > 0
                ? defaultAtecos.filter(ateco => ateco.label.toLowerCase().startsWith(value.toLowerCase()))
                : []
        );
        setActiveIndex(index);
    };
    const handleSecondInputChange = (index: number, value: string) => {
        const newInputs = [...secondInput];
        newInputs[index] = value;

        if (newInputs.length === 1 && index === 0 && value === "") {
            setSecondInput([""]);
        } else {
            setSecondInput(newInputs);
        }

        setSuggestions(
            value.length > 0
                ? defaultAtecos.filter(ateco => ateco.label.toLowerCase().startsWith(value.toLowerCase()))
                : []
        );
        setActiveIndex(index);
    };
    const handleSelectSuggestion = (index: number, value: string) => {
        const newInputs = [...inputs];
        newInputs[index] = value;
        setInputs(newInputs);
        setActiveIndex(null);
    };

    useEffect(() => {
        if (atecoSign === undefined && filters.companyAtecoCode === undefined) {
            return;
        }

        if ((inputs.length === 0) && (secondInput.length === 0) && atecoSign === undefined) {
            setEmptyAtecos(undefined);
            setAtecoSign(undefined)
            updateFilters({ companyAtecoCode: undefined });
        } else if (atecoSign === "=" && inputs.length > 0 && inputs.some(input => input !== "")) {
            let values = inputs.map(input => ({ label: signOptions.find(opt => opt.value === atecoSign)?.label + " " + (input), value: atecoSign + ":" + input }));
            updateFilters({ companyAtecoCode: values });
        } else if (atecoSign === "~" && inputs.length > 0 && inputs.some(input => input !== "")) {
            let values = inputs.map(input => ({ label: signOptions.find(opt => opt.value === atecoSign)?.label + " " + (input), value: atecoSign + ":" + input }));
            updateFilters({ companyAtecoCode: values });
        } else if (atecoSign === "><" && inputs.length > 0 && inputs.some(input => input !== "") && secondInput.length > 0 && secondInput.some(input => input !== "")) {
            let values = inputs.map((input, index) => ({ label: signOptions.find(opt => opt.value === atecoSign)?.label + " " + input + " e " + secondInput[index], value: atecoSign + ":" + input + ":" + secondInput[index] }));
            updateFilters({ companyAtecoCode: values });
        } else if (inputs.length === 0 && secondInput === undefined && atecoSign !== undefined) {
            updateFilters({ companyAtecoCode: undefined });
            const label = signOptions.find(opt => opt.value === atecoSign)?.label;
            setEmptyAtecos([{ label: label!, value: label! }]);
        }
    }, [inputs, secondInput, atecoSign]);

    useEffect(() => {
        if (inputs.length === 0 || atecoSign !== "=") {
            if (filters.companyCategory.length === 0 && filters.companySector.length === 0) {
                return;
            }
            updateFilters({ companyCategory: [], companySector: [] });
        } else if (filters.companyAtecoCode?.length !== 0 && atecoSign === "=") {
            const atecoCodes = filters.companyAtecoCode?.map((value) => value.value.slice(2)) || [];
            let filterSector: { label: string, value: string }[] = []
            let filterCategory: { label: string, value: string }[] = []

            atecoCodes.forEach(atecoCode => {
                const categoryAndSector = atecosState.findAllAtecosResponse.find(_ateco => _ateco.atecoCode === atecoCode);

                const category = categoryAndSector?.category;
                const sector = categoryAndSector?.sector;

                if (category && !filterCategory.map(cat => cat.value).includes(category)) {
                    filterCategory.push({ value: category, label: category });
                }

                if (sector && !filterSector.map(sector => sector.value).includes(sector)) {
                    filterSector.push({ value: sector, label: sector });
                }
            });

            updateFilters({ companyCategory: filterCategory, companySector: filterSector });
        }
    }, [filters.companyAtecoCode, atecoSign]);

    return (
        <AccordionFilter
            key={'person-filter-ateco'}
            label={"Codice Ateco"}
            icon={<CodeIcon color={""} size={0} />}
            showClear={filters.companyAtecoCode !== undefined || atecoSign !== undefined}
            options={filters.companyAtecoCode !== undefined ? filters.companyAtecoCode : emptyAtecos !== undefined ? emptyAtecos : undefined}
            clearAction={handleClear}
            deleteFilter={handleClear}
        >
            <div className="flex flex-col gap-4">
                <SelectCustom
                    isSearchable={false}
                    onChange={(e) => {
                        if (e !== undefined && typeof e === 'string') {
                            setAtecoSign(e as '=' | '~' | '><' | undefined)
                        }
                    }}
                    defaultValue={atecoSign}
                    placeholder={"Uguale a, inizia per, compreso"}
                    options={signOptions}
                />
                {atecoSign !== undefined && (
                    <div className="flex gap-2">
                        <div className="flex flex-col gap-1 w-full">
                            {
                                inputs.map((input, index) => (
                                    <div key={index} className="relative w-full">
                                        <div className="flex flex-row items-center gap-2">
                                            <Input
                                                key={"people-filter-ateco-input-" + index}
                                                value={input}
                                                onChangeText={(e) => handleInputChange(index, e)}
                                                placeholder={signOptions.find(opt => opt.value === atecoSign)?.label}
                                            />
                                            {
                                                atecoSign === '><' &&
                                                <div className="flex flex-col gap-1 w-full relative">
                                                    <Input
                                                        key={"people-filter-ateco-input-2-" + index}
                                                        value={secondInput[index]}
                                                        onChangeText={(e) => handleSecondInputChange(index, e)}
                                                        placeholder={"Compreso tra"}
                                                    />
                                                    {showSuggestionsSecondInput && suggestionsSecondInput.length > 0 && (
                                                        <ul className="absolute top-full left-0 w-full bg-white border border-neutral-300 rounded-md max-h-48 overflow-auto z-50">
                                                            {suggestionsSecondInput.map((sugg, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="p-2 hover:bg-brandPrimary-200 cursor-pointer text-text-sm font-normal text-neutral-600"
                                                                    onClick={() => {
                                                                        // setIsSelectingSecondInput(true)
                                                                        setSecondInput(['']);
                                                                        setShowSuggestionsSecondInput(false);
                                                                    }}
                                                                >
                                                                    {sugg.label}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            }
                                            <div>
                                                <XCircleIcon
                                                    color={colors.neutral[500]}
                                                    size={20}
                                                    onClick={() => {
                                                        if (inputs.length === 1) {
                                                            setInputs(['']);
                                                            setSecondInput(['']);
                                                        }
                                                        else {
                                                            setSecondInput(secondInput.filter((_, i) => i !== index))
                                                            setInputs(inputs.filter((_, i) => i !== index))
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {activeIndex === index && suggestions.length > 0 && (
                                            <ul className="absolute top-full left-0 w-full bg-white border border-neutral-300 rounded-md max-h-48 overflow-auto z-50">
                                                {suggestions.map((sugg, idx) => (
                                                    <li
                                                        key={idx}
                                                        className="p-2 hover:bg-brandPrimary-200 cursor-pointer text-text-sm font-normal text-neutral-600"
                                                        onClick={() => handleSelectSuggestion(index, sugg.value)}
                                                    >
                                                        {sugg.label}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))
                            }
                            {
                                (
                                    <span onClick={() => {
                                        setInputs([...inputs, ""])
                                        setSecondInput([...secondInput, ""])
                                    }} className="flex justify-end text-text-xs text-brandPrimary-600 font-medium mt-1 cursor-pointer">
                                        Aggiungi campo
                                    </span>
                                )}
                        </div>
                    </div>
                )}
            </div>
        </AccordionFilter>
    )
}