import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { arrayMove, horizontalListSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { ReactElement } from "react";
import { DatabasePreferences } from "../../../lib/preferences/dto";
import { CustomTHead } from "./thead";
import { TRow } from "./trow";
import './style.css'

interface Props {
    preferences: DatabasePreferences[]
    prevOrderHeads: ReactElement[]
    postOrderHeads: ReactElement[]
    setOrderFilters: (order: string) => void
    setPreferences: (items: DatabasePreferences[]) => void
    rows: { key: string, data: { item: ReactElement, group: 'preSort' | 'sort' | 'postSort', key: string }[], onClick?: Function }[]
    tableHeight?: string
}

export function CustomTable(props: Props) {
    const { preferences, setOrderFilters, prevOrderHeads, postOrderHeads, rows, tableHeight, setPreferences } = props

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;

        if (over === null) {
            return;
        }

        if (active.id !== over.id) {
            const newPref = [...preferences]
            const oldIndex = newPref.findIndex(
                (item) => item.column === active.id
            );
            const newIndex = newPref.findIndex(
                (item) => item.column === over.id
            );

            props.setPreferences(
                arrayMove(newPref, oldIndex, newIndex)
            );
            return arrayMove(newPref, oldIndex, newIndex);
        }
    }

    return (
        <DndContext
            modifiers={[restrictToHorizontalAxis]}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                strategy={horizontalListSortingStrategy}
                items={preferences.map(item => item.column)}>
                <div className="w-full overflow-auto custom-table" style={{ height: tableHeight }}>
                    <table className="max-w-full w-full">
                        <CustomTHead
                            setOrder={(order) => setOrderFilters(order)}
                            setPreferences={(pref) => setPreferences(pref)}
                            preferences={preferences}
                            prevOrderHeads={prevOrderHeads}
                            postOrderHeads={postOrderHeads}
                        />
                        <tbody>
                            {rows.map(row => <TRow key={row.key} row={row} preferences={preferences} />)}
                        </tbody>
                    </table>
                </div>
            </SortableContext>
        </DndContext>
    )
}