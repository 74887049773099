import { useState } from "react";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto";
import { useContacts } from "../../../../../lib/hooks/contacts/contacts";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Area } from "../../../../../lib/utils";
import { UploadIcon } from "../../../../../ui/icons/upload";
import Button from "../../../../../ui/molecules/button";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { ImportFromDatabaseList } from "./contactsList";
import { addContacts } from "../../../contacts/slice";

interface Props {
  target: { id: string; name: string } | undefined;
}
export function ImportFromPeople(props: Props) {
  const targetListState = useAppSelector((state) => state.targetList);
  const peopleState = useAppSelector((state) => state.people);

  const filters = useContacts(Area.TARGET, 'add-target-tl', ContactType.PERSON, props.target?.id!);


  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const dispatch = useAppDispatch();

  return (
    <div className="flex border-t" style={{ height: 'calc(100% - 64px - 57.33px)' }}>
      <div className="w-full">
        {filters.status === "loading" ? (
          <div className="w-full h-[45%] flex items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="h-full max-h-full">
            <div className="bg-white h-16 flex items-center justify-between px-4">
              <span className="text-heading-xs font-semibold text-neutral-800">
                {"Lista target > Persone"}
              </span>
              <div className="flex items-center gap-2">
                {selectedIds.length > 0 && (
                  <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    label={"Importa " + selectedIds.length + " selezionati"}
                    color={"blue"}
                    icon={<UploadIcon color={""} size={0} />}
                    onClick={() => {
                      dispatch(
                        addContacts({
                          targetListId:
                            targetListState.findTargetListResponse?.id!,
                          type: ContactType.PERSON,
                          contactsId: selectedIds,
                        })
                      );
                    }}
                  />
                )}
              </div>
            </div>
            {filters.contacts.data.length === 0 ||
              filters.contacts.data === null ? (
              <div style={{ height: 'calc(100%)' }}>
                <NotFound />
              </div>
            ) : (
              <ImportFromDatabaseList
                filters={filters.filters}
                selectedIds={selectedIds}
                setSelectedIds={(values) => setSelectedIds(values)}
                setPage={function (page: number): void {
                  filters.changePage(page);
                }}
                setFilters={function (value: Partial<FindAllContactsFilters>): void {
                  filters.updateFilters(value);
                }}
                contacts={filters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
