export enum PreferencesSection {
    personPreferences = 'personPreferences',
    companyPreferences = 'companyPreferences',
    targetListPreferences = 'targetListPreferences',
    targetListCompanyPreferences = 'targetListCompanyPreferences',
    targetListPersonPreferences = 'targetListPersonPreferences'
}

export interface Preferences extends Record<PreferencesSection, DatabasePreferences[]> {
    id: string;
    owner: string;
}

export interface DatabasePreferences {
    column: string;
    visible: boolean;
    hideable: boolean;
    order?: string;
    width?: number | null;
}

export interface CreatePreferenceRequest extends Record<PreferencesSection, DatabasePreferences[]> {
    owner: string;
}

export interface FindAllPreferencesResponse {
    total: number;
    page: number;
    perPage: number;
    prev: false;
    next: false;
    totalPage: number;
    data: Preferences[];
}

export const peopleColumns = [
    { column: "Contatto", visible: true, hideable: false, order: 'name', width: null },
    { column: "Telefono", visible: true, hideable: false, order: 'phone', width: null },
    { column: "Email", visible: true, hideable: false, order: 'email', width: null },
    { column: "Linkedin", visible: true, hideable: false, width: null },
    { column: "Ruolo", visible: true, hideable: false, order: 'role', width: null },
    { column: "Azienda", visible: true, hideable: false, order: 'companyName', width: null },
    { column: "Reparto", visible: true, hideable: false, order: 'department', width: null },
    { column: "Stato", visible: true, hideable: false, order: "state", width: null },
    { column: "Privacy", visible: true, hideable: false, width: null },
];

export const companyColumns = [
    { column: "Azienda", visible: true, hideable: false, order: 'name', width: null },
    { column: "Fatturato", visible: true, hideable: false, order: '_revenue', width: null },
    { column: "Telefono", visible: true, hideable: false, width: null, order: 'firstPhone.phone' },
    { column: "Stato", visible: true, hideable: false, order: 'state', width: null },
    { column: "Email", visible: true, hideable: true, width: null, order: 'firstEmail' },
    { column: "Sede azienda", visible: true, hideable: true, width: null },
    { column: "P. IVA", visible: true, hideable: true, order: "vat", width: null },
    { column: "Cod. ATECO", visible: true, hideable: false, width: null, order: 'firstAteco.atecoCode' },
    { column: "Settore", visible: true, hideable: false, width: null, order: 'firstAteco.sector' },
    { column: "Categoria", visible: true, hideable: true, width: null, order: 'firstAteco.category' },
    { column: "Dipendenti", visible: true, hideable: true, order: "_employeesNumber", width: null },
    { column: "Sito web", visible: true, hideable: true, width: null },
];

export const targetListColumns = [
    { column: "Nome", visible: true, hideable: false, order: "name", width: null },
    { column: "Progetto", visible: true, hideable: false, order: "project.name", width: null },
    { column: "Commerciali", visible: true, hideable: false, width: null },
    { column: "Team Leader", visible: true, hideable: false, order: "teamLeader.name", width: null },
    { column: "Settore", visible: true, hideable: false, width: null, order: 'project.sector' },
    { column: "Contatti", visible: true, hideable: false, width: null, order: 'contacts' },
    { column: "Lead", visible: true, hideable: false, width: null, order: 'leads' },
    { column: "Da lavorare", visible: true, hideable: false, width: null, order: 'drafts' },
    { column: "Stato", visible: true, hideable: false, width: null, order: 'state' },
];

export const targetListPersonColumns = [
    { column: "Nome referente", visible: true, hideable: false, order: 'contact.name', width: null },
    { column: "Cognome referente", visible: true, hideable: false, width: null, order: 'contact.surname' },
    { column: "Ruolo", visible: true, hideable: true, width: null, order: 'contact.role' },
    { column: "Data di contatto", visible: true, hideable: false, width: null, order: 'lastContactDate' },
    { column: "Esito", visible: true, hideable: false, width: null, order: 'outcome' },
    { column: "Note", visible: true, hideable: true, width: null },
    { column: "Smartphone", visible: true, hideable: true, width: null, order: 'firstPhone' },
    { column: "Azienda", visible: true, hideable: false, width: null, order: 'contact.companyName' },
    { column: "Reparto", visible: true, hideable: true, width: null, order: 'contact.department' },
    { column: "Privacy", visible: true, hideable: true, width: null },
    { column: "Email", visible: true, hideable: false, width: null, order: 'firstEmail' },
    { column: "Linkedin", visible: true, hideable: true, width: null },
];

export const targetListCompanyColumns = [
    { column: "Nome azienda", visible: true, hideable: false, order: "contact.name", width: null },
    { column: "Data di contatto", visible: true, hideable: true, width: null, order: 'lastContactDate' },
    { column: "Fatturato", visible: true, hideable: true, width: null, order: '_contact.revenue' },
    { column: "Telefono", visible: true, hideable: false, width: null, order: 'firstPhone' },
    { column: "Esito", visible: true, hideable: false, width: null, order: 'contactState' },
    { column: "Note", visible: true, hideable: false, width: null },
    { column: "Email", visible: true, hideable: true, width: null, order: 'firstEmail' },
    { column: "Dipendenti", visible: true, hideable: true, width: null, order: '_contact.employeesNumber' },
    { column: "P. IVA", visible: true, hideable: true, width: null, order: 'contact.vat' },
    { column: "Sede azienda", visible: true, hideable: true, width: null },
    { column: "Sito web", visible: true, hideable: true, width: null },
    { column: "Cod. ATECO", visible: true, hideable: true, width: null, order: 'firstAteco' },
    { column: "Settore", visible: true, hideable: true, width: null, order: 'firstAteco.sector' },
    { column: "Categoria", visible: true, hideable: true, width: null, order: 'firstAteco.category' },
];

