import Select from "react-select";
import { colors } from "../../colors";
import { GroupSelectProps } from "./dto";
import './style.css';

export function GroupSelectCustom(props: GroupSelectProps) {
    const allOptions = props.options?.flatMap(group => group.options) || [];

    return (
        <div className="w-[100%] flex flex-col gap-[4px]">
            {props.label && <span className="input-label">{props.label}</span>}
            <Select
                isDisabled={props.disabled}
                menuPlacement="auto"
                menuPortalTarget={props.menuPortalTargetIsNotBody ? undefined : document.body}
                options={props.options}
                noOptionsMessage={() => <span className="text-text-sm">{props.noOptionsMessage}</span>}
                isClearable={props.isClearable}
                placeholder={props.placeholder}
                //@ts-ignore
                onChange={(e) => props.onChange && props.onChange(props.isMulti ? e.map(e => e.value) : e?.value)}
                defaultValue={allOptions.find(option => option.value.includes(props.defaultValue?.split('::')[1] ?? props.defaultValue!))}
                value={allOptions.find(option => option.value.includes(props.defaultValue?.split('::')[1] ?? props.defaultValue!))}
                styles={{
                    groupHeading: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        lineHeight: '24px',
                        color: colors.neutral[800],
                        textTransform: 'none',
                        fontWeight: 600
                    }),
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: colors.neutral[400],
                        fontFamily: 'Manrope',
                        fontWeight: 500
                    }),
                    option: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: props.isSelected ? colors.white : colors.neutral[600],
                        fontFamily: 'Manrope',
                        fontWeight: 500,
                        backgroundColor: props.isSelected ? colors.brandPrimary[400] : colors.white,
                        '&:hover': {
                            backgroundColor: colors.brandPrimary[200]
                        }
                    }),
                    input: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontFamily: 'Manrope',
                        fontWeight: 500
                    }),
                    singleValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontFamily: 'Manrope',
                        fontWeight: 500
                    }),
                    multiValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.brandPrimary[900],
                        fontFamily: 'Manrope',
                        fontWeight: 500,
                        padding: '0px 8px',
                        minWidth: 'auto',
                        borderRadius: 8,
                        backgroundColor: colors.brandPrimary[200],
                        border: '1px solid ' + colors.brandPrimary[400],
                        overflow: 'hidden',  // Nasconde l'overflow
                        textOverflow: 'ellipsis',  // Applica l'ellisse quando necessario
                        whiteSpace: 'nowrap',  // Impedisce la rottura di riga
                        maxWidth: '100%',  // Limita la larghezza massima
                    }),
                    multiValueLabel: (baseStyles, props) => ({
                        ...baseStyles,
                        width: '100%',
                        display: "block",
                        overflow: 'hidden',  // Nasconde l'overflow
                        textOverflow: 'ellipsis',  // Applica l'ellisse per i testi lunghi
                        whiteSpace: 'nowrap',  // Impedisce la rottura di riga
                        maxWidth: 'calc(100% - 20px)',  // Lascia spazio per l'icona di rimozione
                    }),
                    multiValueRemove: (baseStyles, props) => ({
                        ...baseStyles,
                        color: colors.neutral[500],
                        '&:hover': {
                            color: colors.brandPrimary[600],
                            backgroundColor: colors.brandPrimary[200],
                        }
                    }),
                    indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        alignItems: 'center',
                        height: 40
                    }),
                    container: (baseStyles) => ({
                        ...baseStyles,
                        width: '100%',
                        maxWidth: '100%',
                        height: '40px',
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '40px',
                        width: '100%',
                        maxWidth: '100%',
                        borderRadius: '8px',
                        backgroundColor: props.disabled ? 'rgba(242, 242, 250, 1)' : 'white',
                        boxShadow: 'none',
                        border: (props.error ? '1px solid ' + colors.red[300] : state.isFocused ? '1px solid ' + colors.neutral[400] : '1px solid ' + colors.neutral[400]),
                        fontFamily: 'Manrope',
                        padding: '0px 12px',
                        '&:hover': {
                            border: '1px neutral ' + colors.neutral[400]
                        }
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: 0,
                        height: 40,
                        overflow: "auto",
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        display: 'none'
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        color: state.isFocused ? colors.neutral[500] : colors.neutral[500]
                    }),
                    menuPortal: base => ({ ...base, zIndex: 40 })
                }}
            />
            {
                props.error &&
                <span className="text-text-sm text-red-500">{props.errorLabel}</span>
            }
        </div>
    )
}