import { useEffect } from "react";
import { FindAllContactsFilters } from "../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { BannersList } from "../../ui/organisms/banner/bannerList";
import { findCompany, setDeleteBulkCompaniesStatus, setDeleteCompanyStatus, setEditCompanyStatus, setSelectedIdsCompanies, setShowPopupCompanies } from "../database/companies/slice";
import { setOtpRequestStatus } from "../database/people/privacy/slice";
import { findPerson, setDeleteBulkPeopleStatus, setDeletePersonStatus, setEditPersonStatus, setNewPersonResponse, setNewPersonStatus, setSelectedIdsPeople, setShowPopupPeople } from "../database/people/slice";
import { getContact, setChangeContactStateStatus } from "../targetList/contacts/slice";
import { setBulkDeleteModal } from "../database/slice";

interface Props {
    reloadPeople: () => void
    reloadCompanies: () => void
    companiesResetFilters: () => void
    peopleResetFilters: () => void
}

export function BannerVerifyBins(props: Props) {
    const { reloadPeople, reloadCompanies, companiesResetFilters, peopleResetFilters } = props
    const contactsState = useAppSelector((state) => state.contacts);
    const companiesState = useAppSelector(state => state.companies)
    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteBulkStatus === "failed" ||
            companiesState.deleteBulkStatus === "successfully"
        ) {
            dispatch(setBulkDeleteModal(false))
            if (companiesState.deleteBulkStatus === "successfully") {
                companiesResetFilters()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteBulkCompaniesStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deleteBulkStatus === "failed" ||
            peopleState.deleteBulkStatus === "successfully"
        ) {
            dispatch(setBulkDeleteModal(false))
            if (peopleState.deleteBulkStatus === "successfully") {
                peopleResetFilters()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteBulkPeopleStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deleteBulkStatus]);

    const dispatch = useAppDispatch()
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            contactsState.changeContactStateStatus === "failed" ||
            contactsState.changeContactStateStatus === "successfully"
        ) {
            if (contactsState.changeContactStateStatus === "successfully") {
                dispatch(getContact(contactsState.getContactResponse?.id!))
                reloadPeople()
                reloadCompanies()
            }
            timeoutId = setTimeout(() => {
                dispatch(setChangeContactStateStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [contactsState.changeContactStateStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteCompanyStatus === "failed" ||
            companiesState.deleteCompanyStatus === "successfully"
        ) {
            dispatch(setSelectedIdsCompanies([]))
            dispatch(setShowPopupCompanies(false))
            if (companiesState.deleteCompanyStatus === "successfully") {
                reloadCompanies()
            }
            timeoutId = setTimeout(() => { 
                dispatch(setDeleteCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deletePersonStatus === "failed" ||
            peopleState.deletePersonStatus === "successfully"
        ) {
            dispatch(setSelectedIdsPeople([]))
            dispatch(setShowPopupPeople(false))
            if (peopleState.deletePersonStatus === "successfully") {
                reloadPeople()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                reloadPeople()
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    return (
        <BannersList banners={
            [
                {
                    label: "Contatto aggiunto correttamente",
                    visible: peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists,
                    closeAction: () => {
                        dispatch(setNewPersonStatus("idle"));
                        dispatch(setNewPersonResponse(undefined));
                    },
                    type: "success"
                },
                {
                    label: "Il contatto che stai provando ad inserire esiste ed è associato all'azienda",
                    visible: peopleState.newPersonStatus === "successfully" && peopleState.newPersonResponse?.alreadyExists === true,
                    closeAction: () => {
                        dispatch(setNewPersonStatus("idle"));
                        dispatch(setNewPersonResponse(undefined));
                    },
                    type: "warning"
                },
                {
                    label: peopleState.newPersonResponseMessage,
                    visible: peopleState.newPersonStatus === "failed",
                    closeAction: () => {
                        dispatch(setNewPersonStatus("idle"));
                        dispatch(setNewPersonResponse(undefined));
                    },
                    type: "error"
                },
                {
                    label: "Persona eliminata.",
                    visible: peopleState.deletePersonStatus === "successfully",
                    closeAction: () => dispatch(setDeletePersonStatus("idle")),
                    type: "success"
                },
                {
                    label: "Si è verificato un errore durante l'eliminazione della persona.",
                    visible: peopleState.deletePersonStatus === "failed",
                    closeAction: () => dispatch(setDeletePersonStatus("idle")),
                    type: "error"
                },
                {
                    label: "Si è verificato un errore durante l'invio dell'OTP.",
                    visible: otpState.otpRequestStatus === "failed",
                    closeAction: () => dispatch(setOtpRequestStatus("idle")),
                    type: "error"
                },
                {
                    label: "Azienda eliminata.",
                    visible: companiesState.deleteCompanyStatus === "successfully",
                    closeAction: () => dispatch(setDeleteCompanyStatus("idle")),
                    type: "success"
                },
                {
                    label: "Si è verificato un errore durante l'eliminazione dell'azienda.",
                    visible: companiesState.deleteCompanyStatus === "failed",
                    closeAction: () => dispatch(setDeleteCompanyStatus("idle")),
                    type: "error"
                },
                {
                    label: "Persona aggiornata.",
                    visible: peopleState.editPersonStatus === "successfully",
                    closeAction: () => dispatch(setEditPersonStatus("idle")),
                    type: "success"
                },
                {
                    label: "Si è verificato un errore durante la modifica della persona.",
                    visible: peopleState.editPersonStatus === "failed",
                    closeAction: () => dispatch(setEditPersonStatus("idle")),
                    type: "error"
                },
                {
                    label: "Azienda aggiornata.",
                    visible: companiesState.editCompanyStatus === "successfully",
                    closeAction: () => dispatch(setEditCompanyStatus("idle")),
                    type: "success"
                },
                {
                    label: "Si è verificato un errore durante la modifica dell'azienda.",
                    visible: companiesState.editCompanyStatus === "failed",
                    closeAction: () => dispatch(setEditCompanyStatus("idle")),
                    type: "error"
                },
                {
                    label: "Esito aggiornato.",
                    visible: contactsState.changeContactStateStatus === "successfully",
                    closeAction: () => dispatch(setChangeContactStateStatus("idle")),
                    type: "success"
                },
                {
                    label: "Si è verificato un errore durante la modifica dell'esito.",
                    visible: contactsState.changeContactStateStatus === "failed",
                    closeAction: () => dispatch(setChangeContactStateStatus("idle")),
                    type: "error"
                }
            ]

        } />
    )
}