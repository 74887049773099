import { useEffect, useState } from "react";
import { ContactType } from "../../../../lib/filters/dto";
import { keycloak } from "../../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { EditIcon } from "../../../../ui/icons/edit";
import { GlobeIcon } from "../../../../ui/icons/globe";
import { LinkedinIcon } from "../../../../ui/icons/linkedin";
import { MailIcon } from "../../../../ui/icons/mail";
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall";
import { Badge } from "../../../../ui/molecules/badge";
import Button from "../../../../ui/molecules/button";
import { GroupSelectCustom } from "../../../../ui/molecules/groupSelect";
import { Spinner } from "../../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../../ui/organisms/confirmModal.tsx";
import { CompanyDTO } from "../../../database/companies/dto";
import { setTabValueDetailsCompany } from "../../../database/companies/slice";
import { PersonDTO } from "../../../database/people/dto";
import { setTabValueDetailsPerson } from "../../../database/people/slice";
import { setMailDetailsTo, setSendEmailModalOpen } from "../../../projects/mails/slice";
import { ContactStateEnum, generateDefaultValue, generateOutcomeOptions } from "../../dto";
import { PlanRecallModal } from "../recall/planRecallModal";
import { changeContactState, setShowRecallContactModal } from "../slice";
import { BannerEvents } from "../recall/banner";
import { findRecalls, setOperationType } from "../recall/slice";
import { RotateIcon } from "../../../../ui/icons/rotate";

type Props = ({
    contactType: ContactType.COMPANY;
    contactData?: CompanyDTO;
} | {
    contactType: ContactType.PERSON;
    contactData?: PersonDTO;
})

export function ContactForm(props: Props) {
    const { contactType, contactData } = props
    const mailsState = useAppSelector(state => state.mails)
    const contactsState = useAppSelector(state => state.contacts)
    const recallsState = useAppSelector(state => state.recalls)
    const dispatch = useAppDispatch()
    const [confirmLeadTaken, setConfirmLeadTaken] = useState(false)

    useEffect(() => {
        if (contactsState.getContactStatus === 'successfully' && contactsState.getContactResponse !== undefined) {
            dispatch(findRecalls({
                page: 0,
                pageSize: 0,
                sort: "",
                sortBy: "",
                fromDateTime: null,
                toDateTime: null,
                contactId: contactsState.getContactResponse.id
            }))
        }
    }, [contactsState.getContactStatus])

    if (!contactData) {
        return <></>
    }

    return (
        <>
            <BannerEvents />
            <div className="w-full px-6 py-3 flex items-center border-y border-y-brandPrimary-100 justify-between mt-1 bg-brandPrimary-50">
                <div className="gap-2 flex">
                    <div className=" relative">
                        <Button
                            disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') || contactData.emails === null}
                            size={"sm"}
                            iconPosition={"only"}
                            variant={"outline"}
                            onClick={() => {
                                if (contactData.emails !== null && contactData.emails[0] !== null)
                                    dispatch(setMailDetailsTo(contactData.emails[0]))
                                dispatch(setSendEmailModalOpen(true))
                            }}
                            color={"blue"}
                            icon={<MailIcon color={""} size={0} />}
                        />
                        {contactData.emails && <div className=" absolute top-[-20%] right-[-8%]">
                            {mailsState.findEmailsHistoryStatus === 'loading' && <Spinner size={10} />}
                            {(mailsState.findEmailsHistoryStatus !== 'loading' && mailsState.findEmailsHistoryStatus !== 'failed' && mailsState.findEmailsHistoryResponse && mailsState.findEmailsHistoryResponse.messages.length > 0) &&
                                <Badge color={mailsState.findEmailsHistoryResponse &&
                                    (mailsState.findEmailsHistoryResponse.messages[0].opens_count > 0) ? 'green' : "red"}
                                    label={mailsState.findEmailsHistoryResponse.messages.length.toString()} />
                            }
                        </div>}
                    </div>
                    <Button
                        disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                        size={"sm"}
                        iconPosition={"only"}
                        variant={"outline"}
                        color={"blue"}
                        onClick={() => {
                            const a = document.createElement("a");
                            document.body.appendChild(a);
                            a.rel = "noreferrer"
                            a.target = "_blank"
                            a.href = 'tel:' + contactData.phones[0];
                            a.click();
                        }}
                        icon={<PhoneCallIcon color={""} size={0} />}
                    />
                    {
                        contactType === ContactType.COMPANY &&
                        <Button
                            disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                            size={"sm"}
                            iconPosition={"only"}
                            variant={"outline"}
                            color={"blue"}
                            onClick={() => {
                                const a = document.createElement("a");
                                document.body.appendChild(a);
                                a.rel = "noreferrer"
                                a.target = "_blank"
                                a.href = contactData.website!;
                                a.click();
                            }}
                            icon={<GlobeIcon color={""} size={0} />}
                        />
                    }
                    {
                        contactType === ContactType.PERSON &&
                        <Button
                            disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                            size={"sm"}
                            iconPosition={"only"}
                            variant={"outline"}
                            color={"blue"}
                            onClick={() => {
                                const a = document.createElement("a");
                                document.body.appendChild(a);
                                a.rel = "noreferrer"
                                a.target = "_blank"
                                a.href = contactData.linkedinProfile!;
                                a.click();
                            }}
                            icon={<LinkedinIcon color={""} size={0} />}
                        />
                    }
                    <Button
                        disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                        size={"sm"}
                        iconPosition={"only"}
                        variant={"outline"}
                        color={"blue"}
                        onClick={() => contactType === ContactType.PERSON ? dispatch(setTabValueDetailsPerson('notes')) : dispatch(setTabValueDetailsCompany(2))}
                        icon={<EditIcon color={""} size={0} />}
                    />
                    <Button
                        // disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                        size={"sm"}
                        iconPosition={"only"}
                        variant={"outline"}
                        color={"blue"}
                        onClick={() => {
                            dispatch(setOperationType('save'))
                            dispatch(setShowRecallContactModal(true))
                        }}
                        icon={<RotateIcon color={""} size={0} />}
                    />
                </div>
                <Button
                    size={"sm"}
                    disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') || contactsState.getContactResponse?.outcome === ContactStateEnum.LEADTAKEN}
                    iconPosition={contactsState.getContactResponse?.outcome === ContactStateEnum.LEADTAKEN ? "left" : 'off'}
                    variant={contactsState.getContactResponse?.outcome === ContactStateEnum.LEADTAKEN ? "solid" : "outline"}
                    label="Lead preso"
                    color={contactsState.getContactResponse?.outcome === ContactStateEnum.LEADTAKEN ? "green" : 'gray'}
                    icon={<CheckCircleIcon color={""} size={0} />}
                    onClick={() => { if (contactsState.getContactResponse?.outcome !== ContactStateEnum.LEADTAKEN) setConfirmLeadTaken(true) }}
                />
            </div>
            <div className="w-full px-6 py-3 flex items-center border-b border-b-brandPrimary-100 justify-between mt-1">
                <GroupSelectCustom
                    disabled={(contactData.contactCount !== null && contactData.contactCount !== undefined && contactData.contactCount >= 3) || keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                    menuPortalTargetIsNotBody
                    placeholder={"Seleziona un esito"}
                    label="Esito"
                    onChange={(e) => {
                        if (e === ContactStateEnum.DRAFT || e === ContactStateEnum.LEADTAKEN) {
                            dispatch(
                                changeContactState({
                                    contactState: e,
                                    contactId: contactsState.getContactResponse?.id!,
                                    stateInfo: null
                                })
                            );
                        } else {
                            dispatch(
                                changeContactState({
                                    contactState: e && e !== undefined && typeof e === 'string' ? e.split('::')[0] as ContactStateEnum : ContactStateEnum.DRAFT,
                                    contactId: contactsState.getContactResponse?.id!,
                                    stateInfo: e && e !== undefined && typeof e === 'string' ? e.split('::')[1] : null
                                })
                            );
                        }
                    }}
                    defaultValue={generateDefaultValue(contactsState.getContactResponse?.outcome || ContactStateEnum.DRAFT, contactsState.getContactResponse?.outcomeInfo ?? null)}
                    options={generateOutcomeOptions(contactsState.getContactResponse?.callAttempts!, contactsState.getContactResponse?.outcome || ContactStateEnum.DRAFT, contactType)}
                />
            </div>
            <ConfirmModal
                title={"Lead preso"}
                label={"Sei sicuro di voler prendere il lead?"}
                confirmAction={function (): void {
                    dispatch(
                        changeContactState({
                            contactState: ContactStateEnum.LEADTAKEN,
                            contactId: contactsState.getContactResponse?.id!,
                            stateInfo: null
                        })
                    )
                }}
                onClose={function (): void {
                    setConfirmLeadTaken(false)
                }}
                visible={confirmLeadTaken} />
            {recallsState.operationType === 'save' && <PlanRecallModal contactType={contactType} type={'save'} data={undefined} />}
        </>
    )
}