import { format } from "date-fns";
import { useEffect, useState } from "react";
import { ContactType } from "../../../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { RotateIcon } from "../../../../ui/icons/rotate";
import { TrashIcon } from "../../../../ui/icons/trash";
import { ConfirmModal } from "../../../../ui/organisms/confirmModal.tsx";
import { NotFound } from "../../../../ui/organisms/notFound";
import { EventDTO } from "../../../calendar/dto";
import { setShowRecallContactModal } from "../slice";
import { PlanRecallModal } from "./planRecallModal";
import { deleteRecall, setOperationType } from "./slice";
import { recallSetter } from "./useRecall";

interface Props {
    contactType: ContactType
}

export function Recall(props: Props) {
    const { contactType } = props
    const dispatch = useAppDispatch()
    const [idToDelete, setIdToDelete] = useState<string | undefined>(undefined)
    const [data, setData] = useState<
        {
            selectionRange: string,
            notificationType: string,
            notificationInterval: string,
            notificationValue: number,
            date: Date | null,
            time: Date | null,
            note: string,
            id: string
        }>({
            selectionRange: '0',
            notificationType: '0',
            notificationInterval: '0',
            notificationValue: 15,
            date: null,
            time: null,
            note: '',
            id: ''
        })

    const recallsState = useAppSelector((state) => state.recalls)

    useEffect(() => {
        if (recallsState.deleteRecall === 'successfully') {
            setIdToDelete(undefined)
        }
    }, [recallsState.deleteRecall])

    if (recallsState.findRecallResponse?.paginationData.total === 0) {
        return (
            <div className="flex justify-center items-center h-3/4">
                <NotFound />
            </div>
        )
    }

    const handleClick = (recall: EventDTO) => {
        setData(recallSetter(recall)!)
        dispatch(setOperationType('edit'))
        dispatch(setShowRecallContactModal(true))

    }

    return (
        <div className="p-6 gap-2 flex flex-col ">
            {
                recallsState.findRecallResponse?.data.map((recall, index) => (
                    <div className="p-3 flex flex-col gap-1 border border-neutral-200 hover:bg-neutral-50 rounded-lg" key={index}>
                        <div className="flex justify-between gap-2 items-center">
                            <div className="flex gap-2 items-center">
                                <div className="h-6 w-6 flex justify-center items-center rounded-full bg-white border border-neutral-200 cursor-pointer">
                                    <RotateIcon color={colors.neutral[500]} size={16} onClick={() => handleClick(recall)} />
                                </div>
                                <span className="text-text-sm font-semibold text-neutral-800">{format(recall.startDate, 'dd/MM/yyyy - HH:mm')}</span>
                            </div>
                            <div className="cursor-pointer">
                                <TrashIcon color={colors.red[500]} size={16} onClick={() => setIdToDelete(recall.id)} />
                            </div>
                        </div>
                        {
                            recall.notes &&
                            <span className="text-text-sm font-medium text-neutral-600 w-full crop-text">{recall.notes}</span>
                        }
                    </div>
                ))
            }
            {
                recallsState.operationType === 'edit' && <PlanRecallModal contactType={contactType} type={'edit'} data={data} />
            }
            <ConfirmModal
                title={"Elimina evento"}
                label={"Sei sicuro di voler eliminare questo evento?"}
                confirmAction={function (): void {
                    dispatch(deleteRecall(idToDelete!))
                }}
                onClose={function (): void {
                    setIdToDelete(undefined)
                }}
                visible={idToDelete !== undefined}
            />
        </div>
    )
}