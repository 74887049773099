import qs from "qs";
import { cdAxios } from "../../../lib/axios";
import { ContactType, FindAllContactsFilters } from "../../../lib/filters/dto";
import { keycloak } from "../../../lib/keycloak";
import { contactsFiltersAdapter } from "../../../lib/utils";
import { ImportCSVResponseDTO } from "../dto";
import {
  AssociatePersonToCompany,
  EditPersonRequest,
  NewPersonRequest,
  PersonDTO
} from "./dto";
import { PeopleService } from "./service";

export class PeopleServiceImpl implements PeopleService {

  public deletePerson(id: string[]): Promise<void> {
    return cdAxios
      .delete("/api/person", {
        data: id,
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public personCount(): Promise<{ toActivate: number }> {
    return cdAxios
      .get("/api/person/count", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findPersonById(id: string): Promise<PersonDTO> {
    return cdAxios
      .get("/api/person/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public updatePerson(id: string, data: EditPersonRequest): Promise<void> {
    return cdAxios
      .put("/api/person/" + id, data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createPerson(data: NewPersonRequest): Promise<{
    id: string,
    alreadyExists: boolean
  }> {
    return cdAxios
      .post("/api/person", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public importContact(data: FormData): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .post("/api/person/csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public associateCompany(
    data: AssociatePersonToCompany,
    personId: string
  ): Promise<void> {
    return cdAxios
      .post("/api/person/" + personId + "/company", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public getCSV(ids: string[]): Promise<string> {
    return cdAxios
      .get("/api/person/csv", {
        params: {
          ids: ids,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/octet-stream",
        },
      })
      .then((response) => response.data);
  }

  public bulkCSV(
    filters: FindAllContactsFilters
  ): Promise<string> {
    return cdAxios
      .get("/api/person/bulk-csv", {
        params: contactsFiltersAdapter(filters, ContactType.PERSON),
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public deleteBulk(
    filters: FindAllContactsFilters
  ): Promise<void> {
    return cdAxios
      .delete("/api/person/bulk", {
        params: { ...contactsFiltersAdapter(filters, ContactType.PERSON), page: 0, itemsPerPage: 0 },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
