import { TargetListPageEnum } from "../../../pages/targetList/dto";
import { NewTargetListsService } from "../../../pages/targetList/service";
import { TargetIcon } from "../../../ui/icons/target";
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { useAppSelector } from "../../redux/hooks";
import { FindAllContactsFilters } from "../dto";

interface Props {
  updateFilters: (filter: Partial<FindAllContactsFilters>) => void
  filters: FindAllContactsFilters
}

export function TargetFilter(props: Props) {
  const { updateFilters, filters } = props
  const targetListState = useAppSelector((state) => state.targetList);

  const filterTarget = async (inputValue: string) => {
    return await NewTargetListsService().findAllTargetLists({
      commercial: [],
      name: inputValue,
      sector: [],
      category: [],
      state: [],
      order: true,
      sort: "name",
      itemsPerPage: 50,
      page: 0,
      contacts: "",
      project: [],
      teamLeader: []
    }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string; }[]>((resolve) => {
      resolve(filterTarget(inputValue));
    });

  return (
    <AccordionFilter
      disabled={
        window.location.pathname.includes("target") &&
        targetListState.targetListPage !== TargetListPageEnum.DB
      }
      key={"person-filter-target"}
      label={"Lista target"}
      icon={<TargetIcon color={""} size={0} />}
      showClear={
        filters.targetList.length > 0 &&
        (!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListState.targetListPage === TargetListPageEnum.DB))
      }
      options={filters.targetList}
      clearAction={function (): void {
        updateFilters({ targetList: [] })
      }}
      deleteFilter={function (option): void {
        updateFilters({
          targetList: filters.targetList.filter(
            (targetList) => targetList.value !== option.value
          )
        })
      }}
    >
      <SelectCustomAsync
        isMulti
        //@ts-ignore
        onChange={e => updateFilters({ targetList: e })}
        defaultValue={filters.targetList}
        noOptionsMessage="Non sono presenti liste target."
        loadOptions={promiseOptions}
        defaultOptions
        isClearable={false}
        placeholder={"Cerca per lista target"}
      />
    </AccordionFilter>
  );
}
