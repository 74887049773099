import { regions } from "../../places/italianRegions";
import { GlobeIcon } from "../../../ui/icons/globe";
import { SelectCustom } from "../../../ui/molecules/select";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { ContactType, FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function RegionFilter(props: Props) {
    const { updateFilters, filters } = props

    return (
        <AccordionFilter
            key={'person-filter-region'}
            label={"Regione azienda"}
            icon={<GlobeIcon color={""} size={0} />}
            showClear={filters.companyRegion.length > 0}
            hideDelete
            options={filters.companyRegion}
            clearAction={function (): void {
                updateFilters({ companyRegion: [] })
            }}
            deleteFilter={function (option): void {
                updateFilters({ companyRegion: [] })
            }}
        >
            <div className="flex flex-col gap-3">
                <SelectCustom
                    isSearchable={false}
                    isMulti
                    onChange={e => {
                        if (e !== undefined && typeof e !== 'string') {
                            updateFilters({ companyRegion: e.map(option => ({ value: option, label: option })) })
                        }
                    }}
                    defaultValue={filters.companyRegion.map(option => option.value)}
                    placeholder={"Seleziona una regione"}
                    options={regions.map(region => ({ value: region.name, label: region.name }))}
                />
            </div>
        </AccordionFilter>
    )
}