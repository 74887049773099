import clsx from "clsx"
import { ReactElement } from "react"
import { groupBy } from "../../../lib/utils"
import { DatabasePreferences } from "../../../lib/preferences/dto"

interface Props<T> {
    row: { key: string, data: { item: ReactElement, group: 'preSort' | 'sort' | 'postSort', key: string }[], onClick?: Function }
    preferences: DatabasePreferences[]
}

export function TRow<T>(props: Props<T>) {
    const { row, preferences } = props

    const preSortList = groupBy(row.data, "group").find(group => group.key === 'preSort')?.values.map(value => value.item)
    const sortList = groupBy(row.data, "group").find(group => group.key === 'sort')?.values
    const postSortList = groupBy(row.data, "group").find(group => group.key === 'postSort')?.values.map(value => value.item)

    return (
        <tr
            key={row.key + '-row'}
            onClick={() => row.onClick && row.onClick()}
            className={clsx("bg-white h-[56px] border-b-[1px] border-b-neutral-100  hover:bg-brandPrimary-200", { "cursor-pointer": row.onClick })}
        >
            {preSortList}
            {
                preferences
                    .filter((column) => column.visible)
                    .map((column) => (
                        sortList?.find(el => el.key === column.column)?.item
                    ))
            }
            {postSortList}
        </tr>
    )
}