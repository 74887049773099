import clsx from "clsx";
import { addMinutes, isValid } from "date-fns";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { NumericFormat } from "react-number-format";
import { useAppDispatch, useAppSelector } from "../../../..//lib/redux/hooks";
import { ContactType } from "../../../../lib/filters/dto";
import { keycloak } from "../../../../lib/keycloak";
import { TrashIcon } from "../../../../ui/icons/trash";
import { XIcon } from "../../../../ui/icons/x";
import Button from "../../../../ui/molecules/button";
import { Calendar } from "../../../../ui/molecules/calendar";
import Input from "../../../../ui/molecules/input";
import { SelectCustom } from "../../../../ui/molecules/select";
import { Spinner } from "../../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../../ui/organisms/confirmModal.tsx";
import { CreateEventDTO, EventType } from "../../../calendar/dto";
import { setShowRecallContactModal } from "../slice";
import { createRecalls, deleteRecall, setErrorsCreateStatus, updateRecall, validateRecall } from "./slice";

type Props = (
    {
        contactType: ContactType
    } & ({
        type: 'save',
        data: undefined
    } | {
        type: 'edit';
        data: {
            selectionRange: string
            notificationType: string
            notificationInterval: string
            notificationValue: number
            date: Date | null
            time: Date | null
            note: string,
            id: string
        }
    }))

export function PlanRecallModal(props: Props) {
    const { type, data, contactType } = props
    const dispatch = useAppDispatch()

    const contactsState = useAppSelector((state) => state.contacts);
    const recallsState = useAppSelector((state) => state.recalls);
    const projectsState = useAppSelector((state) => state.projects);
    const peopleState = useAppSelector((state) => state.people);
    const companiesState = useAppSelector((state) => state.companies);

    const [selectionRange, setSelectionRange] = useState(type === 'save' ? "0" : data.selectionRange)
    const [notificationType, setNotificationType] = useState(type === 'save' ? "0" : data.notificationType)
    const [notificationInterval, setNotificationInterval] = useState(type === 'save' ? "0" : data.notificationInterval)
    const [notificationValue, setNotificationValue] = useState(type === 'save' ? 15 : data.notificationValue)
    const [date, setDate] = useState(type === 'save' ? null : (data.date ? new Date(data.date) : null))
    const [time, setTime] = useState(type === 'save' ? null : (data.time ? new Date(data.time) : null))
    const [note, setNote] = useState(type === 'save' ? "" : data.note)

    const [deleteEventId, setDeleteEventId] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (data) {
            setSelectionRange(data.selectionRange)
            setNotificationType(data.notificationType)
            setNotificationInterval(data.notificationInterval)
            setNotificationValue(data.notificationValue)
            setDate(data.date)
            setTime(data.time)
            setNote(data.note)
        }
    }, [data])

    useEffect(() => {
        if (recallsState.deleteRecall === 'successfully') {
            setDeleteEventId(undefined)
            dispatch(setShowRecallContactModal(false));
        }
    }, [recallsState.deleteRecall])

    useEffect(() => {
        if (recallsState.errors.create.status === "successfully") {
            dispatch(setErrorsCreateStatus("idle"))
            const person = peopleState.findPersonResponse
            const company = companiesState.findCompanyResponse
            const title = contactType === ContactType.PERSON ? ((person?.name ?? '-') + ' ' + (person?.surname ?? '-')) : company?.name
            const notification = notificationType !== "4" ? notificationValue : notificationValue * (notificationInterval === "0" ? 1 : notificationInterval === "1" ? 60 : 1440)
            const testDate = new Date(date?.getFullYear()!, date?.getMonth()!, date?.getDate(), time?.getHours(), time?.getMinutes(), time?.getSeconds());
            const request: CreateEventDTO = {
                contactId: contactsState.getContactResponse?.id!,
                startDate: isValid(testDate) ? testDate.toISOString() : new Date().toISOString(),
                endDate: isValid(testDate) ? addMinutes(testDate, 10).toISOString() : addMinutes(new Date(), 10).toISOString(),
                title: title!,
                labels: [],
                notes: note,
                notification: notification,
                userID: keycloak.subject!,
                type: EventType.CALL,
                projectId: projectsState.findProjectResponse?.id!,
                targetListId: contactsState.getContactResponse?.targetListId!
            }
            if (type === 'edit') {
                dispatch(updateRecall({ data: request, id: data.id }))
            }
            else {
                dispatch(createRecalls(request))
            }
        }
    }, [recallsState.errors.create.status])

    const rangeActionMap = new Map<string, () => void>([
        ["0", () => {
            setDate(null)
            setTime(null)
        }],
        ["1", () => {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            setDate(tomorrow);
            const now = new Date();
            now.setHours(now.getHours());
            setTime(now);
        }],
        ["2", () => {
            const threeDays = new Date();
            threeDays.setDate(threeDays.getDate() + 3);
            setDate(threeDays);
            const now = new Date();
            now.setHours(now.getHours());
            setTime(now);
        }],
        ["3", () => {
            const nextWeek = new Date();
            nextWeek.setDate(nextWeek.getDate() + 7);
            setDate(nextWeek);
            const now = new Date();
            now.setHours(now.getHours());
            setTime(now);
        }]
    ])

    useEffect(() => {
        if (!contactsState.showRecallContactModal) {
            setSelectionRange("0")
            setDate(null)
            setTime(null)
            setNote("")
            setNotificationType('0')
            setNotificationInterval('0')
            setNotificationValue(15)
        }
    }, [contactsState.showRecallContactModal])

    return createPortal(
        <div
            onClick={() => {
                dispatch(setShowRecallContactModal(false));
            }}
            className={clsx(
                "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
                {
                    block: contactsState.showRecallContactModal === true,
                    hidden: contactsState.showRecallContactModal === false
                }
            )}
        >
            <div
                onClick={(e) => {
                    e.stopPropagation();
                }}
                className="transition-all flex flex-col opacity-100 rounded-xl bg-white shadow-sm w-[760px]"
            >
                <div className="border-b flex items-center justify-between h-16 border-b-neutral-200 w-full px-6">
                    <span className="text-heading-sm font-semibold text-neutral-800">Pianificazione richiamo</span>
                    <Button onClick={() => { dispatch(setShowRecallContactModal(false)) }} size={"sm"} iconPosition={"only"} variant={"outline"} color={"blue"} icon={<XIcon color={""} size={0} />} />
                </div>
                <div className="px-6 py-8 flex flex-col gap-6">
                    <div className="flex gap-2">
                        <SelectCustom
                            defaultValue={selectionRange}
                            onChange={(e) => {
                                setSelectionRange(typeof e === "string" ? e : "0")
                                rangeActionMap.get(typeof e === "string" ? e : "0")?.()
                            }}
                            label="Intervallo"
                            menuPortalTargetIsNotBody
                            placeholder={""}
                            options={[
                                { label: "Personalizzato", value: "0" },
                                { label: "Domani alla stessa ora", value: "1" },
                                { label: "Tra 3 giorni", value: "2" },
                                { label: "Settimana prossima", value: "3" },
                            ]}
                        />
                        <Calendar
                            type="date"
                            label="Giorno"
                            error={recallsState.errors.create.date}
                            supportingText={recallsState.errors.create.date ? "Seleziona una data" : ""}
                            startDate={date}
                            setStartDate={function (date: Date | null): void {
                                setDate(date);
                                if (date) {
                                    const today = new Date();
                                    const tomorrow = new Date();
                                    tomorrow.setDate(today.getDate() + 1);
                                    const threeDays = new Date();
                                    threeDays.setDate(today.getDate() + 3);
                                    const nextWeek = new Date();
                                    nextWeek.setDate(today.getDate() + 7);
                                    if (date.toDateString() === tomorrow.toDateString()) {
                                        setSelectionRange("1");
                                    } else if (date.toDateString() === threeDays.toDateString()) {
                                        setSelectionRange("2");
                                    } else if (date.toDateString() === nextWeek.toDateString()) {
                                        setSelectionRange("3");
                                    } else {
                                        setSelectionRange("0");
                                    }
                                    const now = new Date();
                                    now.setHours(now.getHours());
                                    setTime(now);
                                }
                            }
                            }
                        />
                        <Calendar
                            label="Orario"
                            startDate={time}
                            error={recallsState.errors.create.time}
                            supportingText={recallsState.errors.create.time ? "Seleziona un orario" : ""}
                            setStartDate={function (date: Date | null): void {
                                setTime(date);
                                if (selectionRange === "1" && date && date.toTimeString() !== new Date().toTimeString()) {
                                    setSelectionRange("0");
                                } else if (selectionRange === "0" && date && date.toTimeString() === new Date().toTimeString()) {
                                    setSelectionRange("1");
                                }
                            }}
                            type={"time"}
                        />
                    </div>
                    <div className="flex gap-2">
                        <div className="w-[50%] flex flex-col gap-2">
                            <SelectCustom
                                error={recallsState.errors.create.notification && notificationType !== "4"}
                                errorLabel="Seleziona un avviso"
                                menuPortalTargetIsNotBody
                                defaultValue={notificationType}
                                onChange={(e) => {
                                    setNotificationType(typeof e === "string" ? e : "0")
                                    if (typeof e === "string" && e === "0")
                                        setNotificationValue(0)
                                    else if (typeof e === "string" && e === "1")
                                        setNotificationValue(15)
                                    else if (typeof e === "string" && e === "2")
                                        setNotificationValue(60)
                                    else if (typeof e === "string" && e === "3")
                                        setNotificationValue(1440)
                                    else if (typeof e === "string" && e === "4")
                                        setNotificationValue(15)
                                }}
                                label="Avviso"
                                placeholder={""}
                                options={[
                                    { label: "All'ora dell'evento", value: "0" },
                                    { label: "15 minuti prima", value: "1" },
                                    { label: "1 ora prima", value: "2" },
                                    { label: "Il giorno prima", value: "3" },
                                    { label: "Personalizza...", value: "4" },
                                ]}
                            />
                            {
                                notificationType === "4" &&
                                <div className="flex gap-2">
                                    <NumericFormat
                                        error={recallsState.errors.create.notification && notificationType === "4"}
                                        supportingText={recallsState.errors.create.notification && notificationType === "4" ? "Inserisci un valore" : ""}
                                        value={notificationValue}
                                        defaultValue={notificationValue}
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        decimalScale={0}
                                        fixedDecimalScale
                                        customInput={Input}
                                        id={"company-to-confirm-employees"}
                                        key={"company-to-confirm-employees"}
                                        placeholder={'15'}
                                        onValueChange={(e) => {
                                            setNotificationValue(e.floatValue || 0)
                                        }}
                                    />
                                    <SelectCustom
                                        menuPortalTargetIsNotBody
                                        defaultValue={notificationInterval}
                                        onChange={(e) => setNotificationInterval(typeof e === "string" ? e : "0")}
                                        placeholder={""}
                                        options={[
                                            { label: "Minuti", value: "0" },
                                            { label: "Ore", value: "1" },
                                            { label: "Giorni", value: "2" },
                                        ]}
                                    />
                                </div>
                            }
                        </div>
                        <Input label="Note" defaultValue={note} value={note} onChangeText={e => setNote(e)} placeholder="Scrivi qui le note" />
                    </div>
                    <div className="flex justify-end gap-2">
                        {
                            type === 'edit' &&
                            (
                                recallsState.deleteRecall === 'loading' ?
                                    <Spinner />
                                    :
                                    <Button
                                        onClick={() => setDeleteEventId(data.id)}
                                        size={"sm"} iconPosition={"left"} icon={<TrashIcon color={""} size={0} />} variant={"outline"} color={"red"} label="CANCELLA" />
                            )
                        }
                        {
                            recallsState.errors.create.status === "loading" || recallsState.createRecallStatus === 'loading' ?
                                <Spinner />
                                :
                                <Button
                                    onClick={() =>
                                        dispatch(validateRecall({
                                            date,
                                            time,
                                            notification: notificationValue
                                        }))}
                                    size={"sm"} iconPosition={"off"} variant={"solid"} color={"green"} label="Pianifica richiamo" />
                        }
                    </div>
                </div>
            </div>
            <ConfirmModal
                title={"Elimina evento"}
                label={"Sei sicuro di voler eliminare questo evento?"}
                confirmAction={function (): void {
                    dispatch(deleteRecall(deleteEventId!))
                }}
                onClose={function (): void {
                    setDeleteEventId(undefined)
                }}
                visible={deleteEventId !== undefined}
            />
        </div >
        , document.body)
}