import { useEffect, useState } from "react";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../lib/filters/dto";
import { useContacts } from "../../../lib/hooks/contacts/contacts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Area } from "../../../lib/utils";
import { Layout } from "../../../ui/layout";
import { Spinner } from "../../../ui/molecules/spinner";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import {
    setSelectedCompany
} from "../../database/companies/slice";
import {
    setSelectedPerson
} from "../../database/people/slice";
import { setSelectedContact } from "../contacts/slice";
import { TargetListPageEnum } from "../dto";
import { ActionsCompaniesTargetList } from "../editTargetListDetails/companies/list/actions";
import { ActionsPeopleTargetList } from "../editTargetListDetails/people/list/actions";
import {
    findTargetListById,
    setEditTargetListTabValue,
    setTargetListPage
} from "../slice";
import { BannerTargetListBins } from "./banner";
import { TargetCompaniesBins } from "./companies";
import { TargetPeopleBins } from "./people";

export function TargetListBins() {
    const dispatch = useAppDispatch();

    const targetListState = useAppSelector((state) => state.targetList);
    const contactsState = useAppSelector((state) => state.contacts);

    const peopleFilters = useContacts(Area.TARGET, 'target-bin', ContactType.PERSON, targetListState.findTargetListResponse?.id!);
    const companiesFilters = useContacts(Area.TARGET, 'target-bin', ContactType.COMPANY, targetListState.findTargetListResponse?.id!);


    const [direction, setDirection] = useState<"left" | "right">("left");

    const [selectedPeopleIds, setSelectedPeopleBinsIds] = useState<string[]>([]);
    const [showPopupPeople, setShowPopupPeople] = useState<boolean>(false);
    const [selectedCompaniesIds, setSelectedCompaniesBinsIds] = useState<string[]>([]);
    const [showPopupCompanies, setShowPopupCompanies] = useState<boolean>(false);

    useEffect(() => {
        dispatch(findTargetListById(targetListState.selectedTargetBins!));
        dispatch(setEditTargetListTabValue(0))
        dispatch(setSelectedCompany(undefined))
        dispatch(setSelectedPerson(undefined))
        dispatch(setSelectedContact(undefined))
    }, []);

    // useEffect(() => {
    //     if (targetListState.findTargetListStatus === 'successfully') {
    //         dispatch(setFindTargetListStatus("idle"));
    //         // dispatch(getCounters(targetListState.findTargetListResponse?.id!))
    //         // dispatch(getContactsIds(targetListState.findTargetListResponse?.id!))
    //     }
    // }, [targetListState.findTargetListStatus])

    return (
        <Layout
            headerLabel={targetListState.findTargetListResponse?.name + ' > Cestini'}
            headerBackPath="/target-list"
            breadcrumbItems={[
                "Liste target",
                targetListState.findTargetListResponse?.name!,
                'Cestini',
                (targetListState.editTargetListTabValue === 0 ? 'Persone' : 'Aziende')
            ]}
            menuItem={MenuItems.TARGET_LIST}
            headerChildren={<div className="flex items-center justify-between">
                <TabMenu
                    direction={direction}
                    setDirection={(value) => setDirection(value)}
                    setValue={(e) => {
                        dispatch(setEditTargetListTabValue(e));
                        if (e === 1 || e === 2) {
                            dispatch(setTargetListPage(TargetListPageEnum.LIST));
                        }
                    }}
                    value={targetListState.editTargetListTabValue}
                    items={[
                        {
                            icon: <></>,
                            label:
                                "Persone (" +
                                contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.targetListBin.total
                                +
                                ")",
                        },
                        {
                            icon: <></>,
                            label:
                                "Aziende (" +
                                contactsState.getCounterResponse?.find(count => count.contactType === ContactType.COMPANY)?.dto.targetListBin.total
                                +
                                ")",
                        },
                    ]}
                />
                <div>
                    {
                        targetListState.editTargetListTabValue === 0 &&
                        <ActionsPeopleTargetList
                            isInBin
                            targetList={targetListState.findTargetListResponse!}
                            contacts={peopleFilters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
                            selectedIds={selectedPeopleIds} setShowPopup={(action) => setShowPopupPeople(action)} filters={peopleFilters.filters} />
                    }
                    {
                        targetListState.editTargetListTabValue === 1 &&
                        <ActionsCompaniesTargetList
                            isInBin
                            targetList={targetListState.findTargetListResponse!}
                            filters={companiesFilters.filters} selectedIds={selectedCompaniesIds} setShowPopup={(action) => setShowPopupCompanies(action)}
                            contacts={companiesFilters.contacts as unknown as FindContactsTargetResponse<ContactType.COMPANY>} />
                    }
                </div>
            </div>}
        >
            <div className="h-full max-h-full p-4">
                <BannerTargetListBins />
                {targetListState.findTargetListStatus === "loading" || contactsState.getCounterStatus === 'loading' ? (
                    <Spinner />
                ) : (
                    targetListState.findTargetListStatus === "successfully" &&
                    <div className="flex flex-col bg-white rounded-2xl shadow-sm h-full max-h-full">
                        <div style={{ height: 'calc(100%)' }} className="border-t border-t-neutral-200">
                            <TabPages
                                direction={direction}
                                setDirection={(value) => setDirection(value)}
                                pages={[
                                    <div key={0} className="h-full">
                                        <TargetPeopleBins
                                            reloadContacts={() => peopleFilters.findContacts()}
                                            contacts={peopleFilters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
                                            selectedIds={selectedPeopleIds}
                                            setSelectedIds={arg => setSelectedPeopleBinsIds(arg)}
                                            setShowPopup={arg => setShowPopupPeople(arg)}
                                            showPopup={showPopupPeople}
                                            filters={peopleFilters.filters}
                                            setPage={function (action: number): void {
                                                peopleFilters.changePage(action);
                                            }} setFilters={function (action: Partial<FindAllContactsFilters>): void {
                                                peopleFilters.updateFilters(action);
                                            }} resetFilters={function (): void {
                                                peopleFilters.resetAllFilters();
                                            }} status={peopleFilters.status} />
                                    </div>,
                                    <div key={1} className="h-full">
                                        <TargetCompaniesBins
                                            reloadContacts={() => companiesFilters.findContacts()}
                                            contacts={companiesFilters.contacts as unknown as FindContactsTargetResponse<ContactType.COMPANY>}
                                            status={companiesFilters.status}
                                            selectedIds={selectedCompaniesIds}
                                            setSelectedIds={arg => setSelectedCompaniesBinsIds(arg)}
                                            setShowPopup={arg => setShowPopupCompanies(arg)} showPopup={showPopupCompanies}
                                            filters={companiesFilters.filters}
                                            setFilters={function (action: Partial<FindAllContactsFilters>): void {
                                                companiesFilters.updateFilters(action);
                                            }}
                                            setPage={function (action: number): void {
                                                companiesFilters.changePage(action);
                                            }}
                                            resetFilters={function (): void {
                                                companiesFilters.resetAllFilters();
                                            }} />
                                    </div>,
                                ]}
                                value={targetListState.editTargetListTabValue}
                                setValue={(e) => {
                                    dispatch(setEditTargetListTabValue(e));
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </Layout>
    );
}
