import { format } from "date-fns";
import { EventDTO } from "../dto";
import { eventsColorsMap } from "./utils";

interface Props {
    event: EventDTO;
}
export function DetailedListEvent(props: Props) {
    const { event } = props;
    return (
        <div className="flex flex-col gap-1">
            <div className="flex w-full h-auto gap-2 items-center">
                <span className="text-nowrap w-9 text-text-sm font-bold text-neutral-800">{format(new Date(event.startDate), 'HH:mm')}</span>
                <div className="h-6 w-1 block rounded-md" style={{ backgroundColor: eventsColorsMap.get(event.type)?.border }} />
                <span className="text-text-md font-semibold text-neutral-800">{event.title}</span>
            </div>
            <div className="flex">
                <span className="pl-14 text-nowrap crop-text text-text-xs text-neutral-700">{format(new Date(event.startDate), 'HH:mm')} - {format(new Date(event.endDate), 'HH:mm')} {event.notes && ' | ' + event.notes}</span>
            </div>
        </div>
    )
}