import { useEffect } from "react"
import { roleDepartment } from "../../../../lib/atecos/dto"
import { ContactType } from "../../../../lib/filters/dto"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { groupBy } from "../../../../lib/utils"
import { colors } from "../../../../ui/colors"
import { ChevronIcon } from "../../../../ui/icons/chevron"
import { LinkedinIcon } from "../../../../ui/icons/linkedin"
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall"
import Button from "../../../../ui/molecules/button"
import Input from "../../../../ui/molecules/input"
import { SelectCustom } from "../../../../ui/molecules/select"
import Toggle from "../../../../ui/molecules/toggle"
import { addContacts } from "../../../targetList/contacts/slice"
import { PersonEmails } from "../../people/editPerson/personEmails"
import { PersonPhones } from "../../people/editPerson/personPhones"
import { createPerson, setNewPersonRequestEmail, setNewPersonRequestLinkedinProfile, setNewPersonRequestName, setNewPersonRequestPhone, setNewPersonRequestPrivacy, setNewPersonRequestRole, setNewPersonRequestSurname, setValidateCreatePersonStatus, validateCreatePerson } from "../../people/slice"

interface Props {
    goToList: Function
    inTargetList: boolean
}

export function CreatePerson(props: Props) {
    const { inTargetList } = props
    const peopleState = useAppSelector(state => state.people)
    const targetListState = useAppSelector(state => state.targetList)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.errors.validateCreate.status === 'successfully') {
            const filteredEmails = Array.isArray(peopleState.newPersonRequest.emails)
                ? peopleState.newPersonRequest.emails.filter(email => email !== '')
                : [];
            dispatch(createPerson({
                ...peopleState.newPersonRequest, emails: filteredEmails.length > 0 ? filteredEmails : null, phones: peopleState.newPersonRequest.phones?.length! > 0 ? peopleState.newPersonRequest.phones : null
            }))
            dispatch(setValidateCreatePersonStatus('idle'))
        }
    }, [peopleState.errors.validateCreate.status])

    useEffect(() => {
        if (peopleState.newPersonStatus === 'successfully' && !peopleState.newPersonResponse?.alreadyExists) {
            if (inTargetList) {
                dispatch(addContacts({
                    targetListId: targetListState.findTargetListResponse?.id!,
                    type: ContactType.PERSON,
                    contactsId: [peopleState.newPersonResponse?.id!]
                }))
            }
            props.goToList()
        }
    }, [peopleState.newPersonStatus])

    return (
        <div className="px-4">
            <div className="border-y border-neutral-200 py-3 flex items-center justify-between">
                <div className="flex items-center gap-1 cursor-pointer" onClick={() => props.goToList()}>
                    <ChevronIcon color={colors.neutral[400]} size={24} direction="l" />
                    <span className="text-text-sm font-semibold text-neutral-700">Torna alla lista</span>
                </div>
                <Button
                    size={"sm"}
                    iconPosition={"left"}
                    variant={"outline"}
                    color={"blue"}
                    label="Aggiungi contatto"
                    onClick={() => {
                        const filteredEmails = Array.isArray(peopleState.newPersonRequest.emails)
                            ? peopleState.newPersonRequest.emails.filter(email => email !== '')
                            : [];

                        dispatch(validateCreatePerson({
                            ...peopleState.newPersonRequest,
                            emails: filteredEmails.length > 0 ? filteredEmails : null,
                            phones: peopleState.newPersonRequest.phones?.length! > 0 ? peopleState.newPersonRequest.phones : null
                        }));
                    }}
                />
            </div>
            <div
                className={"w-full py-4 flex flex-col gap-4 transition-all"}
            >
                <div className="flex gap-3 items-center">
                    <Input
                        label="Nome"
                        defaultValue={peopleState.newPersonRequest.name}
                        error={peopleState.errors.validateCreate.name}
                        supportingText={peopleState.errors.validateCreate.name ? 'Inserisci il nome' : ''}
                        onChangeText={value => dispatch(setNewPersonRequestName(value))}
                    />
                    <Input
                        label="Cognome"
                        defaultValue={peopleState.newPersonRequest.surname}
                        error={peopleState.errors.validateCreate.surname}
                        supportingText={peopleState.errors.validateCreate.surname ? 'Inserisci il cognome' : ''}
                        onChangeText={value => dispatch(setNewPersonRequestSurname(value))}
                    />
                </div>
                <PersonEmails list={[...peopleState.newPersonRequest.emails!]} errors={peopleState.errors.validateCreate.email} handler={list => dispatch(setNewPersonRequestEmail([...list]))} />
                <SelectCustom
                    label="Ruolo"
                    isClearable
                    onChange={value => dispatch(setNewPersonRequestRole(value))}
                    defaultValue={peopleState.newPersonRequest.role}
                    placeholder={"Seleziona un ruolo"}
                    menuPortalTargetIsNotBody
                    options={groupBy(roleDepartment, 'role').map(role => ({ label: role.key, value: role.key }))}
                />
                <PersonPhones list={[...peopleState.newPersonRequest.phones!]} errors={peopleState.errors.validateCreate.phone} handler={list => dispatch(setNewPersonRequestPhone([...list]))} operationType={"create"} />
                <Input
                    label="Linkedin"
                    defaultValue={peopleState.newPersonRequest.linkedinProfile}
                    startIcon={<LinkedinIcon color={""} size={0} />}
                    onChangeText={value => dispatch(setNewPersonRequestLinkedinProfile(value))}
                />
                <Toggle
                    placeholder="Permessi e privacy"
                    checked={peopleState.newPersonRequest.privacy}
                    onChange={function (value: boolean): void {
                        dispatch(setNewPersonRequestPrivacy(value))
                    }} />
                <Input label="Azienda" value={peopleState.newPersonRequest.companyName} disabled />
                <Input label="P. IVA" value={peopleState.newPersonRequest.companyVat} disabled />
                {
                    peopleState.newPersonRequest.companyPhones?.map(phone => (
                        <div className="flex gap-3">
                            <Input label="Telefono azienda" value={phone.phone} disabled startIcon={<PhoneCallIcon color={""} size={0} />} />
                            <Input label="Note" value={phone.note} disabled startIcon={<PhoneCallIcon color={""} size={0} />} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}