import { EyeIcon } from "../../../ui/icons/eye"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { ContactType, FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function SmartphoneFilter(props: Props) {
    const { updateFilters, filters } = props
    const options = [
        { value: 'true', label: 'Sì' },
        { value: 'false', label: 'No' }
    ]
    return (
        <AccordionFilter
            key={'person-filter-smartphone'}
            label={"Smartphone"}
            icon={<EyeIcon color={""} size={0} />}
            showClear={filters.personHasSmartphone !== undefined}
            options={filters.personHasSmartphone !== undefined ? [filters.personHasSmartphone] : []}
            clearAction={function (): void {
                updateFilters({ personHasSmartphone: undefined })
            }}
            deleteFilter={function (option): void {
                updateFilters({ personHasSmartphone: undefined })
            }}
        >
            <SelectCustom
                isSearchable={false}
                isClearable
                onChange={e => {
                    if (e === undefined) {
                        updateFilters({ personHasSmartphone: undefined })
                    } else if (typeof e === 'string') {
                        updateFilters({ personHasSmartphone: { value: e, label: options.find(opt => opt.value === e)?.label! } })
                    }
                }}
                defaultValue={filters.personHasSmartphone?.value}
                placeholder={"Smartphone"}
                options={options}
            />
        </AccordionFilter>
    )
}