import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DatabaseSection, TargetSection } from '../utils';
import { ContactType, FindAllContactsFilters, SectionFilters } from './dto';

const initialContactState: FindAllContactsFilters = {
    outcome: [],
    outcomeInfo: [],
    companyName: [],
    companySector: [],
    companyCategory: [],
    companyRegion: [],
    companyProvince: [],
    personDepartment: [],
    personRole: [],
    particularity: [],
    order: true,
    itemsPerPage: 50,
    page: 0,
    targetList: [],
    project: [],
    missingFields: []
};

const initialState: SectionFilters = {
    database: { PERSON: { ...initialContactState, toVerifyBin: false, blackListBin: false }, COMPANY: { ...initialContactState, toVerifyBin: false, blackListBin: false } },
    target: { PERSON: { ...initialContactState, targetListBin: false }, COMPANY: { ...initialContactState, targetListBin: false } },
    "add-target-db": { PERSON: { ...initialContactState, toVerifyBin: false, blackListBin: false }, COMPANY: { ...initialContactState, toVerifyBin: false, blackListBin: false } },
    "add-target-tl": { PERSON: { ...initialContactState, targetListBin: false }, COMPANY: { ...initialContactState, targetListBin: false } },
    "target-bin": { PERSON: { ...initialContactState, targetListBin: true }, COMPANY: { ...initialContactState, targetListBin: true } },
    "black-list": { PERSON: { ...initialContactState, toVerifyBin: false, blackListBin: true }, COMPANY: { ...initialContactState, toVerifyBin: false, blackListBin: true } },
    "verify-list": { PERSON: { ...initialContactState, toVerifyBin: true, blackListBin: false }, COMPANY: { ...initialContactState, toVerifyBin: true, blackListBin: false } },
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setFilters: (
            state,
            action: PayloadAction<{ section: DatabaseSection | TargetSection; filters: Partial<FindAllContactsFilters>, contactType: ContactType, targetList: null | string }>
        ) => {
            const { section, filters, contactType, targetList } = action.payload;
            state[section][contactType] = {
                ...state[section][contactType],
                ...filters,
                page: 0,
            };

            if (targetList !== null) {
                state[section][contactType].targetList = [{ value: targetList, label: targetList }];
            }
        },
        resetFilters: (state, action: PayloadAction<{ section: DatabaseSection | TargetSection, contactType: ContactType, targetList: null | string }>) => {
            const { section, contactType, targetList } = action.payload;
            state[section][contactType] = { ...initialState[section][contactType], targetList: targetList ? [{ value: targetList, label: targetList }] : [] };
        },
        setPage: (state, action: PayloadAction<{ section: DatabaseSection | TargetSection; contactType: ContactType; page: number }>) => {
            const { section, contactType, page } = action.payload;
            state[section][contactType].page = page;
        },
    },
});

export const { setFilters, resetFilters, setPage } = filtersSlice.actions;
export default filtersSlice.reducer;
