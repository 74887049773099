import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { OptionType } from "../../../lib/types"
import { groupBy } from "../../../lib/utils"
import { TrashIcon } from "../../../ui/icons/trash"
import Button from "../../../ui/molecules/button"
import { SelectCustom } from "../../../ui/molecules/select"
import { Spinner } from "../../../ui/molecules/spinner"
import { setEditTargetListAtecosOptions, setEditTargetListRequestAtecos } from "../../targetList/slice"

export function AtecosSegment() {
    const targetListState = useAppSelector(state => state.targetList)
    const atecosState = useAppSelector(state => state.atecos)

    const defaultAtecos =
        atecosState.findAllAtecosResponse.map((sector) => ({ label: sector.atecoCode + ' - ' + sector.atecoDescription, value: sector.atecoCode }))

    const defaultSectors = groupBy(
        atecosState.findAllAtecosResponse,
        "sector"
    ).map((sector) => ({ label: sector.key, value: sector.key }))

    const defaultCategories = groupBy(
        atecosState.findAllAtecosResponse
        , "category"
    ).map((category) => ({ label: category.key, value: category.key }))

    useEffect(() => {
        let targetListCopy: { ateco: OptionType[], sector: OptionType[], category: OptionType[] }[] = []
        targetListState.findTargetListResponse?.atecos.forEach((ateco, index) => {
            targetListCopy.push({ ateco: defaultAtecos, category: defaultCategories, sector: defaultSectors })
        })
        dispatch(setEditTargetListAtecosOptions([...targetListCopy]))
    }, [])

    const dispatch = useAppDispatch()

    function getFilteredOptions(category: string | null, sector: string | null) {
        const filteredAtecos = atecosState.findAllAtecosResponse.filter((ateco) => {
            return (
                (!category || ateco.category === category) &&
                (!sector || ateco.sector === sector)
            );
        });

        const atecoOptions = filteredAtecos.map((ateco) => ({
            label: ateco.atecoCode + ' - ' + ateco.atecoDescription,
            value: ateco.atecoCode,
        }));

        const sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
            label: sector.key,
            value: sector.key,
        }));

        const categoryOptions = groupBy(filteredAtecos, "category").map((category) => ({
            label: category.key,
            value: category.key,
        }));

        return { atecoOptions, sectorOptions, categoryOptions };
    }

    function handleChangeAtecos(e: string | undefined, index: number) {
        const atecos = [...targetListState.editTargetListRequest.atecos];
        const selectedAteco = atecosState.findAllAtecosResponse.find(
            (ateco) => ateco.atecoCode === e
        );

        if (e) {
            atecos[index] = {
                atecoCode: e,
                sector: selectedAteco?.sector ?? null,
                category: selectedAteco?.category ?? null,
                atecoDescription: null
            };
        } else {
            atecos[index] = { atecoCode: null, sector: null, category: null, atecoDescription: null };
        }

        dispatch(setEditTargetListRequestAtecos([...atecos]));

        const { atecoOptions, sectorOptions, categoryOptions } = getFilteredOptions(
            selectedAteco?.category ?? null,
            selectedAteco?.sector ?? null
        );

        const optionsCopy = [...targetListState.editTargetListAtecosOptions];
        optionsCopy[index] = { ateco: atecoOptions, sector: sectorOptions, category: categoryOptions };
        dispatch(setEditTargetListAtecosOptions(optionsCopy));
    }

    function handleChangeSectors(e: string | undefined, index: number) {
        const atecos = [...targetListState.editTargetListRequest.atecos];
        const selectedSector = e || null;

        atecos[index] = {
            ...atecos[index],
            sector: selectedSector,
            atecoCode: null,
        };

        const { atecoOptions, categoryOptions } = e
            ? getFilteredOptions(atecos[index].category, selectedSector)
            : { atecoOptions: defaultAtecos, categoryOptions: defaultCategories };

        const optionsCopy = [...targetListState.editTargetListAtecosOptions];
        optionsCopy[index] = { ...optionsCopy[index], ateco: atecoOptions, category: categoryOptions };
        dispatch(setEditTargetListAtecosOptions(optionsCopy));

        dispatch(setEditTargetListRequestAtecos([...atecos]));
    }

    function handleChangeCategories(e: string | undefined, index: number) {
        const atecos = [...targetListState.editTargetListRequest.atecos];
        const selectedCategory = e || null;

        atecos[index] = {
            ...atecos[index],
            category: selectedCategory,
            atecoCode: null,
        };

        let atecoOptions, sectorOptions;

        if (selectedCategory === null) {
            atecoOptions = defaultAtecos;
            sectorOptions = defaultSectors;

            if (!defaultSectors.map((sector) => sector.value).includes(atecos[index].sector!)) {
                atecos[index].sector = null;
            }
        } else {
            const filteredAtecos = atecosState.findAllAtecosResponse.filter(
                (ateco) => ateco.category === selectedCategory
            );
            atecoOptions = groupBy(filteredAtecos, "atecoCode").map((ateco) => ({
                label: ateco.key,
                value: ateco.key,
            }));
            sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
                label: sector.key,
                value: sector.key,
            }));

            if (!sectorOptions.map((sector) => sector.value).includes(atecos[index].sector!)) {
                atecos[index].sector = null;
            }
        }

        const optionsCopy = [...targetListState.editTargetListAtecosOptions];
        optionsCopy[index] = {
            ...optionsCopy[index],
            ateco: atecoOptions,
            sector: sectorOptions,
        };
        dispatch(setEditTargetListAtecosOptions(optionsCopy));

        dispatch(setEditTargetListRequestAtecos([...atecos]));
    }

    if (targetListState.findTargetListStatus === 'loading'
        || targetListState.editTargetListRequest?.atecos?.length !== targetListState.editTargetListAtecosOptions.length
    ) {
        return <Spinner />
    }

    return (
        <div className="flex flex-col gap-3">
            {
                targetListState.editTargetListRequest.atecos.map((ateco, index) =>
                    <div className="flex gap-4 items-end" key={JSON.stringify(ateco) + '-' + index}>
                        <SelectCustom
                            isClearable
                            placeholder={"Indifferente"}
                            options={targetListState.editTargetListAtecosOptions[index].ateco}
                            label="Cod. ATECO"
                            defaultValue={ateco.atecoCode ?? undefined}
                            onChange={(e) => {
                                if (typeof e === 'string' || !e)
                                    handleChangeAtecos(e, index)
                            }}
                        />
                        <SelectCustom
                            isClearable
                            placeholder={"Indifferente"}
                            options={targetListState.editTargetListAtecosOptions[index].sector}
                            label="Settore"
                            defaultValue={ateco.sector ?? undefined}
                            onChange={(e) => {
                                if (typeof e === 'string' || !e)
                                    handleChangeSectors(e, index)
                            }}
                        />
                        <SelectCustom
                            isClearable
                            placeholder={"Indifferente"}
                            options={targetListState.editTargetListAtecosOptions[index].category}
                            label="Categoria"
                            defaultValue={ateco.category ?? undefined}
                            onChange={(e) => {
                                if (typeof e === 'string' || !e)
                                    handleChangeCategories(e, index)
                            }}
                        />
                        <Button
                            size={"md"}
                            iconPosition={"only"}
                            variant={"link"}
                            icon={<TrashIcon color={""} size={0} />}
                            color={"blue"}
                            onClick={() => {
                                if (targetListState.editTargetListRequest.atecos.length === 1) {
                                    dispatch(setEditTargetListRequestAtecos([{ atecoCode: null, atecoDescription: null, sector: null, category: null }]))
                                    dispatch(setEditTargetListAtecosOptions([{ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories }]))
                                } else {
                                    const atecosCopy = [...targetListState.editTargetListRequest.atecos]
                                    const atecosRequest = [...atecosCopy.slice(0, index), ...atecosCopy.slice(index + 1)]
                                    dispatch(setEditTargetListRequestAtecos([...atecosRequest]))
                                    const optionsCopy = [...targetListState.editTargetListAtecosOptions]
                                    const optionsRequest = [...optionsCopy.slice(0, index), ...optionsCopy.slice(index + 1)]
                                    dispatch(setEditTargetListAtecosOptions([...optionsRequest]))
                                }
                            }}
                        />
                    </div>
                )
            }
            <div className="w-full h-[1px] bg-neutral-200" />
            <span
                onClick={() => {
                    const atecosCopy = [...targetListState.editTargetListRequest.atecos];
                    atecosCopy.push({ atecoCode: null, atecoDescription: null, sector: null, category: null });
                    dispatch(setEditTargetListRequestAtecos(atecosCopy));

                    const optionsCopy = [...targetListState.editTargetListAtecosOptions];
                    optionsCopy.push({
                        ateco: defaultAtecos,
                        sector: defaultSectors,
                        category: defaultCategories,
                    });
                    dispatch(setEditTargetListAtecosOptions(optionsCopy));
                }}
                className="text-text-sm text-right cursor-pointer font-semibold text-brandPrimary-600"
            >
                Aggiungi nuovo segmento target
            </span>
        </div>
    )
}