import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { regions } from "../../../../lib/places/italianRegions";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { OptionType } from "../../../../lib/types";
import { groupBy } from "../../../../lib/utils";
import { GlobeIcon } from "../../../../ui/icons/globe";
import Input from "../../../../ui/molecules/input";
import { SelectCustom } from "../../../../ui/molecules/select";
import Toggle from "../../../../ui/molecules/toggle";
import {
  resetValidateEditCompanyRequest,
  setEditCompanyAddress,
  setEditCompanyAtecos,
  setEditCompanyCap,
  setEditCompanyCity,
  setEditCompanyEmail,
  setEditCompanyEmployeesNumber,
  setEditCompanyName,
  setEditCompanyPhone,
  setEditCompanyProvince,
  setEditCompanyRegion,
  setEditCompanyRevenue,
  setEditCompanyState,
  setEditCompanyToVerifyFields,
  setEditCompanyVat,
  setEditCompanyWebSite
} from "../slice";
import { CompanyEmails } from "./companyEmails";
import { CompanyPhones } from "./companyPhones";

interface Props {
  isInTargetList: boolean
}

export function EditCompanyForm(props: Props) {
  const { isInTargetList } = props

  const companiesState = useAppSelector((state) => state.companies);
  const atecosState = useAppSelector((state) => state.atecos);
  const targetListState = useAppSelector((state) => state.targetList);
  const databaseState = useAppSelector((state) => state.database)
  const peopleState = useAppSelector((state) => state.people)

  const dispatch = useAppDispatch();

  const defaultAtecos = atecosState.findAllAtecosResponse.map((sector) => ({ label: sector.atecoCode + ' - ' + sector.atecoDescription, value: sector.atecoCode }))

  const defaultSectors = groupBy(
    atecosState.findAllAtecosResponse,
    "sector"
  ).map((sector) => ({ label: sector.key, value: sector.key }))

  const defaultCategories = groupBy(
    atecosState.findAllAtecosResponse
    , "category"
  ).map((category) => ({ label: category.key, value: category.key }))

  const [options, setOptions] = useState<{ ateco: OptionType[], sector: OptionType[], category: OptionType[] }>({ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories })

  useEffect(() => {
    setOptions({ ateco: defaultAtecos, sector: defaultSectors, category: defaultCategories })
    dispatch(resetValidateEditCompanyRequest())
  }, [])

  function getFilteredOptions(category: string | null, sector: string | null) {
    const filteredAtecos = atecosState.findAllAtecosResponse.filter((ateco) => {
      return (
        (!category || ateco.category === category) &&
        (!sector || ateco.sector === sector)
      );
    });

    const atecoOptions = filteredAtecos.map((ateco) => ({
      label: ateco.atecoCode + ' - ' + ateco.atecoDescription,
      value: ateco.atecoCode,
    }));

    const sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
      label: sector.key,
      value: sector.key,
    }));

    const categoryOptions = groupBy(filteredAtecos, "category").map((category) => ({
      label: category.key,
      value: category.key,
    }));

    return { atecoOptions, sectorOptions, categoryOptions };
  }

  function handleChangeAtecos(e: string | undefined) {
    const selectedAteco = atecosState.findAllAtecosResponse.find(
      (ateco) => ateco.atecoCode === e
    );

    if (selectedAteco) {
      const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]

      atecos[0] = { atecoCode: selectedAteco.atecoCode, sector: selectedAteco.sector, category: selectedAteco.category }
      dispatch(setEditCompanyAtecos([...atecos]))
    } else {
      dispatch(setEditCompanyAtecos([{ atecoCode: null, sector: null, category: null, atecoDescription: null }]))
    }

    const { atecoOptions, sectorOptions, categoryOptions } = getFilteredOptions(
      selectedAteco?.category ?? null,
      selectedAteco?.sector ?? null
    );

    setOptions({ ateco: atecoOptions, sector: sectorOptions, category: categoryOptions });
  }

  function handleChangeSectors(e: string | undefined) {
    const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]
    const selectedSector = e || null;

    atecos[0] = {
      ...atecos[0],
      sector: selectedSector,
      atecoCode: null,
    };

    const { atecoOptions, categoryOptions } = e
      ? getFilteredOptions(atecos[0].category, selectedSector)
      : { atecoOptions: defaultAtecos, categoryOptions: defaultCategories };


    setOptions({ ...options, ateco: atecoOptions, category: categoryOptions });

    dispatch(setEditCompanyAtecos([...atecos]));
  }

  function handleChangeCategories(e: string | undefined) {
    const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]
    const selectedCategory = e || null;

    atecos[0] = {
      ...atecos[0],
      category: selectedCategory,
      atecoCode: null,
    };

    let atecoOptions, sectorOptions;

    if (selectedCategory === null) {
      atecoOptions = defaultAtecos;
      sectorOptions = defaultSectors;

      if (!defaultSectors.map((sector) => sector.value).includes(atecos[0].sector!)) {
        atecos[0].sector = null;
      }
    } else {
      const filteredAtecos = atecosState.findAllAtecosResponse.filter(
        (ateco) => ateco.category === selectedCategory
      );
      atecoOptions = groupBy(filteredAtecos, "atecoCode").map((ateco) => ({
        label: ateco.key,
        value: ateco.key,
      }));
      sectorOptions = groupBy(filteredAtecos, "sector").map((sector) => ({
        label: sector.key,
        value: sector.key,
      }));

      if (!sectorOptions.map((sector) => sector.value).includes(atecos[0].sector!)) {
        atecos[0].sector = null;
      }
    }

    setOptions({
      ...options,
      ateco: atecoOptions,
      sector: sectorOptions,
    });

    dispatch(setEditCompanyAtecos([...atecos]));
  }

  // const [isoCountries, setIsoCountries] = useState<
  //   { value: string; code: string }[]
  // >([
  //   { value: "Italia", code: "IT" },
  //   { value: "Francia", code: "FR" },
  //   { value: "Germania", code: "DE" },
  //   { value: "Stati Uniti d'America", code: "US" },
  //   { value: "Regno Unito", code: "UK" },
  // ]);

  const defaultOptions = [
    { label: "Italia", value: "Italia" },
    { label: "Francia", value: "Francia" },
    { label: "Germania", value: "Germania" },
    { label: "Stati Uniti d'America", value: "Stati Uniti" },
    { label: "Regno Unito", value: "Regno Unito" },
  ];

  // const fetchCountries = async (
  //   inputValue: string
  // ): Promise<{ value: string; label: string }[]> => {
  //   if (!inputValue) {
  //     return defaultOptions;
  //   }

  //   try {
  //     const response = await axios.get(
  //       "https://api.mapbox.com/search/geocode/v6/forward",
  //       {
  //         params: {
  //           q: inputValue,
  //           access_token:
  //             "pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw",
  //           types: ["country"],
  //           language: "it",
  //         },
  //         paramsSerializer: (params) => {
  //           return qs.stringify(params, {
  //             arrayFormat: "repeat",
  //             encode: false,
  //           });
  //         },
  //       }
  //     );

  //     const countries = response.data.features.map(
  //       (feature: PlaceFeatureDTO) => ({
  //         label: feature.properties.name,
  //         value: feature.properties.name,
  //       })
  //     );

  //     setIsoCountries(
  //       response.data.features.map((feature: PlaceFeatureDTO) => ({
  //         code: feature.properties.context.country.country_code,
  //         value: feature.properties.name,
  //       }))
  //     );

  //     return countries;
  //   } catch (error) {
  //     console.error("Errore durante il fetch delle nazioni: ", error);
  //     return [];
  //   }
  // };

  // const fetchCities = async (
  //   inputValue: string
  // ): Promise<{ value: string; label: string }[]> => {
  //   if (!inputValue) {
  //     return defaultOptions;
  //   }

  //   try {
  //     const response = await axios.get(
  //       "https://api.mapbox.com/search/geocode/v6/forward",
  //       {
  //         params: {
  //           q: inputValue,
  //           access_token:
  //             "pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw",
  //           types: ["place"],
  //           language: "it",
  //           country: isoCountries.find(
  //             (country) =>
  //               country.value === companiesState.editCompanyRequest.state
  //           )?.code,
  //         },
  //         paramsSerializer: (params) => {
  //           return qs.stringify(params, {
  //             arrayFormat: "repeat",
  //             encode: false,
  //           });
  //         },
  //       }
  //     );

  //     const countries = response.data.features.map(
  //       (feature: PlaceFeatureDTO) => ({
  //         label: feature.properties.name,
  //         value: feature.properties.name,
  //       })
  //     );

  //     return countries;
  //   } catch (error) {
  //     console.error("Errore durante il fetch delle nazioni: ", error);
  //     return [];
  //   }
  // };

  const provinceFilters = regions.flatMap((regions) => regions.provinces);

  useEffect(() => {
    if (companiesState.findCompanyStatus === "successfully" && companiesState.findCompanyResponse !== undefined) {
      dispatch(setEditCompanyVat(companiesState.findCompanyResponse?.vat));
      dispatch(setEditCompanyName(companiesState.findCompanyResponse?.name));
      dispatch(setEditCompanyToVerifyFields(companiesState.findCompanyResponse?.toVerifyFields))
      dispatch(setEditCompanyAtecos(companiesState.findCompanyResponse?.atecos))
      dispatch(setEditCompanyAddress(companiesState.findCompanyResponse?.address));
      dispatch(setEditCompanyRegion(companiesState.findCompanyResponse?.countryRegion));
      dispatch(setEditCompanyState(companiesState.findCompanyResponse?.state));
      dispatch(setEditCompanyCity(companiesState.findCompanyResponse?.city));
      dispatch(setEditCompanyProvince(companiesState.findCompanyResponse?.province));
      dispatch(setEditCompanyCap(companiesState.findCompanyResponse?.zipCode));
      dispatch(setEditCompanyWebSite(companiesState.findCompanyResponse?.website));
      dispatch(setEditCompanyEmail(companiesState.findCompanyResponse?.emails === null || companiesState.findCompanyResponse?.emails.length === 0 ? [''] : companiesState.findCompanyResponse?.emails));
      dispatch(setEditCompanyRevenue(companiesState.findCompanyResponse?.revenue));
      dispatch(setEditCompanyPhone(companiesState.findCompanyResponse?.phones));
      dispatch(setEditCompanyEmployeesNumber(companiesState.findCompanyResponse?.employeesNumber));
    }
  }, [companiesState.findCompanyStatus, companiesState.findCompanyResponse]);

  return (
    <div key={0} className="px-6 flex flex-col gap-4 relative w-full">
      {
        ((!isInTargetList && databaseState.tabValue === 0) || (isInTargetList && targetListState.editTargetListTabValue === 1)) &&
        <div className="py-4">
          <Input
            label="Ragione sociale"
            defaultValue={peopleState.findPersonResponse?.companyName}
            disabled
          />
        </div>
      }
      <Input
        label="P. IVA"
        defaultValue={companiesState.editCompanyRequest?.vat ?? ""}
        value={companiesState.editCompanyRequest?.vat ?? ""}
        error={(companiesState.errors.validateEdit.vat || companiesState.editCompanyRequest.vat === null) && !isInTargetList}
        supportingText={companiesState.errors.validateEdit.vat ? 'Inserisci una partita IVA valida' : ''}
        // disabled={companiesState.findCompanyResponse?.active}
        onChangeText={(text) => dispatch(setEditCompanyVat(text))}
      />
      <CompanyEmails />
      <CompanyPhones />
      <NumericFormat
        prefix="€ "
        value={companiesState.editCompanyRequest?.revenue !== null ? companiesState.editCompanyRequest?.revenue : ''}
        defaultValue={companiesState.editCompanyRequest?.revenue !== null ? companiesState.editCompanyRequest?.revenue : ''}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
        label="Fatturato"
        // disabled={companiesState.findCompanyResponse?.active}
        customInput={Input}
        id={"company-to-confirm-price"}
        key={"company-to-confirm-price"}
        placeholder={"Fatturato"}
        onValueChange={(e) => {
          dispatch(setEditCompanyRevenue(e.floatValue));
        }}
        error={companiesState.editCompanyRequest?.revenue === undefined && !isInTargetList}
      />
      <NumericFormat
        value={companiesState.editCompanyRequest?.employeesNumber ?? ''}
        defaultValue={companiesState.editCompanyRequest?.employeesNumber ?? ''}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={0}
        fixedDecimalScale
        label="Numero dipendenti"
        // disabled={companiesState.findCompanyResponse?.active}
        customInput={Input}
        id={"company-to-confirm-employees"}
        key={"company-to-confirm-employees"}
        placeholder={"Numero dipendenti"}
        onValueChange={(e) => {
          dispatch(setEditCompanyEmployeesNumber(e.floatValue));
        }}
        error={companiesState.editCompanyRequest?.employeesNumber === undefined && !isInTargetList}
      />
      <div className="flex flex-col gap-[6px]">
        <SelectCustom
          label="cod. ateco"
          menuPortalTargetIsNotBody
          // disabled={companiesState.findCompanyResponse?.active}
          onChange={(e) => {
            if (typeof e === 'string' || !e)
              handleChangeAtecos(e)
            if (companiesState.editCompanyRequest.toVerifyFields !== null)
              dispatch(setEditCompanyToVerifyFields([...companiesState.editCompanyRequest.toVerifyFields.filter(field => field !== 'sector' && field !== 'category')]))
          }}
          isClearable={true}
          defaultValue={companiesState.editCompanyRequest?.atecos ? companiesState.editCompanyRequest?.atecos[0].atecoCode! : undefined}
          placeholder={"Cerca per ateco"}
          options={options.ateco}
          noOptionsMessage="Non sono presenti ateco."
          error={(companiesState.editCompanyRequest?.atecos === null || companiesState.editCompanyRequest?.atecos[0].atecoCode === null) && !isInTargetList}
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <SelectCustom
          label="settore"
          menuPortalTargetIsNotBody
          // disabled={companiesState.findCompanyResponse?.active}
          isClearable
          onChange={(e) => {
            if (typeof e === 'string' || !e)
              handleChangeSectors(e)
            if (companiesState.editCompanyRequest.toVerifyFields !== null)
              dispatch(setEditCompanyToVerifyFields([...companiesState.editCompanyRequest.toVerifyFields.filter(field => field !== 'sector')]))
          }}
          defaultValue={companiesState.editCompanyRequest?.atecos ? companiesState.editCompanyRequest?.atecos[0].sector! : undefined}
          placeholder={"Cerca per settore"}
          options={options.sector}
          noOptionsMessage="Non sono presenti settori."
          error={(companiesState.editCompanyRequest?.atecos === null || companiesState.editCompanyRequest?.atecos[0].sector === null) && !isInTargetList}
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <SelectCustom
          label="categoria"
          menuPortalTargetIsNotBody
          // disabled={companiesState.findCompanyResponse?.active}
          isClearable
          onChange={(e) => {
            if (typeof e === 'string' || !e)
              handleChangeCategories(e)
            if (companiesState.editCompanyRequest.toVerifyFields !== null)
              dispatch(setEditCompanyToVerifyFields([...companiesState.editCompanyRequest.toVerifyFields.filter(field => field !== 'category')]))
          }}
          defaultValue={companiesState.editCompanyRequest?.atecos ? companiesState.editCompanyRequest?.atecos[0].category! : undefined}
          placeholder={"Cerca per categoria"}
          options={options.category}
          noOptionsMessage="Non sono presenti categorie."
          error={(companiesState.editCompanyRequest?.atecos === null || companiesState.editCompanyRequest?.atecos[0].category === null) && !isInTargetList}
        />
      </div>
      <div>
        <Toggle
          checked={companiesState.editCompanyRequest.atecos && companiesState.editCompanyRequest.atecos[1] && companiesState.editCompanyRequest.atecos[1].atecoCode === '47.91.10'}
          onChange={function (value: boolean): void {
            const atecos = companiesState.editCompanyRequest.atecos ? [...companiesState.editCompanyRequest.atecos] : [{ atecoCode: null, sector: null, category: null, atecoDescription: null }]
            const eCommerce = atecosState.findAllAtecosResponse.find(ateco => ateco.atecoCode === '47.91.10')
            if (value) {
              atecos[1] = { atecoCode: eCommerce?.atecoCode!, sector: eCommerce?.sector!, category: eCommerce?.category! }
              dispatch(setEditCompanyAtecos([...atecos]))
            } else {
              dispatch(setEditCompanyAtecos([...atecos.filter((_, index) => index !== 1)]))
            }
          }}
          placeholder="Aggiungi categoria e-commerce"
        />
      </div>
      <Input
        label="Indirizzo"
        value={companiesState.editCompanyRequest?.address}
        onChangeText={(text) => dispatch(setEditCompanyAddress(text))}
        error={companiesState.editCompanyRequest?.address === null && !isInTargetList}
      />
      <div className="flex flex-col gap-3">
        <SelectCustom
          label="Stato"
          isClearable
          menuPortalTargetIsNotBody
          onChange={(e) => {
            //@ts-ignore
            dispatch(setEditCompanyState(e));
          }}
          noOptionsMessage="Stato non trovato"
          placeholder="Seleziona una nazione..."
          defaultValue={companiesState.editCompanyRequest.state}
          options={defaultOptions}
          error={companiesState.editCompanyRequest.state === null && !isInTargetList}
        />
        <SelectCustom
          isClearable
          label="Regione"
          menuPortalTargetIsNotBody
          onChange={(e) => {
            if (e === undefined || typeof e === "string") {
              dispatch(setEditCompanyRegion(e));
            }
          }}
          defaultValue={companiesState.editCompanyRequest.countryRegion}
          placeholder={"Seleziona una regione"}
          options={regions.map(region => ({ value: region.name, label: region.name }))}
          error={companiesState.editCompanyRequest.countryRegion === null && !isInTargetList}
        />
        {/* <SelectCustom
          isClearable
          label="Città"
          menuPortalTargetIsNotBody
          onChange={e => {
            if (e === undefined || typeof e === "string") {
              dispatch(setEditCompanyProvince(e));
            }
          }}
          defaultValue={companiesState.editCompanyRequest.province}
          placeholder={"Seleziona una città"}
          options={provinceFilters.map((province) => ({
            value: province.code,
            label: province.name,
          }))}
        /> */}
        <Input
          label="Cap"
          key={'edit-companies-form-zipcode'}
          placeholder="Inserisci il cap"
          onChangeText={(value) => {
            dispatch(setEditCompanyCap(value))
          }}
          value={companiesState.editCompanyRequest.zipCode}
          error={companiesState.editCompanyRequest.zipCode === null && !isInTargetList}

        />
        <Input
          label="Città"
          key={'edit-companies-form-city'}
          placeholder="Inserisci la città"
          onChangeText={(value) => {
            dispatch(setEditCompanyCity(value))
          }}
          value={companiesState.editCompanyRequest.city}
          error={companiesState.editCompanyRequest.city === null && !isInTargetList}
        />
      </div>
      <Input
        label="Sito web"
        endIcon={<GlobeIcon color={""} size={0} />}
        value={companiesState.editCompanyRequest?.website}
        onChangeText={(text) => dispatch(setEditCompanyWebSite(text))}
        error={companiesState.editCompanyRequest.website === null && !isInTargetList}
      />
    </div>
  );
}
