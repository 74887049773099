import clsx from "clsx";
import React, { cloneElement } from "react";
import { colors } from "../../colors";
import "./Input.css";
import { InputProps } from "./Input.types";

const Input: React.FC<InputProps> = ({
    onChange,
    ...props
}) => {

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px', width: '100%' }}>
            {props.label && <span className="input-label">{props.label}</span>}
            <div
                className="input-container"
                style={{
                    backgroundColor: props.disabled
                        ? 'rgba(242, 242, 250, 1)'
                        : 'white',
                    borderColor: props.error
                        ? 'rgba(255, 198, 194, 1)'
                        : '#ADADD6',
                }}
            >
                {props.startIcon && (
                    <div style={{ maxHeight: '20px' }}>
                        {cloneElement(props.startIcon, {
                            color: colors.neutral[500],
                            size: 16,
                        })}
                    </div>
                )}
                <input
                    type={props.type}
                    autoFocus={props.autofocus}
                    autoComplete={props.autocomplete}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    onChange={(e) => {
                        onChange ? onChange(e) : props.onChangeText && props.onChangeText(e.target.value);
                    }}
                    className={`input-value ring-0 placeholder:text-label-sm placeholder:text-neutral-400 placeholder:font-regular focus:ring-0 ${props.error ? 'input-value-error' : ''
                        }`}
                    style={{
                        border: 0,
                        outline: 0,
                        height: '20px',
                        backgroundColor: 'white',
                        width: '100%',
                    }}
                />
                {props.endIcon && (
                    <div style={{ maxHeight: '20px' }}>
                        {cloneElement(props.endIcon, {
                            color: colors.neutral[500],
                            size: 16,
                        })}
                    </div>
                )}
            </div>
            {props.supportingText && (
                <span
                    className={clsx('text-text-sm', {
                        'text-red-500': props.error,
                        'text-neutral-400': !props.error,
                    })}
                >
                    {props.supportingText}
                </span>
            )}
        </div>
    );
};

export default Input;
