import { format } from "date-fns";
import { keycloak } from "../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { PillsColor } from "../../ui/molecules/pills/Pills.types";
import { Dropdown } from "../../ui/organisms/dropdown";
import { CustomTable } from "../../ui/organisms/table";
import { ProjectStateEnum } from "./dto";
import { setProjectFilterOrder, setProjectFilterSort, setSelectedProject } from "./slice";

interface Props {
    setIdToDelete: (arg?: string) => void
}

export function ProjectTable(props: Props) {
    const { setIdToDelete } = props
    const projectsState = useAppSelector(state => state.projects)
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()

    const projectStateMap = new Map<
        ProjectStateEnum,
        { label: string; color: PillsColor }
    >([
        [ProjectStateEnum.TOSTART, { label: "Da iniziare", color: "gray" }],
        [ProjectStateEnum.CONCLUDED, { label: "Concluso", color: "green" }],
        [ProjectStateEnum.INPROGRESS, { label: "In corso", color: "blue" }],
        [ProjectStateEnum.SUSPENDED, { label: "Sospeso", color: "orange" }],
    ]);

    return (
        <CustomTable
            tableHeight='calc(100% - 48px)'
            prevOrderHeads={[
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Nome progetto
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("name"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Settore
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("customer.companySector"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Team Leader
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("teamLeader.name"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-center">
                    <div className="flex gap-[4px]">
                        Commerciali
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("commercials.name"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Data inizio
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("startDate"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Data fine
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("endDate"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Stato
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("state"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Liste target
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("targetListSize"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Contatti
                        <div
                            onClick={() => {
                                dispatch(setProjectFilterSort("contactsNumber"));
                                dispatch(
                                    setProjectFilterOrder(!projectsState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px] w-[20px]" />
                </th>
            ]}
            postOrderHeads={[]}
            preferences={[]}
            setPreferences={(items) => { }}
            setOrderFilters={(order) => { }}
            rows={projectsState.findAllResponse?.data.map(d => ({
                key: d.id, data: [
                    {
                        item: <td
                            onClick={() => dispatch(setSelectedProject(d.id))}
                            className="max-w-[200px] cursor-pointer crop-text text-text-sm font-semibold text-neutral-600">
                            {d.name}
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },
                    {
                        item: <td className="max-w-[200px]">
                            {d.sector ? (
                                <Pills
                                    size={"xs"}
                                    label={d.sector}
                                    outline={false}
                                    emphasis={false}
                                    color={"blue"}
                                />
                            ) : (
                                ""
                            )}
                        </td>,
                        group: 'preSort',
                        key: 'Nome referente'
                    },
                    {
                        item: <td>
                            <div className="flex items-center gap-2">
                                <Avatar
                                    type="single"
                                    size="sm"
                                    shape="circle"
                                    imageUrl={
                                        usersState.findUsersAvatarsListResponse.find(
                                            (avatar) => avatar.userId === d.teamLeader.id
                                        )?.objectId ?? ""
                                    }
                                    fullName={d.teamLeader.name + " " +
                                        d.teamLeader.surname}
                                    altTextInitials={
                                        d.teamLeader.name[0] +
                                        d.teamLeader.surname[0]
                                    }
                                />
                                {d.teamLeader.name + " " + d.teamLeader.surname}
                            </div>
                        </td>,
                        group: 'preSort',
                        key: 'Cognome referente'
                    },
                    {
                        item: <td>
                            <Avatar
                                type="group"
                                size="sm"
                                length={2}
                                imageUrls={d.commercials.map((commercial) => ({
                                    altTextInitials:
                                        commercial.name[0] + commercial.surname[0],
                                    fullNames: (commercial.name + " " + commercial.surname),
                                    url:
                                        usersState.findUsersAvatarsListResponse.find(
                                            (avatar) => avatar.userId === commercial.id
                                        )?.objectId ?? "ciaoooo",
                                }))}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Linkedin'
                    },
                    {
                        item: <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                            {d.startDate
                                ? format(new Date(d.startDate), "dd/MM/yyyy")
                                : ""}
                        </td>,
                        group: 'preSort',
                        key: 'Ruolo'
                    },
                    {
                        item: <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                            {d.endDate
                                ? format(new Date(d.endDate), "dd/MM/yyyy")
                                : ""}
                        </td>,
                        group: 'preSort',
                        key: 'Data di contatto'
                    },
                    {
                        item: <td>
                            <Pills
                                size={"xs"}
                                label={projectStateMap.get(d.state)?.label!}
                                outline={false}
                                emphasis={false}
                                color={projectStateMap.get(d.state)?.color!}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Esito'
                    },
                    {
                        item: <td>
                            <Pills
                                size={"xs"}
                                label={d.targetListsIds?.length.toString() ?? "0"}
                                outline={false}
                                emphasis={false}
                                color={"blue"}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Note'
                    },
                    {
                        item: <td>
                            <Pills
                                size={"xs"}
                                label={d.contactsNumber?.toString() ?? "0"}
                                outline={false}
                                emphasis={false}
                                color={"blue"}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Smartphone'
                    },
                    {
                        item: <td className="w-[20px] cursor-pointer">
                            <Dropdown
                                items={[
                                    {
                                        label: "Modifica",
                                        icon: <EditIcon color={""} size={0} />,
                                        onClick: () => dispatch(setSelectedProject(d.id)),
                                    },
                                    (keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator') || !usersState.findMeResponse?.isAutonomous || usersState.findMeResponse?.isAutonomous !== null ? null : {
                                        label: "Cancella",
                                        icon: <TrashIcon color={""} size={0} />,
                                        onClick: () => setIdToDelete(d.id),
                                    }),
                                ]}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Email'
                    },
                ]
            })) ?? []}
        />
    )
}