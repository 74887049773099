import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { MenuKebabIcon } from "../../icons/menuKebab";
import { DropdownProps } from "./Dropdown.types";
import DropdownContainer from "./DropdownContainer";

export function Dropdown(props: DropdownProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [shouldOpenUpward, setShouldOpenUpward] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node) &&
            !buttonRef.current?.contains(event.target as Node)
        ) {
            closeMenu();
        }
    };

    const calculatePosition = () => {
        if (buttonRef.current && menuRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect();
            const menuHeight = menuRef.current.offsetHeight;
            const menuWidth = menuRef.current.offsetWidth;
            const spaceBelow = window.innerHeight - buttonRect.bottom;
            const spaceAbove = buttonRect.top;

            if (spaceBelow < menuHeight && spaceAbove >= menuHeight) {
                setShouldOpenUpward(true);
                setMenuPosition({
                    top: buttonRect.top + window.scrollY - menuHeight - 10,
                    left: buttonRect.right + window.scrollX - menuWidth - 20,
                });
            } else {
                setShouldOpenUpward(false);
                setMenuPosition({
                    top: buttonRect.bottom + window.scrollY + 10,
                    left: buttonRect.right + window.scrollX - menuWidth - 20,
                });
            }
        }
    };

    function hasOverflow(element: any) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }

    useEffect(() => {
        if (isMenuOpen) {
            const timeout = setTimeout(() => {
                calculatePosition();
            }, 0);

            const parentTable = buttonRef.current?.closest("table")?.parentElement;
            if (parentTable) {
                parentTable.className = parentTable.className.replace('overflow-auto', "overflow-hidden");
                if (hasOverflow(parentTable)) {
                    parentTable.style.paddingRight = "6px";
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                clearTimeout(timeout);
                document.removeEventListener("mousedown", handleClickOutside);
                if (parentTable) {
                    parentTable.className = parentTable.className.replace('overflow-hidden', "overflow-auto");
                    parentTable.style.paddingRight = "0px";
                }
            };
        }
    }, [isMenuOpen]);

    const menu = isMenuOpen ? (
        <div
            ref={menuRef}
            className={`absolute z-50 ${shouldOpenUpward ? "menu-upward" : "menu-downward"
                }`}
            style={{
                top: menuPosition.top,
                left: menuPosition.left,
                minWidth: buttonRef.current?.offsetWidth || "auto",
            }}
        >
            <DropdownContainer
                onClose={closeMenu}
                items={props.items}
            />
        </div>
    ) : null;

    return (
        <div className="relative">
            <button
                onClick={toggleMenu}
                className="focus:outline-none"
                aria-label="Toggle menu"
                ref={buttonRef}
            >
                <MenuKebabIcon />
            </button>
            {ReactDOM.createPortal(menu, document.body)}
        </div>
    );
}
