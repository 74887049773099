import { BuildingIcon } from "../../../ui/icons/building"
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { useAppSelector } from "../../redux/hooks"
import { ContactType, FindAllContactsFilters } from "../dto"
import { NewContactsListService } from "../service"

interface Props {
    isInTargetList: boolean
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function CompanyFilter(props: Props) {
    const { updateFilters, filters, isInTargetList } = props
    const targetListState = useAppSelector(state => state.targetList)

    const filterCompaniesTarget = async (inputValue: string) => {
        return await NewContactsListService().findContactsTarget({
            order: true,
            sort: 'name',
            itemsPerPage: 25,
            page: 0,
            companyName: [{ label: encodeURIComponent(inputValue), value: encodeURIComponent(inputValue) }],
            targetList: isInTargetList ? [{ value: targetListState.findTargetListResponse?.id!, label: targetListState.findTargetListResponse?.name! }] : [],
            outcome: [],
            outcomeInfo: [],
            companySector: [],
            companyCategory: [],
            companyRegion: [],
            companyProvince: [],
            personDepartment: [],
            personRole: [],
            particularity: [],
            project: [],
            missingFields: []
        }, ContactType.COMPANY).then(data => data.data.map(_value => ({ label: _value.contact.name, value: _value.contact.id })));
    };

    const filterCompaniesDatabase = async (inputValue: string) => {
        return await NewContactsListService().findContactsDatabase({
            order: true,
            sort: 'name',
            itemsPerPage: 25,
            page: 0,
            companyName: [{ label: encodeURIComponent(inputValue), value: encodeURIComponent(inputValue) }],
            targetList: isInTargetList ? [{ value: targetListState.findTargetListResponse?.id!, label: targetListState.findTargetListResponse?.name! }] : [],
            outcome: [],
            outcomeInfo: [],
            companySector: [],
            companyCategory: [],
            companyRegion: [],
            companyProvince: [],
            personDepartment: [],
            personRole: [],
            particularity: [],
            project: [],
            missingFields: []
        }, ContactType.COMPANY).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
    };

    const promiseOptions = (inputValue: string) =>
        new Promise<{ value: string; label: string; }[]>((resolve) => {
            if (isInTargetList)
                resolve(filterCompaniesTarget(inputValue));
            else
                resolve(filterCompaniesDatabase(inputValue));
        });

    return (
        <AccordionFilter
            key={'person-filter-company'}
            label={"Azienda"}
            icon={<BuildingIcon color={""} size={0} />}
            showClear={filters.companyName.length > 0}
            options={filters.companyName}
            clearAction={function (): void {
                updateFilters({ companyName: [] })
            }}
            deleteFilter={function (option): void {
                updateFilters({ companyName: filters.companyName.filter(name => name.value !== option.value) })
            }}
        >
            <SelectCustomAsync
                isMulti
                //@ts-ignore
                onChange={e => updateFilters({ companyName: e })}
                defaultValue={filters.companyName}
                noOptionsMessage="Non sono presenti aziende."
                loadOptions={promiseOptions}
                defaultOptions
                isClearable={false}
                placeholder={"Cerca per ragione sociale"}
            />
        </AccordionFilter>
    )
}