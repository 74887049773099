import React, { useEffect } from "react";
import { keycloak } from "../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { SelectCustomAsync } from "../../../ui/molecules/asyncSelect";
import { NewParticularitiesService } from "../../particularities/service";
import { setEditParticularitiesDefaultValue, setEditTargetListRequestParticularity } from "../slice";

interface OptionType {
  value: string;
  label: string;
}

const SelectParticularities: React.FC = () => {
  const usersState = useAppSelector(state => state.users)
  const contactsState = useAppSelector(state => state.contacts)

  const filterColors = async (inputValue: string) => {
    return await NewParticularitiesService().findAllParticularities({
      itemsPerPage: 25,
      page: 0,
      name: inputValue,
    }).then(data => data.data.map(_value => ({ label: _value.name, value: _value.id })));
  };

  const promiseOptions = (inputValue: string) =>
    new Promise<{ value: string; label: string; }[]>((resolve) => {
      resolve(filterColors(inputValue));
    });

  const dispatch = useAppDispatch()

  const targetListState = useAppSelector(state => state.targetList)

  const particularitiesState = useAppSelector(state => state.particularities)

  useEffect(() => {
    if (targetListState.findTargetListStatus === 'successfully') {
      dispatch(setEditParticularitiesDefaultValue(targetListState.findTargetListResponse?.particularity ? { label: targetListState.findTargetListResponse?.particularity?.name!, value: targetListState.findTargetListResponse?.particularity?.id! } : undefined))
    }
  }, [targetListState.findTargetListStatus])

  useEffect(() => {
    if (particularitiesState.createParticularityStatus === 'successfully') {
      promiseOptions('')
        .then(particularities => {
          const newParticularity = particularities.find(value => value.value === particularitiesState.createParticularityResponse)
          dispatch(setEditParticularitiesDefaultValue(newParticularity))
          dispatch(setEditTargetListRequestParticularity(particularitiesState.createParticularityResponse))
        })
    }
  }, [particularitiesState.createParticularityStatus])

  const disabled = contactsState.getCounterResponse && contactsState.getCounterResponse.some(section => (section.dto.inList.total + section.dto.targetListBin.total) > 0) || (!usersState.findMeResponse?.isAutonomous && keycloak.hasRealmRole("commercial"))

  return (
    <div>
      <SelectCustomAsync
        disabled={disabled}
        label="Particolarità"
        loadOptions={promiseOptions}
        defaultOptions
        defaultValue={targetListState.particularities}
        isClearable
        onChange={(e) => {
          dispatch(
            //@ts-ignore
            setEditTargetListRequestParticularity(e ? e.value : undefined)
          );
          //@ts-ignore
          dispatch(setEditParticularitiesDefaultValue(e))
        }}
        placeholder="Indifferente"
        noOptionsMessage="Non è stata trovata nessuna particolarità"
        error={false}
        errorLabel="Errore durante la selezione"
      />
    </div>
  );
};

export default SelectParticularities;
