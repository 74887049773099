import { useEffect } from "react";
import { FindAllContactsFilters } from "../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { BannersList } from "../../ui/organisms/banner/bannerList";
import { findCompany, setDeleteBulkCompaniesStatus, setDeleteCompanyStatus, setEditCompanyStatus, setSelectedIdsCompanies, setShowPopupCompanies } from "./companies/slice";
import { setOtpRequestStatus } from "./people/privacy/slice";
import { findPerson, setAssociatePersonToCompanyStatus, setDeleteBulkPeopleStatus, setDeletePersonStatus, setEditPersonStatus, setNewPersonResponse, setNewPersonStatus, setPersonToAddCompany, setSelectedIdsPeople, setShowPopupPeople } from "./people/slice";
import { setBulkDeleteModal } from "./slice";

interface Props {
    reloadPeople: () => void
    reloadCompanies: () => void
    companiesResetFilters: () => void
    peopleResetFilters: () => void
}
export function BannerDatabase(props: Props) {
    const { peopleResetFilters, reloadPeople, reloadCompanies, companiesResetFilters } = props
    const companiesState = useAppSelector(state => state.companies)
    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)

    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.associatePersonToCompanyStatus === "failed" ||
            peopleState.associatePersonToCompanyStatus === "successfully"
        ) {
            if (peopleState.associatePersonToCompanyStatus === "successfully") {
                dispatch(setPersonToAddCompany(undefined));
                reloadPeople()
                if (peopleState.selectedPerson !== undefined) {
                    dispatch(findPerson(peopleState.findPersonResponse?.id!))
                }
            }
            timeoutId = setTimeout(() => {
                dispatch(setAssociatePersonToCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.associatePersonToCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.editCompanyStatus === "failed" ||
            companiesState.editCompanyStatus === "successfully"
        ) {
            if (companiesState.editCompanyStatus === "successfully") {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.editPersonStatus === "failed" ||
            peopleState.editPersonStatus === "successfully"
        ) {
            if (peopleState.editPersonStatus === "successfully") {
                dispatch(findPerson(peopleState.findPersonResponse?.id!))
                reloadPeople()
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteCompanyStatus === "failed" ||
            companiesState.deleteCompanyStatus === "successfully"
        ) {
            dispatch(setSelectedIdsCompanies([]))
            dispatch(setShowPopupCompanies(false))
            if (companiesState.deleteCompanyStatus === "successfully") {
                reloadCompanies()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteCompanyStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            companiesState.deleteBulkStatus === "failed" ||
            companiesState.deleteBulkStatus === "successfully"
        ) {
            dispatch(setBulkDeleteModal(false))
            if (companiesState.deleteBulkStatus === "successfully") {
                companiesResetFilters()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteBulkCompaniesStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deleteBulkStatus === "failed" ||
            peopleState.deleteBulkStatus === "successfully"
        ) {
            dispatch(setBulkDeleteModal(false))
            if (peopleState.deleteBulkStatus === "successfully") {
                peopleResetFilters()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteBulkPeopleStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deleteBulkStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.deletePersonStatus === "failed" ||
            peopleState.deletePersonStatus === "successfully"
        ) {
            dispatch(setSelectedIdsPeople([]))
            dispatch(setShowPopupPeople(false))
            if (peopleState.deletePersonStatus === "successfully") {
                reloadPeople()
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus("idle"));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (
            peopleState.newPersonStatus === "failed" ||
            peopleState.newPersonStatus === "successfully"
        ) {
            if (peopleState.newPersonStatus === "successfully" && !peopleState.newPersonResponse?.alreadyExists) {
                reloadCompanies()
                dispatch(findCompany(companiesState.findCompanyResponse?.id!))
            }
            timeoutId = setTimeout(() => {
                dispatch(setNewPersonStatus("idle"));
                dispatch(setNewPersonResponse(undefined));
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.newPersonStatus]);

    return (
        <BannersList banners={[
            {
                label: "Si è verificato un errore durante l'eliminazione delle aziende.",
                visible: companiesState.deleteBulkStatus === "failed",
                closeAction: () => dispatch(setDeleteBulkCompaniesStatus("idle")),
                type: "error",
            },
            {
                label: "Aziende eliminate.",
                visible: companiesState.deleteBulkStatus === "successfully",
                closeAction: () => dispatch(setDeleteBulkCompaniesStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante l'eliminazione delle persone.",
                visible: peopleState.deleteBulkStatus === "failed",
                closeAction: () => dispatch(setDeleteBulkPeopleStatus("idle")),
                type: "error",
            },
            {
                label: "Persone eliminate.",
                visible: peopleState.deleteBulkStatus === "successfully",
                closeAction: () => dispatch(setDeleteBulkPeopleStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante l'invio dell'OTP.",
                visible: otpState.otpRequestStatus === "failed",
                closeAction: () => dispatch(setOtpRequestStatus("idle")),
                type: "error",
            },
            {
                label: "Persona associata correttamente all'azienda.",
                visible: peopleState.associatePersonToCompanyStatus === "successfully",
                closeAction: () => dispatch(setAssociatePersonToCompanyStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante l'associazione della persona all'azienda.",
                visible: peopleState.associatePersonToCompanyStatus === "failed",
                closeAction: () => dispatch(setAssociatePersonToCompanyStatus("idle")),
                type: "error",
            },
            {
                label: "Contatto aggiunto correttamente",
                visible:
                    peopleState.newPersonStatus === "successfully" &&
                    !peopleState.newPersonResponse?.alreadyExists,
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                },
                type: "success",
            },
            {
                label:
                    "Il contatto che stai provando ad inserire esiste ed è associato all'azienda",
                visible:
                    peopleState.newPersonStatus === "successfully" &&
                    peopleState.newPersonResponse?.alreadyExists === true,
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                },
                type: "warning",
            },
            {
                label: peopleState.newPersonResponseMessage,
                visible: peopleState.newPersonStatus === "failed",
                closeAction: () => {
                    dispatch(setNewPersonStatus("idle"));
                    dispatch(setNewPersonResponse(undefined));
                },
                type: "error",
            },
            {
                label: "Persona eliminata.",
                visible: peopleState.deletePersonStatus === "successfully",
                closeAction: () => dispatch(setDeletePersonStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante l'eliminazione della persona.",
                visible: peopleState.deletePersonStatus === "failed",
                closeAction: () => dispatch(setDeletePersonStatus("idle")),
                type: "error",
            },
            {
                label: "Azienda eliminata.",
                visible: companiesState.deleteCompanyStatus === "successfully",
                closeAction: () => dispatch(setDeleteCompanyStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante l'eliminazione dell'azienda.",
                visible: companiesState.deleteCompanyStatus === "failed",
                closeAction: () => dispatch(setDeleteCompanyStatus("idle")),
                type: "error",
            },
            {
                label: "Persona aggiornata.",
                visible: peopleState.editPersonStatus === "successfully",
                closeAction: () => dispatch(setEditPersonStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante la modifica della persona.",
                visible: peopleState.editPersonStatus === "failed",
                closeAction: () => dispatch(setEditPersonStatus("idle")),
                type: "error",
            },
            {
                label: "Azienda aggiornata.",
                visible: companiesState.editCompanyStatus === "successfully",
                closeAction: () => dispatch(setEditCompanyStatus("idle")),
                type: "success",
            },
            {
                label: "Si è verificato un errore durante la modifica dell'azienda.",
                visible: companiesState.editCompanyStatus === "failed",
                closeAction: () => dispatch(setEditCompanyStatus("idle")),
                type: "error",
            },
        ]} />
    )
}