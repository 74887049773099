import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../lib/filters/dto";
import { useContacts } from "../../lib/hooks/contacts/contacts";
import { Preferences } from "../../lib/preferences";
import { PreferencesSection } from "../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Area } from "../../lib/utils";
import { BuildingIcon } from "../../ui/icons/building";
import { DownloadIcon } from "../../ui/icons/download";
import { UsersIcon } from "../../ui/icons/users";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { TabMenu } from "../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../ui/organisms/tabs/tabPages";
import { EditCompany } from "../database/companies/editCompany/index";
import { Companies } from "../database/companies/list";
import { getCSVCompanies, setEmployeeToDelete, setFindEmployeesResponse, setFindEmployeesStatus, setSelectedCompany, setSelectedIdsCompanies } from "../database/companies/slice";
import { EditPerson } from "../database/people/editPerson";
import { People } from "../database/people/list";
import { deletePerson, getCSVPeople, setSelectedIdsPeople, setSelectedPerson } from "../database/people/slice";
import { setTabValue } from "../database/slice";
import { setSelectedContact } from "../targetList/contacts/slice";
import { BannerBlackList } from "./banner";

export function BlackList() {
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.people)
    const companiesState = useAppSelector(state => state.companies)

    const peopleFilters = useContacts(Area.DATABASE, 'black-list', ContactType.PERSON)
    const companiesFilters = useContacts(Area.DATABASE, 'black-list', ContactType.COMPANY)

    const [direction, setDirection] = useState<'left' | 'right'>("left")

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(setSelectedIdsPeople([]))
        dispatch(setSelectedIdsCompanies([]))
    }, []);

    useEffect(() => {
        dispatch(setFindEmployeesResponse([]));
        dispatch(setFindEmployeesStatus("idle"));
        dispatch(setSelectedCompany(undefined))
        dispatch(setSelectedContact(undefined))
        dispatch(setSelectedPerson(undefined))
    }, [databaseState.tabValue]);

    useEffect(() => {
        if (window.location.pathname.includes("people")) {
            dispatch(setTabValue(0));
        }
        if (window.location.pathname.includes("companies")) {
            dispatch(setTabValue(1));
        }
    }, []);

    useEffect(() => {
        if (databaseState.tabValue === 0) {
            navigate(window.location.pathname.replace(/\/specifics|\/companies/, "/people"));
        }
        if (databaseState.tabValue === 1) {
            navigate(window.location.pathname.replace(/\/specifics|\/people/, "/companies"));
        }
    }, [databaseState.tabValue]);

    return (
        <Layout
            headerLabel={"Lista nera"}
            breadcrumbItems={['Lista Nera']}
            menuItem={MenuItems.BLACK_LIST}
            headerChildren={
                <div className="flex justify-between items-center">
                    <TabMenu
                        direction={direction}
                        setDirection={(value) => setDirection(value)}
                        setValue={(e) => {
                            dispatch(setTabValue(e));
                        }}
                        value={databaseState.tabValue}
                        items={[
                            {
                                icon: <UsersIcon color={""} size={0} />,
                                label: "Persone",
                            },
                            {
                                icon: <BuildingIcon color={""} size={0} />,
                                label: "Aziende",
                            },
                        ]}
                    />
                    <div className="flex gap-2">
                        {peopleState.selectedIds.length > 0 && databaseState.tabValue === 0 && (
                            <>
                                <Button
                                    size={"sm"}
                                    iconPosition={"left"}
                                    label="Esporta"
                                    variant={"outline"}
                                    color={"blue"}
                                    icon={<DownloadIcon color={""} size={0} />}
                                    onClick={() => dispatch(getCSVPeople(peopleState.selectedIds))}

                                />
                            </>
                        )}
                        {companiesState.selectedIds.length > 0 && databaseState.tabValue === 1 && (
                            <>
                                <Button
                                    size={"sm"}
                                    iconPosition={"left"}
                                    label="Esporta"
                                    variant={"outline"}
                                    color={"blue"}
                                    icon={<DownloadIcon color={""} size={0} />}
                                    onClick={() => dispatch(getCSVCompanies(companiesState.selectedIds))}

                                />
                            </>
                        )}
                    </div>
                </div>
            }
        >
            <div className="flex max-w-full w-full max-h-full h-full overflow-hidden p-4">
                <BannerBlackList companiesFilters={companiesFilters.filters} reloadCompanies={() => companiesFilters.findContacts()} peopleFilters={peopleFilters.filters} reloadPeople={() => peopleFilters.findContacts()} />
                <div className="flex max-w-full w-full h-full rounded-xl flex-col relative rounded-t-xl bg-white shadow-sm ">
                    <div className="max-h-full max-w-full h-full rounded-xl">
                        <TabPages
                            direction={direction}
                            setDirection={(value) => setDirection(value)}
                            pages={[
                                <People key={0}
                                    resetFilters={function (): void {
                                        peopleFilters.resetAllFilters();
                                    }}
                                    filters={peopleFilters.filters}
                                    updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                                        peopleFilters.updateFilters(newFilters);
                                    }}
                                    setPage={function (page: number): void {
                                        peopleFilters.changePage(page);
                                    }}
                                    status={peopleFilters.status}
                                    contacts={peopleFilters.contacts as unknown as FindContactsDatabaseResponse<ContactType.PERSON>}
                                />,
                                <Companies
                                    key={1}
                                    resetFilters={function (): void {
                                        companiesFilters.resetAllFilters()
                                    }}
                                    filters={companiesFilters.filters}
                                    updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                                        companiesFilters.updateFilters(newFilters)
                                    }} setPage={function (page: number): void {
                                        companiesFilters.changePage(page)
                                    }}
                                    status={companiesFilters.status}
                                    contacts={companiesFilters.contacts as unknown as FindContactsDatabaseResponse<ContactType.COMPANY>} />
                            ]}
                            value={databaseState.tabValue}
                            setValue={(e) => {
                                dispatch(setTabValue(e));
                                dispatch(setSelectedContact(undefined))
                                dispatch(setSelectedCompany(undefined));
                                dispatch(setSelectedPerson(undefined));
                            }}
                        />
                    </div>
                </div>
                <Preferences section={databaseState.tabValue === 0 ? PreferencesSection.personPreferences : PreferencesSection.companyPreferences} key={databaseState.tabValue === 0 ? PreferencesSection.personPreferences : PreferencesSection.companyPreferences} />
                <div
                    className={clsx(
                        "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all h-full",
                        {
                            hidden: peopleState.selectedPerson === undefined,
                        }
                    )}
                >
                    <div className="bg-white shadow-md h-full">
                        <EditPerson isInTargetList={false} reloadContacts={() => peopleFilters.findContacts()} />
                    </div>
                </div>
                <div
                    className={clsx(
                        "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all h-full",
                        {
                            hidden: companiesState.selectedCompany === undefined,
                        }
                    )}
                >
                    <div className="bg-white shadow-md h-full">
                        <EditCompany isInTargetList={false} reloadContacts={() => companiesFilters.findContacts()} />
                    </div>
                </div>
            </div>
            <ConfirmModal
                title={"Elimina persona"}
                label={"Sei sicuro di voler eliminare questa persona?"}
                confirmAction={
                    () => dispatch(deletePerson([companiesState.employeeToDelete!]))
                }
                onClose={() => dispatch(setEmployeeToDelete(undefined))}
                visible={companiesState.employeeToDelete !== undefined}
            />
        </Layout>
    )
}