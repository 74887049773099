export interface EventsFiltersDTO {
    page: number;
    pageSize: number;
    sort: string;
    sortBy: string;
    fromDateTime: string | null;
    toDateTime: string | null;
    contactId?: string;
}

export interface EventDTO {
    contact: string
    contactId: string
    createdAt: Date
    startDate: Date
    title: string
    endDate: Date
    deletedAt: Date
    id: string
    labels: []
    notes: string
    notification: number
    projectId: string
    status: number
    targetListId: string
    type: number
    updatedAt: Date
    userID: string
}

export interface EventResponseDTO {
    data: EventDTO[],
    paginationData: { total: number, page: number, perPage: number, prev: number, next: number, totalPage: number }
}

export interface CreateEventDTO {
    contactId: string,
    startDate: string,
    endDate: string,
    title: string,
    labels: string[],
    notes: string,
    notification: number,
    projectId: string,
    targetListId: string,
    userID: string,
    type: EventType
}

export enum EventStatus {
    IDLE,
    CANCELED,
    COMPLETED
}

export enum EventType {
    CALL,
    MEETING,
}