import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { findProjectById } from "../../../projects/slice";
import { findTargetListById, setTargetListPage } from "../../slice";
import { ImportPersonDTO, TargetListPageEnum } from "../../dto";
import { ArrowRightIcon } from "../../../../ui/icons/arrowRight";
import { colors } from "../../../../ui/colors";
import { Spinner } from "../../../../ui/molecules/spinner";
import Button from "../../../../ui/molecules/button";
import { importContactCSV } from "../slice";
import { ContactType } from "../../../../lib/filters/dto";
import { SelectCustom } from "../../../../ui/molecules/select";
import { UploadIcon } from "../../../../ui/icons/upload";

interface Props {
  step: number;
  setStep: (value: number) => void;
  name: string;
  delimiter: string;
  fields: string[];
  file: File | null;
  rows: Record<string, any>[];
}
export function AssociatePeopleForCSV(props: Props) {
  const dispatch = useAppDispatch();
  const targetListState = useAppSelector((state) => state.targetList);
  const contactsState = useAppSelector(state => state.contacts)


  useEffect(() => {
    if (
      contactsState.importContactStatus === "failed" ||
      contactsState.importContactStatus === "successfully"
    ) {
      if (!window.location.pathname.includes('edit-project'))
        dispatch(
          findProjectById(targetListState.findTargetListResponse?.projectId!)
        );
      dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      dispatch(setTargetListPage(TargetListPageEnum.LIST));
    }
  }, [contactsState.importContactStatus]);

  const [headers, setHeaders] = useState<ImportPersonDTO>({
    name: [],
    surname: [],
    role: [],
    department: [],
    phone: [],
    linkedinProfile: [],
    privacy: [],
    companyName: [],
    notes: [],
    lastContactDate: [],
    email: [],
    companyPhone: [],
    companyVat: [],
    contactState: [],
  });

  useEffect(() => {
    const newHeaders = { ...headers };

    const name = props.fields.find((value) => value.toLowerCase() === "nome");
    if (name) {
      newHeaders.name = [name];
    }
    const surname = props.fields.find(
      (value) => value.toLowerCase() === "cognome"
    );
    if (surname) {
      newHeaders.surname = [surname];
    }
    const role = props.fields.find((value) => value.toLowerCase() === "ruolo");
    if (role) {
      newHeaders.role = [role];
    }
    const department = props.fields.find(
      (value) => value.toLowerCase() === "reparto"
    );
    if (department) {
      newHeaders.department = [department];
    }
    const phone = props.fields.find(
      (value) => value.toLowerCase() === "smartphone"
    );
    if (phone) {
      newHeaders.phone = [phone];
    }
    const email = props.fields.find((value) => value.toLowerCase() === "email");
    if (email) {
      newHeaders.email = [email];
    }
    const linkedinProfile = props.fields.find(
      (value) => value.toLowerCase() === "profilo linkedin"
    );
    if (linkedinProfile) {
      newHeaders.linkedinProfile = [linkedinProfile];
    }
    const privacy = props.fields.find(
      (value) => value.toLowerCase() === "privacy"
    );
    if (privacy) {
      newHeaders.privacy = [privacy];
    }
    const companyName = props.fields.find(
      (value) => value.toLowerCase() === "nome azienda"
    );
    if (companyName) {
      newHeaders.companyName = [companyName];
    }
    const companyVat = props.fields.find(
      (value) => value.toLowerCase() === "p.iva azienda"
    );
    if (companyVat) {
      newHeaders.companyVat = [companyVat];
    }
    const companyPhone = props.fields.find(
      (value) => value.toLowerCase() === "telefono azienda"
    );
    if (companyPhone) {
      newHeaders.companyPhone = [companyPhone];
    }
    const notes = props.fields.find(
      (value) => value.toLowerCase() === "telefono azienda"
    );
    if (notes) {
      newHeaders.notes = [notes];
    }
    const lastContactDate = props.fields.find(
      (value) => value.toLowerCase() === "telefono azienda"
    );
    if (lastContactDate) {
      newHeaders.lastContactDate = [lastContactDate];
    }
    const contactState = props.fields.find(
      (value) => value.toLowerCase() === "telefono azienda"
    );
    if (contactState) {
      newHeaders.contactState = [contactState];
    }
    setHeaders(newHeaders);
  }, [props.fields]);

  const selectedFields = Object.values(headers)
    .flat()
    .filter((field) => field);

  const getFilteredOptions = (currentHeaderKey: keyof ImportPersonDTO) => {
    const selectedHeaderFields = headers[currentHeaderKey];

    return props.fields
      .filter(
        (field) =>
          !selectedFields.includes(field) ||
          selectedHeaderFields.includes(field)
      )
      .map((field) => ({
        label: field,
        value: field,
      }));
  };
  const formData = new FormData();

  return (
    <div className="h-full max-h-full overflow-y-auto overflow-x-hidden">
      <div className="p-4 rounded-t-2xl bg-brandPrimary-50 border-y border-y-neutral-100 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
            onClick={() => dispatch(setTargetListPage(TargetListPageEnum.ADD))}
          >
            Seleziona il tipo di importazione
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span
            onClick={() => props.setStep(0)}
            className="text-text-md font-bold text-neutral-400 cursor-pointer"
          >
            Aggiungi da file CSV
          </span>
          <ArrowRightIcon color={colors.neutral[500]} size={24} />
          <span className="text-text-md font-bold text-neutral-800">
            Mappatura campi
          </span>
        </div>
        {contactsState.importContactStatus === "loading" ? (
          <Spinner />
        ) : (
          <Button
            onClick={() => {
              const filteredHeaders = Object.fromEntries(
                Object.entries(headers).filter(
                  ([key, value]) => Array.isArray(value) && value.length > 0
                )
              );
              formData.delete("targetListId");
              formData.delete("csv");
              formData.delete("headers");
              formData.delete("delimiter");

              formData.append("targetListId", targetListState.findTargetListResponse?.id!);
              if (props.file !== null) formData.append("csv", props.file);
              formData.append("headers", JSON.stringify(filteredHeaders));
              formData.append("delimiter", props.delimiter);
              dispatch(
                importContactCSV({
                  data: formData,
                  id: targetListState.findTargetListResponse?.id!,
                  contactType: ContactType.PERSON
                })
              );
            }}
            disabled={!props.file}
            size={"sm"}
            iconPosition={"right"}
            variant={"outline"}
            color={"blue"}
            label="Importa contatti"
            icon={<UploadIcon color={""} size={0} />}
          />
        )}
      </div>
      <div className="p-4">
        <span className="text-text-md font-bold text-neutral-800">
          Anteprima file CSV
        </span>
      </div>
      <div className="sticky top-0 z-10 bg-white overflow-auto">
        <table className="w-full bg-white" align="left" cellPadding={10}>
          <thead>
            <tr className="bg-neutral-100">
              {props.fields.map((field) => (
                <th
                  align="left"
                  className="first:pl-4 last:pr-4 h-10 text-label-sm font-semibold text-neutral-800"
                >
                  {field}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.rows.slice(0, 3).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {props.fields.map((field) => (
                  <td
                    key={field}
                    className="first:pl-4 last:pr-4 h-10 text-label-sm text-neutral-700"
                  >
                    {row[field] || "-"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-brandPrimary-100 p-4 gap-6 flex flex-col items-start">
        <span className="text-text-md font-bold text-neutral-800">
          Mappa campi CRM
        </span>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.name[0]}
            isClearable
            label="Nome"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.name = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.name = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il nome"}
            options={getFilteredOptions("name")}
          />
          <SelectCustom
            defaultValue={headers.surname[0]}
            isClearable
            label="Cognome"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.surname = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.surname = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il cognome"}
            options={getFilteredOptions("surname")}
          />
          <SelectCustom
            defaultValue={headers.role[0]}
            isClearable
            label="Ruolo"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.role = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.role = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il ruolo"}
            options={getFilteredOptions("role")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.department[0]}
            isClearable
            label="Reparto"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.department = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.department = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il reparto"}
            options={getFilteredOptions("department")}
          />
          <SelectCustom
            defaultValue={headers.phone[0]}
            isClearable
            label="Smartphone"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.phone = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.phone = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il telefono"}
            options={getFilteredOptions("phone")}
          />
          <SelectCustom
            defaultValue={headers.email}
            isClearable
            label="Email"
            isMulti
            onChange={(e) => {
              if (e !== undefined && typeof e !== 'string') {
                const newHeaders = { ...headers };
                newHeaders.email = e;
                setHeaders(newHeaders)
              }
            }}
            placeholder={"Seleziona campo per l'email"}
            options={getFilteredOptions("email")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.linkedinProfile[0]}
            isClearable
            label="Profilo linkedin"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.linkedinProfile = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.linkedinProfile = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il profilo linkedin"}
            options={getFilteredOptions("linkedinProfile")}
          />
          <SelectCustom
            defaultValue={headers.privacy[0]}
            isClearable
            label="Privacy"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.privacy = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.privacy = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la privacy"}
            options={getFilteredOptions("privacy")}
          />
          <SelectCustom
            defaultValue={headers.companyName[0]}
            isClearable
            label="Nome azienda"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.companyName = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.companyName = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il nome dell'azienda"}
            options={getFilteredOptions("companyName")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.companyVat[0]}
            isClearable
            label="P. IVA azienda"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.companyVat = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.companyVat = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la P. IVA dell'azienda"}
            options={getFilteredOptions("companyVat")}
          />
          <SelectCustom
            defaultValue={headers.companyPhone[0]}
            isClearable
            label="Telefono azienda"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.companyPhone = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.companyPhone = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per il telefono dell'azienda"}
            options={getFilteredOptions("companyPhone")}
          />
          <SelectCustom
            defaultValue={headers.contactState[0]}
            isClearable
            label="Esito"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.contactState = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.contactState = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per l'esito"}
            options={getFilteredOptions("contactState")}
          />
        </div>
        <div className="flex w-full gap-4">
          <SelectCustom
            defaultValue={headers.lastContactDate[0]}
            isClearable
            label="Data ultimo contatto"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.lastContactDate = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.lastContactDate = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per la data dell'ultimo contatto"}
            options={getFilteredOptions("lastContactDate")}
          />
          <SelectCustom
            defaultValue={headers.notes[0]}
            isClearable
            label="Note"
            onChange={(e) => {
              if (e === undefined) {
                const newHeaders = { ...headers };
                newHeaders.notes = [];
                setHeaders(newHeaders);
              }
              if (typeof e === "string") {
                const newHeaders = { ...headers };
                newHeaders.notes = [e];
                setHeaders(newHeaders);
              }
            }}
            placeholder={"Seleziona campo per le note"}
            options={getFilteredOptions("notes")}
          />
        </div>
      </div>
    </div>
  );
}
