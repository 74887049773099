import { ReactElement, useEffect, useState } from "react";
import { ContactType } from "../../../../lib/filters/dto";
import useCreateBorder from "../../../../lib/hooks/createBorder";
import { keycloak } from "../../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { PlusIcon } from "../../../../ui/icons/plus";
import { RotateIcon } from "../../../../ui/icons/rotate";
import Avatar from "../../../../ui/molecules/avatar";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { TabMenu } from "../../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../../ui/organisms/tabs/tabPages";
import { SendEmailModal } from "../../../projects/mails/sendEmailModal";
import { SentMailsList } from "../../../projects/mails/sentMailsList";
import { findEmailsHistory, setEmailsHistoryFilters } from "../../../projects/mails/slice";
import { ContactForm } from "../../../targetList/contacts/edit/form";
import { EditContactNotes } from "../../../targetList/contacts/edit/notes";
import { Recall } from "../../../targetList/contacts/recall";
import { getContact, setSelectedContact } from "../../../targetList/contacts/slice";
import { findUsersAvatarsList, getUsersByUserIds, setFindUsersByUserIdStatus } from "../../../users/slice";
import { setNewPersonRequestCompanyName, setNewPersonRequestCompanyPhone, setNewPersonRequestCompanyVat, setNewPersonRequestEmail, setNewPersonRequestLinkedinProfile, setNewPersonRequestName, setNewPersonRequestPhone, setNewPersonRequestPrivacy, setNewPersonRequestRole, setNewPersonRequestSurname, setNewPersonResponse, setNewPersonStatus, setSelectedPerson, setValidateCreatePersonEmail, setValidateCreatePersonName, setValidateCreatePersonPhone, setValidateCreatePersonStatus, setValidateCreatePersonSurname } from "../../people/slice";
import { editCompany, findCompany, findEmployees, setFindCompanyStatus, setSelectedCompany, setTabValueDetailsCompany, setValidateCompanyEditRequestStatus, validateEditCompany } from "./../slice";
import { CompanyEmployees } from "./companyEmployees";
import { CreatePerson } from "./createPerson";
import { EditCompanyForm } from "./editCompanyForm";
import { EditEmployee } from "./editEmployee";

interface Props {
  isInTargetList: boolean
  reloadContacts: () => void
}

export function EditCompany(props: Props) {
  const { isInTargetList, reloadContacts } = props
  const companiesState = useAppSelector((state) => state.companies);
  const contactsState = useAppSelector((state) => state.contacts);
  const peopleState = useAppSelector((state) => state.people);
  const usersState = useAppSelector((state) => state.users);
  const targetListsState = useAppSelector((state) => state.targetList);
  const mailsState = useAppSelector(state => state.mails)

  const borderStyle = useCreateBorder(isInTargetList)

  const [direction, setDirection] = useState<"left" | "right">("left");
  const [personPage, setPersonPage] = useState<"list" | "new" | "edit">("list")

  const personPageMap = new Map<"list" | "new" | "edit", ReactElement>([
    ['list', <CompanyEmployees goToForm={() => setPersonPage("new")} />],
    ['new', <CreatePerson inTargetList={isInTargetList} goToList={() => setPersonPage('list')} />],
    ['edit', <EditEmployee isInTargetList={isInTargetList} goToList={() => { setPersonPage('list'); dispatch(setSelectedPerson(undefined)) }} />],
  ])

  useEffect(() => {
    if (companiesState.tabValueDetailsCompany === 1) {
      setPersonPage('list')
      dispatch(setSelectedPerson(undefined))
    }
  }, [companiesState.tabValueDetailsCompany])

  useEffect(() => {
    if (peopleState.selectedPerson !== undefined)
      setPersonPage('edit')
  }, [peopleState.selectedPerson])

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (contactsState.selectedContact !== undefined) {
      dispatch(setSelectedContact(undefined))
      dispatch(getContact(contactsState.selectedContact));
    }
  }, [contactsState.selectedContact]);

  useEffect(() => {
    if (companiesState.selectedCompany !== undefined) {
      dispatch(setSelectedPerson(undefined))
      dispatch(findCompany(companiesState.selectedCompany));
      setPersonPage('list')
      dispatch(setNewPersonRequestCompanyName(''))
      dispatch(setNewPersonRequestCompanyPhone([]))
      dispatch(setNewPersonRequestCompanyVat(''))
    }
  }, [companiesState.selectedCompany]);

  useEffect(() => {
    if (companiesState.findCompanyStatus === 'successfully') {
      dispatch(findEmployees(companiesState.findCompanyResponse?.employeesId!))
      if (isInTargetList) {
        if (companiesState.findCompanyResponse?.emails && targetListsState.editTargetListTabValue === 2 && companiesState.selectedCompany)
          dispatch(setEmailsHistoryFilters({ ...mailsState.emailsHistoryFilters, toEmail: companiesState.findCompanyResponse?.emails }))
        dispatch(getUsersByUserIds(contactsState.getContactResponse?.notes?.map(note => note.authorUserId) ?? []))
      }
      dispatch(setFindCompanyStatus("idle"))
    }
  }, [companiesState.findCompanyStatus])

  useEffect(() => {
    if (mailsState.emailsHistoryFilters.toEmail.length > 0 && companiesState.selectedCompany)
      dispatch(findEmailsHistory(mailsState.emailsHistoryFilters))
  }, [mailsState.emailsHistoryFilters.toEmail])

  useEffect(() => {
    if (usersState.findUsersByUserIdStatus === 'successfully') {
      dispatch(findUsersAvatarsList(usersState.findUsersByUserIdResponse.map(user => ({ userId: user.userId, objectId: user.avatarObjectId }))))
      dispatch(setFindUsersByUserIdStatus('idle'))
    }
  }, [usersState.findUsersByUserIdStatus])

  useEffect(() => {
    if (companiesState.errors.validateEdit.status === 'successfully') {
      dispatch(editCompany({
        id: companiesState.findCompanyResponse?.id!,
        data: companiesState.editCompanyRequest,
      }))
      dispatch(setValidateCompanyEditRequestStatus('idle'))
    }
  }, [companiesState.errors.validateEdit.status])

  useEffect(() => {
    dispatch(setNewPersonRequestEmail(''))
    dispatch(setNewPersonRequestLinkedinProfile(''))
    dispatch(setNewPersonRequestName(''))
    dispatch(setNewPersonRequestPhone(''))
    dispatch(setNewPersonRequestRole(''))
    dispatch(setNewPersonRequestPrivacy(false))
    dispatch(setNewPersonRequestSurname(''))
    dispatch(setNewPersonStatus('idle'))
    dispatch(setNewPersonResponse(undefined))

    dispatch(setValidateCreatePersonEmail(false))
    dispatch(setValidateCreatePersonName(false))
    dispatch(setValidateCreatePersonPhone(false))
    dispatch(setValidateCreatePersonSurname(false))
    dispatch(setValidateCreatePersonStatus('idle'))
  }, [personPage])

  return (
    <div className="h-full overflow-auto">
      {companiesState.findCompanyStatus === "loading" ||
        companiesState.editCompanyStatus === 'loading' ||
        peopleState.editPersonStatus === 'loading' ||
        targetListsState.findTargetListStatus === "loading" ? (
        <Spinner />
      ) : (
        <div className={borderStyle}>
          <div className="px-6 pt-4 flex items-center justify-between">
            <Avatar
              type="info"
              imageUrl=""
              firstRow={companiesState.findCompanyResponse?.name!}
              size="md"
              altTextInitials={
                companiesState.findCompanyResponse?.name
                  .slice(0, 1)
                  .toUpperCase()!
              }
            />
            <div className="flex items-center gap-4">
              <div
                className="cursor-pointer rotate-45"
                onClick={() => dispatch(setSelectedCompany(undefined))}
              >
                <PlusIcon color={colors.neutral[500]} size={20} />
              </div>
            </div>
          </div>
          {
            isInTargetList && <ContactForm contactData={companiesState.findCompanyResponse} contactType={ContactType.COMPANY} />
          }
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => dispatch(setTabValueDetailsCompany(e))}
            value={companiesState.tabValueDetailsCompany}
            items={[
              {
                icon: <></>,
                label: "Azienda",
              },
              {
                icon: <></>,
                label: "Dipendenti",
              },
              {
                icon: <RotateIcon color={""} size={0} />,
                label: "Richiami",
                hide: !isInTargetList
              },
              {
                icon: <></>,
                label: "Note",
                hide: !isInTargetList
              },
              {
                icon: <></>,
                label: "Cronologia email",
                hide: !isInTargetList || !companiesState.findCompanyResponse?.emails
              },
            ]}
          />
          <TabPages
            pages={[
              <div className="py-4">
                <EditCompanyForm isInTargetList={isInTargetList} />
                <div className="flex justify-end gap-2 py-4 px-6">
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"outline"}
                    color={"blue"}
                    label="Annulla"
                    onClick={() =>
                      dispatch(setSelectedCompany(undefined))
                    }
                  />
                  <Button
                    disabled={keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')}
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"solid"}
                    color={"blue"}
                    label="Salva"
                    onClick={() =>
                      dispatch(
                        validateEditCompany(companiesState.editCompanyRequest)
                      )
                    }
                  />
                </div>
              </div>,
              personPageMap.get(personPage)!,
              <Recall contactType={ContactType.COMPANY} />,
              <EditContactNotes reloadContacts={() => reloadContacts()} />,
              <SentMailsList />,
            ]}
            direction={direction}
            setDirection={(value) => setDirection(value)}
            value={companiesState.tabValueDetailsCompany}
            setValue={(e) => dispatch(setTabValueDetailsCompany(e))}
          />
        </div>
      )}
      <SendEmailModal />
    </div>
  );
}
