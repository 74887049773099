import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../lib/filters/dto";
import { useContacts } from "../../lib/hooks/contacts/contacts";
import { Preferences } from "../../lib/preferences";
import { PreferencesSection } from "../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Area } from "../../lib/utils";
import { BuildingIcon } from "../../ui/icons/building";
import { DownloadIcon } from "../../ui/icons/download";
import { TrashIcon } from "../../ui/icons/trash";
import { UsersIcon } from "../../ui/icons/users";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { TabMenu } from "../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../ui/organisms/tabs/tabPages";
import { setSelectedContact } from "../targetList/contacts/slice";
import { BannerDatabase } from "./banner";
import { EditCompany } from "./companies/editCompany/index";
import { ImportModalCompanies } from "./companies/import/modal";
import { Companies } from "./companies/list";
import {
  bulkCSVCompanies,
  deleteBulkCompanies,
  deleteCompany,
  getCSVCompanies,
  setEmployeeToDelete,
  setFindEmployeesResponse,
  setFindEmployeesStatus,
  setSelectedCompany,
  setSelectedIdsCompanies,
  setShowPopupCompanies
} from "./companies/slice";
import { EditPerson } from "./people/editPerson";
import { AssociateCompanyModal } from "./people/editPerson/associateCompany";
import { ImportModalPeople } from "./people/import/modal";
import { People } from "./people/list";
import {
  bulkCSVPeople,
  deleteBulkPeople,
  deletePerson,
  getCSVPeople,
  setSelectedIdsPeople,
  setSelectedPerson,
  setShowPopupPeople
} from "./people/slice";
import { setBulkCSVModal, setBulkDeleteModal, setTabValue } from "./slice";
import ButtonDropdown from "../../ui/molecules/buttonDropdown";

export function Database() {
  const peopleFilters = useContacts(Area.DATABASE, 'database', ContactType.PERSON)
  const companiesFilters = useContacts(Area.DATABASE, 'database', ContactType.COMPANY)
  const databaseState = useAppSelector((state) => state.database);
  const peopleState = useAppSelector((state) => state.people);
  const companiesState = useAppSelector((state) => state.companies);
  const [direction, setDirection] = useState<"left" | "right">("left");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setSelectedIdsPeople([]))
    dispatch(setSelectedIdsCompanies([]))
  }, []);

  useEffect(() => {
    dispatch(setFindEmployeesResponse([]));
    dispatch(setFindEmployeesStatus("idle"));
    dispatch(setSelectedCompany(undefined))
    dispatch(setSelectedContact(undefined))
    dispatch(setSelectedPerson(undefined))
  }, [databaseState.tabValue]);

  useEffect(() => {
    if (companiesState.companiesBulkCSVStatus === "successfully" || peopleState.peopleBulkCSVStatus === "successfully" ||
      companiesState.companiesBulkCSVStatus === "failed" || peopleState.peopleBulkCSVStatus === "failed") {
      dispatch(setBulkCSVModal(false))
    }
  }, [companiesState.companiesBulkCSVStatus, peopleState.peopleBulkCSVStatus])

  useEffect(() => {
    if (window.location.pathname.includes("people")) {
      dispatch(setTabValue(0));
    }
    if (window.location.pathname.includes("companies")) {
      dispatch(setTabValue(1));
    }
  }, []);

  useEffect(() => {
    if (databaseState.tabValue === 0) {
      navigate(window.location.pathname.replace(/\/specifics|\/companies/, "/people"));
    }
    if (databaseState.tabValue === 1) {
      navigate(window.location.pathname.replace(/\/specifics|\/people/, "/companies"));
    }
  }, [databaseState.tabValue]);


  return (
    <Layout
      menuItem={MenuItems.DATABASE}
      breadcrumbItems={["Database", (databaseState.tabValue === 0 ? 'Persone' : 'Aziende')]}
      headerLabel="Database"
      headerChildren={
        <div className="flex items-center h-full justify-between gap-2">
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => {
              dispatch(setTabValue(e));
              dispatch(setSelectedCompany(undefined));
              dispatch(setSelectedContact(undefined))
              dispatch(setSelectedPerson(undefined));
            }}
            value={databaseState.tabValue}
            items={[
              {
                icon: <UsersIcon color={""} size={0} />,
                label: "Persone",
              },
              {
                icon: <BuildingIcon color={""} size={0} />,
                label: "Aziende",
              },
            ]}
          />
          <div className="flex gap-2">
            {
              ((companiesFilters.contacts.total !== 0 && databaseState.tabValue === 1) || (databaseState.tabValue === 0 && peopleFilters.contacts.total !== 0)) &&
              <ButtonDropdown
                disabled={(databaseState.tabValue === 0 && peopleState.selectedIds.length <= 0) || (databaseState.tabValue === 1 && companiesState.selectedIds.length <= 0)}
                options={[
                  {
                    label: 'Esporta tutti',
                    onClick: () => dispatch(setBulkCSVModal(true))
                  }
                ]}
                size={"sm"}
                iconPosition={"left"}
                color={"blue"}
                onClick={() => databaseState.tabValue === 0 ? dispatch(getCSVPeople(peopleState.selectedIds)) : dispatch(getCSVCompanies(companiesState.selectedIds))}
                label="Esporta"
                icon={<DownloadIcon color={""} size={0} />}
              />
            }
            {
              ((companiesFilters.contacts.total !== 0 && databaseState.tabValue === 1) || (databaseState.tabValue === 0 && peopleFilters.contacts.total !== 0)) &&
              !window.location.pathname.includes('black-list') &&
              <ButtonDropdown
                disabled={(databaseState.tabValue === 0 && peopleState.selectedIds.length <= 0) || (databaseState.tabValue === 1 && companiesState.selectedIds.length <= 0)}
                icon={<TrashIcon color={""} size={0} />}
                options={[
                  {
                    label: 'Elimina tutti',
                    onClick: () => {
                      dispatch(setBulkDeleteModal(true))
                    }
                  }
                ]}
                onClick={() => databaseState.tabValue === 0 ? dispatch(setShowPopupPeople(true)) : dispatch(setShowPopupCompanies(true))}
                label="Elimina"
                size={"sm"}
                iconPosition={"left"}
                color={"blue"}
              />
            }
            <Button
              size={"sm"}
              iconPosition={"off"}
              variant={"solid"}
              color={"blue"}
              onClick={() => {
                if (databaseState.tabValue === 0) {
                  navigate("/import-people");
                }
                if (databaseState.tabValue === 1) {
                  navigate("/import-companies");
                }
              }}
              label="Importa contatti"
            />
          </div>
        </div>
      }
    >
      <div className="flex max-w-full w-full max-h-full h-full overflow-hidden p-4">
        <BannerDatabase companiesResetFilters={() => companiesFilters.resetAllFilters()} peopleResetFilters={() => peopleFilters.resetAllFilters()} reloadCompanies={() => companiesFilters.findContacts()} reloadPeople={() => peopleFilters.findContacts()} />
        <div className="flex max-w-full w-full h-full rounded-xl flex-col relative rounded-t-xl bg-white shadow-sm ">
          <div className="max-h-full max-w-full h-full rounded-xl">
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <People
                  status={peopleFilters.status}
                  contacts={peopleFilters.contacts as unknown as FindContactsDatabaseResponse<ContactType.PERSON>}
                  setPage={page => peopleFilters.changePage(page)} filters={peopleFilters.filters} resetFilters={() => peopleFilters.resetAllFilters()} updateFilters={value => peopleFilters.updateFilters(value)} key={0} />,
                <Companies key={1}
                  resetFilters={function (): void {
                    companiesFilters.resetAllFilters()
                  }}
                  filters={companiesFilters.filters}
                  updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                    companiesFilters.updateFilters(newFilters)
                  }}
                  setPage={function (page: number): void {
                    companiesFilters.changePage(page)
                  }}
                  status={companiesFilters.status}
                  contacts={companiesFilters.contacts as unknown as FindContactsDatabaseResponse<ContactType.COMPANY>} />
              ]}
              value={databaseState.tabValue}
              setValue={(e) => {
                dispatch(setTabValue(e));
                dispatch(setSelectedContact(undefined))
                dispatch(setSelectedCompany(undefined));
                dispatch(setSelectedPerson(undefined));
              }}
            />
          </div>
        </div>
        <ConfirmModal
          loading={peopleState.deletePersonStatus === "loading"}
          title={
            peopleState.selectedIds.length > 1 ?
              "Elimina persone" :
              "Elimina persona"
          }
          label={
            peopleState.selectedIds.length > 1 ?
              "Sei sicuro di voler eliminare queste persone?" :
              "Sei sicuro di voler eliminare questa persona?"
          }
          confirmAction={function (): void {
            dispatch(
              deletePerson(peopleState.selectedIds)
            );
          }}
          onClose={function (): void {
            dispatch(setSelectedIdsPeople([]));
            dispatch(setShowPopupPeople(false));
          }}
          visible={peopleState.showPopup}
        />
        <ConfirmModal
          loading={companiesState.deleteCompanyStatus === "loading"}
          title={
            companiesState.selectedIds.length > 1 ?
              "Elimina aziende" :
              "Elimina azienda"
          }
          label={
            companiesState.selectedIds.length > 1 ?
              "Sei sicuro di voler eliminare queste aziende?" :
              "Sei sicuro di voler eliminare questa azienda?"
          }
          confirmAction={function (): void {
            dispatch(
              deleteCompany(companiesState.selectedIds)
            );
          }}
          onClose={function (): void {
            dispatch(setSelectedIdsCompanies([]));
            dispatch(setShowPopupCompanies(false));
          }}
          visible={companiesState.showPopup}
        />
        <ConfirmModal
          loading={companiesState.deleteBulkStatus === "loading" || peopleState.deleteBulkStatus === 'loading'}
          title={databaseState.tabValue === 1 ? 'Elimina aziende' : 'Elimina persone'}
          label={
            databaseState.tabValue === 1 ?
              "Sei sicuro di voler eliminare tutte le aziende trovate?" :
              "Sei sicuro di voler eliminare tutte le persone trovate?"
          }
          confirmAction={function (): void {
            if (databaseState.tabValue === 1) {
              dispatch(deleteBulkCompanies(companiesFilters.filters))
            } else {
              dispatch(deleteBulkPeople(peopleFilters.filters))
            }
          }}
          onClose={function (): void {
            dispatch(setBulkDeleteModal(false))
          }}
          visible={databaseState.bulkDeleteModal}
        />
        <ConfirmModal
          loading={companiesState.companiesBulkCSVStatus === "loading" || peopleState.peopleBulkCSVStatus === 'loading'}
          title={databaseState.tabValue === 1 ? 'Esporta aziende' : 'Esporta persone'}
          label={
            databaseState.tabValue === 1 ?
              "Sei sicuro di voler esportare tutte le aziende trovate?" :
              "Sei sicuro di voler esportare tutte le persone trovate?"
          }
          confirmAction={function (): void {
            if (databaseState.tabValue === 1) {
              dispatch(bulkCSVCompanies(companiesFilters.filters))
            } else {
              dispatch(bulkCSVPeople(peopleFilters.filters))
            }
          }}
          onClose={function (): void {
            dispatch(setBulkCSVModal(false))
          }}
          visible={databaseState.bulkCSVModal}
        />
        <ImportModalCompanies />
        <ImportModalPeople />
        <AssociateCompanyModal />
        <Preferences section={databaseState.tabValue === 0 ? PreferencesSection.personPreferences : PreferencesSection.companyPreferences} key={databaseState.tabValue === 0 ? PreferencesSection.personPreferences : PreferencesSection.companyPreferences} />
        <div
          className={clsx(
            "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all h-full",
            {
              hidden: peopleState.selectedPerson === undefined,
            }
          )}
        >
          <div className="bg-white shadow-md h-full">
            <EditPerson isInTargetList={false} reloadContacts={() => peopleFilters.findContacts()} />
          </div>
        </div>
        <div
          className={clsx(
            "absolute right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all h-full",
            {
              hidden: companiesState.selectedCompany === undefined,
            }
          )}
        >
          <div className="bg-white shadow-md h-full">
            <EditCompany isInTargetList={false} reloadContacts={() => companiesFilters.findContacts()} />
          </div>
        </div>
      </div>
      <ConfirmModal
        title={"Elimina persona"}
        label={"Sei sicuro di voler eliminare questa persona?"}
        confirmAction={
          () => dispatch(deletePerson([companiesState.employeeToDelete!]))
        }
        onClose={() => dispatch(setEmployeeToDelete(undefined))}
        visible={companiesState.employeeToDelete !== undefined}
      />
    </Layout>
  );
}
