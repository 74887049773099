import { useCallback, useEffect, useState } from "react";
import { MonthCalendar } from "../../../pages/calendar/calendars/monthCalendar";
import { WeekCalendar } from "../../../pages/calendar/calendars/weekCalendar";
import { EventDTO } from "../../../pages/calendar/dto";
import { MonthCalendarFilters } from "../../../pages/calendar/filters/monthFilters";
import { WeekCalendarFilters } from "../../../pages/calendar/filters/weekFilters";
import { findEvents, setPeriod } from "../../../pages/calendar/slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useMonthCalendar } from "./monthCalendar";
import { useWeekCalendar } from "./weekCalendar";


export const useCalendar = () => {
    const dispatch = useAppDispatch();
    const period = useAppSelector((state) => state.events.period);
    const events = useAppSelector((state) => state.events.events[period]);
    const monthCalendar = useMonthCalendar();
    const weekCalendar = useWeekCalendar();
    const [visible, setVisible] = useState(false);
    const [eventsDetails, setEventDetails] = useState<EventDTO[]>([]);
    const [event, setEvent] = useState<EventDTO | null>(null);

    const changePeriod = (period: 'month' | 'week') => {
        dispatch(setPeriod(period));
    };

    const filters = period === 'month' ? monthCalendar.filters : weekCalendar.filters;

    const loadEvents = useCallback(() => {
        dispatch(findEvents({ filters: filters, period }));
    }, [dispatch, filters]);

    useEffect(() => {
        loadEvents();
    }, [loadEvents]);


    return {
        visible,
        setVisible,
        eventsDetails,
        setEventDetails,
        events: events.data,
        status: events.status,
        event,
        loadEvents,
        period,
        changePeriod,
        calendar: period === 'week' ? weekCalendar : monthCalendar,
        calendarComponent: period === 'week' ?
            <WeekCalendar setEvent={((event: EventDTO) => setEvent(event))} filters={weekCalendar.filters} events={events.data?.data ?? []} setModalVisible={(visible) => setVisible(visible)} setEventDetails={(events) => setEventDetails(events)} /> :
            <MonthCalendar setEvent={((event: EventDTO) => setEvent(event))} events={events.data?.data ?? []} filters={monthCalendar.filters} setModalVisible={(visible) => setVisible(visible)} setEventDetails={(events) => setEventDetails(events)} />,
        filterComponents:
            period === 'week' ?
                <WeekCalendarFilters
                    filters={weekCalendar.filters}
                    changePeriod={function (period: "month" | "week"): void {
                        changePeriod(period);
                    }}
                    changeInterval={function (action: "next" | "prev" | "today"): void {
                        weekCalendar.changeInterval(action);
                    }}
                />
                :
                <MonthCalendarFilters
                    filters={monthCalendar.filters}
                    changePeriod={function (period: "month" | "week"): void {
                        changePeriod(period);
                    }}
                    changeInterval={function (action: "next" | "prev" | "today"): void {
                        monthCalendar.changeInterval(action);
                    }}
                />
    };
};
