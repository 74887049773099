import { FindAllContactsFilters } from "../../lib/filters/dto";
import {
  EditTargetListRequest,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  TargetListDTO,
  TargetListStatusEnum
} from "./dto";
import { TargetListsServiceImpl } from "./serviceImpl";

export interface TargetListsService {
  deleteTargetList(id: string): Promise<void>;
  createTargetList(request: NewTargetListRequest): Promise<string>;
  findTargetListById(id: string): Promise<TargetListDTO>;
  findAllTargetLists(
    filters: FindAllTargetListsFilters
  ): Promise<FindAllTargetListsResponse>;
  changeStatus(
    request: {
      state:
      | TargetListStatusEnum.CONCLUDED
      | TargetListStatusEnum.INPROGRESS
      | undefined;
      id: string;
    }[]
  ): Promise<void>;
  editTargetList(
    targetListId: string,
    data: EditTargetListRequest
  ): Promise<void>
}

export function NewTargetListsService(): TargetListsService {
  return new TargetListsServiceImpl();
}
