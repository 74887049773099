import { useEffect } from "react"
import { TruckIcon } from "../../../ui/icons/truck"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { roleDepartment } from "../../atecos/dto"
import { groupBy } from "../../utils"
import { FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function DepartmentFilter(props: Props) {
    const { updateFilters, filters } = props

    useEffect(() => {
        if (filters.personDepartment.length === 0 && filters.personRole.length === 0) {
            return
        }
        if (filters.personDepartment.length === 0) {
            updateFilters({ personRole: [] })
        } else {
            let newRoles: { value: string, label: string }[] = []
            filters.personDepartment.forEach(personDepartment => {
                const roles = roleDepartment.filter(_department => personDepartment.value === _department.department)
                if (roles.length > 0) {
                    roles.forEach(role => {
                        if (role && !newRoles.map(cat => cat.value).includes(role.role)) {
                            newRoles.push({ value: role.role, label: role.role })
                        }
                    })
                }
            })
            updateFilters({ personRole: newRoles })
        }
    }, [filters.personDepartment])

    return (
        <AccordionFilter
            key={'person-filter-department'}
            label={"Reparto"}
            icon={<TruckIcon color={""} size={0} />}
            showClear={filters.personDepartment.length > 0}
            options={filters.personDepartment}
            clearAction={function (): void {
                updateFilters({ personDepartment: [] })
            }}
            deleteFilter={function (option): void {
                updateFilters({ personDepartment: filters.personDepartment.filter(name => name.value !== option.value) })
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        updateFilters({ personDepartment: e.map(option => ({ value: option, label: option })) })
                    }
                }}
                defaultValue={filters.personDepartment.map(option => option.value)}
                placeholder={"Cerca per reparto"}
                options={
                    groupBy(roleDepartment, 'department').map(role => ({ value: role.key, label: role.key }))
                }
                noOptionsMessage="Non sono presenti reparti."
            />
        </AccordionFilter>
    )
}