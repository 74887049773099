import { ReactElement } from "react";
import { numericFormatter } from "react-number-format";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../lib/filters/dto";
import { companyColumns } from "../../../../lib/preferences/dto";
import { updatePreferences } from "../../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { Area } from "../../../../lib/utils";
import { colors } from "../../../../ui/colors";
import { EditIcon } from "../../../../ui/icons/edit";
import { GlobeIcon } from "../../../../ui/icons/globe";
import { TrashIcon } from "../../../../ui/icons/trash";
import Button from "../../../../ui/molecules/button";
import Checkbox from "../../../../ui/molecules/checkbox";
import Pills from "../../../../ui/molecules/pills";
import { Dropdown } from "../../../../ui/organisms/dropdown";
import { CustomTable } from "../../../../ui/organisms/table";
import { findEmployees, resetEditCompanyRequest, setSelectedCompany, setSelectedCompanyNameForEmployees, setSelectedIdsCompanies, setShowPopupCompanies, setTabValueDetailsCompany } from "../slice";

interface Props {
    contacts: FindContactsDatabaseResponse<ContactType.COMPANY>
    filters: FindAllContactsFilters
    setFilters: (newFilters: Partial<FindAllContactsFilters>) => void
}
export function CompaniesTable(props: Props) {
    const { contacts, setFilters, filters } = props
    const companiesState = useAppSelector(state => state.companies)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()
    return (
        <CustomTable
            tableHeight="calc(100% - 48px - 68px)"
            prevOrderHeads={[
                <th className="text-left w-10">
                    <Checkbox
                        shape={"square"}
                        checked={
                            contacts.data.every((company) => companiesState.selectedIds.includes(company.id))!
                        }
                        onCheck={function (value: boolean): void {
                            if (
                                contacts.data
                                    .every((company) => companiesState.selectedIds.includes(company.id))
                            ) {
                                let newSelected = [...companiesState.selectedIds];
                                contacts.data.forEach((company) => {
                                    newSelected = newSelected.filter(
                                        (id) => company.id !== id
                                    );
                                });
                                dispatch(setSelectedIdsCompanies([...newSelected]));
                            } else {
                                dispatch(setSelectedIdsCompanies([
                                    ...companiesState.selectedIds,
                                    ...contacts.data
                                        .filter(
                                            (company) =>
                                                !companiesState.selectedIds.includes(company.id)
                                        )
                                        .map((company) => company.id)!,
                                ]));
                            }
                        }}
                    />
                </th>
            ]}
            postOrderHeads={[<th className={("text-left")}></th>, <th className="text-left"></th>]}
            preferences={preferencesState.findAllResponse?.data[0].companyPreferences?.map(pref => ({ ...pref, order: companyColumns.find(col => col.column === pref.column)?.order })) ?? []}
            setPreferences={(items) => {
                dispatch(
                    updatePreferences({
                        data: {
                            owner: preferencesState.findAllResponse?.data[0].owner!,
                            personPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .personPreferences!,
                            companyPreferences: items,
                            targetListPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPreferences!,
                            targetListCompanyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListCompanyPreferences!,
                            targetListPersonPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPersonPreferences!
                        },
                        id: preferencesState.findAllResponse?.data[0].id!,
                    })
                );
            }}
            setOrderFilters={(order) => {
                setFilters({ order: !filters.order, sort: order })
            }}
            rows={contacts.data.map(d => ({
                key: d.id, data: [
                    {
                        item: <td className="text-text-sm font-semibold text-neutral-600">
                            <Checkbox
                                shape={"square"}
                                checked={companiesState.selectedIds.includes(d.id)}
                                onCheck={function (value: boolean): void {
                                    if (companiesState.selectedIds.includes(d.id)) {
                                        dispatch(setSelectedIdsCompanies([
                                            ...companiesState.selectedIds.filter((id) => id !== d.id),
                                        ]));
                                    } else {
                                        dispatch(setSelectedIdsCompanies([...companiesState.selectedIds, d.id]));
                                    }
                                }}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },
                    {
                        item: <td >
                            <div
                                onClick={() => {
                                    dispatch(setTabValueDetailsCompany(0))
                                    dispatch(findEmployees(d.employeesId));
                                    dispatch(
                                        setSelectedCompanyNameForEmployees(d.name)
                                    );
                                    dispatch(setSelectedCompany(d.id))
                                    dispatch(resetEditCompanyRequest())
                                }}
                                className="text-text-sm crop-text cursor-pointer font-semibold text-brandPrimary-500" style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Azienda')?.width ?? 200 }}>
                                {d.name}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Azienda'
                    },
                    {
                        item: <td >
                            <div
                                className="text-text-sm text-neutral-600 crop-text"
                                style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Cod. ATECO')?.width ?? 150 }}
                            >
                                {d.atecos && d.atecos[0].atecoCode ? d.atecos[0]?.atecoCode : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Cod. ATECO'
                    },
                    {
                        item: <td >
                            <div
                                className="text-text-sm text-neutral-600 crop-text"
                                style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Fatturato')?.width ?? 120 }}
                            >
                                {d.revenue !== null ? numericFormatter(d.revenue.toString(), { thousandSeparator: '.', decimalSeparator: ',', fixedDecimalScale: true, decimalScale: 2, prefix: '€ ' }) : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Fatturato'
                    },
                    {
                        item: <td >
                            {d.toVerifyFields?.includes('phone') ?
                                <Pills
                                    size={"xs"}
                                    label={d.phones && d.phones.length > 0 && d.phones[0] ? (d.phones[0].phone + (d.phones.length > 1 ? (', ..., + ' + (d.phones.length - 1)) : '')) : '-'}
                                    outline={true}
                                    emphasis={true}
                                    color={"red"}
                                /> :
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={'tel:' + (d.phones && d.phones[0]?.phone ? d.phones[0]?.phone : '')}
                                >
                                    <div className="crop-text text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Telefono')?.width ?? 150 }}>
                                        {d.phones && d.phones[0] ? (d.phones[0].phone + (d.phones.length > 1 ? (', ..., + ' + (d.phones.length - 1)) : '')) : '-'}
                                    </div>
                                </a>}
                        </td>,
                        group: 'sort',
                        key: 'Telefono'
                    },
                    {
                        item: <td >
                            <a
                                rel="noreferrer"
                                target="_blank"
                                href={'mailto:' + (d.emails)}
                            >
                                <div className="crop-text text-text-sm text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Email')?.width ?? 200 }}>
                                    {d.emails && d.emails[0] ? (d.emails[0] + (d.emails.length > 1 ? (', ..., + ' + (d.emails.length - 1)) : '')) : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                                </div>
                            </a>
                        </td>,
                        group: 'sort',
                        key: 'Email'
                    },
                    {
                        item: <td >
                            <div
                                className="text-text-sm text-neutral-600 crop-text"
                                style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Sede azienda')?.width ?? 200 }}
                            >
                                {buildAddress(d.address, d.city, d.countryRegion, d.state, d.zipCode, Area.DATABASE)}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Sede azienda'
                    },
                    {
                        item: <td >
                            <div
                                className="text-text-sm text-neutral-600 crop-text"
                                style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'P. IVA')?.width ?? 100 }}
                            >
                                {d.vat ?? <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'P. IVA'
                    },
                    {
                        item: <td >
                            {
                                d.toVerifyFields?.includes('sector') ?
                                    <Pills
                                        size={"xs"}
                                        label={d.atecos ? d.atecos[0]?.sector! : "-"}
                                        outline={true}
                                        emphasis={true}
                                        color={"red"}
                                    /> :
                                    <div
                                        className="text-text-sm text-neutral-600 crop-text"
                                        style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Settore')?.width ?? 150 }}
                                    >
                                        {d.atecos && d.atecos[0]?.sector ? d.atecos[0]?.sector : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                                    </div>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Settore'
                    },
                    {
                        item: <td >
                            {
                                d.toVerifyFields?.includes('category') ?
                                    <Pills
                                        size={"xs"}
                                        label={d.atecos ? d.atecos[0]?.category! : "-"}
                                        outline={true}
                                        emphasis={true}
                                        color={"red"}
                                    /> :
                                    <div
                                        className="text-text-sm text-neutral-600 crop-text"
                                        style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Categoria')?.width ?? 150 }}
                                    >
                                        {d.atecos && d.atecos[0]?.category ? d.atecos[0]?.category : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                                    </div>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Categoria'
                    },
                    {
                        item: <td >
                            <div>
                                <Pills
                                    size={"xs"}
                                    label={d.active ? "Attivo" : "Inattivo"}
                                    outline={false}
                                    emphasis={false}
                                    color={d.active ? "green" : "blue"}
                                />
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Stato'
                    },
                    {
                        item: <td >
                            <div
                                className="text-text-sm text-neutral-600 crop-text"
                                style={{ width: preferencesState.findAllResponse?.data[0].companyPreferences?.find(col => col.column === 'Dipendenti')?.width ?? 100 }}
                            >
                                {d.employeesNumber !== null ? numericFormatter(d.employeesNumber.toString(), { thousandSeparator: '.' }) : <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} />}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Dipendenti'
                    },
                    {
                        item: <td >
                            <a rel="noreferrer" target="_blank" href={d.website} className="flex items-center justify-center"><GlobeIcon color={colors.neutral[600]} size={20} /></a>
                        </td>,
                        group: 'sort',
                        key: 'Sito web'
                    },
                    {
                        item: <td className="">
                            <Button
                                size={"sm"}
                                iconPosition={"off"}
                                disabled={!d.employeesId}
                                variant={"outline"}
                                color={"blue"}
                                label="Mostra dipendenti"
                                onClick={() => {
                                    dispatch(setSelectedCompany(d.id))
                                    dispatch(findEmployees(d.employeesId));
                                    dispatch(
                                        setSelectedCompanyNameForEmployees(d.name)
                                    );
                                    dispatch(setTabValueDetailsCompany(1))
                                }}
                            />
                        </td>,
                        group: 'postSort',
                        key: 'show-employees'
                    },
                    {
                        item: <td className="w-[20px] cursor-pointer">
                            <Dropdown
                                items={[
                                    {
                                        label: "Modifica",
                                        icon: <EditIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setTabValueDetailsCompany(0))
                                            dispatch(setSelectedCompany(d.id))
                                            dispatch(findEmployees(d.employeesId));
                                            dispatch(
                                                setSelectedCompanyNameForEmployees(d.name)
                                            );
                                            dispatch(resetEditCompanyRequest())
                                        },
                                    },
                                    window.location.pathname.includes('black-list') ?
                                        null :
                                        {
                                            label: "Cancella",
                                            icon: <TrashIcon color={""} size={0} />,
                                            onClick: () => {
                                                dispatch(setSelectedIdsCompanies([d.id]))
                                                dispatch(setShowPopupCompanies(true))
                                            },
                                        },
                                ]}
                            />
                        </td>,
                        group: 'postSort',
                        key: 'menu'
                    }
                ]
            })) ?? []}
        />
    )
}

export const buildAddress = (address?: string, city?: string, region?: string, state?: string, zipCode?: string | number, area?: Area): string | ReactElement => {
    let location = ''
    if (address) {
        location = address
    }
    if (city) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + city
    }
    // if (province) {
    //     if (location !== '') {
    //         location = location + ', '
    //     }
    //     location = location + province
    // }
    if (region) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + region
    }
    if (state) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + state
    }
    if (zipCode) {
        if (location !== '') {
            location = location + ', '
        }
        location = location + zipCode
    }
    return location === '' ? area === Area.DATABASE ? <Pills size={"xs"} label={"-"} outline={true} emphasis={false} color={"red"} /> : '-' : location
}
