import { format } from "date-fns";
import { EventDTO } from "../dto";
import { eventsColorsMap } from "./utils";
import { useState } from "react";
import { EventTooltip } from "./tooltip";
import { EventsModal } from "../modals";
import { ContactType } from "../../../lib/filters/dto";
import { recallSetter } from "../../targetList/contacts/recall/useRecall";
import { useAppDispatch } from "../../../lib/redux/hooks";
import { setShowRecallContactModal } from "../../targetList/contacts/slice";

interface Props {
    height: string;
    width: string;
    event: EventDTO;
    offset: string;
    setEvent: (event: EventDTO) => void;
}
export function WeekEvent(props: Props) {
    const { height, event, width, offset, setEvent } = props;
    const [visible, setVisible] = useState(false);
    const [mouseY, setMouseY] = useState(0);
    const [mouseX, setMouseX] = useState(0);

    const isLastColumn = new Date(event.startDate).getDay() === 0;

    const dispatch = useAppDispatch()

    return (
        <div
            onClick={() => { dispatch(setShowRecallContactModal(true)); setEvent(event) }}
            style={{
                height,
                width,
                minWidth: "5px",
                minHeight: "16px",
                left: offset,
                padding: "0 2px",
            }}
            key={event.id}
            className="absolute cursor-pointer right-0 top-0 z-10"
        >
            <div
                onMouseEnter={() => setVisible(true)}
                onMouseMove={(e) => { !visible && setMouseY(e.clientY); !visible && setMouseX(e.clientX) }}
                onMouseLeave={() => setVisible(false)}
                style={{
                    backgroundColor: eventsColorsMap.get(event.type)?.bg,
                    color: eventsColorsMap.get(event.type)?.text,
                    borderColor: eventsColorsMap.get(event.type)?.border,
                }}
                className="h-full text-text-xs font-bold w-full rounded gap-2 flex"
            >
                <EventTooltip visible={visible} event={event} isLastColumn={isLastColumn} mouseY={mouseY} mouseX={mouseX} />
                <div
                    className="h-full flex rounded flex-shrink-0"
                    style={{
                        backgroundColor: eventsColorsMap.get(event.type)?.text,
                        width: "5px",
                        flexBasis: "5px",
                    }}
                />
                <span className="pr-2 w-full crop-text inline-block">
                    {event.title} -{" "}
                    <span className="font-normal text-right">
                        {format(event.startDate!, "HH:mm")}
                    </span>
                </span>
            </div>
            {/* <EventsModal
                eventType={event.type}
                contactType={ContactType.PERSON}
                type={'edit'}
                data={recallSetter(event)!}
            /> */}
        </div>
    );
}


