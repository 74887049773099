import qs from "qs";
import { cdAxios } from "../../../lib/axios";
import { ContactDTO, ContactType, FindAllContactsFilters } from "../../../lib/filters/dto";
import { keycloak } from "../../../lib/keycloak";
import { contactsFiltersAdapter } from "../../../lib/utils";
import { ImportCSVResponseDTO } from "../../database/dto";
import { ContactStateEnum, NoteDTO } from "../dto";
import { ContactIdsDTO, CounterDTO } from "./dto";
import { ContactsService } from "./service";

export class ContactsServiceImpl implements ContactsService {

  public deleteBulk(
    filters: FindAllContactsFilters,
    targetList: string,
    contactType: ContactType
  ): Promise<void> {
    return cdAxios
      .delete("/api/contacts/bulk", {
        params: { ...contactsFiltersAdapter(filters, ContactType.PERSON), page: 0, itemsPerPage: 0, contactType, targetList },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public addContactsBulk(
    targetListId: string,
    filters: FindAllContactsFilters,
    contactType: ContactType
  ): Promise<void> {
    return cdAxios
      .post(
        ("/api/contacts/bulk-" + (contactType === ContactType.COMPANY ? 'companies' : 'persons')),
        null,
        {
          params: { ...contactsFiltersAdapter(filters, contactType), targetListId: targetListId, page: 0, itemsPerPage: 0 },
          //@ts-ignore
          paramsSerializer: (params: FindAllContactsFilters) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: true,
            });
          },
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public getContactIds(
    targetListId: string, contactType: ContactType
  ): Promise<ContactIdsDTO[]> {
    return cdAxios
      .get(
        "/api/contacts/ids",
        {
          params: {
            targetList: targetListId,
            contactType
          },
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public getCounters(
    targetListId: string, contactType: ContactType
  ): Promise<CounterDTO> {
    return cdAxios
      .get(
        "/api/contacts/count",
        {
          params: {
            targetList: targetListId,
            contactType
          },
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public importContactCSV(
    data: FormData,
    type: ContactType,
  ): Promise<ImportCSVResponseDTO> {
    return cdAxios
      .post("/api/contacts/" + type.toLowerCase() + "-csv", data, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  public getContact(
    contactId: string,
  ): Promise<ContactDTO<ContactType>> {
    return cdAxios
      .get(
        "/api/contacts/" + contactId,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public restoreContacts(
    contactIds: string[],
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/contacts/restore",
        contactIds,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public updateNotes(
    contactId: string,
    data: NoteDTO[]
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/contacts/" +
        contactId +
        "/notes",
        { notes: data },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public changeContactStatus(
    contactId: string,
    contactState?: ContactStateEnum,
    stateInfo?: string | null
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/contacts/" + contactId,
        {
          outcome: contactState,
          outcomeInfo: stateInfo
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public addContacts(
    targetListId: string,
    type: ContactType,
    contactIds: string[]
  ): Promise<void> {
    return cdAxios
      .post(
        "/api/contacts/bulk",
        {
          "targetListId": targetListId,
          "contactIds": contactIds,
          "contactType": type
        },
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public removeContacts(
    contactIds: string[]
  ): Promise<void> {
    return cdAxios
      .delete(
        "/api/contacts",
        {
          params: { ids: contactIds },
          paramsSerializer: (params) => {
            return qs.stringify(params, {
              arrayFormat: "repeat",
              encode: true,
            });
          },
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public softBulk(
    targetList: string,
    contactType: ContactType
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/contacts/bulk-soft-delete?targetList=" + targetList + "&type=" + contactType,
        undefined,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }
}
