import clsx from "clsx";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Filters } from "../../../../lib/filters";
import { CompaniesHorizontalFilters } from "../../../../lib/filters/accordion/horizontalFiltersCompany";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse } from "../../../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../../lib/types";
import { Spinner } from "../../../../ui/molecules/spinner";
import { setTabValue } from "../../slice";
import {
  countCompanies
} from "../slice";
import { CompaniesList } from "./list";

interface Props {
  resetFilters: () => void
  filters: FindAllContactsFilters
  updateFilters: (newFilters: Partial<FindAllContactsFilters>) => void
  setPage: (page: number) => void
  status: PromiseStatuses
  contacts: FindContactsDatabaseResponse<ContactType.COMPANY>
}

export function Companies(props: Props) {
  const { resetFilters, filters, updateFilters, setPage, status, contacts } = props
  const databaseState = useAppSelector((state) => state.database);
  const companiesState = useAppSelector((state) => state.companies);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'successfully') {
      dispatch(countCompanies())
    }
  }, [status])

  useEffect(() => {
    if (
      companiesState.importCSVStatus === "failed" ||
      companiesState.importCSVStatus === "successfully"
    ) {
      navigate("/database/companies");
      dispatch(setTabValue(1));
    }
  }, [companiesState.importCSVStatus]);

  return (
    <div className="max-h-full flex" style={{ height: 'calc(100%)' }}>
      <div className="flex" style={{ width: 'calc(100%)' }}>
        <div
          className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
            flex: databaseState.filtersActive,
            hidden: !databaseState.filtersActive,
          })}
        >
          <Filters
            contactType={ContactType.COMPANY}
            isInTargetList={false}
            resetFilters={function (): void {
              resetFilters()
            }}
            filters={filters}
            updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
              updateFilters(newFilters)
            }} />
        </div>
        <div
          className={clsx({
            "w-full": !databaseState.filtersActive,
            "w-4/5": databaseState.filtersActive,
          })}
        >
          {status === "loading" ? (
            <div className="w-full h-[45%] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <div className="h-full max-h-full">
              <CompaniesHorizontalFilters
                updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                  updateFilters(newFilters)
                }} filters={filters}
                isInTargetList={false} />

              <CompaniesList contacts={contacts} filters={filters} setFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                updateFilters(newFilters)
              }} changePage={function (page: number): void {
                setPage(page)
              }} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
