import { MailIcon } from "../../../../ui/icons/mail"
import { XCircleIcon } from "../../../../ui/icons/xCircle"
import Button from "../../../../ui/molecules/button"
import Input from "../../../../ui/molecules/input"

interface Props {
    list: string[]
    errors: boolean[]
    handler: (list: string[]) => void
    isInTargetList?: boolean
}

export function PersonEmails(props: Props) {
    const { list, errors, handler, isInTargetList } = props

    return (
        <>
            {
                list.map((email, index) => (
                    <div className="flex items-end">
                        <Input
                            onChangeText={(text) => {
                                const emails = [...list]
                                emails[index] = text
                                handler([...emails])
                            }}
                            label="Email"
                            error={errors[index] || (errors.length === 0 && !isInTargetList)}
                            defaultValue={email}
                            supportingText={errors[index] ? "Inserisci un'email valida" : ''}
                            startIcon={<MailIcon color={""} size={0} />}
                            value={email}
                        />
                        <Button
                            size={"sm"}
                            iconPosition={"only"}
                            icon={<XCircleIcon color={""} size={0} />}
                            variant={"ghost"}
                            color={"blue"}
                            onClick={() => {
                                const emails = [...list]
                                const emailsRequest = [...emails.slice(0, index), ...emails.slice(index + 1)]
                                if (emailsRequest.length === 0) {
                                    handler([''])
                                } else {
                                    handler([...emailsRequest])
                                }
                            }}
                        />
                    </div>
                ))
            }
            <div className="flex items-end justify-end">
                <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"link"}
                    color={"blue"}
                    label="Aggiungi email"
                    onClick={() => {
                        handler([...list, ''])
                    }}
                />
            </div>
        </>
    )
}