import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../lib/types";
import {
  EditProjectPage,
  EditProjectRequest,
  FindAllProjectsFilters,
  FindAllProjectsResponse,
  NewProjectRequest,
  ProjectCustomerBindDTO,
  ProjectDTO,
  ProjectStateEnum
} from "./dto";
import { NewProjectsService } from "./service";

interface ProjectState {
  filters: FindAllProjectsFilters;
  findAllStatus: PromiseStatuses;
  findAllResponse?: FindAllProjectsResponse;
  newProjectRequest: NewProjectRequest;
  newProjectStatus: PromiseStatuses;
  deleteProjectStatus: PromiseStatuses;
  selectedProject?: string;
  findProjectResponse?: ProjectDTO;
  findProjectStatus: PromiseStatuses;
  editProjectRequest: EditProjectRequest;
  editProjectStatus: PromiseStatuses;
  validateNewProject: {
    errors: {
      name: boolean;
      customerId: boolean;
      teamLeaderId: boolean;
      commercialIds: boolean;
    };
    status: PromiseStatuses;
  };
  editProjectPage: EditProjectPage;
  changeStateStatus: PromiseStatuses;
  editCustomerInProjectStatus: PromiseStatuses;
  editCustomerInProjectRequest: ProjectCustomerBindDTO;
}

const initialState: ProjectState = {
  filters: {
    commercial: [],
    name: "",
    sector: [],
    startDate: [],
    category: [],
    state: [],
    teamLeaderId: undefined,
    order: true,
    sort: "name",
    itemsPerPage: 50,
    page: 0,
  },
  findAllStatus: "idle",
  newProjectRequest: {
    name: "",
    description: "",
    commercialIds: [],
    emailFrom: null,
    nameFrom: null
  },
  newProjectStatus: "idle",
  validateNewProject: {
    errors: {
      name: false,
      commercialIds: false,
      teamLeaderId: false,
      customerId: false,
    },
    status: "idle",
  },
  deleteProjectStatus: "idle",
  findProjectStatus: "idle",
  editProjectRequest: {
    description: null,
    targetListsIds: null,
    emailFrom: null,
    nameFrom: null,
    commercialIds: null
  },
  editProjectStatus: "idle",
  editProjectPage: EditProjectPage.LIST,
  changeStateStatus: "idle",
  editCustomerInProjectStatus: "idle",
  editCustomerInProjectRequest: {
    customerId: "",
  }
};

export const findAllProjects = createAsyncThunk(
  "projects/findAllProjects",
  async (
    filters: FindAllProjectsFilters,
    thunkApi
  ): Promise<FindAllProjectsResponse> => {
    const ProjectsService = NewProjectsService();

    return ProjectsService.findAllProjects(filters);
  }
);

export const changeStatus = createAsyncThunk(
  "projects/changeStatus",
  async (
    request: {
      state:
      | ProjectStateEnum.CONCLUDED
      | ProjectStateEnum.INPROGRESS
      | undefined;
      id: string;
    }[]
  ): Promise<void> => {
    const ProjectListsService = NewProjectsService();

    return ProjectListsService.changeStatus(request);
  }
);

export const findProjectById = createAsyncThunk(
  "projects/findProjectById",
  async (request: string, thunkApi): Promise<ProjectDTO> => {
    const ProjectsService = NewProjectsService();

    return ProjectsService.findProjectById(request);
  }
);

export const newProject = createAsyncThunk(
  "projects/newProject",
  async (request: NewProjectRequest, thunkApi): Promise<string> => {
    const ProjectsService = NewProjectsService();

    return ProjectsService.createProject(request);
  }
);

export const editProject = createAsyncThunk(
  "projects/editProject",
  async (
    request: { id: string; data: EditProjectRequest },
    thunkApi
  ): Promise<void> => {
    const ProjectsService = NewProjectsService();

    return ProjectsService.editProject(request.id, request.data);
  }
);

export const editCustomerInProject = createAsyncThunk(
  "projects/editCustomerInProject",
  async (request: { id: string, data: ProjectCustomerBindDTO }): Promise<void> => {
    const ProjectsService = NewProjectsService();

    return ProjectsService.editCustomerInProject(request.id, request.data);
  }
)

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (request: string, thunkApi): Promise<void> => {
    const ProjectsService = NewProjectsService();

    return ProjectsService.deleteProject(request);
  }
);

export const validateNewProject = createAsyncThunk(
  "projects/validateNewProject",
  async (project: NewProjectRequest, thunkApi): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateNewProjectName(false));
    thunkApi.dispatch(setValidateNewProjectCommercialIds(false));
    thunkApi.dispatch(setValidateNewProjectCustomerId(false));
    thunkApi.dispatch(setValidateNewProjectTeamLeaderId(false));
    thunkApi.dispatch(setValidateNewProjectStatus("idle"));

    if (project.name === "" || !project.name) {
      thunkApi.dispatch(setValidateNewProjectName(true));
      isValid = false;
    }

    if (project.commercialIds.length === 0) {
      thunkApi.dispatch(setValidateNewProjectCommercialIds(true));
      isValid = false;
    }

    if (project.customerId === undefined) {
      thunkApi.dispatch(setValidateNewProjectCustomerId(true));
      isValid = false;
    }

    if (project.teamLeaderId === undefined) {
      thunkApi.dispatch(setValidateNewProjectTeamLeaderId(true));
      isValid = false;
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

const projectsSlice = createSlice({
  name: "projects/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(findAllProjects.pending, (state) => {
        state.findAllStatus = "loading";
      })
      .addCase(findAllProjects.fulfilled, (state, action) => {
        state.findAllStatus = "successfully";
        state.findAllResponse = action.payload;
      })
      .addCase(findAllProjects.rejected, (state) => {
        state.findAllStatus = "failed";
      })
      .addCase(newProject.pending, (state) => {
        state.newProjectStatus = "loading";
      })
      .addCase(newProject.fulfilled, (state, action) => {
        state.newProjectStatus = "successfully";
        state.selectedProject = action.payload;
      })
      .addCase(newProject.rejected, (state) => {
        state.newProjectStatus = "failed";
      })
      .addCase(validateNewProject.pending, (state) => {
        state.validateNewProject.status = "loading";
      })
      .addCase(validateNewProject.fulfilled, (state, action) => {
        state.validateNewProject.status = "successfully";
      })
      .addCase(validateNewProject.rejected, (state) => {
        state.validateNewProject.status = "failed";
      })
      .addCase(deleteProject.pending, (state) => {
        state.deleteProjectStatus = "loading";
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.deleteProjectStatus = "successfully";
      })
      .addCase(deleteProject.rejected, (state) => {
        state.deleteProjectStatus = "failed";
      })
      .addCase(findProjectById.pending, (state) => {
        state.findProjectStatus = "loading";
      })
      .addCase(findProjectById.fulfilled, (state, action) => {
        state.findProjectStatus = "successfully";
        state.findProjectResponse = action.payload;
      })
      .addCase(findProjectById.rejected, (state) => {
        state.findProjectStatus = "failed";
      })
      .addCase(editProject.pending, (state) => {
        state.editProjectStatus = "loading";
      })
      .addCase(editProject.fulfilled, (state, action) => {
        state.editProjectStatus = "successfully";
      })
      .addCase(editProject.rejected, (state) => {
        state.editProjectStatus = "failed";
      })
      .addCase(changeStatus.pending, (state) => {
        state.changeStateStatus = "loading";
      })
      .addCase(changeStatus.fulfilled, (state, action) => {
        state.changeStateStatus = "successfully";
      })
      .addCase(changeStatus.rejected, (state) => {
        state.changeStateStatus = "failed";
      })
      .addCase(editCustomerInProject.pending, (state) => {
        state.editCustomerInProjectStatus = "loading";
      })
      .addCase(editCustomerInProject.fulfilled, (state, action) => {
        state.editCustomerInProjectStatus = "successfully";
      })
      .addCase(editCustomerInProject.rejected, (state) => {
        state.editCustomerInProjectStatus = "failed";
      })
  },
  reducers: {
    setProjectFilterCommercial: (state, action) => {
      state.filters.commercial = action.payload;
    },
    setProjectFilterName: (state, action) => {
      state.filters.name = action.payload;
    },
    setProjectFilterSector: (state, action) => {
      state.filters.sector = action.payload;
    },
    setProjectFilterStartDate: (state, action) => {
      state.filters.startDate = action.payload;
    },
    setProjectFilterState: (state, action) => {
      state.filters.state = action.payload;
    },
    setProjectFilterCategory: (state, action) => {
      state.filters.category = action.payload;
    },
    setProjectFilterTeamLeader: (state, action) => {
      state.filters.teamLeaderId = action.payload;
    },
    setProjectFilterOrder: (state, action) => {
      state.filters.order = action.payload;
    },
    setProjectFilterSort: (state, action) => {
      state.filters.sort = action.payload;
    },
    setProjectFilterPage: (state, action) => {
      state.filters.page = action.payload;
    },
    setProjectFilterItemsPerPage: (state, action) => {
      state.filters.itemsPerPage = action.payload;
    },
    setNewProjectName: (state, action) => {
      state.newProjectRequest.name = action.payload;
    },
    setNewProjectDescription: (state, action) => {
      state.newProjectRequest.description = action.payload;
    },
    setNewProjectCustomerId: (state, action) => {
      state.newProjectRequest.customerId = action.payload;
    },
    setNewProjectCommercialIds: (state, action) => {
      state.newProjectRequest.commercialIds = action.payload;
    },
    setNewProjectTeamLeaderId: (state, action) => {
      state.newProjectRequest.teamLeaderId = action.payload;
    },
    setNewProjectEmailFrom: (state, action) => {
      state.newProjectRequest.emailFrom = action.payload;
    },
    setNewProjectNameFrom: (state, action) => {
      state.newProjectRequest.nameFrom = action.payload;
    },
    setNewProjectStatus: (state, action) => {
      state.newProjectStatus = action.payload;
    },
    setValidateNewProjectName: (state, action) => {
      state.validateNewProject.errors.name = action.payload;
    },
    setValidateNewProjectCommercialIds: (state, action) => {
      state.validateNewProject.errors.commercialIds = action.payload;
    },
    setValidateNewProjectCustomerId: (state, action) => {
      state.validateNewProject.errors.customerId = action.payload;
    },
    setValidateNewProjectTeamLeaderId: (state, action) => {
      state.validateNewProject.errors.teamLeaderId = action.payload;
    },
    setValidateNewProjectStatus: (state, action) => {
      state.validateNewProject.status = action.payload;
    },
    setDeleteProjectStatus: (state, action) => {
      state.deleteProjectStatus = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
    setFindProjectResponse: (state, action) => {
      state.findProjectResponse = action.payload;
    },
    setFindProjectStatus: (state, action) => {
      state.findProjectStatus = action.payload;
    },
    setEditProjectDescription: (state, action) => {
      state.editProjectRequest.description = action.payload;
    },
    setEditProjectCommercialIds: (state, action) => {
      state.editProjectRequest.commercialIds = action.payload
    },
    setEditProjectTargetListIds: (state, action) => {
      state.editProjectRequest.targetListsIds = action.payload;
    },
    setEditProjectEmailFrom: (state, action) => {
      state.editProjectRequest.emailFrom = action.payload
    },
    setEditProjectNameFrom: (state, action) => {
      state.editProjectRequest.nameFrom = action.payload;
    },
    setEditProjectStatus: (state, action) => {
      state.editProjectStatus = action.payload;
    },
    setEditProjectPage: (state, action) => {
      state.editProjectPage = action.payload;
    },
    setProjectChangeStateStatus: (state, action) => {
      state.changeStateStatus = action.payload;
    },
    setEditCustomerInProject: (state, action) => {
      state.editCustomerInProjectRequest.customerId = action.payload
    },
    setEditCustomerInProjectStatus: (state, action) => {
      state.editCustomerInProjectStatus = action.payload
    }
  },
});

export const {
  setProjectFilterCommercial,
  setProjectFilterName,
  setProjectFilterOrder,
  setProjectFilterPage,
  setProjectFilterItemsPerPage,
  setProjectFilterSector,
  setProjectFilterSort,
  setProjectFilterStartDate,
  setProjectFilterState,
  setProjectFilterTeamLeader,
  setNewProjectCommercialIds,
  setNewProjectCustomerId,
  setNewProjectDescription,
  setNewProjectName,
  setNewProjectStatus,
  setNewProjectTeamLeaderId,
  setValidateNewProjectCommercialIds,
  setValidateNewProjectCustomerId,
  setValidateNewProjectName,
  setValidateNewProjectStatus,
  setValidateNewProjectTeamLeaderId,
  setDeleteProjectStatus,
  setSelectedProject,
  setFindProjectStatus,
  setEditProjectDescription,
  setEditProjectStatus,
  setEditProjectTargetListIds,
  setEditProjectPage,
  setProjectChangeStateStatus,
  setProjectFilterCategory,
  setEditCustomerInProject,
  setEditCustomerInProjectStatus,
  setEditProjectCommercialIds,
  setEditProjectEmailFrom,
  setEditProjectNameFrom,
  setNewProjectEmailFrom,
  setNewProjectNameFrom,
  setFindProjectResponse
} = projectsSlice.actions;

export default projectsSlice.reducer;
