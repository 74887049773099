import clsx from "clsx";
import { Filters } from "../../../../../lib/filters";
import { CompaniesHorizontalFilters } from "../../../../../lib/filters/accordion/horizontalFiltersCompany";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto";
import { useAppSelector } from "../../../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../../../lib/types";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { TargetCompaniesList } from "./list";

interface Props {
  showPopup: boolean
  setShowPopup: (action: boolean) => void
  selectedIds: string[]
  setSelectedIds: (action: string[]) => void
  filters: FindAllContactsFilters
  setFilters: (action: Partial<FindAllContactsFilters>) => void
  setPage: (action: number) => void
  resetFilters: () => void
  contacts: FindContactsTargetResponse<ContactType.COMPANY>
  status: PromiseStatuses
  reloadContacts: () => void
}

export function TargetListCompaniesList(props: Props) {
  const { selectedIds, setSelectedIds, setShowPopup, showPopup, setFilters, setPage, filters, resetFilters, contacts, status, reloadContacts } = props
  const databaseState = useAppSelector((state) => state.database);
  const targetsListsState = useAppSelector((state) => state.targetList);

  return (
    <div className="flex w-full h-full rounded-t-2xl max-h-full transition-all duration-300">
      <div
        className={clsx("transition-all duration-300 w-[20%] flex-shrink-0", {
          block: databaseState.filtersActive,
          hidden: !databaseState.filtersActive,
        })}
      >
        <Filters isInTargetList={true}
          contactType={ContactType.COMPANY}
          resetFilters={function (): void {
            resetFilters()
          }}
          filters={filters}
          updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
            setFilters(newFilters)
          }} />
      </div>
      <div
        className={clsx("transition-all h-full max-h-full duration-300", {
          "w-full": !databaseState.filtersActive,
          "w-4/5": databaseState.filtersActive,
        })}
      >
        <CompaniesHorizontalFilters
          updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
            setFilters(newFilters)
          }} filters={filters} isInTargetList={true} />
        {status === "loading" ||
          targetsListsState.findTargetListStatus === "loading" ? (
          <div className="flex w-full items-center justify-center h-[45%]">
            {" "}
            <Spinner />
          </div>
        ) :
          <TargetCompaniesList
            reloadContacts={() => reloadContacts()}
            contacts={contacts}
            status={status}
            showPopup={showPopup}
            setShowPopup={arg => setShowPopup(arg)}
            selectedIds={selectedIds}
            setSelectedIds={arg => setSelectedIds(arg)}
            filters={filters}
            setFilters={function (action: Partial<FindAllContactsFilters>): void {
              setFilters(action)
            }}
            setPage={function (action: number): void {
              setPage(action)
            }}
            resetFilters={function (): void {
              resetFilters()
            }} />
        }
      </div>
    </div>
  );
}
