import { BriefcaseIcon } from "../../../ui/icons/briefcase"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { useAppSelector } from "../../redux/hooks"
import { groupBy } from "../../utils"
import { FindAllContactsFilters } from "../dto"

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
    atecoSign?: string
}

export function SectorFilter(props: Props) {
    const { updateFilters, filters, atecoSign } = props
    const atecosState = useAppSelector(state => state.atecos)

    const filteredAtecos = atecosState.findAllAtecosResponse
        .filter(ateco => filters.companyAtecoCode !== undefined && filters.companyAtecoCode[0].value && atecoSign === "=" ? filters.companyAtecoCode.map((value) => value.value.includes(ateco.atecoCode)) : ateco)

    return (
        <AccordionFilter
            key={'person-filter-sector'}
            label={"Settore"}
            icon={<BriefcaseIcon color={""} size={0} />}
            showClear={filters.companySector.length > 0}
            options={filters.companySector}
            clearAction={function (): void {
                updateFilters({ companySector: [] })
            }}
            deleteFilter={function (option): void {
                updateFilters({ companySector: filters.companySector.filter(name => name.value !== option.value) })
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        updateFilters({ companySector: e.map(option => ({ value: option, label: (groupBy(atecosState.findAllAtecosResponse, 'sector').find(ateco => ateco.key === option)?.key)! })) })
                    }
                }}
                defaultValue={filters.companySector.map(option => option.value)}
                placeholder={"Cerca per settore"}
                options={
                    groupBy(filteredAtecos, 'sector').map(sector => ({ value: sector.key, label: sector.key }))
                }
                noOptionsMessage="Non sono presenti settori."
            />
        </AccordionFilter>
    )
}