import { error } from "console"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { PhoneCallIcon } from "../../../../ui/icons/phoneCall"
import { XCircleIcon } from "../../../../ui/icons/xCircle"
import Button from "../../../../ui/molecules/button"
import Input from "../../../../ui/molecules/input"
import { setEditPersonToVerifyFields } from "../slice"

interface Props {
    list: string[]
    errors: boolean[]
    handler: (list: string[]) => void
    operationType: 'create' | 'update'
    isInTargetList?: boolean
}

export function PersonPhones(props: Props) {
    const { list, errors, handler, operationType, isInTargetList } = props
    const peopleState = useAppSelector(state => state.people)
    const dispatch = useAppDispatch()

    return (
        <>
            {
                list.map((phone, index) => (
                    <div className="flex items-end">
                        <Input
                            label="Telefono"
                            startIcon={<PhoneCallIcon color={""} size={0} />}
                            value={phone}
                            defaultValue={phone}
                            error={errors[index] || (errors.length === 0 && !isInTargetList)}
                            supportingText={errors[index] ? 'Inserisci un numero di telefono valido' : ''}
                            onChangeText={(text) => {
                                const phones = [...list]
                                phones[index] = text
                                handler([...phones])
                            }}
                        />
                        <Button
                            size={"sm"}
                            iconPosition={"only"}
                            icon={<XCircleIcon color={""} size={0} />}
                            variant={"ghost"}
                            color={"blue"}
                            onClick={() => {
                                const phones = [...list]
                                const phoneRequest = [...phones.slice(0, index), ...phones.slice(index + 1)]
                                if (phoneRequest.length === 0)
                                    handler([''])
                                else
                                    handler([...phoneRequest])
                                if (operationType === 'update' && peopleState.editPersonRequest.toVerifyFields !== null)
                                    dispatch(setEditPersonToVerifyFields([...peopleState.editPersonRequest.toVerifyFields.filter(field => field !== 'phone')]))
                            }}
                        />
                    </div>
                ))
            }
            <div className="flex items-end justify-end">
                <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"link"}
                    color={"blue"}
                    label="Aggiungi numero di telefono"
                    onClick={() => {
                        handler([...list, ''])
                    }}
                />
            </div>
        </>
    )
}