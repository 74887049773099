import { CustomMailCreateDTO, CustomMailDTO, CustomMailUpdateDTO, EmailsHistoryFilters, EmailsMessagesDTO, FindAllCustomMailsResponseDTO, findEmailByIdResponseDTO, Mail, MailFilters, SaveResponseDTO } from "./dto";
import { MailServiceImpl } from "./serviceImpl";

export interface MailService {
    sendCustomMail(customMailId: string, request: Mail): Promise<any>;
    findAllCustomMails(filters: MailFilters): Promise<FindAllCustomMailsResponseDTO>
    findMailByID(id: string): Promise<CustomMailDTO>
    saveMail(request: CustomMailCreateDTO): Promise<SaveResponseDTO>
    updateMail(id: string, request: CustomMailUpdateDTO): Promise<string>
    removeMail(id: string): Promise<string>
    findEmailsHistory(filters: EmailsHistoryFilters): Promise<{ messages: EmailsMessagesDTO[] }>
    findEmailById(id: string): Promise<findEmailByIdResponseDTO>
    sendVerificationMail(request: string): Promise<void>
}

export function NewMailService(): MailService {
    return new MailServiceImpl();
}