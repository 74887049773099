import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { formatDate } from "date-fns";
import { FindAllContactsFilters } from "../../../lib/filters/dto";
import { emailRegex, vatRegex } from "../../../lib/regex";
import { PromiseStatuses } from "../../../lib/types";
import { downloadCSVfromBase64 } from "../../../lib/utils";
import { BulkCSVCompaniesFilters, ImportCSVResponseDTO } from "../dto";
import { PersonDTO } from "../people/dto";
import { NewPeopleService } from "../people/service";
import {
  CompanyDTO,
  EditCompanyRequest,
} from "./dto";
import { NewCompaniesService } from "./service";

interface CompaniesState {
  deleteCompanyStatus: PromiseStatuses;
  deleteBulkStatus: PromiseStatuses;
  idToDelete?: string;
  findCompanyResponse?: CompanyDTO;
  findCompanyStatus: PromiseStatuses;
  selectedCompany?: string;
  selectedCompanyNameForEmployees?: string;
  editCompanyRequest: EditCompanyRequest;
  editCompanyStatus: PromiseStatuses;
  findEmployeesResponse: PersonDTO[];
  findEmployeesStatus: PromiseStatuses;
  importCSVStatus: PromiseStatuses;
  importCSVResponse?: ImportCSVResponseDTO;
  selectedIds: string[];
  showPopup: boolean;
  getCSVStatus: PromiseStatuses
  companiesCountResponse: { toActivate: number }
  companiesCountStatus: PromiseStatuses,
  tabValueDetailsCompany: 0 | 1 | 2
  employeeToDelete?: string
  errors: {
    validateEdit: {
      vat: boolean,
      email: boolean[],
      phone: boolean[],
      status: PromiseStatuses
    }
  },
  companiesBulkCSVStatus: PromiseStatuses
  companiesBulkCSVResponse?: string
  companiesBulkCSVFilters: BulkCSVCompaniesFilters
  atecoFilterSign: '=' | '~' | '><' | undefined
}

const initialState: CompaniesState = {
  deleteBulkStatus: 'idle',
  deleteCompanyStatus: "idle",
  findCompanyStatus: "idle",
  editCompanyRequest: {
    name: "",
    revenue: 0,
    phones: [],
    emails: [],
    address: "",
    vat: "",
    employeesNumber: 0,
    website: "",
    active: false,
    atecos: [{ atecoCode: null, sector: null, category: null }],
    toVerifyFields: null
  },
  editCompanyStatus: "idle",
  findEmployeesStatus: "idle",
  findEmployeesResponse: [],
  importCSVStatus: "idle",
  selectedIds: [],
  showPopup: false,
  getCSVStatus: 'idle',
  companiesCountResponse: { toActivate: 0 },
  companiesCountStatus: 'idle',
  tabValueDetailsCompany: 0,
  errors: {
    validateEdit: {
      vat: false,
      email: [],
      phone: [],
      status: 'idle'
    }
  },
  companiesBulkCSVStatus: "idle",
  companiesBulkCSVFilters: {
    name: [],
    atecos: undefined,
    sector: [],
    category: [],
    department: [],
    role: [],
    zipCode: undefined,
    countryRegion: [],
    city: undefined,
    targetList: [],
    project: [],
    outcome: [],
    particularity: [],
    outcomeInfo: [],
    province: []
  },
  atecoFilterSign: undefined
};

export const validateEditCompany = createAsyncThunk(
  "companies/validateEditCompany",
  async (
    request: EditCompanyRequest,
    thunkApi
  ): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateCompanyEditRequestEmail([]));
    thunkApi.dispatch(setValidateCompanyEditRequestPhone([]));
    thunkApi.dispatch(setValidateCompanyEditRequestVat(false));
    thunkApi.dispatch(setValidateCompanyEditRequestStatus("idle"));

    if (request.emails) {
      const errors: boolean[] = []
      request.emails.forEach((email) => {
        if (request.emails.length === 1 && request.emails[0] === '') {
          errors.push(false)
          return
        }
        if (!emailRegex.test(email)) {
          errors.push(true)
          isValid = false
        } else {
          errors.push(false)
        }
      })
      thunkApi.dispatch(setValidateCompanyEditRequestEmail([...errors]));
    }

    if (request.vat && !vatRegex.test(request.vat)) {
      thunkApi.dispatch(setValidateCompanyEditRequestVat(true));
      isValid = false;
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async (id: string[]): Promise<void> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.deleteCompany(id);
  }
);

export const countCompanies = createAsyncThunk(
  "companies/countCompanies",
  async (): Promise<{ toActivate: number }> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.countCompanies();
  }
);

export const getCSVCompanies = createAsyncThunk(
  "companies/getCSVCompanies",
  async (request: string[]): Promise<string> => {
    const CompaniesService = NewCompaniesService();

    return CompaniesService.getCSV(request);
  }
);

export const findCompany = createAsyncThunk(
  "companies/findCompany",
  async (id: string): Promise<CompanyDTO> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.findCompanyById(id);
  }
);

export const editCompany = createAsyncThunk(
  "companies/editCompany",
  async (request: { id: string; data: EditCompanyRequest }): Promise<void> => {
    const CompanyService = NewCompaniesService();

    return CompanyService.editCompany(request.id, request.data);
  }
);

export const findEmployees = createAsyncThunk(
  "companies/findEmployees",
  async (ids: string[], thunkApi): Promise<PersonDTO[]> => {
    if (ids.length === 0 || !ids || ids === null) {
      return Promise.resolve([])
    }
    let promises: Promise<PersonDTO>[] = [];
    promises = ids.map(async (id) => {
      const personService = NewPeopleService();

      return personService.findPersonById(id);
    });
    return Promise.all(promises);
  }
);

export const importCSV = createAsyncThunk(
  "companies/importCSV",
  async (request: FormData): Promise<ImportCSVResponseDTO> => {
    const companyService = NewCompaniesService();

    return companyService.importContact(request);
  }
);

export const bulkCSVCompanies = createAsyncThunk(
  "companies/bulkCSV",
  async (request: FindAllContactsFilters): Promise<string> => {
    const companyService = NewCompaniesService();

    return companyService.bulkCSV(request);
  }
);

export const deleteBulkCompanies = createAsyncThunk(
  "companies/deleteBulkCompanies",
  async (request: FindAllContactsFilters): Promise<void> => {
    const companyService = NewCompaniesService();

    return companyService.deleteBulk(request);
  }
);

const companiesSlice = createSlice({
  name: "companies/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteBulkCompanies.pending, (state) => {
        state.deleteBulkStatus = "loading";
      })
      .addCase(deleteBulkCompanies.fulfilled, (state, action) => {
        state.deleteBulkStatus = "successfully";
      })
      .addCase(deleteBulkCompanies.rejected, (state) => {
        state.deleteBulkStatus = "failed";
      })
      .addCase(validateEditCompany.pending, (state) => {
        state.errors.validateEdit.status = "loading";
      })
      .addCase(validateEditCompany.fulfilled, (state, action) => {
        state.errors.validateEdit.status = "successfully";
      })
      .addCase(validateEditCompany.rejected, (state) => {
        state.errors.validateEdit.status = "failed";
      })
      .addCase(countCompanies.pending, (state) => {
        state.companiesCountStatus = "loading";
      })
      .addCase(countCompanies.fulfilled, (state, action) => {
        state.companiesCountStatus = "successfully";
        state.companiesCountResponse = action.payload;
      })
      .addCase(countCompanies.rejected, (state) => {
        state.companiesCountStatus = "failed";
      })
      .addCase(deleteCompany.pending, (state) => {
        state.deleteCompanyStatus = "loading";
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.deleteCompanyStatus = "successfully";
      })
      .addCase(deleteCompany.rejected, (state) => {
        state.deleteCompanyStatus = "failed";
      })
      .addCase(findCompany.pending, (state) => {
        state.findCompanyStatus = "loading";
      })
      .addCase(findCompany.fulfilled, (state, action) => {
        state.findCompanyStatus = "successfully";
        state.findCompanyResponse = action.payload;
      })
      .addCase(findCompany.rejected, (state) => {
        state.findCompanyStatus = "failed";
      })
      .addCase(editCompany.pending, (state) => {
        state.editCompanyStatus = "loading";
      })
      .addCase(editCompany.fulfilled, (state, action) => {
        state.editCompanyStatus = "successfully";
      })
      .addCase(editCompany.rejected, (state) => {
        state.editCompanyStatus = "failed";
      })
      .addCase(findEmployees.pending, (state) => {
        state.findEmployeesStatus = "loading";
      })
      .addCase(findEmployees.fulfilled, (state, action) => {
        state.findEmployeesStatus = "successfully";
        state.findEmployeesResponse = action.payload;
      })
      .addCase(findEmployees.rejected, (state) => {
        state.findEmployeesStatus = "failed";
      })
      .addCase(importCSV.pending, (state) => {
        state.importCSVStatus = "loading";
      })
      .addCase(importCSV.fulfilled, (state, action) => {
        state.importCSVStatus = "successfully";
        state.importCSVResponse = action.payload;
      })
      .addCase(importCSV.rejected, (state) => {
        state.importCSVStatus = "failed";
      })
      .addCase(getCSVCompanies.pending, (state) => {
        state.getCSVStatus = "loading";
      })
      .addCase(getCSVCompanies.fulfilled, (state, action) => {
        downloadCSVfromBase64(action.payload, "cd_companies_download_" + formatDate(new Date(), "dd_MM_yyyy"))
        state.getCSVStatus = "successfully";
        state.selectedIds = []
      })
      .addCase(getCSVCompanies.rejected, (state) => {
        state.getCSVStatus = "failed";
      })
      .addCase(bulkCSVCompanies.pending, (state) => {
        state.companiesBulkCSVStatus = "loading";
      })
      .addCase(bulkCSVCompanies.fulfilled, (state, action) => {
        downloadCSVfromBase64(action.payload, "cd_companies_download_" + formatDate(new Date(), "dd_MM_yyyy"))
        state.companiesBulkCSVStatus = "successfully";
      })
      .addCase(bulkCSVCompanies.rejected, (state) => {
        state.companiesBulkCSVStatus = "failed";
      });
  },
  reducers: {
    setDeleteBulkCompaniesStatus: (state, action) => {
      state.deleteBulkStatus = action.payload;
    },
    setFindCompanyResponse: (state, action) => {
      state.findCompanyResponse = action.payload;
    },
    setValidateCompanyEditRequestEmail: (state, action) => {
      state.errors.validateEdit.email = action.payload;
    },
    setValidateCompanyEditRequestPhone: (state, action) => {
      state.errors.validateEdit.phone = action.payload;
    },
    setValidateCompanyEditRequestStatus: (state, action) => {
      state.errors.validateEdit.status = action.payload;
    },
    setValidateCompanyEditRequestVat: (state, action) => {
      state.errors.validateEdit.vat = action.payload;
    },
    setEmployeeToDelete: (state, action) => {
      state.employeeToDelete = action.payload;
    },
    setDeleteCompanyStatus: (state, action) => {
      state.deleteCompanyStatus = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
    resetEditCompanyRequest: (state) => {
      state.editCompanyRequest = {
        name: "",
        revenue: 0,
        phones: [],
        emails: [],
        address: "",
        vat: "",
        employeesNumber: 0,
        website: "",
        active: false,
        atecos: [{ atecoCode: null, sector: null, category: null }],
        toVerifyFields: null
      }
    },
    resetValidateEditCompanyRequest: (state) => {
      state.errors.validateEdit = {
        vat: false,
        email: [],
        phone: [],
        status: "idle"
      }
    },
    setEditCompanyVat: (state, action) => {
      state.editCompanyRequest.vat = action.payload;
    },
    setEditCompanyAtecos: (state, action) => {
      state.editCompanyRequest.atecos = action.payload;
    },
    setEditCompanyRevenue: (state, action) => {
      state.editCompanyRequest.revenue = action.payload;
    },
    setEditCompanyAddress: (state, action) => {
      state.editCompanyRequest.address = action.payload;
    },
    setEditCompanyRegion: (state, action) => {
      state.editCompanyRequest.countryRegion = action.payload;
    },
    setEditCompanyCity: (state, action) => {
      state.editCompanyRequest.city = action.payload;
    },
    setEditCompanyProvince: (state, action) => {
      state.editCompanyRequest.province = action.payload;
    },
    setEditCompanyCap: (state, action) => {
      state.editCompanyRequest.zipCode = action.payload;
    },
    setEditCompanyState: (state, action) => {
      state.editCompanyRequest.state = action.payload;
    },
    setEditCompanyName: (state, action) => {
      state.editCompanyRequest.name = action.payload;
    },
    setEditCompanyWebSite: (state, action) => {
      state.editCompanyRequest.website = action.payload;
    },
    setEditCompanyEmail: (state, action) => {
      state.editCompanyRequest.emails = action.payload;
    },
    setEditCompanyPhone: (state, action) => {
      state.editCompanyRequest.phones = action.payload;
    },
    setEditCompanyEmployeesNumber: (state, action) => {
      state.editCompanyRequest.employeesNumber = action.payload;
    },
    setEditCompanyToVerifyFields: (state, action) => {
      state.editCompanyRequest.toVerifyFields = action.payload;
    },
    setEditCompanyStatus: (state, action) => {
      state.editCompanyStatus = action.payload;
    },
    setFindEmployeesStatus: (state, action) => {
      state.findEmployeesStatus = action.payload;
    },
    setFindEmployeesResponse: (state, action) => {
      state.findEmployeesResponse = action.payload;
    },
    setSelectedCompanyNameForEmployees: (state, action) => {
      state.selectedCompanyNameForEmployees = action.payload;
    },
    setImportCSVStatus: (state, action) => {
      state.importCSVStatus = action.payload;
    },
    setImportCSVSResponse: (state, action) => {
      state.importCSVResponse = action.payload;
    },
    setSelectedIdsCompanies: (state, action) => {
      state.selectedIds = action.payload
    },
    setShowPopupCompanies: (state, action) => {
      state.showPopup = action.payload
    },
    setCountCompaniesStatus: (state, action) => {
      state.companiesCountStatus = action.payload
    },
    setCountCompaniesResponse: (state, action) => {
      state.companiesCountResponse = action.payload
    },
    setTabValueDetailsCompany: (state, action) => {
      state.tabValueDetailsCompany = action.payload
    },
    setFindCompanyStatus: (state, action) => {
      state.findCompanyStatus = action.payload
    },
    setAtecoFilterSign: (state, action) => {
      state.atecoFilterSign = action.payload
    }
  },
});

export const {
  setDeleteCompanyStatus,
  setSelectedCompany,
  setEditCompanyAddress,
  setEditCompanyAtecos,
  setEditCompanyCap,
  setEditCompanyCity,
  setEditCompanyEmail,
  setEditCompanyName,
  setEditCompanyPhone,
  setEditCompanyProvince,
  setEditCompanyRegion,
  setEditCompanyState,
  setEditCompanyVat,
  setEditCompanyWebSite,
  setEditCompanyEmployeesNumber,
  setEditCompanyStatus,
  setFindEmployeesStatus,
  setSelectedCompanyNameForEmployees,
  setFindEmployeesResponse,
  setImportCSVSResponse,
  setImportCSVStatus,
  setEditCompanyRevenue,
  setSelectedIdsCompanies,
  setShowPopupCompanies,
  setCountCompaniesResponse,
  setCountCompaniesStatus,
  setTabValueDetailsCompany,
  setEditCompanyToVerifyFields,
  setEmployeeToDelete,
  setValidateCompanyEditRequestEmail,
  setValidateCompanyEditRequestPhone,
  setValidateCompanyEditRequestStatus,
  setValidateCompanyEditRequestVat,
  setFindCompanyResponse,
  setFindCompanyStatus,
  resetEditCompanyRequest,
  resetValidateEditCompanyRequest,
  setAtecoFilterSign,
  setDeleteBulkCompaniesStatus
} = companiesSlice.actions;

export default companiesSlice.reducer;
