import { useEffect, useState } from "react";
import { GlobeIcon } from "../../../ui/icons/globe";
import Input from "../../../ui/molecules/input";
import { AccordionFilter } from "../../../ui/organisms/accordionFilter";
import { useDebounce } from "../../hooks/debounce";
import { FindAllContactsFilters } from "../dto";

interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
}

export function CityFilter(props: Props) {
    const { updateFilters, filters } = props

    const [value, setValue] = useState(
        filters.companyCity?.value ?? ""
    );

    const debounceCompanyFilter = useDebounce((value) => {
        updateFilters(value ? { companyCity: { label: value, value: value } } : { companyCity: undefined });

    }, 1000);

    useEffect(() => {
        setValue(filters.companyCity?.value ?? "");
    }, [filters.companyCity]);

    return (
        <AccordionFilter
            key={'person-filter-city'}
            label={"Città azienda"}
            icon={<GlobeIcon color={""} size={0} />}
            showClear={filters.companyCity !== undefined}
            hideDelete
            options={filters.companyCity !== undefined ? [filters.companyCity] : undefined}
            clearAction={() => updateFilters({ companyCity: undefined })}
            deleteFilter={() => updateFilters({ companyCity: undefined })}
        >
            <Input
                key={'find-person-filter-city'}
                placeholder="Cerca per città"
                onChangeText={(value) => {
                    setValue(value);
                    debounceCompanyFilter(value);
                }}
                value={value === undefined ? "" : value}
            />
        </AccordionFilter>
    )
}