import clsx from "clsx";
import { ContactType } from "../../../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { XCircleIcon } from "../../../../ui/icons/xCircle";
import Button from "../../../../ui/molecules/button";
import { setImportContactCSVResponse, setImportContactCSVStatus } from "../slice";
import { ContactMissed } from "./contactMissed";

interface Props {
  contactType: ContactType
}

export function ImportModalContacts(props: Props) {
  const { contactType } = props
  const contactsState = useAppSelector(state => state.contacts)

  const dispatch = useAppDispatch();

  return (
    <div
      onClick={() => {
        dispatch(setImportContactCSVStatus("idle"));
        dispatch(setImportContactCSVResponse(undefined));
      }}
      className={clsx(
        "transition-all fixed bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]",
        {
          block:
            contactsState.importContactStatus === "successfully" ||
            contactsState.importContactStatus === "failed",
          hidden: !(
            contactsState.importContactStatus === "successfully" ||
            contactsState.importContactStatus === "failed"
          ),
        }
      )}
    >
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="transition-all flex flex-col items-center gap-5 px-[40px] py-6 bg-white rounded-[30px] opacity-100 shadow-sm min-w-[400px]"
      >
        {
          contactsState.importContactStatus === "successfully" && contactsState.importContactResponse?.missed.length && contactsState.importContactResponse?.missed.length >= 0 ?
            <ContactMissed contactType={contactType} /> :
            <>
              {contactsState.importContactStatus === "successfully" ? (
                <CheckCircleIcon color={colors.green[500]} size={70} />
              ) : (
                <XCircleIcon color={colors.red[500]} size={70} />
              )}
              <span className="text-text-2xl font-semibold text-neutral-800">
                {contactsState.importContactStatus === "successfully"
                  ? contactsState.importContactResponse?.ids.length +
                  " contatti importati con successo"
                  : "Impossibile importare i contatti"}
              </span>
              {contactsState.importContactResponse !== undefined &&
                contactsState.importContactResponse.missed.length > 0 && (
                  <div className="flex flex-col gap-3 bg-neutral-50 p-6 rounded-[16px]">
                    <span className="text-text-md font-medium text-neutral-700 text-start justify-start items-start w-full">
                      Attenzione
                    </span>
                    <span className="text-text-md font-medium text-neutral-700 text-start justify-start items-start w-full">
                      {contactsState.importContactResponse.missed.length} contatti{" "}
                      <span className="font-normal">
                        nelle seguenti righe presentano errori e non sono stati
                        importati:
                      </span>
                    </span>
                    <ul className="overflow-auto max-h-40 w-full flex flex-col gap-1">
                      {contactsState.importContactResponse.missed.map((line) => (
                        <li key={line} className="flex flex-col gap-[4px]">
                          <span className="text-text-md font-medium text-neutral-700 block pl-[8px]">
                            {line}
                          </span>
                          <div className="w-full h-[1px] bg-neutral-200" />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              <div className="w-[30%]">
                <Button
                  size={"md"}
                  iconPosition={"off"}
                  variant={"solid"}
                  color={
                    contactsState.importContactStatus === "successfully"
                      ? "green"
                      : "red"
                  }
                  fullWidth
                  label="OK"
                  onClick={() => {
                    dispatch(setImportContactCSVStatus("idle"));
                    dispatch(setImportContactCSVResponse(undefined));
                  }}
                />
              </div>
            </>
        }
      </div>
    </div>
  );
}
