import { AtecoDTO } from "./dto";
import { AtecosServiceImpl } from "./serviceImpl";

export interface AtecosService {
  findAllAtecos(): Promise<AtecoDTO[]>;
}

export function NewAtecosService(): AtecosService {
  return new AtecosServiceImpl();
}
