import { useState } from "react";
import { TargetListPageEnum } from "../../pages/targetList/dto";
import Button from "../../ui/molecules/button";
import { useAppSelector } from "../redux/hooks";
import { AtecoFilter } from "./accordion/ateco";
import { CapFilter } from "./accordion/cap";
import { CategoryFilter } from "./accordion/category";
import { CityFilter } from "./accordion/city";
import { CompanyFilter } from "./accordion/company";
import { ContactCreationFilter } from "./accordion/contactCreationFilter";
import { DateOfContactFilter } from "./accordion/dateOfContactFilter";
import { DepartmentFilter } from "./accordion/department";
import { EmployeesNumberFilter } from "./accordion/employeesNumber";
import { OutcomeFilter } from "./accordion/outcome";
import { ParticularitiesFilters } from "./accordion/particularitiesFilters";
import { PhoneFilter } from "./accordion/phoneFilter";
import { PrivacyFilter } from "./accordion/privacy";
import { ProjectFilter } from "./accordion/project";
import { RegionFilter } from "./accordion/region";
import { RevenueFilter } from "./accordion/revenue";
import { RoleFilter } from "./accordion/role";
import { SectorFilter } from "./accordion/sector";
import { SmartphoneFilter } from "./accordion/smartphone";
import { StateFilter } from "./accordion/state";
import { TargetFilter } from "./accordion/target";
import { ContactType, FindAllContactsFilters } from "./dto";

interface Props {
  contactType: ContactType
  isInTargetList: boolean
  resetFilters: () => void
  filters: FindAllContactsFilters
  updateFilters: (newFilters: Partial<FindAllContactsFilters>) => void
}

export function Filters(props: Props) {
  const { isInTargetList, resetFilters, filters, updateFilters, contactType } = props
  const targetListsState = useAppSelector((state) => state.targetList);

  const [atecoSign, setAtecoSign] = useState<string | undefined>(undefined)
  const [inputs, setInputs] = useState<string[]>([""]);

  const filtersCount = Object.entries(filters).filter(
    (entr) =>
      entr[0] !== "page" &&
      entr[0] !== "itemsPerPage" &&
      entr[0] !== "order" &&
      entr[0] !== "sort" &&
      entr[0] !== "personName" &&
      entr[0] !== "personEmail" &&
      entr[0] !== "targetListBin" &&
      entr[0] !== "blackListBin" &&
      entr[0] !== "toVerifyBin" &&
      entr[0] !== "targetListBin" &&
      entr[0] !== "hasCompany" &&
      entr[0] !== "active" &&
      entr[1] !== undefined &&
      entr[1].length !== 0 &&
      (window.location.pathname.includes("target") &&
        targetListsState.targetListPage !== TargetListPageEnum.DB
        ? entr[0] !== "project" && entr[0] !== "targetList"
        : true)
  ).length;

  return (
    <div className="bg-white border-r h-full border-neutral-200 rounded-xl rounded-tr-none w-full relative">
      <div className="px-[24px] h-16 flex items-center justify-between border-b border-neutral-200 sticky">
        <span className="text-text-md text-neutral-800 font-bold">
          {"Filtri " + (filtersCount === 0 ? "" : "(" + filtersCount + ")")}
        </span>
        <div className="flex items-center">
          <Button
            size={"sm"}
            iconPosition={"off"}
            variant={"link"}
            color={"blue"}
            label="Pulisci tutti"
            onClick={() => {
              resetFilters();
              setAtecoSign(undefined)
              setInputs([""])
            }}
          />
        </div>
      </div>
      <div className="overflow-auto rounded-b-xl" style={{ height: 'calc(100% - 64px)' }}>
        <CompanyFilter isInTargetList={isInTargetList} updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <RevenueFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <OutcomeFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <EmployeesNumberFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        {(!window.location.pathname.includes("target") ||
          (window.location.pathname.includes("target") &&
            targetListsState.targetListPage === TargetListPageEnum.DB)) && (
            <>
              <ProjectFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
              <TargetFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
            </>
          )}
        <AtecoFilter
          updateFilters={(filters) => updateFilters(filters)}
          filters={filters}
          setAtecoSign={sign => setAtecoSign(sign)}
          atecoSign={atecoSign}
          inputs={inputs}
          setInputs={(input) => setInputs(input)}
        />
        <SectorFilter atecoSign={atecoSign} updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <CategoryFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <DepartmentFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <RoleFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <SmartphoneFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <PrivacyFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <StateFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <RegionFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        {/* <ProvinceFilter /> */}
        <CityFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <CapFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <ParticularitiesFilters updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <PhoneFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} contactType={contactType} />
        <ContactCreationFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
        <DateOfContactFilter updateFilters={(filters) => updateFilters(filters)} filters={filters} />
      </div>
    </div>
  );
}
