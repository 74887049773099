import clsx from "clsx";
import { cloneElement, useEffect, useRef, useState } from "react";
import { colors } from "../../colors";
import { ChevronIcon } from "../../icons/chevron";
import { TabMenuProps } from "./dto";

export function TabMenu(props: TabMenuProps) {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const handleClick = (index: number) => {
        const newDirection = index > props.value ? "left" : "right";
        props.setDirection(newDirection);
        props.setValue(index);
    };

    const updateScrollButtons = () => {
        if (scrollRef.current) {
            setCanScrollLeft(scrollRef.current.scrollLeft > 0);
            setCanScrollRight(
                scrollRef.current.scrollLeft < scrollRef.current.scrollWidth - scrollRef.current.clientWidth
            );
        }
    };

    useEffect(() => {
        updateScrollButtons();
        const handleResize = () => updateScrollButtons();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleScroll = (direction: "left" | "right") => {
        if (scrollRef.current) {
            const scrollAmount = 150; // Distanza di scroll
            scrollRef.current.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="relative flex items-center">
            {canScrollLeft && (
                <button
                    className="absolute left-0 z-10 h-full bg-gradient-to-r from-white/90 to-transparent px-2"
                    onClick={() => handleScroll("left")}
                >
                    <ChevronIcon color={colors.neutral[700]} size={20} direction="l" />
                </button>
            )}
            <div
                ref={scrollRef}
                onScroll={updateScrollButtons}
                className="flex gap-5 px-6 mb-[-1px] overflow-x-auto madfarm-tabs scrollbar-hide bg-white"
            >
                {props.items.filter(tab => !tab.hide).map((item, index) => (
                    <div
                        key={'menu-tab-' + index}
                        onClick={() => !item.disabled && handleClick(index)}
                        className={clsx(
                            "h-[64px] flex-shrink-0 flex gap-[8px] border-b-2 items-center cursor-pointer transition-all",
                            {
                                'border-transparent': index !== props.value,
                                "border-brandPrimary-500": index === props.value,
                                "opacity-80 cursor-default": item.disabled
                            }
                        )}
                    >
                        {cloneElement(item.icon, { size: 16, color: index !== props.value ? colors.neutral[500] : colors.brandPrimary[500] })}
                        <span className={clsx(
                            "text-label-sm",
                            {
                                "text-brandPrimary-500 font-semibold": index === props.value,
                                "text-neutral-600": index !== props.value,
                            }
                        )}
                        >
                            {item.label}
                        </span>
                    </div>
                ))}
            </div>
            {canScrollRight && (
                <button
                    className="absolute right-0 z-10 h-full bg-gradient-to-l from-white/90 to-transparent px-2"
                    onClick={() => handleScroll("right")}
                >
                    <ChevronIcon color={colors.neutral[700]} size={20} direction="r" />
                </button>
            )}
        </div>
    );
}
