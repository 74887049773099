import { CompanyPhoneDTO } from "../companies/dto";

export interface PersonDTO {
  id: string;
  name: string;
  surname: string;
  phones: string[];
  emails: string[];
  linkedinProfile: string;
  role: string;
  contactCount: number
  department: string;
  privacy: boolean;
  companyId: string;
  companyName: string;
  lastContactDate?: Date;
  active: boolean;
  deletedAt: Date | null
  disabled: null | boolean,
  toVerifyFields: null | string[]
}

export interface NewPersonRequest {
  name: string,
  surname: string,
  phones: string[] | null,
  emails: string[] | null,
  linkedinProfile: string,
  role?: string,
  privacy: boolean,
  companyName: string,
  companyVat: string,
  companyPhones: CompanyPhoneDTO[]
}

export interface FindAllPeopleResponse {
  total: number;
  page: number;
  perPage: number;
  prev: false;
  next: false;
  totalPage: number;
  data: PersonDTO[];
}

export interface EditPersonRequest {
  name: string;
  surname: string;
  phones: string[];
  emails: string[];
  linkedinProfile: string;
  role: string;
  department: string;
  privacy: boolean;
  companyId: string;
  toVerifyFields: null | string[]
}

export interface PeopleHeader {
  name: string[];
  surname: string[];
  role: string[];
  department: string[];
  phones: string[];
  emails: string[];
  linkedinProfile: string[];
  privacy: string[];
  companyName: string[];
  companyVat: string[];
  companyPhone: string[];
}

export interface AssociatePersonToCompany {
  companyId?: string;
  companyName?: string;
  companyVat?: string;
  companyPhone: string[];
}

export const TabValuePersonStringToNumberMap = new Map<'form' | 'company' | 'notes' | 'emails' | 'privacy' | 'recall', number>([
  ['form', 0],
  ['company', 1],
  ['notes', 3],
  ['emails', 4],
  ['privacy', 5],
  ['recall', 2]
])

export const TabValuePersonNumberToStringMap = new Map<number, 'form' | 'company' | 'notes' | 'emails' | 'privacy' | 'recall'>([
  [0, 'form'],
  [1, 'company'],
  [2, 'notes'],
  [3, 'emails'],
  [4, 'privacy'],
  [5, 'recall']
])