import { OTPServiceImpl } from "./serviceImpl";

export interface OTPService {
  otpRequest(phoneNumber: string): Promise<void>
  otpVerify(phoneNumber: string, code: string): Promise<void>
}

export function NewOTPService(): OTPService {
  return new OTPServiceImpl();
}
