import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { formatDate } from "date-fns";
import { FindAllContactsFilters } from "../../../lib/filters/dto";
import { emailRegex } from "../../../lib/regex";
import { PromiseStatuses } from "../../../lib/types";
import { downloadCSVfromBase64 } from "../../../lib/utils";
import { BulkCSVFilters, ImportCSVResponseDTO } from "../dto";
import {
  AssociatePersonToCompany,
  EditPersonRequest,
  NewPersonRequest,
  PersonDTO
} from "./dto";
import { NewPeopleService } from "./service";

interface PeopleState {
  deletePersonStatus: PromiseStatuses;
  deleteBulkStatus: PromiseStatuses;
  idToDelete?: string;
  findPersonStatus: PromiseStatuses;
  findPersonResponse?: PersonDTO;
  editPersonRequest: EditPersonRequest;
  editPersonStatus: PromiseStatuses;
  selectedPerson?: string;
  personToAddCompany?: PersonDTO;
  importCSVStatus: PromiseStatuses;
  importCSVResponse?: ImportCSVResponseDTO;
  getCSVStatus: PromiseStatuses;
  errors: {
    validateAssociate: {
      name: boolean;
      phone: boolean;
      vat: boolean;
      id: boolean;
      status: PromiseStatuses;
    };
    validateCreate: {
      name: boolean
      surname: boolean,
      email: boolean[],
      phone: boolean[]
      status: PromiseStatuses
    },
    validateUpdate: {
      name: boolean
      surname: boolean,
      email: boolean[],
      phone: boolean[]
      status: PromiseStatuses
    }
  };
  associatePersonToCompanyStatus: PromiseStatuses;
  selectedIds: string[];
  showPopup: boolean;
  tabValueDetailsPerson: 'form' | 'company' | 'notes' | 'emails' | 'privacy' | 'recall'
  personCountStatus: PromiseStatuses
  personCountResponse: { toActivate: number }
  newPersonRequest: NewPersonRequest
  newPersonStatus: PromiseStatuses
  newPersonResponseMessage: string
  newPersonResponse?: {
    id: string,
    alreadyExists: boolean
  }
  peopleBulkCSVStatus: PromiseStatuses
  peopleBulkCSVResponse?: string
  peopleBulkCSVFilters: BulkCSVFilters
}

const initialState: PeopleState = {
  deleteBulkStatus: 'idle',
  deletePersonStatus: "idle",
  findPersonStatus: "idle",
  editPersonRequest: {
    toVerifyFields: null,
    name: "",
    surname: "",
    role: "",
    department: "",
    privacy: false,
    phones: [""],
    emails: [""],
    linkedinProfile: "",
    companyId: "",
  },
  editPersonStatus: "idle",
  importCSVStatus: "idle",
  errors: {
    validateAssociate: {
      name: false,
      phone: false,
      vat: false,
      id: false,
      status: "idle",
    },
    validateCreate: {
      name: false,
      surname: false,
      email: [],
      phone: [],
      status: 'idle'
    },
    validateUpdate: {
      name: false,
      surname: false,
      email: [],
      phone: [],
      status: 'idle'
    }
  },
  associatePersonToCompanyStatus: "idle",
  selectedIds: [],
  getCSVStatus: 'idle',
  showPopup: false,
  tabValueDetailsPerson: 'form',
  personCountResponse: { toActivate: 0 },
  personCountStatus: 'idle',
  newPersonRequest: {
    name: "",
    surname: "",
    phones: [''],
    emails: [''],
    linkedinProfile: "",
    privacy: false,
    companyName: "",
    companyVat: "",
    companyPhones: []
  },
  newPersonStatus: 'idle',
  newPersonResponseMessage: '',
  peopleBulkCSVStatus: "idle",
  peopleBulkCSVFilters: {
    name: [],
    atecos: undefined,
    sector: [],
    category: [],
    department: [],
    role: [],
    countryRegion: [],
    city: undefined,
    targetList: [],
    project: [],
    outcome: [],
    particularity: [],
    outcomeInfo: [],
    province: []
  },
};

export const deleteBulkPeople = createAsyncThunk(
  "people/deleteBulkPeople",
  async (request: FindAllContactsFilters): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.deleteBulk(request);
  }
);

export const personCount = createAsyncThunk(
  "people/personCount",
  async (): Promise<{ toActivate: number }> => {
    const PeopleService = NewPeopleService();

    return PeopleService.personCount();
  }
);

export const getCSVPeople = createAsyncThunk(
  "people/getCSVPeople",
  async (request: string[]): Promise<string> => {
    const PeopleService = NewPeopleService();

    return PeopleService.getCSV(request);
  }
);

export const createPerson = createAsyncThunk(
  "people/createPerson",
  async (request: NewPersonRequest, thunkApi): Promise<{
    id: string,
    alreadyExists: boolean
  }> => {
    const PeopleService = NewPeopleService();

    return PeopleService.createPerson(request).catch((error) => {
      throw thunkApi.rejectWithValue(error);
    });
  }
);

export const deletePerson = createAsyncThunk(
  "people/deletePerson",
  async (id: string[]): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.deletePerson(id);
  }
);

export const findPerson = createAsyncThunk(
  "people/findPerson",
  async (id: string): Promise<PersonDTO> => {
    const PeopleService = NewPeopleService();

    return PeopleService.findPersonById(id);
  }
);

export const editPerson = createAsyncThunk(
  "people/editPerson",
  async (request: { id: string; data: EditPersonRequest }): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.updatePerson(request.id, request.data);
  }
);

export const importCSV = createAsyncThunk(
  "people/importCSV",
  async (request: FormData): Promise<ImportCSVResponseDTO> => {
    const PeopleService = NewPeopleService();

    return PeopleService.importContact(request);
  }
);

export const bulkCSVPeople = createAsyncThunk(
  "people/bulkCSV",
  async (request: FindAllContactsFilters): Promise<string> => {
    const PeopleService = NewPeopleService();

    return PeopleService.bulkCSV(request);
  }
);

export const associatePersonToCompany = createAsyncThunk(
  "people/associatePersonToCompany",
  async (request: {
    data: AssociatePersonToCompany;
    personId: string;
  }): Promise<void> => {
    const PeopleService = NewPeopleService();

    return PeopleService.associateCompany(request.data, request.personId);
  }
);

export const validateAssociatePersonToCompany = createAsyncThunk(
  "people/validateAssociatePersonToCompany",
  async (
    request: { body: AssociatePersonToCompany; notFound: boolean },
    thunkApi
  ): Promise<void> => {
    let isValid = true;

    const body = request.body;

    thunkApi.dispatch(setErrorAssociateName(false));
    thunkApi.dispatch(setErrorAssociatePhone(false));
    thunkApi.dispatch(setErrorAssociateId(false));
    thunkApi.dispatch(setErrorAssociateVat(false));
    thunkApi.dispatch(setErrorAssociateStatus("idle"));

    if (
      !request.notFound &&
      (body.companyId === undefined ||
        body.companyId === "" ||
        !(body.companyId.length > 0))
    ) {
      thunkApi.dispatch(setErrorAssociateId(true));
      isValid = false;
    } else if (request.notFound) {
      if (body.companyName === "" || !body.companyName) {
        thunkApi.dispatch(setErrorAssociateName(true));
        isValid = false;
      }

      if (
        body.companyVat &&
        (body.companyVat.length < 11 || body.companyVat.length > 16)
      ) {
        thunkApi.dispatch(setErrorAssociateVat(true));
        isValid = false;
      }
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

export const validateCreatePerson = createAsyncThunk(
  "people/validateCreatePerson",
  async (
    request: NewPersonRequest,
    thunkApi
  ): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateCreatePersonName(false));
    thunkApi.dispatch(setValidateCreatePersonSurname(false));
    thunkApi.dispatch(setValidateCreatePersonEmail(false));
    thunkApi.dispatch(setValidateCreatePersonPhone(false));
    thunkApi.dispatch(setValidateCreatePersonStatus("idle"));

    if (!request.name || request.name === '') {
      thunkApi.dispatch(setValidateCreatePersonName(true));
      isValid = false;
    }

    if (!request.surname || request.surname === '') {
      thunkApi.dispatch(setValidateCreatePersonSurname(true));
      isValid = false;
    }

    if (request.emails) {
      const errors: boolean[] = []
      request.emails.forEach((email) => {
        if (!emailRegex.test(email)) {
          errors.push(true)
          isValid = false
        } else {
          errors.push(false)
        }
      })
      thunkApi.dispatch(setValidateCreatePersonEmail([...errors]));
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

export const validateUpdatePerson = createAsyncThunk(
  "people/validateUpdatePerson",
  async (
    request: EditPersonRequest,
    thunkApi
  ): Promise<void> => {
    let isValid = true;

    thunkApi.dispatch(setValidateUpdatePersonName(false));
    thunkApi.dispatch(setValidateUpdatePersonSurname(false));
    thunkApi.dispatch(setValidateUpdatePersonEmail(false));
    thunkApi.dispatch(setValidateUpdatePersonPhone(false));
    thunkApi.dispatch(setValidateUpdatePersonStatus("idle"));

    if (!request.name || request.name === '') {
      thunkApi.dispatch(setValidateUpdatePersonName(true));
      isValid = false;
    }

    if (!request.surname || request.surname === '') {
      thunkApi.dispatch(setValidateUpdatePersonSurname(true));
      isValid = false;
    }


    // if (request.phones && !phoneRegex.test(request.phones)) {
    //   thunkApi.dispatch(setValidateUpdatePersonPhone(true));
    //   isValid = false;
    // }

    if (request.emails) {
      const errors: boolean[] = []
      request.emails.forEach((email) => {
        if (!emailRegex.test(email)) {
          errors.push(true)
          isValid = false
        } else {
          errors.push(false)
        }
      })
      thunkApi.dispatch(setValidateUpdatePersonEmail([...errors]));
    }

    if (!isValid) {
      return Promise.reject();
    }

    return Promise.resolve();
  }
);

const peopleSlice = createSlice({
  name: "people/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(deleteBulkPeople.pending, (state) => {
        state.deleteBulkStatus = "loading";
      })
      .addCase(deleteBulkPeople.fulfilled, (state, action) => {
        state.deleteBulkStatus = "successfully";
      })
      .addCase(deleteBulkPeople.rejected, (state) => {
        state.deleteBulkStatus = "failed";
      })
      .addCase(personCount.pending, (state) => {
        state.personCountStatus = "loading";
      })
      .addCase(personCount.fulfilled, (state, action) => {
        state.personCountStatus = "successfully";
        state.personCountResponse = action.payload;
      })
      .addCase(personCount.rejected, (state) => {
        state.personCountStatus = "failed";
      })
      .addCase(deletePerson.pending, (state) => {
        state.deletePersonStatus = "loading";
      })
      .addCase(deletePerson.fulfilled, (state, action) => {
        state.deletePersonStatus = "successfully";
      })
      .addCase(deletePerson.rejected, (state) => {
        state.deletePersonStatus = "failed";
      })
      .addCase(findPerson.pending, (state) => {
        state.findPersonStatus = "loading";
      })
      .addCase(findPerson.fulfilled, (state, action) => {
        state.findPersonStatus = "successfully";
        state.findPersonResponse = action.payload;
      })
      .addCase(findPerson.rejected, (state) => {
        state.findPersonStatus = "failed";
      })
      .addCase(editPerson.pending, (state) => {
        state.editPersonStatus = "loading";
      })
      .addCase(editPerson.fulfilled, (state) => {
        state.editPersonStatus = "successfully";
      })
      .addCase(editPerson.rejected, (state) => {
        state.editPersonStatus = "failed";
      })
      .addCase(importCSV.pending, (state) => {
        state.importCSVStatus = "loading";
      })
      .addCase(importCSV.fulfilled, (state, action) => {
        state.importCSVStatus = "successfully";
        state.importCSVResponse = action.payload;
      })
      .addCase(importCSV.rejected, (state) => {
        state.importCSVStatus = "failed";
      })
      .addCase(validateAssociatePersonToCompany.pending, (state) => {
        state.errors.validateAssociate.status = "loading";
      })
      .addCase(validateAssociatePersonToCompany.fulfilled, (state) => {
        state.errors.validateAssociate.status = "successfully";
      })
      .addCase(validateAssociatePersonToCompany.rejected, (state) => {
        state.errors.validateAssociate.status = "failed";
      })
      .addCase(associatePersonToCompany.pending, (state) => {
        state.associatePersonToCompanyStatus = "loading";
      })
      .addCase(associatePersonToCompany.fulfilled, (state) => {
        state.associatePersonToCompanyStatus = "successfully";
      })
      .addCase(associatePersonToCompany.rejected, (state) => {
        state.associatePersonToCompanyStatus = "failed";
      })
      .addCase(getCSVPeople.pending, (state) => {
        state.getCSVStatus = "loading";
      })
      .addCase(getCSVPeople.fulfilled, (state, action) => {
        downloadCSVfromBase64(action.payload, "cd_people_download_" + formatDate(new Date(), "dd_MM_yyyy"))
        state.getCSVStatus = "successfully";
        state.selectedIds = []
      })
      .addCase(getCSVPeople.rejected, (state) => {
        state.getCSVStatus = "failed";
      })
      .addCase(validateCreatePerson.pending, (state) => {
        state.errors.validateCreate.status = "loading";
      })
      .addCase(validateCreatePerson.fulfilled, (state, action) => {
        state.errors.validateCreate.status = "successfully";
      })
      .addCase(validateCreatePerson.rejected, (state) => {
        state.errors.validateCreate.status = "failed";
      })
      .addCase(validateUpdatePerson.pending, (state) => {
        state.errors.validateUpdate.status = "loading";
      })
      .addCase(validateUpdatePerson.fulfilled, (state, action) => {
        state.errors.validateUpdate.status = "successfully";
      })
      .addCase(validateUpdatePerson.rejected, (state) => {
        state.errors.validateUpdate.status = "failed";
      })
      .addCase(createPerson.pending, (state) => {
        state.newPersonStatus = "loading";
      })
      .addCase(createPerson.fulfilled, (state, action) => {
        state.newPersonStatus = "successfully";
        state.newPersonResponse = action.payload
      })
      .addCase(createPerson.rejected, (state, action) => {
        const error = action.payload as AxiosError
        state.newPersonStatus = "failed";
        const errorList = error.response?.data as { errors: { message: string }[] }
        if (errorList && errorList.errors && errorList.errors.map(err => err.message).some(mess => mess.includes('already has a company'))) {
          state.newPersonResponseMessage = "Il contatto è già associato ad un'azienda."
        } else
          state.newPersonResponseMessage = "Si è verificato un errore durante la creazione del contatto."
      })
      .addCase(bulkCSVPeople.pending, (state) => {
        state.peopleBulkCSVStatus = "loading";
      })
      .addCase(bulkCSVPeople.fulfilled, (state, action) => {
        downloadCSVfromBase64(action.payload, "cd_person_download_" + formatDate(new Date(), "dd_MM_yyyy"))
        state.peopleBulkCSVStatus = "successfully";
      })
      .addCase(bulkCSVPeople.rejected, (state) => {
        state.peopleBulkCSVStatus = "failed";
      });
  },
  reducers: {
    setDeleteBulkPeopleStatus: (state, action) => {
      state.deleteBulkStatus = action.payload;
    },
    setDeletePersonStatus: (state, action) => {
      state.deletePersonStatus = action.payload;
    },
    setFindPersonResponse: (state, action) => {
      state.findPersonResponse = action.payload;
    },
    setFindPersonStatus: (state, action) => {
      state.findPersonStatus = action.payload;
    },
    setEditPersonName: (state, action) => {
      state.editPersonRequest.name = action.payload;
    },
    setEditPersonCompanyId: (state, action) => {
      state.editPersonRequest.companyId = action.payload;
    },
    setEditPersonSurname: (state, action) => {
      state.editPersonRequest.surname = action.payload;
    },
    setEditPersonRole: (state, action) => {
      state.editPersonRequest.role = action.payload;
    },
    setEditPersonDepartment: (state, action) => {
      state.editPersonRequest.department = action.payload;
    },
    setEditPersonPhone: (state, action) => {
      state.editPersonRequest.phones = action.payload;
    },
    setEditPersonEmail: (state, action) => {
      state.editPersonRequest.emails = action.payload;
    },
    setEditPersonLinkedinProfile: (state, action) => {
      state.editPersonRequest.linkedinProfile = action.payload;
    },
    setEditPersonPrivacy: (state, action) => {
      state.editPersonRequest.privacy = action.payload;
    },
    setEditPersonToVerifyFields: (state, action) => {
      state.editPersonRequest.toVerifyFields = action.payload;
    },
    setSelectedPerson: (state, action) => {
      state.selectedPerson = action.payload;
    },
    setEditPersonStatus: (state, action) => {
      state.editPersonStatus = action.payload;
    },
    setImportCSVStatus: (state, action) => {
      state.importCSVStatus = action.payload;
    },
    setImportCSVSResponse: (state, action) => {
      state.importCSVResponse = action.payload;
    },
    setPersonToAddCompany: (state, action) => {
      state.personToAddCompany = action.payload;
    },
    setErrorAssociateName: (state, action) => {
      state.errors.validateAssociate.name = action.payload;
    },
    setErrorAssociatePhone: (state, action) => {
      state.errors.validateAssociate.phone = action.payload;
    },
    setErrorAssociateVat: (state, action) => {
      state.errors.validateAssociate.vat = action.payload;
    },
    setErrorAssociateStatus: (state, action) => {
      state.errors.validateAssociate.status = action.payload;
    },
    setErrorAssociateId: (state, action) => {
      state.errors.validateAssociate.id = action.payload;
    },
    setAssociatePersonToCompanyStatus: (state, action) => {
      state.associatePersonToCompanyStatus = action.payload;
    },
    setSelectedIdsPeople: (state, action) => {
      state.selectedIds = action.payload
    },
    setShowPopupPeople: (state, action) => {
      state.showPopup = action.payload
    },
    setTabValueDetailsPerson: (state, action) => {
      state.tabValueDetailsPerson = action.payload
    },
    setPersonCountStatus: (state, action) => {
      state.personCountStatus = action.payload
    },
    setPersonCountResponse: (state, action) => {
      state.personCountResponse = action.payload
    },
    setValidateCreatePersonName: (state, action) => {
      state.errors.validateCreate.name = action.payload
    },
    setValidateCreatePersonSurname: (state, action) => {
      state.errors.validateCreate.surname = action.payload
    },
    setValidateCreatePersonEmail: (state, action) => {
      state.errors.validateCreate.email = action.payload
    },
    setValidateCreatePersonPhone: (state, action) => {
      state.errors.validateCreate.phone = action.payload
    },
    setValidateCreatePersonStatus: (state, action) => {
      state.errors.validateCreate.status = action.payload
    },
    setValidateUpdatePersonName: (state, action) => {
      state.errors.validateUpdate.name = action.payload
    },
    setValidateUpdatePersonSurname: (state, action) => {
      state.errors.validateUpdate.surname = action.payload
    },
    setValidateUpdatePersonEmail: (state, action) => {
      state.errors.validateUpdate.email = action.payload
    },
    setValidateUpdatePersonPhone: (state, action) => {
      state.errors.validateUpdate.phone = action.payload
    },
    setValidateUpdatePersonStatus: (state, action) => {
      state.errors.validateUpdate.status = action.payload
    },
    setNewPersonStatus: (state, action) => {
      state.newPersonStatus = action.payload
    },
    setNewPersonRequestCompanyName: (state, action) => {
      state.newPersonRequest.companyName = action.payload
    },
    setNewPersonRequestCompanyPhone: (state, action) => {
      state.newPersonRequest.companyPhones = action.payload
    },
    setNewPersonRequestCompanyVat: (state, action) => {
      state.newPersonRequest.companyVat = action.payload
    },
    setNewPersonRequestEmail: (state, action) => {
      state.newPersonRequest.emails = action.payload
    },
    setNewPersonRequestLinkedinProfile: (state, action) => {
      state.newPersonRequest.linkedinProfile = action.payload
    },
    setNewPersonRequestName: (state, action) => {
      state.newPersonRequest.name = action.payload
    },
    setNewPersonRequestPhone: (state, action) => {
      state.newPersonRequest.phones = action.payload
    },
    setNewPersonRequestPrivacy: (state, action) => {
      state.newPersonRequest.privacy = action.payload
    },
    setNewPersonRequestRole: (state, action) => {
      state.newPersonRequest.role = action.payload
    },
    setNewPersonRequestSurname: (state, action) => {
      state.newPersonRequest.surname = action.payload
    },
    setNewPersonResponse: (state, action) => {
      state.newPersonResponse = action.payload
    },
  },
});

export const {
  setDeleteBulkPeopleStatus,
  setDeletePersonStatus,
  setFindPersonResponse,
  setFindPersonStatus,
  setEditPersonDepartment,
  setEditPersonEmail,
  setEditPersonLinkedinProfile,
  setEditPersonName,
  setEditPersonPhone,
  setEditPersonRole,
  setEditPersonSurname,
  setEditPersonPrivacy,
  setSelectedPerson,
  setEditPersonStatus,
  setEditPersonCompanyId,
  setImportCSVStatus,
  setImportCSVSResponse,
  setPersonToAddCompany,
  setErrorAssociateName,
  setErrorAssociatePhone,
  setErrorAssociateStatus,
  setErrorAssociateVat,
  setErrorAssociateId,
  setAssociatePersonToCompanyStatus,
  setSelectedIdsPeople,
  setTabValueDetailsPerson,
  setShowPopupPeople,
  setPersonCountResponse,
  setPersonCountStatus,
  setValidateCreatePersonName,
  setValidateCreatePersonStatus,
  setValidateCreatePersonSurname,
  setNewPersonRequestCompanyName,
  setNewPersonRequestCompanyPhone,
  setNewPersonRequestCompanyVat,
  setNewPersonRequestEmail,
  setNewPersonRequestLinkedinProfile,
  setNewPersonRequestName,
  setNewPersonRequestPhone,
  setNewPersonRequestPrivacy,
  setNewPersonRequestRole,
  setNewPersonRequestSurname,
  setNewPersonStatus,
  setValidateCreatePersonEmail,
  setValidateCreatePersonPhone,
  setNewPersonResponse,
  setEditPersonToVerifyFields,
  setValidateUpdatePersonEmail,
  setValidateUpdatePersonName,
  setValidateUpdatePersonPhone,
  setValidateUpdatePersonStatus,
  setValidateUpdatePersonSurname,
} = peopleSlice.actions;

export default peopleSlice.reducer;
