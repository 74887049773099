import { ContactType } from "../../filters/dto";
import { Area, DatabaseSection, TargetSection } from "../../utils";
import { useContactsDatabase } from "./contactsDatabase";
import { useContactsTarget } from "./contactsTarget";

type ContactsArg = [Area.DATABASE, DatabaseSection, ContactType] | [Area.TARGET, TargetSection, ContactType, string];
export const useContacts = (...args: ContactsArg) => {
    const [area, section, contactType, targetList] = args;

    const contactsDatabase = useContactsDatabase(isDatabaseSection(section) ? section : 'database', contactType);
    const contactsTarget = useContactsTarget(isTargetSection(section) ? section : 'target', targetList, contactType);

    if (area === Area.DATABASE) {
        return contactsDatabase;
    } else {
        return contactsTarget;
    }
};

const isDatabaseSection = (section: string): section is DatabaseSection => {
    return ["database", "add-target-db", "black-list", "verify-list"].includes(section);
};

const isTargetSection = (section: string): section is TargetSection => {
    return ["target", "add-target-tl", "target-bin"].includes(section);
};

