import qs from "qs";
import { cdAxios } from "../../lib/axios";
import { FindAllContactsFilters } from "../../lib/filters/dto";
import { keycloak } from "../../lib/keycloak";
import {
  EditTargetListRequest,
  FindAllTargetListsFilters,
  FindAllTargetListsResponse,
  NewTargetListRequest,
  TargetListDTO,
  TargetListStatusEnum
} from "./dto";
import { TargetListsService } from "./service";

export class TargetListsServiceImpl implements TargetListsService {

  public findAllTargetLists(
    filters: FindAllTargetListsFilters
  ): Promise<FindAllTargetListsResponse> {
    return cdAxios
      .get("/api/target-lists", {
        params: {
          itemsPerPage: filters.itemsPerPage,
          page: filters.page,
          sort: filters.sort,
          order: filters.order,
          name: filters.name,
          sector: filters.sector,
          category: filters.category,
          commercial: filters.commercial,
          project: filters.project,
          teamLeader: filters.teamLeader,
          state: filters.state,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public editTargetList(
    targetListId: string,
    data: EditTargetListRequest
  ): Promise<void> {
    return cdAxios
      .put(
        "/api/target-lists/" + targetListId,
        data,
        {
          headers: {
            Authorization: "Bearer " + keycloak.token,
            Accept: "*/*",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => response.data);
  }

  public findTargetListById(id: string): Promise<TargetListDTO> {
    let idToUse: string = "";
    if (window.location.pathname.includes("edit-project")) {
      if (window.location.pathname.split("/")[4] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[4];
      }
    } else if (window.location.pathname.includes("edit-target-list")) {
      if (window.location.pathname.split("/")[2] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[2];
      }
    } else if (window.location.pathname.includes("target-list-bins")) {
      if (window.location.pathname.split("/")[2] === undefined) {
        idToUse = id;
      } else {
        idToUse = window.location.pathname.split("/")[2];
      }
    } else {
      idToUse = id;
    }
    return cdAxios
      .get("/api/target-lists/" + idToUse, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public createTargetList(request: NewTargetListRequest): Promise<string> {
    return cdAxios
      .post("/api/target-lists", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public changeStatus(
    request: {
      state:
      | TargetListStatusEnum.CONCLUDED
      | TargetListStatusEnum.INPROGRESS
      | undefined;
      id: string;
    }[]
  ): Promise<void> {
    return cdAxios
      .put("/api/target-lists/state", request, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }

  public deleteTargetList(id: string): Promise<void> {
    return cdAxios
      .delete("/api/target-lists/" + id, {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.data);
  }
}
