import clsx from "clsx";
import { ReactElement, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ColumnsOptions } from "./columns";
import { DefaultOption } from "./default";
import { companyColumns, CreatePreferenceRequest, DatabasePreferences, peopleColumns, PreferencesSection, targetListColumns, targetListCompanyColumns, targetListPersonColumns } from "./dto";
import { setOption } from "./slice";

interface Props {
    section: PreferencesSection
}

export function Preferences(props: Props) {
    const { section } = props
    const preferencesState = useAppSelector(state => state.preferences)
    const dispatch = useAppDispatch()


    const mapReference = new Map<PreferencesSection, typeof companyColumns>([
        [PreferencesSection.companyPreferences, companyColumns],
        [PreferencesSection.personPreferences, peopleColumns],
        [PreferencesSection.targetListCompanyPreferences, targetListCompanyColumns],
        [PreferencesSection.targetListPersonPreferences, targetListPersonColumns],
        [PreferencesSection.targetListPreferences, targetListColumns],
    ])

    const columns = preferencesState.findAllResponse && preferencesState.findAllResponse.data[0] && preferencesState.findAllResponse?.data[0][section] ? (preferencesState.findAllResponse?.data[0][section].map(option => ({ ...option, hideable: mapReference.get(section)?.find(opt => opt.column === option.column)?.hideable! })) || []) : []
    const columnsActive = columns.filter((col) => col.visible).length
    const request: { data: CreatePreferenceRequest; id: string } = {
        data: {
            owner: preferencesState.findAllResponse?.data[0].owner!,
            ...Object.values(PreferencesSection).reduce((acc, key) => {
                acc[key] = preferencesState.findAllResponse?.data[0][key]!;
                return acc;
            }, {} as Record<PreferencesSection, DatabasePreferences[]>),
        },
        id: preferencesState.findAllResponse?.data[0].id!,
    };

    const optionMap = new Map<'columns' | undefined, ReactElement>([
        [undefined, <DefaultOption columnActive={columnsActive} />],
        ['columns', <ColumnsOptions preferences={columns} request={request} section={section} />],
    ])

    useEffect(() => {
        dispatch(setOption(undefined))
    }, [preferencesState.optionsActive])

    return (
        <div
            className={clsx(
                "fixed right-0 h-full bottom-0 z-50 flex-shrink-0 w-[290px]",
                {
                    visible:
                        preferencesState.optionsActive,
                    hidden:
                        !preferencesState.optionsActive,
                }
            )}
        >
            <div className="bg-white shadow-md h-full">
                <div className="h-full flex-shrink-0 relative bg-white border-l-[1px] border-neutral-100">
                    {optionMap.get(preferencesState.option)}
                </div>
            </div>
        </div>
    )
}