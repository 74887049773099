import { format } from "date-fns";
import { useState } from "react";
import { useAppDispatch } from "../../../lib/redux/hooks";
import { setShowRecallContactModal } from "../../targetList/contacts/slice";
import { EventDTO } from "../dto";
import { EventTooltip } from "./tooltip";
import { eventsColorsMap } from "./utils";

interface Props {
    event: EventDTO;
    setEvent: (event: EventDTO) => void;
}
export function MonthEvent(props: Props) {
    const { event } = props;
    const [visible, setVisible] = useState(false);
    const [mouseY, setMouseY] = useState(0);
    const [mouseX, setMouseX] = useState(0);
    const isLastColumn = new Date(event.startDate).getDay() === 0;
    const dispatch = useAppDispatch()
    return (
        <div
            onClick={() => { dispatch(setShowRecallContactModal(true)); props.setEvent(event) }}
            onMouseEnter={() => setVisible(true)}
            onMouseMove={(e) => { !visible && setMouseY(e.clientY); !visible && setMouseX(e.clientX) }}
            onMouseLeave={() => setVisible(false)}
            style={{
                backgroundColor: eventsColorsMap.get(event.type)?.bg,
                color: eventsColorsMap.get(event.type)?.text,
                borderColor: eventsColorsMap.get(event.type)?.border
            }}
            key={event.id}
            className="h-7 cursor-pointer relative items-center text-text-xs font-bold w-full border rounded-lg p-2 flex justify-between">
            <EventTooltip visible={visible} event={event} isLastColumn={isLastColumn} mouseY={mouseY} mouseX={mouseX} />
            <span className="crop-text">{event.title}</span>
            <span className="text-nowrap">{format(new Date(event.startDate), 'HH:mm')}</span>
        </div>
    )
}