import clsx from "clsx"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import Button from "../../../../ui/molecules/button"
import { Spinner } from "../../../../ui/molecules/spinner"
import { ConfirmOtpModal } from "./confirmOtpModal"
import { SendOtpModal } from "./sendOtpModal"
import { setOtpRequestStatus, setOtpVerifyStatus } from "./slice"
import { editPerson } from "../slice"

export function PersonPrivacy() {
    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)
    const [sendOtpModal, setSendOtpModal] = useState(false)
    const [confirmOtpModal, setConfirmOtpModal] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (otpState.otpRequestStatus === "failed") {
            setSendOtpModal(false)
            timeoutId = setTimeout(() => {
                dispatch(setOtpRequestStatus("idle"));
            }, 5000);
        }
        if (otpState.otpRequestStatus === 'successfully') {
            setSendOtpModal(false)
            setConfirmOtpModal(true)
            dispatch(setOtpRequestStatus("idle"));
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [otpState.otpRequestStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (otpState.otpVerifyStatus === 'successfully') {
            dispatch(setOtpVerifyStatus("idle"));

            dispatch(editPerson({ id: peopleState.findPersonResponse?.id!, data: { ...peopleState.editPersonRequest, privacy: true } }))
            timeoutId = setTimeout(() => {
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [otpState.otpVerifyStatus]);

    return (
        <div className={clsx("p-6 flex bg-neutral-50", { relative: sendOtpModal })}>
            <div className={clsx("items-center justify-center w-full", {
                flex: peopleState.findPersonStatus === 'loading',
                hidden: peopleState.findPersonStatus !== 'loading',
            })}>
                <Spinner />
            </div>
            <div className={clsx("items-center w-full", {
                flex: peopleState.findPersonStatus !== 'loading',
                hidden: peopleState.findPersonStatus === 'loading',
            })}>
                <div className={clsx("flex items-center justify-center w-full", { hidden: peopleState.findPersonResponse?.privacy })}>
                    <Button size={"md"} iconPosition={"off"} variant={"solid"} color={"green"} label="Richiedi consenso" onClick={() => setSendOtpModal(true)} />
                </div>
                <div className={clsx("text-text-md", { hidden: !peopleState.findPersonResponse?.privacy })}>
                    <span className="text-neutral-800 font-bold">Consenso Privacy</span>
                    <br />
                    <span className="text-green-600 font-semibold">Accettato</span>
                </div>
            </div>
            <SendOtpModal
                visible={sendOtpModal}
                onClose={function (): void {
                    setSendOtpModal(false)
                }}
            />
            <ConfirmOtpModal
                visible={confirmOtpModal}
                onClose={function (): void {
                    setConfirmOtpModal(false)
                    dispatch(setOtpVerifyStatus('idle'))
                }}
            />
        </div>
    )
}