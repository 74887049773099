import clsx from "clsx";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import RICIBs from "react-individual-character-input-boxes";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { PlusIcon } from "../../../../ui/icons/plus";
import Button from "../../../../ui/molecules/button";
import { Spinner } from "../../../../ui/molecules/spinner";
import { otpRequest, otpVerify, setOtpRequestStatus, setOtpVerifyStatus } from "./slice";

interface Props {
    visible: boolean
    onClose: () => void
}
export function ConfirmOtpModal(prsops: Props) {
    const { visible, onClose } = prsops

    const dispatch = useAppDispatch()

    const [otp, setOtp] = useState('')

    const peopleState = useAppSelector(state => state.people)
    const otpState = useAppSelector(state => state.otp)

    useEffect(() => {
        if (otpState.otpRequestStatus === 'successfully') {
            dispatch(setOtpVerifyStatus('idle'))
            dispatch(setOtpRequestStatus("idle"));
            setOtp('')
        }
    }, [otpState.otpRequestStatus])

    return createPortal(
        <div
            onClick={() => onClose()}
            className={clsx(
                "transition-all fixed bg-opacity-30 z-[999] bg-neutral-950 backdrop-blur-sm top-0 left-0 right-0 bottom-0 h-full w-full flex items-center justify-center",
                {
                    block: visible,
                    hidden: !visible,
                }
            )}
        >
            <div
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                className="transition-all relative bg-white rounded-[12px] opacity-100 shadow-sm min-w-[600px]"
            >
                <div
                    onClick={() => onClose()}
                    className="absolute cursor-pointer rotate-45 top-[-16px] right-[-16px] border border-brandPrimary-600 h-8 w-8 rounded-full flex items-center justify-center bg-brandPrimary-50"
                >
                    <PlusIcon color={colors.brandPrimary[600]} size={16} />
                </div>
                <div className="p-6 text-heading-xs font-semibold text-center">
                    Inserisci OTP
                </div>
                <div className="px-6 py-8 flex flex-col justify-center items-center gap-6 border-t-neutral-200 border-t">
                    <p className="text-text-md font-medium text-neutral-600 text-center">
                        Abbiamo inviato un codice OTP a
                        <span className="font-bold text-neutral-800">
                            { } {peopleState.findPersonResponse?.phones}
                        </span>.
                        <br />
                        Inseriscilo quio per confermare il consenso.
                    </p>
                    <RICIBs
                        amount={6}
                        autoFocus
                        inputRegExp={/^[0-9]$/}
                        handleOutputString={(e) => { setOtp(e) }}
                        inputProps=
                        {
                            Array(6).fill('').map((_, index) => (
                                {
                                    value: otp[index] ?? '',
                                    style: { height: '48px', width: 48, fontSize: 20 },
                                    className: clsx("shadow-sm border text-text-xl font-medium text-neutral-600 placeholder:text-neutral-200", {
                                        "border-neutral-200": otpState.otpVerifyStatus === 'idle',
                                        "border-red-300": otpState.otpVerifyStatus === 'failed',
                                        "border-green-300": otpState.otpVerifyStatus === 'successfully',
                                    }),
                                    placeholder: "0"
                                }
                            ))
                        }
                    />
                    <div className={clsx({ hidden: otpState.otpVerifyStatus !== 'idle' })}>
                        <Button disabled={otp.length !== 6} size={"md"} iconPosition={"off"} variant={"solid"} color={"green"} label="Conferma"
                            onClick={() => dispatch(otpVerify({ phoneNumber: peopleState.findPersonResponse?.phones[0]!, code: otp }))} />
                    </div>
                    <div className={clsx({ hidden: otpState.otpVerifyStatus !== 'loading' })}>
                        <Spinner />
                    </div>

                    <p className={clsx("text-red-500 text-text-md font-semibold text-center", { hidden: otpState.otpVerifyStatus !== 'failed' })}>
                        Il codice OTP è scaduto.
                        <br />
                        Richiedi un nuovo codice cliccando su INVIA NUOVO OTP
                    </p>
                    <p className={clsx("text-green-500 text-text-md font-semibold text-center", { hidden: otpState.otpVerifyStatus !== 'successfully' })}>
                        Il consenso è stato registrato correttamente.
                    </p>
                    <div className={clsx({ hidden: otpState.otpVerifyStatus !== 'failed' })}>
                        <Button disabled={otp.length !== 6} size={"md"} iconPosition={"off"} variant={"solid"} color={"green"} label="Invia nuovo OTP"
                            onClick={() => dispatch(otpRequest(peopleState.findPersonResponse?.phones[0]!))} />
                    </div>
                    <div className={clsx({ hidden: otpState.otpRequestStatus !== 'loading' })}>
                        <Spinner />
                    </div>
                </div>
            </div>
        </div>
        , document.body);
}