import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { groupBy } from "../../utils"
import { ShoppingCartIcon } from "../../../ui/icons/shoppingCart"
import { SelectCustom } from "../../../ui/molecules/select"
import { AccordionFilter } from "../../../ui/organisms/accordionFilter"
import { ContactType, FindAllContactsFilters } from "../dto"


interface Props {
    updateFilters: (filter: Partial<FindAllContactsFilters>) => void
    filters: FindAllContactsFilters
    atecoSign?: string
}

export function CategoryFilter(props: Props) {
    const { updateFilters, filters, atecoSign } = props
    const atecosState = useAppSelector(state => state.atecos)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (filters.companyAtecoCode?.length === 1 && atecoSign === "=") {
            let newCategories: { value: string, label: string }[] = []
            filters.companySector.forEach(sector => {
                const categories = atecosState.findAllAtecosResponse.filter(_ateco => _ateco.sector === sector.value)
                categories.forEach(category => {
                    if (category && !newCategories.map(cat => cat.value).includes(category.category)) {
                        newCategories.push({ value: category.category, label: category.category })
                    }
                })
            })
            updateFilters({ companyCategory: newCategories })
        }
    }, [filters.companySector])

    const filteredAtecos = atecosState.findAllAtecosResponse
        .filter(ateco =>
            filters.companyAtecoCode !== undefined && atecoSign === "=" ?
                filters.companyAtecoCode.map((value) => value.value.includes(ateco.atecoCode)) :
                filters.companySector.length > 0 ?
                    filters.companySector.map(_ateco => _ateco.value).includes(ateco.sector) :
                    ateco
        )

    return (
        <AccordionFilter
            key={'person-filter-category'}
            label={"Categoria"}
            icon={<ShoppingCartIcon color={""} size={0} />}
            showClear={filters.companyCategory.length > 0}
            options={filters.companyCategory}
            clearAction={function (): void {
                updateFilters({ companyCategory: [] })
            }}
            deleteFilter={function (option): void {
                updateFilters({ companyCategory: filters.companyCategory.filter(name => name.value !== option.value) })
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        updateFilters({ companyCategory: e.map(option => ({ value: option, label: (groupBy(atecosState.findAllAtecosResponse, 'category').find(ateco => ateco.key === option)?.key)! })) })
                    }
                }}
                defaultValue={filters.companyCategory.map(option => option.value)}
                placeholder={"Cerca per categoria"}
                options={groupBy(filteredAtecos, 'category').map(sector => ({ value: sector.key, label: sector.key }))}
                noOptionsMessage="Non sono presenti categorie."
            />
        </AccordionFilter>
    )
}