import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NewOTPService } from "./service";
import { PromiseStatuses } from "../../../../lib/types";

interface OTPState {
  otpRequestStatus: PromiseStatuses
  otpVerifyStatus: PromiseStatuses
}

const initialState: OTPState = {
  otpRequestStatus: 'idle',
  otpVerifyStatus: 'idle'
};

export const otpRequest = createAsyncThunk(
  "otp/otpRequest",
  async (
    phoneNumber: string,
    thunkApi
  ): Promise<void> => {
    const otpService = NewOTPService();

    return otpService.otpRequest(phoneNumber);
  }
);

export const otpVerify = createAsyncThunk(
  "otp/otpVerify",
  async (
    request: { phoneNumber: string, code: string },
    thunkApi
  ): Promise<void> => {
    const otpService = NewOTPService();

    return otpService.otpVerify(request.phoneNumber, request.code); 
  }
);

const otpSlice = createSlice({
  name: "otp/slice",
  initialState,
  extraReducers(builder) {
    builder
      .addCase(otpRequest.pending, (state) => {
        state.otpRequestStatus = "loading";
      })
      .addCase(otpRequest.fulfilled, (state, action) => {
        state.otpRequestStatus = "successfully";
      })
      .addCase(otpRequest.rejected, (state) => {
        state.otpRequestStatus = "failed";
      })
      .addCase(otpVerify.pending, (state) => {
        state.otpVerifyStatus = "loading";
      })
      .addCase(otpVerify.fulfilled, (state, action) => {
        state.otpVerifyStatus = "successfully";
      })
      .addCase(otpVerify.rejected, (state) => {
        state.otpVerifyStatus = "failed";
      })
  },
  reducers: {
    setOtpRequestStatus: (state, action) => {
      state.otpRequestStatus = action.payload;
    },
    setOtpVerifyStatus: (state, action) => {
      state.otpVerifyStatus = action.payload;
    },
  },
});

export const {
  setOtpRequestStatus,
  setOtpVerifyStatus
} = otpSlice.actions;

export default otpSlice.reducer;
