import clsx from "clsx";
import { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../lib/filters/dto";
import { useContacts } from "../../../lib/hooks/contacts/contacts";
import { keycloak } from "../../../lib/keycloak";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Area } from "../../../lib/utils";
import { SettingsIcon } from "../../../ui/icons/settings";
import { TrashIcon } from "../../../ui/icons/trash";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { Spinner } from "../../../ui/molecules/spinner";
import { BannersList } from "../../../ui/organisms/banner/bannerList";
import { ConfirmModal } from "../../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { AssociateCompanyModal } from "../../database/people/editPerson/associateCompany";
import { setBulkDeleteModal, setSoftDeleteModal } from "../../database/slice";
import { deleteBulk, setDeleteBulkContactsStatus, setRemoveContactsStatus, softBulk } from "../../targetList/contacts/slice";
import { TargetListPageEnum } from "../../targetList/dto";
import { ActionsCompaniesTargetList } from "../../targetList/editTargetListDetails/companies/list/actions";
import { EditTargetList } from "../../targetList/editTargetListDetails/index";
import { ActionsPeopleTargetList } from "../../targetList/editTargetListDetails/people/list/actions";
import {
  findAllTArgetLists,
  setEditTargetListTabValue,
  setFindAllTargetListsStatus,
  setNewTargetListAtecos,
  setNewTargetListProjectId,
  setNewTargetListStatus,
  setSelectedTargetBins,
  setSelectedTargetList,
  setTargetListPage,
  setTargetListsFilterPage,
  validateEditTargetList
} from "../../targetList/slice";
import {
  findClients,
  findCommercials,
  findTeamLeaders,
  findUsersAvatarsList,
} from "../../users/slice";
import { EditProjectPage } from "../dto";
import { EmailSettingModal } from "../mails/emailSettingModal";
import { setEmailSettingsModalOpen, setSaveMailStatus, setUpdateMailStatus } from "../mails/slice";
import {
  editCustomerInProject,
  editProject,
  findProjectById,
  setEditCustomerInProjectStatus,
  setEditProjectCommercialIds,
  setEditProjectDescription,
  setEditProjectEmailFrom,
  setEditProjectNameFrom,
  setEditProjectPage,
  setEditProjectStatus,
  setEditProjectTargetListIds,
  setFindProjectStatus,
} from "../slice";
import { AddTargetList } from "./addTargetList";
import { Form } from "./form";
import { TargetLists } from "./targetLists";

export function EditProject() {
  const mailsState = useAppSelector((state) => state.mails);
  const usersState = useAppSelector((state) => state.users);
  const databaseState = useAppSelector((state) => state.database);
  const projectsState = useAppSelector((state) => state.projects);
  const contactsState = useAppSelector((state) => state.contacts);
  const targetListsState = useAppSelector((state) => state.targetList);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();

  const peopleFilters = useContacts(Area.TARGET, 'target', ContactType.PERSON, targetListsState.findTargetListResponse?.id!);
  const companiesFilters = useContacts(Area.TARGET, 'target', ContactType.COMPANY, targetListsState.findTargetListResponse?.id!);

  const [selectedPeopleIds, setSelectedPeopleIds] = useState<string[]>([]);
  const [showPopupPeople, setShowPopupPeople] = useState<boolean>(false);
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<string[]>([]);
  const [showPopupCompanies, setShowPopupCompanies] = useState<boolean>(false);
  const [direction, setDirection] = useState<"left" | "right">("left");
  const [tabValue, setTabValue] = useState<number>(
    window.location.pathname.includes("specifics") ? 0 : 1
  );

  useEffect(() => {
    if (window.location.pathname.includes("specifics")) {
      dispatch(setEditTargetListTabValue(0));
    }
    if (window.location.pathname.includes("people")) {
      dispatch(setEditTargetListTabValue(1));
    }
    if (window.location.pathname.includes("companies")) {
      dispatch(setEditTargetListTabValue(2));
    }
  }, []);

  useEffect(() => {
    if (targetListsState.editTargetListTabValue === 0) {
      navigation(window.location.pathname.replace(/\/people|\/companies/, "/specifics"));
    }
    if (targetListsState.editTargetListTabValue === 1) {
      navigation(window.location.pathname.replace(/\/specifics|\/companies/, "/people"));
    }
    if (targetListsState.editTargetListTabValue === 2) {
      navigation(window.location.pathname.replace(/\/specifics|\/people/, "/companies"));
    }
  }, [targetListsState.editTargetListTabValue]);


  useEffect(() => {
    dispatch(findCommercials());
    dispatch(findClients());
    dispatch(findTeamLeaders());
    dispatch(findProjectById(projectsState.selectedProject!));
    if (window.location.pathname.includes("specifics")) {
      setTabValue(0);
    }
    if (window.location.pathname.includes("target-lists")) {
      setTabValue(1);
      if (window.location.pathname.split("/")[4]) {
        dispatch(setEditProjectPage(EditProjectPage.EDITLIST));
      }
    }
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      contactsState.deleteBulkStatus === "failed" ||
      contactsState.deleteBulkStatus === "successfully"
    ) {
      dispatch(setBulkDeleteModal(false))
      if (contactsState.deleteBulkStatus === "successfully") {
        peopleFilters.resetAllFilters()
        companiesFilters.resetAllFilters()
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteBulkContactsStatus("idle"));
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [contactsState.deleteBulkStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      contactsState.softBulkStatus === "failed" ||
      contactsState.softBulkStatus === "successfully"
    ) {
      dispatch(setSoftDeleteModal(false))
      if (contactsState.softBulkStatus === "successfully") {
        peopleFilters.resetAllFilters()
        companiesFilters.resetAllFilters()
      }
      timeoutId = setTimeout(() => {
        dispatch(setDeleteBulkContactsStatus("idle"));
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [contactsState.softBulkStatus]);

  useEffect(() => {
    if (targetListsState.newTargetListStatus === 'successfully') {
      dispatch(setNewTargetListStatus('idle'))
      dispatch(
        findAllTArgetLists({
          commercial: [],
          contacts: "",
          itemsPerPage: 50,
          name: "",
          order: true,
          page: 0,
          sector: [],
          sort: "name",
          category: [],
          state: [],
          teamLeader: [],
          project: [projectsState.findProjectResponse?.id!],
        })
      );
      dispatch(setFindAllTargetListsStatus("idle"))
    }
  }, [targetListsState.newTargetListStatus])

  useEffect(() => {
    if (projectsState.findProjectStatus === "successfully") {
      dispatch(setFindProjectStatus("idle"));
      dispatch(
        findUsersAvatarsList(
          projectsState.findProjectResponse?.targetLists
            ?.flatMap((data) => data.commercials)
            .map((commercial) => ({
              objectId: commercial.avatarObjectId,
              userId: commercial.id,
            }))!
        )
      );
      dispatch(
        findAllTArgetLists({
          commercial: [],
          contacts: "",
          itemsPerPage: 50,
          name: "",
          order: true,
          page: 0,
          sector: [],
          sort: "name",
          category: [],
          state: [],
          teamLeader: [],
          project: [projectsState.findProjectResponse?.id!],
        })
      );
      dispatch(
        setEditProjectDescription(
          projectsState.findProjectResponse?.description
        )
      );
      dispatch(
        setEditProjectTargetListIds(
          projectsState.findProjectResponse?.targetListsIds
            ? [...projectsState.findProjectResponse?.targetListsIds]
            : null
        )
      );
      dispatch(
        setEditProjectCommercialIds(
          projectsState.findProjectResponse?.commercials
            ? [...projectsState.findProjectResponse?.commercials.map(comm => comm.id)]
            : null
        )
      );
      dispatch(setEditProjectEmailFrom(projectsState.findProjectResponse?.emailFrom
        ? projectsState.findProjectResponse?.emailFrom
        : null))
      dispatch(setEditProjectNameFrom(projectsState.findProjectResponse?.nameFrom
        ? projectsState.findProjectResponse?.nameFrom
        : null))
    }
  }, [projectsState.findProjectStatus]);

  useEffect(() => {
    if (projectsState.editCustomerInProjectStatus === "successfully") {
      dispatch(findProjectById(projectsState.findProjectResponse?.id!))
    }
  }, [projectsState.editCustomerInProjectStatus])

  useEffect(() => {
    if (projectsState.editProjectStatus === "successfully") {
    }
  }, [projectsState.editProjectStatus])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (projectsState.editProjectStatus === "failed" || projectsState.editProjectStatus === "successfully") {
      if (projectsState.editProjectStatus === "successfully")
        dispatch(findProjectById(projectsState.findProjectResponse?.id!))

      timeoutId = setTimeout(() => {
        dispatch(setEditProjectStatus("idle"));
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [projectsState.editProjectStatus]);

  useEffect(() => {
    if (targetListsState.selectedTargetList !== undefined) {
      navigate(
        window.location.pathname + "/" + targetListsState.selectedTargetList + "/specifics"
      );
      dispatch(setEditProjectPage(EditProjectPage.EDITLIST));
      dispatch(setSelectedTargetList(undefined));
    }
  }, [targetListsState.selectedTargetList]);

  useEffect(() => {
    if (projectsState.findProjectResponse) {
      dispatch(setTargetListsFilterPage(0));
      dispatch(
        findAllTArgetLists({
          ...targetListsState.filters,
          page: 0,
          project: [projectsState.findProjectResponse?.id!],
        })
      );
    }
  }, [
    targetListsState.filters.order,
    targetListsState.filters.itemsPerPage,
    targetListsState.filters.sort,
    targetListsState.filters.name,
    targetListsState.filters.teamLeader,
    targetListsState.filters.commercial,
    targetListsState.filters.sector,
    targetListsState.filters.state,
  ]);

  useEffect(() => {
    if (projectsState.findProjectResponse !== undefined)
      dispatch(
        findAllTArgetLists({
          ...targetListsState.filters,
          project: [projectsState.findProjectResponse?.id!],
        })
      );
  }, [targetListsState.filters.page, projectsState.findProjectResponse]);

  useEffect(() => {
    if (targetListsState.selectedTargetBins !== undefined) {
      navigation("/target-list-bins/" + targetListsState.selectedTargetBins);
      dispatch(setSelectedTargetBins(undefined));
    }
  }, [targetListsState.selectedTargetBins]);

  const editProjectMap = new Map<EditProjectPage, ReactElement>([
    [EditProjectPage.LIST, <TargetLists />],
    [EditProjectPage.ADDLIST, <AddTargetList />],
    [EditProjectPage.EDITLIST, <EditTargetList
      reloadCompanies={() => companiesFilters.findContacts()}
      reloadPeople={() => peopleFilters.findContacts()}
      peopleStatus={peopleFilters.status}
      peopleContacts={peopleFilters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
      companiesContacts={companiesFilters.contacts as unknown as FindContactsTargetResponse<ContactType.COMPANY>}
      companiesStatus={companiesFilters.status}
      companiesFilters={companiesFilters.filters}
      setCompaniesFilters={function (action: Partial<FindAllContactsFilters>): void {
        companiesFilters.updateFilters(action);
      }}
      setCompaniesPage={function (action: number): void { companiesFilters.changePage(action); }}
      resetCompaniesFilters={function (): void { companiesFilters.resetAllFilters() }}
      selectedIdsPeople={selectedPeopleIds}
      setSelectedIdsPeople={arg => setSelectedPeopleIds(arg)}
      setShowPopupPeople={arg => setShowPopupPeople(arg)}
      showPopupPeople={showPopupPeople}
      selectedIdsCompanies={selectedCompaniesIds}
      setSelectedIdsCompanies={arg => setSelectedCompaniesIds(arg)}
      setShowPopupCompanies={arg => setShowPopupCompanies(arg)}
      showPopupCompanies={showPopupCompanies}
      peopleFilters={peopleFilters.filters}
      setPeopleFilters={function (action: Partial<FindAllContactsFilters>): void {
        peopleFilters.updateFilters(action);
      }}
      setPeoplePage={function (action: number): void {
        peopleFilters.changePage(action);
      }}
      resetPeopleFilters={function (): void {
        peopleFilters.resetAllFilters()
      }} />],
  ]);

  const tabValuePathMap = new Map<number, string>([
    [0, "/specifics"],
    [1, "/target-lists"],
  ]);

  const navigate = useNavigate();

  if (projectsState.findProjectStatus === 'loading') {
    return (
      <Layout
        menuItem={MenuItems.PROJECTS}
        breadcrumbItems={["Progetti", "Modifica progetto"]}
        headerLabel={projectsState.findProjectResponse?.name!}
        headerBackPath={"/projects"}
      />
    )
  }

  return (
    <Layout
      menuItem={MenuItems.PROJECTS}
      breadcrumbItems={["Progetti", "Modifica progetto"]}
      headerLabel={projectsState.findProjectResponse?.name!}
      headerBackPath={"/projects"}
      headerChildren={
        <div className="flex items-center h-[100%] w-[100%] justify-between">
          <TabMenu
            direction={direction}
            setDirection={(value) => setDirection(value)}
            setValue={(e) => {
              setTabValue(e);
              navigate(
                "/edit-project/" +
                window.location.pathname.split("/")[2] +
                tabValuePathMap.get(e)
              );
              if (e === 1) {
                dispatch(setEditProjectPage(EditProjectPage.LIST));
              }
            }}
            value={tabValue}
            items={[
              {
                icon: <></>,
                label: "Specifiche",
              },
              {
                icon: <></>,
                label:
                  "Liste target (" +
                  (targetListsState.findAllTargetListsResponse?.total ?? 0) +
                  ")",
              },
            ]}
          />
          {
            (tabValue !== 1 || !window.location.pathname.split('/')[4]) && !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) && projectsState.editProjectPage !== EditProjectPage.ADDLIST &&
            <div className="flex flex-row gap-2">
              <Button
                onClick={() => dispatch(setEmailSettingsModalOpen(true))}
                size={"sm"}
                label="Impostazioni email"
                iconPosition={"left"}
                icon={<SettingsIcon color={""} size={0} />}
                variant={"link"}
                color={"blue"}
              />
              {
                (usersState.findMeResponse?.isAutonomous || usersState.findMeResponse?.isAutonomous === null) &&
                <Button
                  size={"sm"}
                  iconPosition={"off"}
                  variant={"outline"}
                  color={"blue"}
                  label="Aggiungi lista target"
                  onClick={() => {
                    setTabValue(1)
                    dispatch(setEditProjectPage(EditProjectPage.ADDLIST));
                    dispatch(
                      setNewTargetListProjectId(projectsState.findProjectResponse?.id)
                    );
                    dispatch(setNewTargetListAtecos([{ atecoCode: null, atecoDescription: null, sector: null, category: null }]))
                  }}
                />
              }
              {
                tabValue === 0 &&
                !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                (projectsState.newProjectStatus === "loading" ||
                  projectsState.validateNewProject.status === "loading" ? (
                  <Spinner />
                ) : (
                  (
                    (projectsState.editProjectRequest.description === '' ? null : projectsState.editProjectRequest.description) !== projectsState.findProjectResponse?.description ||
                    JSON.stringify(projectsState.editProjectRequest.commercialIds) !== JSON.stringify(projectsState.findProjectResponse?.commercials?.map(comm => comm.id))
                  ) &&
                  <Button
                    size={"sm"}
                    iconPosition={"off"}
                    label="Modifica progetto"
                    variant={"solid"}
                    color={"blue"}
                    onClick={() => {
                      if (projectsState.editCustomerInProjectRequest.customerId !== '') {
                        dispatch(editCustomerInProject({ id: projectsState.findProjectResponse?.id!, data: projectsState.editCustomerInProjectRequest }))
                      }
                      if (projectsState.editProjectRequest.description !== "") {
                        dispatch(
                          editProject({
                            id: projectsState.findProjectResponse?.id!,
                            data: {
                              ...projectsState.editProjectRequest,
                              description: projectsState.editProjectRequest.description,
                              commercialIds: projectsState.editProjectRequest.commercialIds
                            },
                          })
                        );
                      }
                    }}
                  />
                ))
              }
            </div>
          }
        </div>
      }
    >
      <EmailSettingModal />
      {
        window.location.pathname.includes("edit-project") &&
        <AssociateCompanyModal />
      }
      <BannersList banners={
        [
          {
            label: "Si è verificato un errore durante l'eliminazione dei contatti.",
            visible: contactsState.deleteBulkStatus === "failed",
            closeAction: () => dispatch(setDeleteBulkContactsStatus("idle")),
            type: "error",
          },
          {
            label: "Contatti eliminati.",
            visible: contactsState.deleteBulkStatus === "successfully",
            closeAction: () => dispatch(setDeleteBulkContactsStatus("idle")),
            type: "success",
          },
          {
            label: "Contatto rimosso dalla lista.",
            visible: contactsState.removeContactsStatus === "successfully",
            closeAction: () => dispatch(setRemoveContactsStatus("idle")),
            type: "success",
          },
          {
            label:
              "Si è verificato un errore durante la rimozione del contatto dalla lista target.",
            visible: contactsState.removeContactsStatus === "failed",
            closeAction: () => dispatch(setRemoveContactsStatus("idle")),
            type: "error",
          },
          {
            label: "Cliente aggiunto al progetto.",
            visible: projectsState.editCustomerInProjectStatus === "successfully",
            closeAction: () => dispatch(setEditCustomerInProjectStatus("idle")),
            type: "success",
          },
          {
            label:
              "Si è verificato un errore durante l'aggiunta del contatto nel progetto.",
            visible: projectsState.editCustomerInProjectStatus === "failed",
            closeAction: () => dispatch(setEditCustomerInProjectStatus("idle")),
            type: "error",
          },
          {
            label: "Progetto modificato correttamente.",
            visible: projectsState.editProjectStatus === "successfully",
            closeAction: () => dispatch(setEditProjectStatus("idle")),
            type: "success",
          },
          {
            label: "Si è verificato un errore durante la modifica del progetto.",
            visible: projectsState.editProjectStatus === "failed",
            closeAction: () => dispatch(setEditProjectStatus("idle")),
            type: "error",
          },
          {
            label: "Template modificato con successo!",
            visible: mailsState.updateMailStatus === "successfully",
            closeAction: () => dispatch(setUpdateMailStatus("idle")),
            type: "success",
          },
          {
            label: "Modifica del template fallita!",
            visible: mailsState.updateMailStatus === "failed",
            closeAction: () => dispatch(setUpdateMailStatus("idle")),
            type: "error",
          },
          {
            label: "Template creato con successo!",
            visible: mailsState.saveMailStatus === "successfully",
            closeAction: () => dispatch(setSaveMailStatus("idle")),
            type: "success",
          },
          {
            label: "Creazione del template fallita!",
            visible: mailsState.saveMailStatus === "failed",
            closeAction: () => dispatch(setSaveMailStatus("idle")),
            type: "error",
          },
        ]
      } />
      <div className={clsx("h-16 w-full px-6 bg-white", { hidden: !window.location.pathname.split('/')[4] })}>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-text-md text-neutral-800">{targetListsState.findTargetListResponse?.name}</span>
            <TabMenu
              direction={direction}
              setDirection={(value) => setDirection(value)}
              setValue={(e) => {
                dispatch(setEditTargetListTabValue(e));
                if (e === 1 || e === 2) {
                  dispatch(setTargetListPage(TargetListPageEnum.LIST));
                }
              }}
              value={targetListsState.editTargetListTabValue}
              items={[
                {
                  icon: <></>,
                  label: "Specifiche lista",
                },
                {
                  icon: <></>,
                  label:
                    "Persone (" + contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.inList.total
                    +
                    ")",
                },
                {
                  icon: <></>,
                  label:
                    "Aziende (" +
                    contactsState.getCounterResponse?.find(count => count.contactType === ContactType.COMPANY)?.dto.inList.total
                    +
                    ")",
                },
              ]}
            />
          </div>
          <div className="flex items-center gap-2">
            {
              targetListsState.editTargetListTabValue === 1 &&
              <ActionsPeopleTargetList
                targetList={targetListsState.findTargetListResponse!}
                isInBin={false}
                contacts={peopleFilters.contacts as unknown as FindContactsTargetResponse<ContactType.PERSON>}
                selectedIds={selectedPeopleIds} setShowPopup={(action) => setShowPopupPeople(action)} filters={peopleFilters.filters} />
            }
            {
              targetListsState.editTargetListTabValue === 2 &&
              <ActionsCompaniesTargetList
                targetList={targetListsState.findTargetListResponse!}
                isInBin={false}
                contacts={companiesFilters.contacts as unknown as FindContactsTargetResponse<ContactType.COMPANY>}
                filters={companiesFilters.filters} selectedIds={selectedCompaniesIds} setShowPopup={(action) => setShowPopupCompanies(action)} />
            }
            {
              targetListsState.targetListPage === TargetListPageEnum.LIST && targetListsState.editTargetListTabValue === 0 && (usersState.findMeResponse?.isAutonomous || usersState.findMeResponse?.isAutonomous === null) &&
              <div className="flex items-center justify-end w-full h-full">
                <Button
                  size={"sm"}
                  iconPosition={"left"}
                  variant={"outline"}
                  color={"blue"}
                  icon={<TrashIcon color={""} size={0} />}
                  label="Visualizza cestini"
                  onClick={() => dispatch(setSelectedTargetBins(targetListsState.findTargetListResponse?.id!))}
                />
              </div>
            }
            <div className="flex justify-end items-center gap-2">
              {
                (targetListsState.editTargetListTabValue === 0 &&
                  !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                  !(keycloak.hasRealmRole('commercial') && !usersState.findMeResponse?.isAutonomous)) &&
                <Button
                  size={"sm"}
                  iconPosition={"off"}
                  label="Aggiorna"
                  variant={"solid"}
                  color={"blue"}
                  onClick={() => {
                    dispatch(
                      validateEditTargetList(targetListsState.editTargetListRequest)
                    );
                  }}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: window.location.pathname.split('/')[4] ? 'calc(100% - 64px)' : '100%' }}>
        <TabPages
          direction={direction}
          setDirection={(value) => setDirection(value)}
          pages={[
            <Form key={0} />,
            <div key={1} className="max-h-full h-full">
              {editProjectMap.get(projectsState.editProjectPage)}
            </div>,
          ]}
          value={tabValue}
          setValue={(e) => {
            setTabValue(e);
          }}
        />
      </div>
      <ConfirmModal
        loading={contactsState.deleteBulkStatus === "loading"}
        title={targetListsState.editTargetListTabValue === 2 ? 'Elimina aziende' : 'Elimina persone'}
        label={
          targetListsState.editTargetListTabValue === 2 ?
            "Sei sicuro di voler eliminare tutte le aziende trovate?" :
            "Sei sicuro di voler eliminare tutte le persone trovate?"
        }
        confirmAction={function (): void {
          const filters = targetListsState.editTargetListTabValue === 2 ? companiesFilters.filters : peopleFilters.filters
          const contactType = targetListsState.editTargetListTabValue === 2 ? ContactType.COMPANY : ContactType.PERSON
          dispatch(deleteBulk({ targetListId: targetListsState.findTargetListResponse?.id!, filters, contactType }))
        }}
        onClose={function (): void {
          dispatch(setBulkDeleteModal(false))
        }}
        visible={databaseState.bulkDeleteModal}
      />
      <ConfirmModal
        loading={contactsState.softBulkStatus === "loading"}
        title={targetListsState.editTargetListTabValue === 2 ? 'Cestina aziende' : 'Cestina persone'}
        label={
          targetListsState.editTargetListTabValue === 2 ?
            "Sei sicuro di voler cestinare tutte le aziende trovate?" :
            "Sei sicuro di voler cestinare tutte le persone trovate?"
        }
        confirmAction={function (): void {
          const contactType = targetListsState.editTargetListTabValue === 2 ? ContactType.COMPANY : ContactType.PERSON
          dispatch(softBulk({ targetListId: targetListsState.findTargetListResponse?.id!, contactType }))
        }}
        onClose={function (): void {
          dispatch(setSoftDeleteModal(false))
        }}
        visible={databaseState.softDeleteModal}
      />
    </Layout>
  );
}
