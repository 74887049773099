import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../ui/organisms/confirmModal.tsx";
import { Pagination } from "../../../ui/organisms/pagination";
import { CopyTargetListToProjectModal } from "../copyToProjectModal";
import {
  deleteTargetList,
  setFindTargetListResponse,
  setSelectedTargetBins,
  setTargetListsFilterItemsPerPage,
  setTargetListsFilterPage
} from "../slice";
import { TargetListTable } from "./table";

export function TargetListsList() {
  const targetListsState = useAppSelector((state) => state.targetList);

  const dispatch = useAppDispatch();
  const navigation = useNavigate()

  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (targetListsState.deleteTargetListStatus === "successfully") {
      setIdToDelete(undefined);
    }
  }, [targetListsState.deleteTargetListStatus]);

  useEffect(() => {
    dispatch(setFindTargetListResponse(undefined))
  }, [])

  useEffect(() => {
    if (targetListsState.selectedTargetBins !== undefined) {
      navigation("/target-list-bins/" + targetListsState.selectedTargetBins);
      dispatch(setSelectedTargetBins(undefined));
    }
  }, [targetListsState.selectedTargetBins]);

  if (targetListsState.findAllTargetListsStatus === "loading") {
    return (
      <div className="w-full flex items-center justify-center h-[45%]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-[100%] rounded-b-xl shadow-sm bg-white flex flex-col" style={{ height: 'calc(100% - 64px)' }}>
      <TargetListTable setIdToDelete={(value => setIdToDelete(value))} />
      <Pagination
        itemsPerPage={targetListsState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setTargetListsFilterItemsPerPage(value))}
        found={targetListsState.findAllTargetListsResponse?.total || 0}
        pages={targetListsState.findAllTargetListsResponse?.totalPage || 1}
        currentPage={targetListsState.filters.page}
        setPage={(page) => dispatch(setTargetListsFilterPage(page))}
      />
      <CopyTargetListToProjectModal />
      <ConfirmModal
        title={"Elimina lista target"}
        label={"Sei sicuro di voler eliminare questa lista target?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteTargetList(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
