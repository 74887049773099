import { useEffect, useState } from "react";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../lib/filters/dto";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../lib/types";
import { Spinner } from "../../../ui/molecules/spinner";
import { TabPages } from "../../../ui/organisms/tabs/tabPages";
import { AssociateCompanyModal } from "../../database/people/editPerson/associateCompany";
import { findProjectById } from "../../projects/slice";
import { getContactsIds, getCounters } from "../contacts/slice";
import {
  editTargetList,
  findTargetListById,
  setEditParticularitiesDefaultValue,
  setEditTargetListAtecosOptions,
  setEditTargetListRequestParticularity,
  setEditTargetListTabValue,
  setFindTargetListStatus,
  setValidateEditTargetListCategories,
  setValidateEditTargetListCommercials,
  setValidateEditTargetListDepartments,
  setValidateEditTargetListEmployeesFirst,
  setValidateEditTargetListEmployeesSecond,
  setValidateEditTargetListEmployeesSign,
  setValidateEditTargetListName,
  setValidateEditTargetListRevenueFirst,
  setValidateEditTargetListRevenueSecond,
  setValidateEditTargetListRevenueSign,
  setValidateEditTargetListRoles,
  setValidateEditTargetListSectors,
  setValidateEditTargetListStatus
} from "../slice";
import { BannerTargetListDetails } from "./banner";
import { TargetListCompanies } from "./companies";
import { Form } from "./form";
import { TargetListPeople } from "./people";
import { useNavigate } from "react-router-dom";

interface Props {
  showPopupPeople: boolean
  setShowPopupPeople: (action: boolean) => void
  selectedIdsPeople: string[]
  setSelectedIdsPeople: (action: string[]) => void

  peopleFilters: FindAllContactsFilters
  setPeopleFilters: (action: Partial<FindAllContactsFilters>) => void
  setPeoplePage: (action: number) => void
  resetPeopleFilters: () => void
  reloadPeople: () => void
  peopleContacts: FindContactsTargetResponse<ContactType.PERSON>
  peopleStatus: PromiseStatuses

  showPopupCompanies: boolean
  setShowPopupCompanies: (action: boolean) => void
  selectedIdsCompanies: string[]
  setSelectedIdsCompanies: (action: string[]) => void

  companiesFilters: FindAllContactsFilters
  setCompaniesFilters: (action: Partial<FindAllContactsFilters>) => void
  setCompaniesPage: (action: number) => void
  resetCompaniesFilters: () => void
  reloadCompanies: () => void
  companiesContacts: FindContactsTargetResponse<ContactType.COMPANY>
  companiesStatus: PromiseStatuses
}

export function EditTargetList(props: Props) {
  const {
    selectedIdsPeople,
    setSelectedIdsPeople,
    setShowPopupPeople,
    showPopupPeople,
    selectedIdsCompanies,
    setSelectedIdsCompanies,
    setShowPopupCompanies,
    showPopupCompanies,
    peopleFilters,
    peopleStatus,
    peopleContacts,
    setPeopleFilters,
    setPeoplePage,
    resetPeopleFilters,
    setCompaniesFilters,
    setCompaniesPage,
    resetCompaniesFilters,
    companiesFilters,
    companiesContacts,
    companiesStatus,
    reloadCompanies,
    reloadPeople
  } = props
  const dispatch = useAppDispatch();

  const targetListState = useAppSelector((state) => state.targetList);
  const contactsState = useAppSelector((state) => state.contacts);

  const [direction, setDirection] = useState<"left" | "right">("left");

  useEffect(() => {
    dispatch(findTargetListById(targetListState.selectedTargetList!));
    dispatch(setEditTargetListAtecosOptions([]))
    dispatch(setEditTargetListRequestParticularity(""))
    dispatch(setEditParticularitiesDefaultValue(undefined))
  }, []);

  useEffect(() => {
    if (targetListState.validateEditTargetList.status === "successfully") {
      dispatch(editTargetList({ id: targetListState.findTargetListResponse?.id!, data: targetListState.editTargetListRequest }));
      dispatch(setValidateEditTargetListStatus("idle"));
      dispatch(setEditTargetListAtecosOptions([]))
    }
  }, [targetListState.validateEditTargetList.status]);

  useEffect(() => {
    dispatch(setValidateEditTargetListStatus('idle'))
    dispatch(setValidateEditTargetListCategories(false))
    dispatch(setValidateEditTargetListCommercials(false))
    dispatch(setValidateEditTargetListDepartments(false))
    dispatch(setValidateEditTargetListEmployeesFirst(false))
    dispatch(setValidateEditTargetListEmployeesSecond(false))
    dispatch(setValidateEditTargetListEmployeesSign(false))
    dispatch(setValidateEditTargetListName(false))
    dispatch(setValidateEditTargetListRevenueFirst(false))
    dispatch(setValidateEditTargetListRevenueSecond(false))
    dispatch(setValidateEditTargetListRevenueSign(false))
    dispatch(setValidateEditTargetListRoles(false))
    dispatch(setValidateEditTargetListSectors(false))
  }, [])

  useEffect(() => {
    if (targetListState.findTargetListStatus === "successfully") {
      dispatch(setFindTargetListStatus("idle"));
      dispatch(getCounters(targetListState.findTargetListResponse?.id!))
      dispatch(getContactsIds(targetListState.findTargetListResponse?.id!))
      if (!window.location.pathname.includes("edit-project"))
        dispatch(findProjectById(targetListState.findTargetListResponse?.projectId!))
    }
  }, [targetListState.findTargetListStatus]);

  useEffect(() => {
    if (window.location.pathname.includes("specifics")) {
      dispatch(setEditTargetListTabValue(0));
    }
    if (window.location.pathname.includes("people")) {
      dispatch(setEditTargetListTabValue(1));
    }
    if (window.location.pathname.includes("companies")) {
      dispatch(setEditTargetListTabValue(2));
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (targetListState.editTargetListTabValue === 0) {
      navigate(window.location.pathname.replace(/\/people|\/companies/, "/specifics"));
    }
    if (targetListState.editTargetListTabValue === 1) {
      navigate(window.location.pathname.replace(/\/specifics|\/companies/, "/people"));
    }
    if (targetListState.editTargetListTabValue === 2) {
      navigate(window.location.pathname.replace(/\/specifics|\/people/, "/companies"));
    }
  }, [targetListState.editTargetListTabValue]);

  return (
    <div className="h-full max-h-full p-4">
      <BannerTargetListDetails reloadCompanies={() => reloadCompanies()} reloadPeople={() => reloadPeople()} peopleResetFilters={() => resetPeopleFilters()} companiesResetFilters={() => resetCompaniesFilters()} />
      {targetListState.findTargetListStatus === "loading" || contactsState.getCounterStatus === 'loading' ? (
        <Spinner />
      ) : (
        <div className="flex flex-col bg-white rounded-2xl shadow-sm h-full max-h-full">
          <div
            className="h-full"
          >
            <TabPages
              direction={direction}
              setDirection={(value) => setDirection(value)}
              pages={[
                <div key={0} className="max-h-full h-full overflow-auto">
                  <Form />
                </div>,
                <div key={1} className="h-full">
                  <TargetListPeople status={peopleStatus} contacts={peopleContacts} filters={peopleFilters} setFilters={(action) => setPeopleFilters(action)} setPage={(action) => setPeoplePage(action)} resetFilters={() => resetPeopleFilters()} selectedIds={selectedIdsPeople} setSelectedIds={arg => setSelectedIdsPeople(arg)} setShowPopup={arg => setShowPopupPeople(arg)} showPopup={showPopupPeople} reloadContacts={() => reloadPeople()} />
                </div>,
                <div key={2} className="h-full">
                  <TargetListCompanies contacts={companiesContacts} status={companiesStatus} filters={companiesFilters} setFilters={(action) => setCompaniesFilters(action)} setPage={(page) => setCompaniesPage(page)} resetFilters={() => resetCompaniesFilters()} selectedIds={selectedIdsCompanies} setSelectedIds={arg => setSelectedIdsCompanies(arg)} setShowPopup={arg => setShowPopupCompanies(arg)} showPopup={showPopupCompanies} reloadContacts={() => reloadCompanies()} />
                </div>,
              ]}
              value={targetListState.editTargetListTabValue}
              setValue={(e) => {
                dispatch(setEditTargetListTabValue(e));
              }}
            />
          </div>
        </div>
      )}
      {
        !window.location.pathname.includes("edit-project") &&
        <AssociateCompanyModal />
      }
    </div>
  );
}
