import clsx from "clsx";
import { useEffect } from "react";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto";
import { Preferences } from "../../../../../lib/preferences";
import { PreferencesSection } from "../../../../../lib/preferences/dto";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { Spinner } from "../../../../../ui/molecules/spinner";
import { ConfirmModal } from "../../../../../ui/organisms/confirmModal.tsx";
import { NotFound } from "../../../../../ui/organisms/notFound";
import { Pagination } from "../../../../../ui/organisms/pagination";
import { EditPerson } from "../../../../database/people/editPerson";
import {
  ContactStateEnum
} from "../../../../targetList/dto";
import { findTargetListById } from "../../../../targetList/slice";
import { removeContacts, setRestoreContactsStatus } from "../../../contacts/slice";
import { TargetPeopleTable } from "./table";

interface Props {
  showPopup: boolean
  setShowPopup: (action: boolean) => void
  selectedIds: string[]
  setSelectedIds: (action: string[]) => void
  filters: FindAllContactsFilters
  setFilters: (action: Partial<FindAllContactsFilters>) => void
  setPage: (action: number) => void
  resetFilters: () => void
  contacts: FindContactsTargetResponse<ContactType.PERSON>
  reloadContacts: () => void
}

export function TargetPeopleList(props: Props) {
  const { showPopup, setShowPopup, selectedIds, setSelectedIds, filters, setFilters, setPage, contacts, reloadContacts } = props
  const dispatch = useAppDispatch();
  const targetListState = useAppSelector((state) => state.targetList);
  const contactsState = useAppSelector((state) => state.contacts);
  const peopleState = useAppSelector((state) => state.people);
  const databaseState = useAppSelector((state) => state.database);

  useEffect(() => {
    if (
      contactsState.removeContactsStatus === "failed" ||
      contactsState.removeContactsStatus === "successfully"
    ) {
      setShowPopup(false);
    }
  }, [contactsState.removeContactsStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      contactsState.restoreContactsStatus === "failed" ||
      contactsState.restoreContactsStatus === "successfully"
    ) {
      dispatch(setRestoreContactsStatus("idle"));
      if (contactsState.restoreContactsStatus === "successfully") {
        dispatch(findTargetListById(targetListState.findTargetListResponse?.id!));
      }
      timeoutId = setTimeout(() => {
        dispatch(setRestoreContactsStatus("idle"));
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [contactsState.restoreContactsStatus]);

  return (
    <div className="w-full" style={{ height: 'calc(100% - 64px' + (databaseState.filtersActive ? ' - 64px)' : ')') }}>
      <div className={clsx("px-4 h-16 flex items-center justify-between border-b border-b-neutral-100", { visible: databaseState.filtersActive, hidden: !databaseState.filtersActive })}>
        <div className={clsx("flex items-center gap-10")}>
          {
            contactsState.getCounterStatus === 'loading' ?
              <div className="flex items-center w-full justify-center">
                <Spinner />
              </div> :
              <div className="flex items-center gap-2 h-7">
                <div
                  onClick={() => {
                    if (filters.outcome.map(value => value.value).includes(ContactStateEnum.LEADTAKEN))
                      setFilters({ outcome: filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.LEADTAKEN) })
                    else
                      setFilters({ outcome: [...filters.outcome, { value: ContactStateEnum.LEADTAKEN, label: 'Lead preso' }] })
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-green-200", {
                    'border-[2px] border-green-600': filters.outcome.map(value => value.value).includes(ContactStateEnum.LEADTAKEN),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-green-800 text-label-sm font-medium whitespace-nowrap">
                    Lead preso {contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.inList.leadTaken}
                  </span>
                </div>
                <div
                  onClick={() => {
                    if (filters.outcome.map(value => value.value).includes(ContactStateEnum.TORECALL))
                      setFilters({ outcome: filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.TORECALL) })
                    else
                      setFilters({ outcome: [...filters.outcome, { value: ContactStateEnum.TORECALL, label: 'Da richiamare' }] })
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-warning-200", {
                    'border-[2px] border-warning-600': filters.outcome.map(value => value.value).includes(ContactStateEnum.TORECALL),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-warning-800 text-label-sm font-medium whitespace-nowrap">
                    Da richiamare  {contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.inList.toRecall}
                  </span>
                </div>
                <div
                  onClick={() => {
                    if (filters.outcome.map(value => value.value).includes(ContactStateEnum.KO))
                      setFilters({ outcome: filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.KO) })
                    else
                      setFilters({ outcome: [...filters.outcome, { value: ContactStateEnum.KO, label: 'Ko' }] })
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-red-200", {
                    'border-[2px] border-red-600': filters.outcome.map(value => value.value).includes(ContactStateEnum.KO),
                    'hidden': !window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-red-800 text-label-sm font-medium whitespace-nowrap">Ko {contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.targetListBin.ko}</span>
                </div>
                <div
                  onClick={() => {
                    if (filters.outcome.map(value => value.value).includes(ContactStateEnum.INCORRECT))
                      setFilters({ outcome: filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.INCORRECT) })
                    else
                      setFilters({ outcome: [...filters.outcome, { value: ContactStateEnum.INCORRECT, label: 'Info errate' }] })
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-neutral-200", {
                    'border-[2px] border-neutral-600': filters.outcome.map(value => value.value).includes(ContactStateEnum.INCORRECT),
                    'hidden': !window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-neutral-800 text-label-sm font-medium whitespace-nowrap">
                    Info errate {contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.targetListBin.incorrect}
                  </span>
                </div>
                <div
                  onClick={() => {
                    if (filters.outcome.map(value => value.value).includes(ContactStateEnum.DRAFT))
                      setFilters({ outcome: filters.outcome.filter(outcome => outcome.value !== ContactStateEnum.DRAFT) })
                    else
                      setFilters({ outcome: [...filters.outcome, { value: ContactStateEnum.DRAFT, label: 'In attesa' }] })
                  }}
                  className={clsx("cursor-pointer flex items-center h-full px-2 py-1 gap-2 rounded-[4px] bg-brandPrimary-200", {
                    'border-[2px] border-brandPrimary-600': filters.outcome.map(value => value.value).includes(ContactStateEnum.DRAFT),
                    'hidden': window.location.pathname.includes('target-list-bins')
                  })}
                >
                  <span className="text-brandPrimary-800 text-label-sm font-medium whitespace-nowrap">
                    In attesa {contactsState.getCounterResponse?.find(count => count.contactType === ContactType.PERSON)?.dto.inList.draft}
                  </span>
                </div>
              </div>
          }
        </div>
      </div>
      {contacts.data.length === 0 ||
        contacts.data === null ? (
        <div style={{ height: 'calc(100% - 64px - 48px)' }}>
          <NotFound />
        </div>
      ) : (
        <TargetPeopleTable
          contacts={contacts}
          selectedIds={selectedIds}
          setFilters={setFilters}
          filters={filters}
          setSelectedIds={arg => setSelectedIds(arg)}
          setShowPopup={arg => setShowPopup(arg)}
        />
      )}
      {!(contacts.data.length === 0 ||
        contacts.data === null) &&
        <Pagination
          itemsPerPage={filters.itemsPerPage}
          setItemsPerPage={value => setFilters({ itemsPerPage: value })}
          found={contacts.total || 0}
          key={"pagination-people-bottom"}
          pages={contacts.totalPage || 0}
          currentPage={contacts.page || 0}
          setPage={(page) => {
            setPage(page);
          }}
        />
      }
      <Preferences section={PreferencesSection.targetListPersonPreferences} key={PreferencesSection.targetListPersonPreferences} />
      <div
        className={clsx(
          "fixed right-0 bottom-0 z-50 flex-shrink-0 w-[480px] transition-all h-full",
          {
            hidden: peopleState.selectedPerson === undefined,
          }
        )}
      >
        <div className="bg-white shadow-md h-full">
          <EditPerson isInTargetList reloadContacts={() => reloadContacts()} />
        </div>
      </div>
      <ConfirmModal
        loading={contactsState.removeContactsStatus === "loading"}
        title={"Rimuovi persone dalla lista"}
        label={
          "Sei sicuro di voler eliminare le persone selezionate dalla lista?"
        }
        confirmAction={function (): void {
          dispatch(
            removeContacts(selectedIds)
          );
        }}
        onClose={function (): void {
          setSelectedIds([]);
          setShowPopup(false);
        }}
        visible={showPopup}
      />
    </div>
  );
}
