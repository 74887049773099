import { cdAxios } from "../axios";
import { keycloak } from "../keycloak";
import { AtecoDTO } from "./dto";
import { AtecosService } from "./service";

export class AtecosServiceImpl implements AtecosService {
  public findAllAtecos(): Promise<AtecoDTO[]> {
    return cdAxios
      .get("/api/atecos", {
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
