import qs from "qs";
import { cdAxios } from "../../lib/axios";
import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse, FindContactsTargetResponse } from "../../lib/filters/dto";
import { keycloak } from "../../lib/keycloak";
import { contactsFiltersAdapter } from "../utils";
import { ContactsListService } from "./service";

export class ContactsListServiceImpl implements ContactsListService {
  public findContactsTarget(filters: FindAllContactsFilters, contactType: ContactType): Promise<FindContactsTargetResponse<ContactType>> {
    return cdAxios
      .get("/api/contacts", {
        params: contactsFiltersAdapter(filters, contactType),
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
            encode: true,
          });
        },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }

  public findContactsDatabase(filters: FindAllContactsFilters, contactType: ContactType): Promise<FindContactsDatabaseResponse<ContactType>> {
    const url = contactType === ContactType.PERSON ? "/api/person" : "/api/companies"
    return cdAxios
      .get(url, {
        params: { ...contactsFiltersAdapter(filters, contactType) },
        headers: {
          Authorization: "Bearer " + keycloak.token,
          Accept: "*/*",
          ContentType: "application/json",
          "Access-Control-Allow-Headers":
            "accept, authorization, content-type, x-requested-with",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET",
        },
      })
      .then((response) => response.data);
  }
}
