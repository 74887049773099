import React, { useState } from "react";
import "./Button.css";
import {
  ButtonProps,
  buttonIconPositionMap,
  buttonIconSizeMap,
  buttonLabelSizeMap,
  buttonSizeMap,
  buttonVariantKey,
  colorKey,
} from "./Button.types";
import { ChevronIcon } from "../../icons/chevron";
import clsx from "clsx";

const ButtonDropdown: React.FC<ButtonProps> = ({ ...props }) => {
  const [isDarker, setIsDarker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsDarker(true);
  const handleMouseLeave = () => setIsDarker(false);
  const handleFocus = () => setIsDarker(true);
  const handleBlur = () => setIsDarker(false);

  if ("paintWorklet" in CSS) {
    //@ts-ignore
    CSS.paintWorklet.addModule(
      "https://www.unpkg.com/css-houdini-squircle@0.3.0/squircle.min.js"
    );
  }

  return (
    <div className="flex items-center"
      style={{
        ...buttonSizeMap.get(props.size),
        ...colorKey(
          props.color,
          buttonVariantKey('outline', isDarker),
          true
        ).buttonVariant,
        ...buttonIconPositionMap.get(props.iconPosition),
        ...{
          borderRadius: "8px",
          width: props.fullWidth ? "100%" : "",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
          // gap: 8,
          borderWidth: "1px",
          borderStyle: "solid",
          textWrap: 'nowrap',
          cursor: props.disabled ? "default" : "pointer",
          paddingRight: 0
        },
      }}>
      <button
        aria-label="test"
        className={clsx("transition-all flex items-center justify-center gap-2", {
          'flex-row-reverse': props.iconPosition === "right",
          'flex-row': props.iconPosition === "left",
        })}
        onClick={() => props.onClick && props.onClick()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={props.disabled}
      >
        {props.iconPosition !== "off" &&
          props.icon &&
          React.cloneElement(props.icon, {
            color: colorKey(
              props.color,
              buttonVariantKey('outline', isDarker),
              false
            ).buttonIconVariant,
            size: buttonIconSizeMap.get(props.size),
          })}
        {props.label && props.iconPosition !== "only" && (
          <span
            style={{
              ...buttonLabelSizeMap.get(props.size),
              ...{
                color: colorKey(
                  props.color,
                  buttonVariantKey('outline', isDarker),
                  false
                ).buttonIconVariant,
              },
            }}
          >
            {props.label}
          </span>
        )}
      </button>
      <div
        className="h-full w-px ml-4"
        style={{
          backgroundColor: colorKey(
            props.color,
            buttonVariantKey('outline', isDarker),
            false
          ).buttonIconVariant
        }}
      />
      <div
        className="flex-shrink-0 h-full cursor-pointer w-7 flex items-center justify-center"
        onClick={() => setIsOpen(!isOpen)}
        style={{ opacity: !isOpen ? 1 : 0.5 }}
      >
        <ChevronIcon color={colorKey(
          props.color,
          buttonVariantKey('outline', isDarker),
          false
        ).buttonIconVariant} size={16} direction="b" />
      </div>
      {
        isOpen &&
        <div
          className="absolute top-10 right-0 z-10 bg-neutral-50 shadow-xl"
          style={{
            borderRadius: '8px',
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          {props.options.filter(option => !option.isHidden).map((option, index) => (
            <div
              className={clsx('bg-neutral-50 hover:bg-neutral-100')}
              style={{
                ...buttonSizeMap.get(props.size),
                ...buttonIconPositionMap.get(props.iconPosition),
                ...{
                  borderRadius: 6,
                  display: "flex",
                  justifyContent: "center",
                  position: "relative",
                  alignItems: "center",
                  gap: 8,
                  textWrap: 'nowrap',
                  cursor: option.disabled ? "default" : "pointer",
                  padding: 8,
                  opacity: option.disabled ? 0.5 : 1,
                },
              }}
              onClick={() => {
                if (!option.disabled) {
                  option.onClick()
                  setIsOpen(false)
                }
              }}>
              <span
                style={{
                  ...buttonLabelSizeMap.get(props.size),
                  ...{
                    color: colorKey(
                      'gray',
                      buttonVariantKey('outline', true),
                      false
                    ).buttonIconVariant,
                  },
                }}
              >
                {option.label}
              </span>
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default ButtonDropdown;
