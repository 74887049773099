import clsx from "clsx";
import { Filters } from "../../../lib/filters";
import { PeopleHorizontalFilters } from "../../../lib/filters/accordion/horizontalFiltersPerson";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../lib/filters/dto";
import { useAppSelector } from "../../../lib/redux/hooks";
import { PromiseStatuses } from "../../../lib/types";
import { Spinner } from "../../../ui/molecules/spinner";
import { TargetPeopleList } from "../editTargetListDetails/people/list/list";

interface Props {
    showPopup: boolean
    setShowPopup: (action: boolean) => void
    selectedIds: string[]
    setSelectedIds: (action: string[]) => void
    filters: FindAllContactsFilters
    setPage: (action: number) => void
    setFilters: (action: Partial<FindAllContactsFilters>) => void
    resetFilters: () => void
    contacts: FindContactsTargetResponse<ContactType.PERSON>
    status: PromiseStatuses
    reloadContacts: () => void
}

export function TargetPeopleBins(props: Props) {
    const { selectedIds, setSelectedIds, setShowPopup, showPopup, filters, setFilters, setPage, resetFilters, contacts, status, reloadContacts } = props
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.people)

    return (
        <div className="max-h-full w-full flex h-full">
            <div className="flex w-full">
                <div
                    className={clsx("transition-all duration-300 w-[20%] h-full opacity-100 rounded-bl-xl", {
                        flex: databaseState.filtersActive,
                        hidden: !databaseState.filtersActive,
                    })}
                >
                    <Filters
                        contactType={ContactType.PERSON}
                        isInTargetList
                        resetFilters={function (): void {
                            resetFilters()
                        }} filters={filters}
                        updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                            setFilters(newFilters)
                        }} />
                </div>
                <div
                    className={clsx({
                        "w-full": !databaseState.filtersActive,
                        "w-4/5": databaseState.filtersActive,
                    })}
                >
                    {status === "loading" ? (
                        <div className="w-full h-[45%] flex items-center justify-center">
                            <Spinner />
                        </div>
                    ) : (
                        <div className="h-full">
                            <PeopleHorizontalFilters
                                updateFilters={function (newFilters: Partial<FindAllContactsFilters>): void {
                                    setFilters(newFilters)
                                }}
                                filters={filters}
                                isInTargetList={true}
                            />
                            <TargetPeopleList
                                reloadContacts={() => reloadContacts()}
                                contacts={contacts}
                                showPopup={showPopup}
                                setShowPopup={arg => setShowPopup(arg)}
                                selectedIds={selectedIds}
                                setSelectedIds={arg => setSelectedIds(arg)}
                                filters={filters}
                                setFilters={function (action: Partial<FindAllContactsFilters>): void {
                                    setFilters(action)
                                }} setPage={function (action: number): void {
                                    setPage(action)
                                }} resetFilters={function (): void {
                                    resetFilters()
                                }} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}