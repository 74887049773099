import { colors } from "../../ui/colors";
import { ChevronIcon } from "../../ui/icons/chevron";
import { PlusIcon } from "../../ui/icons/plus";
import { Sorting } from "../../ui/organisms/sorting";
import { useAppDispatch } from "../redux/hooks";
import { CreatePreferenceRequest, DatabasePreferences, PreferencesSection } from "./dto";
import { setOption, setOptionsActive, updatePreferences } from "./slice";

interface Props {
  preferences: DatabasePreferences[]
  request: { data: CreatePreferenceRequest; id: string }
  section: PreferencesSection
}

export function ColumnsOptions(props: Props) {
  const { preferences, request, section } = props
  const dispatch = useAppDispatch();

  return (
    <div className="h-full overflow-auto">
      <div className="h-12 flex-shrink-0 px-[16px] py-[12px] flex items-center justify-between border-b-[1px]">
        <div className="flex items-center gap-1">
          <div
            className="cursor-pointer"
            onClick={() => dispatch(setOption(undefined))}
          >
            <ChevronIcon color={colors.neutral[400]} size={16} direction="l" />
          </div>
          <span className="text-label-md text-neutral-800 font-bold">
            Colonne
          </span>
        </div>
        <div
          className="rotate-45 cursor-pointer"
          onClick={() => dispatch(setOptionsActive(false))}
        >
          <PlusIcon color={colors.neutral[400]} size={24} />
        </div>
      </div>
      <div className="flex flex-col gap-1 px-1 py-2 relative overflow-auto mb-4">
        <Sorting
          key={"person-sortings"}
          items={
            preferences.map(
              (item) => ({
                visible: item.visible,
                column: item.column,
                hideable: item.hideable,
              })
            )!
          }
          sortItems={(items) => {
            dispatch(
              updatePreferences({
                ...request,
                data: {
                  ...request.data,
                  [section]: items,
                },
              })
            );
          }}
        />
      </div>
    </div>
  );
}
