import { ContactType, FindAllContactsFilters, FindContactsDatabaseResponse, FindContactsTargetResponse } from "../../lib/filters/dto";
import { ContactsListServiceImpl } from "./serviceImpl";

export interface ContactsListService {
  findContactsTarget(
    filters: FindAllContactsFilters,
    contactType: ContactType
  ): Promise<FindContactsTargetResponse<ContactType>>;
  findContactsDatabase(filters: FindAllContactsFilters, contactType: ContactType): Promise<FindContactsDatabaseResponse<ContactType>>
}

export function NewContactsListService(): ContactsListService {
  return new ContactsListServiceImpl();
}
