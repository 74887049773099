import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { colors } from "../../colors";
import { EyeIcon } from "../../icons/eye";
import { EyeOffIcon } from "../../icons/eyeOff";
import { MenuIcon } from "../../icons/menu";
import { DatabasePreferences } from "../../../lib/preferences/dto";

interface Props {
  id: {
    item: DatabasePreferences;
    uniqueIdentifier: UniqueIdentifier;
  };
  sort: (items: boolean) => void;
}

export function SortableItem(props: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id.uniqueIdentifier });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={"h-8 px-4 py-2 flex items-center justify-between"}
    >
      <div className={"flex items-center gap-2"}>
        <div {...attributes} {...listeners}>
          <MenuIcon color={colors.neutral[600]} size={16} />
        </div>
        <span className="text-label-md font-medium text-neutral-600">
          {props.id.item.column}
        </span>
      </div>
      {props.id.item.hideable && (
        <div
          className="cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.sort(!props.id.item.visible);
          }}
        >
          {props.id.item.visible ? (
            <EyeIcon size={16} color={colors.neutral[600]} />
          ) : (
            <EyeOffIcon size={16} color={colors.neutral[600]} />
          )}
        </div>
      )}
    </div>
  );
}
