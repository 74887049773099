import { FindAllContactsFilters } from "../../../lib/filters/dto";
import { ImportCSVResponseDTO } from "../dto";
import { AssociatePersonToCompany, EditPersonRequest, NewPersonRequest, PersonDTO } from "./dto";
import { PeopleServiceImpl } from "./serviceImpl";

export interface PeopleService {
    deleteBulk(
        filters: FindAllContactsFilters
    ): Promise<void>
    deletePerson(id: string[]): Promise<void>
    findPersonById(id: string): Promise<PersonDTO>
    updatePerson(id: string, data: EditPersonRequest): Promise<void>
    importContact(data: FormData): Promise<ImportCSVResponseDTO>
    associateCompany(data: AssociatePersonToCompany, personId: string): Promise<void>
    getCSV(ids: string[]): Promise<string>
    personCount(): Promise<{ toActivate: number }>
    createPerson(data: NewPersonRequest): Promise<{
        id: string,
        alreadyExists: boolean
    }>
    bulkCSV(request: FindAllContactsFilters): Promise<string>
}

export function NewPeopleService(): PeopleService {
    return new PeopleServiceImpl();
}
