import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Spinner } from "../../ui/molecules/spinner";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Pagination } from "../../ui/organisms/pagination";
import {
  deleteProject,
  setFindProjectResponse,
  setProjectFilterItemsPerPage,
  setProjectFilterPage,
  setSelectedProject
} from "./slice";
import { ProjectTable } from "./table";

export function ProjectsList() {
  const projectsState = useAppSelector((state) => state.projects);
  const dispatch = useAppDispatch();
  const [idToDelete, setIdToDelete] = useState<string | undefined>();

  useEffect(() => {
    if (projectsState.deleteProjectStatus === "successfully") {
      setIdToDelete(undefined);
    }
  }, [projectsState.deleteProjectStatus]);

  useEffect(() => {
    dispatch(setSelectedProject(undefined))
    dispatch(setFindProjectResponse(undefined))
  }, [])

  if (projectsState.findAllStatus === "loading") {
    return (
      <div className="w-full flex items-center justify-center h-[45%]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-[100%] h-full max-h-full flex flex-col"
      style={{ height: 'calc(100% - 64px)' }}
    >
      <ProjectTable setIdToDelete={function (arg?: string): void {
        setIdToDelete(arg)
      }} />
      <Pagination
        itemsPerPage={projectsState.filters.itemsPerPage}
        setItemsPerPage={value => dispatch(setProjectFilterItemsPerPage(value))}
        found={projectsState.findAllResponse?.total || 0}
        pages={projectsState.findAllResponse?.totalPage || 1}
        currentPage={projectsState.filters.page}
        setPage={(page) => dispatch(setProjectFilterPage(page))}
      />
      <ConfirmModal
        title={"Elimina progetto"}
        label={"Sei sicuro di voler eliminare questo progetto?"}
        confirmAction={function (): void {
          idToDelete && dispatch(deleteProject(idToDelete));
        }}
        onClose={function (): void {
          setIdToDelete(undefined);
        }}
        visible={idToDelete !== undefined}
      />
    </div>
  );
}
