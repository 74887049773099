import { formatDate } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { downloadCSVfromBase64 } from "../../../../lib/utils";
import { colors } from "../../../../ui/colors";
import { CheckCircleIcon } from "../../../../ui/icons/checkCircle";
import { DownloadIcon } from "../../../../ui/icons/download";
import Button from "../../../../ui/molecules/button";
import { setImportContactCSVResponse, setImportContactCSVStatus } from "../slice";
import { ContactType } from "../../../../lib/filters/dto";

interface Props {
    contactType: ContactType
}

export function ContactMissed(props: Props) {
    const { contactType } = props

    const contactsState = useAppSelector(state => state.contacts)
    const dispatch = useAppDispatch()

    return (
        <>
            <CheckCircleIcon color={colors.green[500]} size={64} />
            <span className="text-heading-xs text-neutral-800 font-bold">Importazione completata</span>
            <div className="flex items-center justify-center gap-3">
                <span className="text-text-sm text-neutral-600">Contatti importati: <span className="text-text-sm text-neutral-800 font-semibold">{contactsState.importContactResponse?.ids.length}</span></span>
                <span className="text-text-sm text-neutral-600">Non importati: <span className="text-text-sm text-red-500 font-semibold">{contactsState.importContactResponse?.missed.length}</span></span>
            </div>
            <Button
                size={"md"}
                iconPosition={"off"}
                variant={"solid"}
                color={
                    contactsState.importContactStatus === "successfully" ? "green" : "red"
                }
                label="Chiudi modale"
                onClick={() => {
                    dispatch(setImportContactCSVStatus("idle"));
                    dispatch(setImportContactCSVResponse(undefined));
                }}
            />
            <Button
                size={"md"}
                iconPosition={"left"}
                variant={"link"}
                color={"blue"}
                label="Scarica lista contatti non importati"
                icon={<DownloadIcon color={""} size={0} />}
                onClick={() => {
                    downloadCSVfromBase64(contactsState.importContactResponse?.base64CsvMissed!, (contactType === ContactType.COMPANY ? "cd_missed_target_companies_download_" : "cd_missed_target_people_download_") + formatDate(new Date(), "dd_MM_yyyy"))
                }}
            />
        </>
    )
}