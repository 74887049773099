import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { SortIcon } from "../../../ui/icons/sort";
import { TrashIcon } from "../../../ui/icons/trash";
import Avatar from "../../../ui/molecules/avatar";
import Pills from "../../../ui/molecules/pills";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { CustomTable } from "../../../ui/organisms/table";
import { UserRoleMap } from "../dto";
import { setFindAllUsersFiltersOrder, setFindAllUsersFiltersSort, setSelectedUser, setUpdateUserStatus } from "../slice";

interface Props {
    setIdToDelete: (arg?: string) => void
}

export function UsersTable(props: Props) {
    const { setIdToDelete } = props
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()

    return (
        <CustomTable
            tableHeight='calc(100% - 48px)'
            prevOrderHeads={[
                <th className="text-left" />,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Nome
                        <div
                            onClick={() => {
                                dispatch(setFindAllUsersFiltersSort("name"));
                                dispatch(
                                    setFindAllUsersFiltersOrder(!usersState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Cognome
                        <div
                            onClick={() => {
                                dispatch(setFindAllUsersFiltersSort("surname"));
                                dispatch(
                                    setFindAllUsersFiltersOrder(!usersState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Ruolo
                        <div
                            onClick={() => {
                                dispatch(setFindAllUsersFiltersSort("role"));
                                dispatch(
                                    setFindAllUsersFiltersOrder(!usersState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Email
                        <div
                            onClick={() => {
                                dispatch(setFindAllUsersFiltersSort("email"));
                                dispatch(
                                    setFindAllUsersFiltersOrder(!usersState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]">
                        Telefono
                        <div
                            onClick={() => {
                                dispatch(setFindAllUsersFiltersSort("phone"));
                                dispatch(
                                    setFindAllUsersFiltersOrder(!usersState.filters.order)
                                );
                            }}
                            className="cursor-pointer hover: opacity-70"
                        >
                            <SortIcon />
                        </div>
                    </div>
                </th>,
                <th className="text-left">
                    <div className="flex gap-[4px]" />
                </th>
            ]}
            postOrderHeads={[]}
            preferences={[]}
            setPreferences={(items) => { }}
            setOrderFilters={(order) => { }}
            rows={usersState.findAllResponse?.data.map(d => ({
                onClick: () => {
                    dispatch(setUpdateUserStatus("idle"));
                    dispatch(setSelectedUser(d.id));
                },
                key: d.id,
                data: [
                    {
                        item: <td>
                            <Avatar
                                type="single"
                                size="sm"
                                shape="circle"
                                fullName={d.name + " " + d.surname}
                                altTextInitials={d.name[0] + d.surname[0]}
                                imageUrl={
                                    usersState.findUsersAvatarsListResponse.find(
                                        (object) => object.userId === d.id
                                    )?.objectId!
                                }
                            />
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },
                    {
                        item: <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                            {d.name}
                        </td>,
                        group: 'preSort',
                        key: 'Nome referente'
                    },
                    {
                        item: <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                            {d.surname}
                        </td>,
                        group: 'preSort',
                        key: 'Cognome referente'
                    },
                    {
                        item: <td className="max-w-[150px]">
                            <Pills
                                size={"sm"}
                                label={UserRoleMap.get(d.role)!}
                                outline={true}
                                emphasis={false}
                                color={"blue"}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Linkedin'
                    },
                    {
                        item: <td className="max-w-[200px] crop-text text-text-sm font-semibold text-neutral-600">
                            {d.email}
                        </td>,
                        group: 'preSort',
                        key: 'Ruolo'
                    },
                    {
                        item: <td className="max-w-[150px] crop-text text-text-sm font-semibold text-neutral-600">
                            {d.phone}
                        </td>,
                        group: 'preSort',
                        key: 'Data di contatto'
                    },
                    {
                        item: <td
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                            }}
                            className="w-[20px] cursor-pointer">
                            <Dropdown
                                items={[
                                    {
                                        label: "Modifica",
                                        icon: <EditIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setUpdateUserStatus("idle"));
                                            dispatch(setSelectedUser(d.id));
                                        },
                                    },
                                    {
                                        label: "Cancella",
                                        icon: <TrashIcon color={""} size={0} />,
                                        onClick: () => setIdToDelete(d.id),
                                    },
                                ]}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'Esito'
                    },
                ]
            })) ?? []}
        />
    )
}