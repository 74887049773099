import { formatDate } from "date-fns";
import { ContactType, FindAllContactsFilters, FindContactsTargetResponse } from "../../../../../lib/filters/dto";
import { keycloak } from "../../../../../lib/keycloak";
import { targetListPersonColumns } from "../../../../../lib/preferences/dto";
import { updatePreferences } from "../../../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { colors } from "../../../../../ui/colors";
import { CheckCircleIcon } from "../../../../../ui/icons/checkCircle";
import { CircleIcon } from "../../../../../ui/icons/circle";
import { EditIcon } from "../../../../../ui/icons/edit";
import { LinkedinIcon } from "../../../../../ui/icons/linkedin";
import { TrashIcon } from "../../../../../ui/icons/trash";
import { UserPlusIcon } from "../../../../../ui/icons/userPlus";
import Checkbox from "../../../../../ui/molecules/checkbox";
import Pills from "../../../../../ui/molecules/pills";
import { Dropdown } from "../../../../../ui/organisms/dropdown";
import { CustomTable } from "../../../../../ui/organisms/table";
import { setSelectedPerson, setTabValueDetailsPerson } from "../../../../database/people/slice";
import { restoreContacts, setSelectedContact } from "../../../contacts/slice";
import { contactsStatePillMap, NoteDTO } from "../../../dto";

interface Props {
    contacts: FindContactsTargetResponse<ContactType.PERSON>
    selectedIds: string[]
    setSelectedIds: (arg: string[]) => void
    setShowPopup: (arg: boolean) => void
    filters: FindAllContactsFilters
    setFilters: (arg: Partial<FindAllContactsFilters>) => void
}

export function TargetPeopleTable(props: Props) {
    const { selectedIds, setSelectedIds, setShowPopup, setFilters, filters, contacts } = props
    const preferencesState = useAppSelector(state => state.preferences)
    const dispatch = useAppDispatch()

    function lastNote(notes: NoteDTO[] | null): string {
        if (!notes || notes.length <= 0) {
            return '-'
        } if (notes && notes.length > 0) {
            return notes[notes?.length - 1].note
        }
        else return '-'
    }
    return (
        <CustomTable
            tableHeight='calc(100% - 48px)'
            prevOrderHeads={[
                <th className="text-left w-10">
                    <Checkbox
                        shape={"square"}
                        checked={
                            selectedIds.length ===
                            contacts.data.length
                        }
                        onCheck={function (value: boolean): void {
                            if (
                                selectedIds.length ===
                                contacts.data.length
                            ) {
                                setSelectedIds([]);
                            } else {
                                setSelectedIds(
                                    contacts.data?.map(
                                        (person) => person.id
                                    )!
                                );
                            }
                        }}
                    />
                </th>
            ]}
            postOrderHeads={[
                <th className="text-left">
                    <div className="flex gap-[4px]" />
                </th>
            ]}
            preferences={preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.map(pref => ({ ...pref, order: targetListPersonColumns.find(col => col.column === pref.column)?.order })) ?? []}
            setPreferences={(items) => {
                dispatch(
                    updatePreferences({
                        data: {
                            owner: preferencesState.findAllResponse?.data[0].owner!,
                            personPreferences: preferencesState.findAllResponse?.data[0]
                                .personPreferences!,
                            companyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .companyPreferences!,
                            targetListPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListPreferences!,
                            targetListCompanyPreferences:
                                preferencesState.findAllResponse?.data[0]
                                    .targetListCompanyPreferences!,
                            targetListPersonPreferences: items
                        },
                        id: preferencesState.findAllResponse?.data[0].id!,
                    })
                );
            }}
            setOrderFilters={(order) => {
                setFilters({ order: !filters.order, sort: order })
            }}
            rows={contacts.data.map(d => ({
                key: d.id, data: [
                    {
                        item: <td className="text-text-sm font-semibold text-neutral-600">
                            <Checkbox
                                shape={"square"}
                                checked={selectedIds.includes(d.id)}
                                onCheck={function (value: boolean): void {
                                    if (selectedIds.includes(d.id)) {
                                        setSelectedIds([
                                            ...selectedIds.filter((id) => id !== d.id),
                                        ]);
                                    } else {
                                        setSelectedIds([...selectedIds, d.id]);
                                    }
                                }}
                            />
                        </td>,
                        group: 'preSort',
                        key: 'checkbox'
                    },
                    {
                        item: <td
                            onClick={() => {
                                dispatch(setSelectedContact(d.id))
                                dispatch(setSelectedPerson(d.contact.id))
                                dispatch(setTabValueDetailsPerson('form'))
                            }}
                        >
                            <div
                                className="cursor-pointer crop-text text-text-sm font-semibold text-brandPrimary-600"
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Nome referente')?.width ?? 150 }}
                            >
                                {(d.contact.name ?? "-")}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Nome referente'
                    },
                    {
                        item: <td
                            onClick={() => {
                                dispatch(setSelectedContact(d.id))
                                dispatch(setSelectedPerson(d.contact.id))
                                dispatch(setTabValueDetailsPerson('form'))
                            }}
                        >
                            <div
                                className="cursor-pointer crop-text text-text-sm font-semibold text-brandPrimary-600"
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Cognome referente')?.width ?? 150 }}
                            >
                                {(d.contact.surname ?? "-")}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Cognome referente'
                    },
                    {
                        item: <td>
                            {
                                d.contact.linkedinProfile ?
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        href={d.contact.linkedinProfile ?? undefined}
                                        className="flex items-center"
                                    >
                                        <LinkedinIcon
                                            color={
                                                d.contact.linkedinProfile
                                                    ? colors.neutral[400]
                                                    : colors.neutral[200]
                                            }
                                            size={20}
                                        />
                                    </a> : <span className="text-text-sm crop-text font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Linkedin')?.width ?? 150 }}>-</span>
                            }
                        </td>,
                        group: 'sort',
                        key: 'Linkedin'
                    },
                    {
                        item: <td>
                            <div className="text-text-sm crop-text font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Ruolo')?.width ?? 150 }}>
                                {d.contact.role ?? "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Ruolo'
                    },
                    {
                        item: <td>
                            <div
                                className="text-text-sm crop-text text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Data di contatto')?.width ?? 150 }}
                            >
                                {d.lastContactDate ? formatDate(new Date(d.lastContactDate!), 'dd/MM/yyyy hh:mm') : "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Data di contatto'
                    },
                    {
                        item: <td>
                            <div
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Esito')?.width ?? 150 }}>
                                {
                                    d.outcome ?
                                        <Pills
                                            size={"sm"}
                                            label={contactsStatePillMap.get(d.outcome)?.label! + (d.outcomeInfo ? (" | " + d.outcomeInfo) : '') + ((d.outcomeInfo === 'Richiamo' || d.outcomeInfo === 'Decisore assente') && d.callAttempts && d.callAttempts > 0 ? (" (" + d.callAttempts + ")") : '')}
                                            outline={false}
                                            emphasis={false}
                                            color={contactsStatePillMap.get(d.outcome)?.color ?? 'blue'}
                                        />
                                        : '-'
                                }
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Esito'
                    },
                    {
                        item: <td>
                            <div
                                className="text-text-sm crop-text text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Note')?.width ?? 150 }}
                            >
                                {lastNote(d.notes)}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Note'
                    },
                    {
                        item: <td>
                            {d.contact.phones ? (
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={"tel:" + d.contact.phones}
                                >
                                    <div className="text-text-sm font-semibold text-neutral-600 crop-text" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Smartphone')?.width ?? 100 }}>
                                        {d.contact.phones}
                                    </div>
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>,
                        group: 'sort',
                        key: 'Smartphone'
                    },
                    {
                        item: <td>
                            {d.contact.emails ? (
                                <a
                                    rel="noreferrer"
                                    target="_blank"
                                    href={"mailto:" + d.contact.emails}
                                >
                                    <div className="text-text-sm font-semibold text-neutral-600 crop-text" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Email')?.width ?? 200 }}>
                                        {d.contact.emails}
                                    </div>
                                </a>
                            ) : (
                                "-"
                            )}
                        </td>,
                        group: 'sort',
                        key: 'Email'
                    },
                    {
                        item: <td
                            onClick={() => {
                                dispatch(setTabValueDetailsPerson('company'))
                                dispatch(setSelectedContact(d.id))
                                dispatch(setSelectedPerson(d.contact.id))
                            }}
                        >
                            <div
                                className="cursor-pointer crop-text text-text-sm font-semibold text-brandPrimary-600"
                                style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Azienda')?.width ?? 200 }}
                            >
                                {d.contact.companyName ?? "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Azienda'
                    },
                    {
                        item: <td>
                            <div
                                className="text-text-sm font-semibold text-neutral-600" style={{ width: preferencesState.findAllResponse?.data[0].targetListPersonPreferences?.find(col => col.column === 'Reparto')?.width ?? 150 }}
                            >
                                {d.contact.department ?? "-"}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Reparto'
                    },
                    {
                        item: <td className="h-full">
                            <div className="flex justify-center items-center">
                                {d.contact.privacy ? (
                                    <CheckCircleIcon color={colors.neutral[400]} size={20} />
                                ) : (
                                    <CircleIcon color={colors.neutral[400]} size={20} />
                                )}
                            </div>
                        </td>,
                        group: 'sort',
                        key: 'Privacy'
                    },
                    {
                        item: <td className="w-[20px] cursor-pointer">
                            <Dropdown
                                items={[
                                    {
                                        label: "Modifica",
                                        icon: <EditIcon color={""} size={0} />,
                                        onClick: () => {
                                            dispatch(setTabValueDetailsPerson('form'))
                                            dispatch(setSelectedContact(d.id))
                                            dispatch(setSelectedPerson(d.contact.id))
                                        },
                                    },
                                    (
                                        !(keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) &&
                                            d.deletedAt !== null ?
                                            {
                                                label: "Ripristina",
                                                icon: <UserPlusIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(restoreContacts([d.id]))
                                                },
                                            } :
                                            null
                                    ),
                                    ((keycloak.hasRealmRole('customer') || keycloak.hasRealmRole('collaborator')) ? null : {
                                        label: "Cancella",
                                        icon: <TrashIcon color={""} size={0} />,
                                        onClick: () => {
                                            setSelectedIds([d.id]);
                                            setShowPopup(true);
                                        },
                                    }),
                                ]}
                            />
                        </td>,
                        group: 'postSort',
                        key: 'menu'
                    }
                ]
            })) ?? []}
        />
    )
}